<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 8V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V10C20 8.89543 19.1046 8 18 8H4ZM9.82659 2C11.1706 2 12.4115 2.72014 13.0783 3.88705C13.2635 4.21114 13.4659 4.52438 13.6843 4.82549C13.986 5.24119 14.3183 5.6338 14.6785 6H18C20.2091 6 22 7.79086 22 10V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V5C2 3.34315 3.34315 2 5 2H9.82659ZM4 6V5C4 4.44772 4.44772 4 5 4H9.82659C10.4529 4 11.0311 4.33557 11.3418 4.87932C11.5557 5.25363 11.7888 5.6158 12.0399 5.96447C12.0484 5.97633 12.057 5.98817 12.0655 6H4Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.84 6.60001H15.02C14.56 6.60001 14.13 6.36 13.9 5.97L13.24 4.88C12.74 4.04 11.82 3.51999 10.84 3.51999H4.81C3.26 3.51999 2 4.77999 2 6.32999V17.32C2 19.06 3.42 20.48 5.16 20.48H18.84C20.58 20.48 22 19.06 22 17.32V9.76001C22 8.02001 20.58 6.60001 18.84 6.60001ZM3.5 6.32999C3.5 5.60999 4.09 5.01999 4.81 5.01999H10.84C11.29 5.01999 11.72 5.25999 11.96 5.64999L12.53 6.60001H3.5V6.32999Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Folder",
};
</script>
