<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 11.5C13 10.9477 12.5523 10.5 12 10.5C11.4477 10.5 11 10.9477 11 11.5V12.5H10C9.44772 12.5 9 12.9477 9 13.5C9 14.0523 9.44772 14.5 10 14.5H11V15.5C11 16.0523 11.4477 16.5 12 16.5C12.5523 16.5 13 16.0523 13 15.5V14.5H14C14.5523 14.5 15 14.0523 15 13.5C15 12.9477 14.5523 12.5 14 12.5H13V11.5Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 8V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V10C20 8.89543 19.1046 8 18 8H4ZM9.82659 2C11.1706 2 12.4115 2.72014 13.0783 3.88705C13.2635 4.21114 13.4659 4.52438 13.6843 4.82549C13.986 5.24119 14.3183 5.6338 14.6785 6H18C20.2091 6 22 7.79086 22 10V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V5C2 3.34315 3.34315 2 5 2H9.82659ZM4 6V5C4 4.44772 4.44772 4 5 4H9.82659C10.4529 4 11.0311 4.33557 11.3418 4.87932C11.5557 5.25363 11.7888 5.6158 12.0399 5.96447C12.0484 5.97633 12.057 5.98817 12.0655 6H4Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.84 6.61002H15.01C14.56 6.61002 14.13 6.37002 13.89 5.97002L13.24 4.89002C12.74 4.05002 11.82 3.52002 10.84 3.52002H4.81C3.26 3.52002 2 4.78002 2 6.33002V7.36002V9.41002V9.77002V17.32C2 19.07 3.42 20.48 5.16 20.48H18.84C20.58 20.48 22 19.07 22 17.32V9.77002C22 8.03002 20.58 6.61002 18.84 6.61002ZM10 12.8H11.25V11.55C11.25 11.13 11.59 10.8 12 10.8C12.41 10.8 12.75 11.13 12.75 11.55V12.8H14C14.41 12.8 14.75 13.13 14.75 13.55C14.75 13.96 14.41 14.3 14 14.3H12.75V15.55C12.75 15.96 12.41 16.3 12 16.3C11.59 16.3 11.25 15.96 11.25 15.55V14.3H10C9.59 14.3 9.25 13.96 9.25 13.55C9.25 13.13 9.59 12.8 10 12.8ZM3.5 6.33002C3.5 5.61002 4.09 5.02002 4.81 5.02002H10.84C11.29 5.02002 11.72 5.27002 11.96 5.66002L12.53 6.61002H3.5V6.33002Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MedicalFolder",
};
</script>
