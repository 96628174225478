<template>
  <v-app class="bg-basics-20">
    <v-app-bar v-if="showNavbar" app dense color="var(--primary-pressed)">
      <v-tabs v-model="tab">
        <template v-for="(item, index) in tabs">
          <v-tab :ref="item.ref" :key="index" :to="item.to" class="navbar">
            <span class="text-body-1 font-demi-bold">{{ item.name }}</span>
          </v-tab>
        </template>
      </v-tabs>
      <v-menu offset-y min-width="150px" max-width="250">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            fab
            icon
            small
            v-bind="attrs"
            class="my-auto"
            v-on="on"
          >
            <icon name="user-circle" color="basics-10" size="30" />
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item class="no-hover-color" @click="goToAccount">
            <div class="d-inline-flex">
              <icon
                name="user-circle"
                color="basics-90"
                outline
                size="16"
                class="my-auto mr-1"
              />
              <span class="basics-90">{{ t("Profile") }}</span>
            </div>
          </v-list-item>
          <v-list-item class="no-hover-color" @click="logout">
            <div class="d-inline-flex">
              <icon
                name="logout"
                color="basics-90"
                outline
                size="16"
                class="my-auto mr-1"
              />
              <span class="basics-90">{{ t("Logout") }}</span>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <snack-bar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "@/components/design_system/SnackBar";

export default {
  name: "App",

  components: { SnackBar },

  data: () => ({
    tab: "",
    tabs: [
      { name: "Hospitals", to: { name: "Hospitals" } },
      { name: "Indicators", to: { name: "Indicators" } },
      { name: "Settings", to: { name: "Settings" } },
      { name: "Administration", to: { name: "AdministrationUsers" } },
    ],
  }),

  computed: {
    showNavbar() {
      return !this.$route.meta.disableNavBar;
    },
  },

  mounted() {
    this.$store.dispatch("auth/initSession");
  },

  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .finally(() => this.$router.push({ name: "Login" }));
    },

    goToAccount() {
      this.tab = -1;
      this.$router.push({ name: "Account" });
    },
  },
};
</script>
