<template>
  <svg
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.53 2H18.48C20.42 2 22 3.58 22 5.53V18.47C22 20.42 20.42 22 18.48 22H5.53C3.58 22 2 20.42 2 18.47V5.53C2 3.58 3.58 2 5.53 2ZM5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8H19C19.5523 8 20 7.55228 20 7C20 6.44772 19.5523 6 19 6H5Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Category",
};
</script>
