<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4.00002C7.58172 4.00002 4 7.58174 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58174 16.4183 4.00002 12 4.00002ZM2 12C2 6.47717 6.47715 2.00002 12 2.00002C17.5228 2.00002 22 6.47717 22 12C22 17.5229 17.5228 22 12 22C6.47715 22 2 17.5229 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2889 15.2972C11.4853 15.1017 11.7256 15 12 15C12.2667 15 12.5022 15.0973 12.6979 15.2837C12.8977 15.474 13 15.7134 13 15.9896C13 16.2649 12.8985 16.5055 12.7026 16.7013C12.512 16.8985 12.274 17 12 17C11.8666 17 11.7372 16.9739 11.6132 16.9223L11.6086 16.9204C11.4949 16.8698 11.3926 16.8018 11.3027 16.7168C11.2104 16.6297 11.1363 16.5263 11.0798 16.4088L11.0773 16.4036L11.0751 16.3983C11.0246 16.2758 11 16.1459 11 16.0104C11 15.7372 11.0966 15.4969 11.2859 15.3003L11.2889 15.2972Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8779 7.18898C11.2441 7.06235 11.6351 7 12.0492 7C12.4373 7 12.8061 7.05799 13.1541 7.17551C13.5116 7.28492 13.8274 7.45437 14.0985 7.68509C14.3809 7.9097 14.6017 8.19011 14.7605 8.52313C14.9224 8.86286 15 9.24778 15 9.67228C15 9.95826 14.9685 10.2172 14.9012 10.4458C14.8355 10.6689 14.7411 10.8753 14.6174 11.0638C14.4968 11.2476 14.35 11.4243 14.1783 11.5945C14.0207 11.7506 13.8423 11.9143 13.6433 12.0857L13.6413 12.0874C13.476 12.2266 13.3418 12.3475 13.2376 12.4505C13.1477 12.5474 13.0745 12.6419 13.0168 12.7338C12.9729 12.8159 12.9404 12.9044 12.9195 13.0002L12.9172 13.0485C12.8916 13.5812 12.4515 14 11.9173 14C11.3646 14 10.9025 13.5485 10.9276 12.9839C10.9303 12.9228 10.9335 12.8659 10.9372 12.8186C10.9405 12.7773 10.9453 12.7266 10.9546 12.6882C10.9858 12.5595 11.0394 12.3595 11.1062 12.1987L11.1106 12.188C11.1859 12.0295 11.2797 11.8771 11.3907 11.7304L11.3962 11.7232C11.5146 11.5789 11.658 11.4285 11.8249 11.2718L12.4042 10.7235C12.5496 10.5946 12.6711 10.4516 12.7694 10.2943L12.7735 10.2877C12.8622 10.1559 12.9125 9.98854 12.9125 9.77337C12.9125 9.50028 12.8264 9.2863 12.6595 9.11435L12.6531 9.10778C12.504 8.94165 12.2813 8.8441 11.9471 8.8441C11.7826 8.8441 11.6384 8.87579 11.5108 8.93567L11.5057 8.93806C11.3877 8.98999 11.2815 9.06376 11.1863 9.16152L10.7788 9.77274C10.5454 10.1227 10.1129 10.2829 9.70734 10.1695C9.25577 10.0432 8.91396 9.59247 9.01916 9.09612C9.0543 8.93027 9.10489 8.76548 9.18827 8.60462L9.18936 8.60252C9.37285 8.25715 9.60857 7.96331 9.89692 7.72374C10.1838 7.48537 10.5115 7.30726 10.8779 7.18898Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11 16.013C11 15.7359 11.0958 15.4978 11.2875 15.2987C11.4875 15.0996 11.725 15 12 15C12.2667 15 12.5 15.0952 12.7 15.2857C12.9 15.4762 13 15.71 13 15.987C13 16.2641 12.9 16.5022 12.7 16.7013C12.5083 16.9004 12.275 17 12 17C11.8667 17 11.7375 16.974 11.6125 16.9221C11.4958 16.8701 11.3917 16.8009 11.3 16.7143C11.2083 16.6277 11.1333 16.5238 11.075 16.4026C11.025 16.2814 11 16.1515 11 16.013ZM11.1655 12.9033C11.1983 12.7679 11.2519 12.5693 11.3156 12.4159C11.3884 12.2624 11.4794 12.1135 11.5885 11.9691C11.7068 11.8246 11.8524 11.6712 12.0252 11.5087L12.6393 10.9265C12.8121 10.773 12.9577 10.6015 13.0759 10.412C13.2033 10.2224 13.267 9.99226 13.267 9.72147C13.267 9.36944 13.1533 9.07608 12.9258 8.84139C12.7075 8.59768 12.3936 8.47582 11.9843 8.47582C11.775 8.47582 11.584 8.51644 11.4111 8.59768C11.2474 8.66989 11.1018 8.77369 10.9745 8.90909L10.5264 9.58226C10.3367 9.86711 9.98526 9.99747 9.65575 9.90516C9.27894 9.79961 9.01573 9.43044 9.09674 9.0476C9.13122 8.88461 9.17914 8.73089 9.25509 8.58414C9.43704 8.24113 9.66902 7.95229 9.95103 7.7176C10.233 7.48291 10.556 7.3069 10.9199 7.18956C11.2838 7.06319 11.675 7 12.0934 7C12.4846 7 12.8531 7.05867 13.1987 7.17601C13.5535 7.28433 13.8628 7.45132 14.1267 7.67698C14.3996 7.89362 14.6134 8.16441 14.768 8.48936C14.9227 8.81431 15 9.18891 15 9.61315C15 9.902 14.9682 10.1547 14.9045 10.3714C14.8408 10.588 14.7498 10.7866 14.6316 10.9671C14.5133 11.1476 14.3677 11.3237 14.1949 11.4952C14.0311 11.6576 13.8447 11.8291 13.6354 12.0097C13.4535 12.1631 13.3034 12.2985 13.1851 12.4159C13.0759 12.5332 12.985 12.6505 12.9122 12.7679C12.8485 12.8852 12.803 13.0116 12.7757 13.147L12.7723 13.2185C12.7513 13.656 12.3904 14 11.9524 14C11.4963 14 11.1232 13.628 11.1434 13.1724C11.1491 13.044 11.1563 12.9412 11.1655 12.9033Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "InfoCircle",
};
</script>
