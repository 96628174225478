import { ApplicationTypes } from "@/services/resources";
import { CrudStore } from "@/store/crud";

const state = {
  current: {},
};
const getters = {};

const mutations = {
  setCurrent(state, object) {
    state.current = object;
  },
};

export const application_types = CrudStore(
  ApplicationTypes,
  state,
  getters,
  mutations,
  {}
);
