<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.81215 2C3.64613 2 2 3.85204 2 6.00001C2 7.17259 2.49056 8.25696 3.28845 9C2.49056 9.74305 2 10.8274 2 12C2 14.148 3.64614 16 5.81215 16H18.1878C20.3538 16 22 14.148 22 12C22 10.8274 21.5094 9.74305 20.7115 9.00001C21.5094 8.25697 22 7.17259 22 6.00001C22 3.85203 20.3538 2 18.1878 2H5.81215ZM18.1889 8C19.1301 7.99939 20 7.16677 20 6.00001C20 4.83281 19.1294 4 18.1878 4H5.81215C4.87057 4 4 4.83281 4 6.00001C4 7.1672 4.87056 8 5.81215 8H18.1878L18.1889 8ZM5.81215 10C4.87057 10 4 10.8328 4 12C4 13.1672 4.87056 14 5.81215 14H18.1878C19.1294 14 20 13.1672 20 12C20 10.8328 19.1294 10 18.1878 10H5.81215ZM14 11C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H14ZM7.01478 13C6.73892 13 6.49261 12.9026 6.30542 12.7077C6.10837 12.5128 6 12.2692 6 11.9963C6 11.7333 6.10837 11.4799 6.30542 11.285C6.34483 11.246 6.3941 11.2071 6.45321 11.1681C6.50247 11.1291 6.56158 11.0999 6.62069 11.0804C6.67981 11.0512 6.73892 11.0317 6.80789 11.0219C6.93597 10.9927 7.07389 10.9927 7.20197 11.0219C7.26109 11.0317 7.3202 11.0512 7.38917 11.0804C7.44828 11.0999 7.49754 11.1291 7.55665 11.1681L7.70443 11.285C7.7931 11.3825 7.86208 11.4994 7.93104 11.6163C7.9803 11.7333 8 11.8697 8 11.9963C8 12.2692 7.90147 12.5128 7.70443 12.7077C7.53694 12.8928 7.29064 13 7.01478 13ZM13 6C13 5.44772 13.4477 5 14 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H14C13.4477 7 13 6.55228 13 6ZM6.62136 6.92227C6.73786 6.97085 6.87379 7 7 7C7.13592 7 7.27185 6.97085 7.38836 6.92227C7.50486 6.86398 7.62136 6.79597 7.70873 6.70853C7.90291 6.51421 8 6.27133 8 5.99929C8 5.87299 7.98059 5.73695 7.93205 5.62036C7.87379 5.49406 7.79611 5.38719 7.70873 5.29004C7.42718 5.00828 6.99029 4.92084 6.62136 5.07629C6.50485 5.13458 6.38835 5.20259 6.30097 5.29004C6.19417 5.38719 6.12622 5.50378 6.07767 5.62036C6.02913 5.73695 6 5.87299 6 5.99929C6 6.27133 6.10679 6.51421 6.30097 6.70853C6.38835 6.80568 6.50485 6.87369 6.62136 6.92227Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 17.0039C12.4142 17.0039 12.75 17.3397 12.75 17.7539V20.1893L13.4697 19.4697C13.7626 19.1768 14.2374 19.1768 14.5303 19.4697C14.8232 19.7626 14.8232 20.2374 14.5303 20.5303L12.5307 22.53C12.5306 22.5301 12.5304 22.5302 12.5303 22.5303C12.2374 22.8232 11.7626 22.8232 11.4697 22.5303L9.46967 20.5303C9.17678 20.2374 9.17678 19.7626 9.46967 19.4697C9.76256 19.1768 10.2374 19.1768 10.5303 19.4697L11.25 20.1893V17.7539C11.25 17.3397 11.5858 17.0039 12 17.0039Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14.72H17C17.3998 14.7434 17.8002 14.6848 18.1766 14.5478C18.553 14.4109 18.8974 14.1985 19.1888 13.9236C19.4801 13.6488 19.7123 13.3173 19.8709 12.9496C20.0296 12.5818 20.1114 12.1855 20.1114 11.785C20.1114 11.3845 20.0296 10.9882 19.8709 10.6204C19.7123 10.2527 19.4801 9.9212 19.1888 9.64637C18.8974 9.37154 18.553 9.15913 18.1766 9.02219C17.8002 8.88525 17.3998 8.82665 17 8.85H7C6.60016 8.82665 6.19977 8.88525 5.82339 9.02219C5.447 9.15913 5.10257 9.37154 4.81122 9.64637C4.51987 9.9212 4.28775 10.2527 4.1291 10.6204C3.97045 10.9882 3.88861 11.3845 3.88861 11.785C3.88861 12.1855 3.97045 12.5818 4.1291 12.9496C4.28775 13.3173 4.51987 13.6488 4.81122 13.9236C5.10257 14.1985 5.447 14.4109 5.82339 14.5478C6.19977 14.6848 6.60016 14.7434 7 14.72ZM7.75 10.88C7.99135 10.88 8.22281 10.9759 8.39347 11.1465C8.56413 11.3172 8.66 11.5487 8.66 11.79C8.66 12.0313 8.56413 12.2628 8.39347 12.4335C8.22281 12.6041 7.99135 12.7 7.75 12.7C7.50774 12.6974 7.2763 12.5993 7.10592 12.427C6.93554 12.2548 6.83999 12.0223 6.84 11.78C6.84264 11.5404 6.93967 11.3115 7.11004 11.143C7.28042 10.9745 7.51038 10.88 7.75 10.88ZM17.52 11.78C17.52 11.955 17.4505 12.1229 17.3267 12.2467C17.2029 12.3705 17.035 12.44 16.86 12.44H14.42C14.2458 12.4374 14.0794 12.3671 13.9562 12.2438C13.8329 12.1206 13.7626 11.9542 13.76 11.78C13.7626 11.6058 13.8329 11.4394 13.9562 11.3162C14.0794 11.1929 14.2458 11.1226 14.42 11.12H16.86C17.0342 11.1226 17.2006 11.1929 17.3238 11.3162C17.4471 11.4394 17.5174 11.6058 17.52 11.78Z"
      :fill="$parent.getColor"
    />
    <path
      d="M14.91 18.37C14.7758 18.2405 14.5965 18.1681 14.41 18.1681C14.2235 18.1681 14.0442 18.2405 13.91 18.37L12.76 19.52V16.29C12.76 16.1941 12.7411 16.0992 12.7044 16.0106C12.6677 15.9221 12.614 15.8416 12.5462 15.7738C12.4784 15.706 12.3979 15.6523 12.3093 15.6156C12.2208 15.5789 12.1259 15.56 12.03 15.56C11.9341 15.56 11.8392 15.5789 11.7506 15.6156C11.6621 15.6523 11.5816 15.706 11.5138 15.7738C11.446 15.8416 11.3922 15.9221 11.3556 16.0106C11.3189 16.0992 11.3 16.1941 11.3 16.29V19.52L10.13 18.37C10.0733 18.2742 9.99546 18.1926 9.90241 18.1315C9.80936 18.0704 9.70358 18.0314 9.59314 18.0175C9.4827 18.0035 9.37054 18.015 9.26523 18.0511C9.15992 18.0872 9.06425 18.1468 8.98554 18.2256C8.90683 18.3043 8.84716 18.3999 8.8111 18.5052C8.77504 18.6106 8.76354 18.7227 8.77747 18.8332C8.79141 18.9436 8.83042 19.0494 8.89152 19.1424C8.95262 19.2355 9.03419 19.3133 9.12999 19.37L11.52 21.76C11.6437 21.898 11.8153 21.9838 12 22C12.194 21.9999 12.3803 21.9246 12.52 21.79L14.91 19.4C15.0438 19.2619 15.1186 19.0772 15.1186 18.885C15.1186 18.6928 15.0438 18.508 14.91 18.37Z"
      :fill="$parent.getColor"
    />
    <path
      d="M7 7.88H17C17.7797 7.88 18.5275 7.57025 19.0789 7.01889C19.6302 6.46754 19.94 5.71974 19.94 4.94C19.94 4.16026 19.6302 3.41246 19.0789 2.86111C18.5275 2.30975 17.7797 2 17 2L7 2C6.22026 2 5.47246 2.30975 4.9211 2.86111C4.36975 3.41246 4.06 4.16026 4.06 4.94C4.06 5.71974 4.36975 6.46754 4.9211 7.01889C5.47246 7.57025 6.22026 7.88 7 7.88ZM7.75 4C7.92998 4 8.10592 4.05337 8.25557 4.15336C8.40522 4.25335 8.52185 4.39548 8.59073 4.56176C8.6596 4.72804 8.67762 4.91101 8.64251 5.08753C8.6074 5.26406 8.52073 5.4262 8.39346 5.55347C8.2662 5.68073 8.10405 5.7674 7.92753 5.80251C7.75101 5.83763 7.56804 5.81961 7.40176 5.75073C7.23548 5.68185 7.09335 5.56522 6.99336 5.41557C6.89337 5.26592 6.84 5.08998 6.84 4.91C6.84 4.66865 6.93587 4.43719 7.10653 4.26653C7.27719 4.09587 7.50865 4 7.75 4ZM17.52 4.91C17.52 5.08504 17.4505 5.25292 17.3267 5.37669C17.2029 5.50046 17.035 5.57 16.86 5.57H14.42C14.245 5.57 14.0771 5.50046 13.9533 5.37669C13.8295 5.25292 13.76 5.08504 13.76 4.91C13.76 4.73496 13.8295 4.56708 13.9533 4.44331C14.0771 4.31954 14.245 4.25 14.42 4.25H16.86C16.9493 4.24991 17.0376 4.26792 17.1197 4.30295C17.2018 4.33798 17.2759 4.38929 17.3376 4.45379C17.3993 4.51829 17.4473 4.59464 17.4786 4.67821C17.51 4.76178 17.5241 4.85084 17.52 4.94V4.91Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ServerDownload",
};
</script>
