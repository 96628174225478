<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4.00113L7.00001 4.00042C7.47713 4.01806 8.04898 4.09411 8.66345 4.2788L8.66344 4.27882L8.66982 4.28069C9.70732 4.58502 10.4873 5.08495 11 5.4879V16.4644C10.5646 16.1407 10.0494 15.8341 9.44675 15.5876L9.44461 15.5867C8.52587 15.2134 7.66077 15.0915 7 15.0581V4.00113ZM9.23595 2.36249C10.6528 2.77867 11.6921 3.4736 12.3419 4H18.9957C20.641 4 22 5.33637 22 6.99442V16.9958C22 18.6476 20.6473 20 18.9957 20H12.013C12.0043 20.0001 11.9957 20.0001 11.987 20H5.00432C3.35331 20 2 18.6488 2 16.9972V7.00277C2 5.35259 3.35095 4.00235 5 4C5.00062 2.88904 5.90959 1.96172 7.05712 2.00118L7.05772 2.00121C7.6877 2.02325 8.43481 2.12203 9.23595 2.36249ZM13 18V6H18.9957C19.5493 6 20 6.45373 20 6.99442V16.9958C20 17.5428 19.5429 18 18.9957 18H13ZM9.71073 18C9.41815 17.7927 9.07941 17.5983 8.69075 17.4392C8.03674 17.1736 7.40659 17.0813 6.90038 17.0556C5.80908 17.0117 5 16.1037 5 15.0657V6.00001C4.45402 6.0024 4 6.45861 4 7.00277V16.9972C4 17.5428 4.45642 18 5.00432 18H9.71073ZM15 7C14.4477 7 14 7.44771 14 8C14 8.55228 14.4477 9 15 9H18C18.5523 9 19 8.55228 19 8C19 7.44771 18.5523 7 18 7H15ZM14 12C14 11.4477 14.4477 11 15 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H15C14.4477 13 14 12.5523 14 12ZM15 15C14.4477 15 14 15.4477 14 16C14 16.5523 14.4477 17 15 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H15Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1 2.39998C8.4 2.19998 7.6 2.09998 6.9 2.09998C6.4 2.09998 6 2.19998 5.6 2.59998C5.3 2.89998 5.1 3.39998 5.1 3.89998V4.19998H4.8C3.3 4.19998 2 5.39998 2 6.99998V17.3C2 18.8 3.3 20.1 4.8 20.1H12.2V4.19998C11.3 3.39998 10.2 2.79998 9.1 2.39998ZM4.8 18.6C4.1 18.6 3.5 18 3.5 17.3V6.99998C3.5 6.29998 4.1 5.69998 4.8 5.69998H5.1V15.3C5.1 16.3 5.9 17 6.8 17.1C7.5 17.1 8.1 17.3 8.7 17.5C9.4 17.8 10 18.2 10.4 18.6H4.8ZM19.2 4.19998H13.2V20.1H19.2C20.7 20.1 22 18.8 22 17.3V6.99998C22 5.39998 20.7 4.19998 19.2 4.19998ZM18.2 17H15.1C14.7 17 14.3 16.7 14.3 16.2C14.3 15.8 14.6 15.4 15.1 15.4H18.2C18.6 15.4 19 15.7 19 16.2C18.9 16.7 18.6 17 18.2 17ZM18.2 12.9H15.1C14.7 12.9 14.3 12.6 14.3 12.1C14.3 11.7 14.6 11.3 15.1 11.3H18.2C18.6 11.3 19 11.6 19 12.1C18.9 12.5 18.6 12.9 18.2 12.9ZM18.2 8.69998H15.1C14.7 8.69998 14.3 8.39998 14.3 7.89998C14.3 7.49998 14.6 7.09998 15.1 7.09998H18.2C18.6 7.09998 19 7.39998 19 7.89998C18.9 8.39998 18.6 8.69998 18.2 8.69998Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BookOpen",
};
</script>
