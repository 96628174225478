<template>
  <auth-container :title="t('Authentification à deux facteurs')">
    <v-row class="mt-5 mb-16 mx-5 justify-center" align="center">
      <v-col cols="10" sm="8" md="7" lg="4" class="mb-16">
        <v-card outlined>
          <v-card-text>
            <text-input
              v-model="totp"
              input-class="mt-2"
              :placeholder="t('Code à 6 chiffres')"
              required
              solo
              dense
              hide-details
              autofocus
              :input-label="t('Code d\'authentification')"
              :loading="loading"
              @input="verifyTotp"
            />

            <div class="mt-1">
              <span class="text-danger">{{ errorMsg }}</span>
            </div>

            <div class="mt-4">
              <v-btn class="primary" block depressed small @click="verifyTotp">
                {{ t("Vérification") }}
              </v-btn>
            </div>
            <div class="mt-3">
              <small>
                {{
                  t(
                    "Ouvrez l'application de double authentification (TOTP) sur votre téléphone pour voir votre code."
                  )
                }}
              </small>
            </div>
            <div class="mt-3 text-center">
              <router-link :to="{ name: 'OTPStatic' }">
                {{ t("Utiliser un code de récupération") }}
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </auth-container>
</template>

<script>
import i18n from "@/mixins/i18n";
import { Totp } from "@/services/auth/totp";
import AuthContainer from "@/components/auth/AuthContainer";

export default {
  name: "OTPVerification",
  components: { AuthContainer },
  mixins: [i18n],
  data() {
    return {
      totp: "",
      loading: false,
      errorMsg: "",
    };
  },

  computed: {},
  mounted() {},
  methods: {
    verifyTotp() {
      if (this.totp.length === 6) {
        this.loading = true;
        Totp.verify(this.totp)
          .then(
            (response) => {
              this.$store.dispatch("auth/login", response.data);
              this.$router.push({ name: "Home" });
            },
            (err) => {
              if (err.response.status === 401) {
                this.$router.push({ name: "Login" });
              } else {
                this.errorMsg = this.t("Code invalide");
              }
            }
          )
          .finally(() => (this.loading = false));
      } else {
        this.errorMsg = "";
      }
    },
  },
};
</script>
