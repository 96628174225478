<template>
  <div class="d-inline-flex align-center">
    <text-input
      v-model="annotation.named_field"
      class="pr-2"
      hide-details
      persistent-hint
      :input-label="t('Name')"
      @blur="$emit('save')"
    />
    <drop-down
      v-model="annotation.function"
      class="pr-2"
      :input-label="t('Function')"
      item-value="value"
      search-bar
      item-text="label"
      :items="functions"
      @blur="$emit('save')"
    />
    <text-input
      v-model="annotation.field"
      class="pr-2"
      hide-details
      persistent-hint
      :input-label="t('Field')"
      @blur="$emit('save')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IndicatorAnnotation",
  props: {
    annotation: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      functions: (state) => state["indicators"].functions,
    }),
  },

  watch: {
    annotation: {
      handler: function (val) {
        delete val.kwargs["distinct"];
        if (val.function === "count") {
          val.kwargs["distinct"] = true;
        }
      },

      deep: true,
    },
  },
};
</script>
