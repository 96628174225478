<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="18" r="2" :fill="$parent.getColor" />
    <circle cx="8" cy="12" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="12" r="2" :fill="$parent.getColor" />
    <circle cx="8" cy="6" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="6" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="18" r="2" :fill="$parent.getColor" />
  </svg>
</template>

<script>
export default {
  name: "DragDotsVertical",
};
</script>
