<template>
  <div
    class="text-input-container flex-fill mw-100"
    :class="{ 'search-input': searchInput }"
  >
    <label
      v-if="inputLabel"
      :class="[{ 'disabled-label': disabled }, inputLabelClass]"
      class="mb-1"
      :for="inputId"
    >
      {{ inputLabel }}
      <span v-if="required" class="danger-main"> * </span>
    </label>
    <v-text-field
      :id="inputId"
      ref="node"
      :hide-details="hideDetails"
      :autofocus="autofocus"
      :class="[
        {
          'disabled-error': (disabled && error) || (disabled && errorMessages)
        },
        {
          'disabled-success':
            (disabled && success) || (disabled && successMessages)
        },
        inputClass
      ]"
      :placeholder="searchInput && !placeholder ? 'Rechercher' : placeholder"
      :type="password ? (show_db_password ? 'text' : 'password') : type"
      :append-icon="
        password ? (show_db_password ? 'mdi-eye' : 'mdi-eye-off') : ''
      "
      :style="
        type === 'number'
          ? customWidth > 0
            ? 'width: ' + (customWidth + 3) + 'ch'
            : 'width: 32px'
          : ''
      "
      :hint="hint"
      :persistent-hint="persistentHint"
      dense
      solo
      v-bind="[$props, $attrs]"
      @click:append="password ? (show_db_password = !show_db_password) : {}"
      v-on="$listeners"
      @input="(v) => setCustomWidth(v)"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #prepend-inner>
        <template v-if="!$slots.prependInner && searchInput">
          <icon name="search" outline color="basics-80" />
        </template>
        <slot name="prepend-inner" />
      </template>
      <template v-if="!appendIcon" #append>
        <template
          v-if="
            !$slots.append && !searchInput && type !== 'number' && !password
          "
        >
          <icon
            name="checkmark-circle"
            size="20"
            color="success-hover"
            class="d-none text-input-success-icon"
          />
          <icon
            name="alert-circle"
            size="17"
            color="danger-main"
            class="d-none text-input-error-icon"
          />
        </template>
        <slot name="append" />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
      <template #message>
        <slot name="message" />
      </template>
      <template #label>
        <slot name="label" />
      </template>
      <template #counter>
        <slot name="counter" />
      </template>
      <template #progress>
        <slot name="progress" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextInput",

  props: {
    inputLabel: {
      type: String,
      default: null,
    },

    hideDetails: {
      type: [Boolean, String],
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      default: null,
    },

    success: {
      type: Boolean,
      default: false,
    },

    successMessages: {
      type: [String, Array],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    prependIcon: {
      type: String,
      default: null,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    // eslint-disable-next-line vue/no-unused-properties
    prependInnerIcon: {
      type: String,
      default: null,
    },

    appendOuterIcon: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    inputClass: {
      type: String,
      default: null,
    },

    inputLabelClass: {
      type: String,
      default: null,
    },

    searchInput: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: null,
    },

    type: {
      type: String,
      default: "text",
    },

    password: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    hint: {
      type: String,
      required: false,
    },

    persistentHint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputId: "",
      customWidth: "",
      show_db_password: false,
    };
  },

  mounted() {
    if (this.inputLabel || this.label) {
      this.setInputId();
    }

    if (
      this.prependIcon ||
      this.appendOuterIcon ||
      this.$slots.prepend ||
      this.$slots["append-outer"]
    ) {
      this.setIconStyle();
    }

    if (this.type === "number" && this.$refs.node.value) {
      this.customWidth = this.$refs.node.value.length;
    }
  },

  methods: {
    setInputId() {
      this.id
        ? (this.inputId = this.id)
        : (this.inputId = "text-input-" + this._uid);
    },

    setIconStyle() {
      const hoverElement = this.$el.querySelector(".v-input__slot");
      const inputNode = this.$el.querySelector(".v-text-field");
      hoverElement.addEventListener("mouseenter", () => {
        inputNode.classList.add("hovering");
      });
      hoverElement.addEventListener("mouseleave", () => {
        inputNode.classList.remove("hovering");
      });
    },

    setCustomWidth(v) {
      if (v) {
        this.customWidth = v.length;
      }
    },
  },
};
</script>
