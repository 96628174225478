<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 5.92202C4 4.47305 5.59649 3.54659 6.91838 4.22846L18.8837 10.3064C20.3721 11.0403 20.3721 12.9597 18.8837 13.6936L6.91838 19.7715C5.59649 20.4534 4 19.527 4 18.078V5.92202ZM16.8939 12.5093C17.3171 12.2932 17.3171 11.7069 16.8939 11.4908L6.85773 6.36776C6.46126 6.16538 5.9845 6.44341 5.9845 6.877V17.123C5.9845 17.5566 6.46125 17.8346 6.85773 17.6322L16.8939 12.5093Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.92 10.44L7.69 5.2C7.14 4.92 6.5 4.94 5.97 5.26C5.44 5.58 5.13 6.14 5.13 6.76V17.25C5.13 17.87 5.44 18.43 5.97 18.75C6.25 18.92 6.57 19.01 6.88 19.01C7.15 19.01 7.42999 18.95 7.67999 18.82L17.91 13.58C18.5 13.28 18.86 12.68 18.86 12.02C18.86 11.36 18.51 10.74 17.92 10.44Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Play",
};
</script>
