<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6781 7.28251C15.2721 6.88328 14.6395 6.91047 14.265 7.34323C13.8905 7.776 13.916 8.45047 14.322 8.8497L17.5252 12L14.322 15.1503C13.916 15.5495 13.8905 16.224 14.265 16.6568C14.6395 17.0895 15.2721 17.1167 15.6781 16.7175L19.6697 12.7918C19.6824 12.7796 19.6949 12.7669 19.7071 12.7539C19.7893 12.6663 19.8544 12.567 19.9021 12.4608C20.0333 12.1691 20.032 11.8286 19.9021 11.5392C19.8544 11.433 19.7893 11.3337 19.7071 11.2461C19.6949 11.2331 19.6824 11.2204 19.6697 11.2082L15.6781 7.28251Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.67806 8.8497C10.084 8.45047 10.1095 7.776 9.73502 7.34323C9.36054 6.91047 8.72789 6.88328 8.32195 7.28251L4.33033 11.2082C4.31761 11.2204 4.30513 11.2331 4.29289 11.2461C4.09852 11.4531 4.00006 11.7261 4 12C3.99996 12.1575 4.03246 12.3152 4.09795 12.4608C4.14565 12.567 4.21069 12.6663 4.29289 12.7539C4.30513 12.7669 4.31762 12.7796 4.33035 12.7918L8.32195 16.7175C8.72789 17.1167 9.36054 17.0895 9.73502 16.6568C10.1095 16.224 10.084 15.5495 9.67806 15.1503L6.47482 12L9.67806 8.8497Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.25 2H5.75C3.67893 2 2 3.67893 2 5.75V18.25C2 20.3211 3.67893 22 5.75 22H18.25C20.3211 22 22 20.3211 22 18.25V5.75C22 3.67893 20.3211 2 18.25 2ZM13.6347 8.14226C14.009 7.73617 14.6417 7.7104 15.0477 8.08471L18.4889 11.2566C18.5028 11.2691 18.5165 11.2821 18.5298 11.2956C18.7224 11.4894 18.8199 11.7443 18.82 12C18.82 12.1497 18.7867 12.2997 18.7194 12.4377C18.6725 12.5342 18.6092 12.6245 18.5298 12.7044C18.5165 12.7179 18.5028 12.7309 18.4889 12.7435L15.0477 15.9153C14.6417 16.2896 14.009 16.2638 13.6347 15.8577C13.2604 15.4517 13.2862 14.819 13.6922 14.4447L16.3445 12L13.6922 9.5553C13.2862 9.18099 13.2604 8.54835 13.6347 8.14226ZM10.3653 8.14226C10.7396 8.54835 10.7138 9.18099 10.3077 9.5553L7.65547 12L10.3077 14.4447C10.7138 14.819 10.7396 15.4517 10.3653 15.8577C9.99098 16.2638 9.35834 16.2896 8.95224 15.9153L5.51109 12.7434C5.49717 12.7309 5.48352 12.7179 5.47017 12.7044C5.39076 12.6245 5.32752 12.5342 5.2806 12.4377C5.14624 12.162 5.14711 11.8389 5.27926 11.565C5.32631 11.4675 5.39 11.3763 5.47017 11.2956C5.48352 11.2821 5.49717 11.2691 5.51109 11.2566L8.95224 8.08471C9.35834 7.7104 9.99098 7.73617 10.3653 8.14226Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftAndRight",
};
</script>
