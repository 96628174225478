<template>
  <v-container>
    <c-r-u-d-data-table
      namespace="vms"
      :list-params="{ hospital: hospitalId }"
      :default-new-object="defaultVm"
      :headers="headers"
    >
      <template #dialogContent="{ currentObject }">
        <drop-down
          v-model="currentObject.type"
          :input-label="t('Type')"
          required
          item-value="value"
          item-text="text"
          :items="Object.values(VirtualMachineType)"
          hide-details="auto"
          :rules="requiredRule"
          @input="currentObject.name = $event"
        />
        <text-input
          v-model="currentObject.name"
          autofocus
          required
          class="mt-2"
          :rules="requiredRule"
          hide-details="auto"
          :input-label="t('Name')"
        />
        <text-input
          v-model="currentObject.host"
          required
          class="mt-2"
          :input-label="t('Host')"
          :rules="requiredRule"
          hide-details="auto"
        />
      </template>
    </c-r-u-d-data-table>
  </v-container>
</template>

<script>
import { VirtualMachineType } from "@/enums/hospitals";
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import { mapState } from "vuex";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "HospitalVirtualMachine",
  components: { CRUDDataTable },
  mixins: [form_rules],

  props: {
    hospitalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      VirtualMachineType,
    };
  },

  computed: {
    ...mapState({
      hospital: (state) => state["hospitals"].current,
    }),

    defaultVm() {
      return {
        name: "",
        host: "",
        type: "",
        hospital_id: this.hospital.id,
      };
    },

    headers() {
      return [
        { text: this.t("Name"), value: "name", sortable: false },
        { text: this.t("Type"), value: "type", sortable: false },
        { text: this.t("Host"), value: "host", sortable: false },
        {
          text: "",
          value: "edit",
          align: "right",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
