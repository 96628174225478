<template>
  <auth-container :title="t('Récupération de compte')">
    <v-row>
      <v-col>
        <div class="text-center">
          Pour récupérer l'accès à votre compte, vous pouvez envoyer un mail à
          <b>support@codoc.co</b>.
        </div>
      </v-col>
    </v-row>
  </auth-container>
</template>

<script>
import AuthContainer from "@/components/auth/AuthContainer";
import i18n from "@/mixins/i18n";

export default {
  name: "LockOut",
  components: { AuthContainer },
  mixins: [i18n],
};
</script>
