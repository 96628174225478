<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.878 3.95217C7.50735 3.95217 3.95217 7.50735 3.95217 11.878V12.122C3.95217 16.4933 7.49829 20.0478 11.878 20.0478H12.122C16.4933 20.0478 20.0478 16.5017 20.0478 12.122V11.878C20.0478 7.50666 16.5017 3.95217 12.122 3.95217H11.878ZM2 11.878C2 6.4292 6.4292 2 11.878 2H12.122C17.5813 2 22 6.4299 22 11.878V12.122C22 17.5813 17.5701 22 12.122 22H11.878C6.41874 22 2 17.5701 2 12.122V11.878Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.074 8.79398C12.4567 9.18708 12.458 9.82572 12.0768 10.2204L11.3323 10.9914H15.022C15.5621 10.9914 16 11.4429 16 12C16 12.5571 15.5621 13.0086 15.022 13.0086H11.3323L12.0768 13.7796C12.458 14.1743 12.4567 14.8129 12.074 15.206C11.6913 15.5991 11.0721 15.5978 10.6909 15.2031L8.28545 12.7122L8.28505 12.7118C7.90477 12.318 7.90514 11.6814 8.28521 11.2881L10.6909 8.79687C11.0721 8.40217 11.6913 8.40088 12.074 8.79398Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.13 2H11.87C6.43 2 2 6.43 2 11.87V12.13C2 17.57 6.43 22 11.87 22H12.13C17.57 22 22 17.57 22 12.13V11.87C22 6.43 17.57 2 12.13 2ZM15.09 12.75H10.72L11.9 13.94C12.2 14.23 12.2 14.7 11.9 15C11.76 15.14 11.57 15.22 11.37 15.22C11.18 15.22 10.99 15.14 10.84 15L8.38 12.53C8.09 12.24 8.09 11.76 8.38 11.47L10.84 9C11.14 8.71 11.61 8.71 11.9 9C12.2 9.3 12.2 9.77 11.9 10.06L10.72 11.25H15.09C15.51 11.25 15.84 11.59 15.84 12C15.84 12.41 15.51 12.75 15.09 12.75Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftCircle",
};
</script>
