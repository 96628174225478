<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 3C8 2.44772 8.44772 2 9 2H15C15.5523 2 16 2.44771 16 3L16 5H18H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H19V19C19 20.6823 17.6823 22 16 22H8C6.31771 22 5 20.6823 5 19V7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H8V3ZM17 7L7 7.00001V19C7 19.5777 7.42228 20 8 20H16C16.5777 20 17 19.5777 17 19V7ZM10 4V4.81998H14V4H10Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 9C14.5523 9 15 9.44772 15 10V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V10C13 9.44772 13.4477 9 14 9Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.21 5.07999H15.83V2.73999C15.83 2.32999 15.5 1.98999 15.08 1.98999H8.91998C8.50998 1.98999 8.16998 2.32999 8.16998 2.73999V5.07999H3.78998C3.37998 5.07999 3.03998 5.40999 3.03998 5.82999C3.03998 6.23999 3.37998 6.57999 3.78998 6.57999H4.93999V19.19C4.93999 20.74 6.19998 21.99 7.74998 21.99H15.91C17.45 21.99 18.71 20.74 18.71 19.19V6.57999H20.21C20.62 6.57999 20.96 6.23999 20.96 5.82999C20.96 5.40999 20.62 5.07999 20.21 5.07999ZM9.18999 17.14V9.74998C9.18999 9.33998 9.52999 8.99998 9.93999 8.99998C10.36 8.99998 10.69 9.33998 10.69 9.74998V17.14C10.69 17.55 10.36 17.89 9.93999 17.89C9.52999 17.89 9.18999 17.55 9.18999 17.14ZM14.81 17.14C14.81 17.55 14.47 17.89 14.06 17.89C13.64 17.89 13.31 17.55 13.31 17.14V9.74998C13.31 9.32998 13.64 8.99998 14.06 8.99998C14.47 8.99998 14.81 9.32998 14.81 9.74998V17.14ZM9.66998 3.48999H14.33V5.07999H9.66998V3.48999Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Trash",
};
</script>
