<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7071 3.29289C18.6112 3.19702 18.5007 3.12468 18.3828 3.07588C18.2657 3.02735 18.1375 3.0004 18.003 3L18.0026 3L18 3H17.9993H14C13.4477 3 13 3.44771 13 4C13 4.55229 13.4477 5 14 5H15.5858L13.7722 6.81355C12.9925 6.29943 12.0585 6 11.0541 6H10.9459C8.21803 6 6 8.21228 6 10.9459V11.0541C6 13.4591 7.71959 15.4678 10 15.9096V18H9C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20H10V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V20H13C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18H12V15.9094C14.2772 15.4672 16 13.4592 16 11.0541V10.9459C16 9.94261 15.7007 9.00827 15.1863 8.22789L17 6.41421V8C17 8.55228 17.4477 9 18 9C18.5523 9 19 8.55228 19 8V4L19 3.99077C18.9988 3.86382 18.9736 3.73705 18.9244 3.61787L18.7071 3.29289ZM11.0541 14H11H10.9459C9.31828 14 8 12.6788 8 11.0541V10.9459C8 9.31828 9.32118 8 10.9459 8H11.0541C12.6817 8 14 9.32118 14 10.9459V11.0541C14 12.6781 12.6781 14 11.0541 14Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Other",
};
</script>
