<template>
  <div class="bg-basics-10 pa-2 rounded-shadow-container">
    <div
      class="text-body-1 clickable d-flex align-center justify-space-between"
      @click="$emit('update:open', !open)"
    >
      <div>{{ title }} <span v-if="required" class="red--text">*</span></div>
      <div class="text-right d-flex align-center">
        <badge class="mr-2" :text="count.toString()" />
        <icon size="18" outline :name="open ? 'caret-down' : 'caret-right'" />
      </div>
    </div>
    <div v-if="open">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicatorDefinitionSection",
  props: {
    open: {
      type: Boolean,
      required: true,
    },

    required: {
      type: Boolean,
      default: false,
    },

    count: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },
};
</script>
