<template>
  <div>
    <div
      v-for="(annotation, index) in annotations"
      :key="'annotation' + index"
      class="d-inline-flex align-center pt-2"
    >
      <indicator-annotation
        :annotation.sync="annotations[index]"
        @save="$emit('save')"
      />
      <v-btn
        icon
        small
        class="mt-4"
        @click="
          remove(index);
          $emit('save');
        "
      >
        <icon name="trash" size="18" />
      </v-btn>
    </div>
    <div>
      <v-btn
        text
        small
        color="primary"
        class="mt-2"
        @click="
          add();
          $emit('save');
        "
      >
        <icon name="plus" outline size="14" class="mr-1" />
        {{ t("Ajouter") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import IndicatorAnnotation from "@/components/indicators/tabs/parameters/definition/IndicatorAnnotation";
export default {
  name: "IndicatorAnnotations",
  components: { IndicatorAnnotation },
  props: {
    annotations: {
      type: Array,
      required: true,
    },
  },

  methods: {
    add() {
      this.annotations.push({
        field: "",
        named_field: "",
        function: null,
        kwargs: {},
      });
    },

    remove(index) {
      this.annotations.splice(index, 1);
    },
  },
};
</script>
