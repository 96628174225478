<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9252 9.26623C16.5251 5.70364 13.603 3.94987 10.8232 4.00109C9.39011 4.02749 7.93695 4.52498 6.82026 5.53071C5.72578 6.51643 5.00656 7.94485 4.92148 9.74727C2.77799 10.8195 1.62077 13.3371 2.11213 15.7709L2.1124 15.7722C2.40964 17.2345 3.36531 18.6427 4.7427 19.3984L4.74358 19.3988C5.88144 20.0216 7.49959 19.9999 7.99959 19.9999H11.9996H14.9996H16.9996C18.4513 19.9933 19.9674 19.1375 20.7491 18.1357C21.528 17.1374 21.9996 15.8412 21.9996 14.6096C21.9996 13.3241 21.5421 12.3002 21.0877 11.6004C20.86 11.2498 20.6289 10.9739 20.4462 10.7777C20.3545 10.6792 20.2742 10.6001 20.2117 10.5411C20.1805 10.5116 20.1539 10.4873 20.1329 10.4685C20.1167 10.4539 20.0916 10.4318 20.097 10.4367C20.0894 10.4299 20.0817 10.4232 20.0738 10.4166C19.2055 9.6913 18.1007 9.24828 16.9252 9.26623ZM8.1587 7.01683C7.45649 7.64926 6.94508 8.61162 6.91444 9.98029L6.91414 9.9936C6.89174 10.6207 6.52874 11.2219 5.90234 11.4946C4.57161 12.1077 3.74001 13.7263 4.07244 15.3744C4.26194 16.3057 4.88267 17.1938 5.70446 17.6448C6.38836 18.0189 7.49959 17.9999 7.99959 17.9999C7.99959 17.9999 8.9768 18 8.99959 17.9999H11.9996H12.9996C14.6499 17.9926 15.3446 18.0048 16.9996 17.9999C17.6693 17.9967 18.6499 17.5748 19.1723 16.9054C19.6975 16.2323 19.9996 15.3632 19.9996 14.6096C19.9996 13.0597 18.9256 12.0715 18.7811 11.9428L18.7916 11.9515L18.7719 11.9346L18.7811 11.9428C18.193 11.4557 17.4803 11.2137 16.7753 11.2753L16.7669 11.276C15.9096 11.3437 15.0389 10.7455 14.9614 9.76413L14.9603 9.74829C14.8007 7.21503 12.8746 5.96363 10.86 6.00075C9.85033 6.01935 8.87653 6.37034 8.1587 7.01683Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7929 11.7929C13.1834 11.4024 13.8166 11.4024 14.2071 11.7929C14.5976 12.1834 14.5976 12.8166 14.2071 13.2071L11.7071 15.7071C11.3166 16.0976 10.6834 16.0976 10.2929 15.7071L8.79289 14.2071C8.40237 13.8166 8.40237 13.1834 8.79289 12.7929C9.18342 12.4024 9.81658 12.4024 10.2071 12.7929L11 13.5858L12.7929 11.7929Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.12 10.58C19.16 9.78 17.98 9.38 16.81 9.48C16.54 5.9 13.67 4.06 10.91 4.06H10.78C8.05999 4.11 5.08999 5.96 5.00999 9.86C2.82999 10.86 1.60999 13.36 2.11999 15.82C2.41999 17.32 3.37999 18.64 4.67999 19.36C5.52999 19.83 6.35999 19.95 6.95999 19.95C7.07999 19.95 7.19999 19.94 7.30999 19.93L17.48 19.89C20.07 19.88 22 17.13 22.01 14.68C22.01 12.22 20.31 10.74 20.12 10.58ZM9.00999 13.09C9.29999 12.8 9.76999 12.8 10.07 13.09L11 14.02L13.12 11.9C13.41 11.61 13.88 11.61 14.18 11.9C14.47 12.2 14.47 12.67 14.18 12.96L11.53 15.61C11.38 15.76 11.19 15.83 11 15.83C10.8 15.83 10.61 15.76 10.47 15.61L9.00999 14.15C8.70999 13.86 8.70999 13.39 9.00999 13.09Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CloudCheckmark",
};
</script>
