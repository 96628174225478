<template>
  <div class="mt-2">
    <div
      v-for="(group, index) in groupBy"
      :key="'group' + index"
      class="mb-4 pl-2"
    >
      <div
        v-if="group.type === IndicatorGroupByType.ANNOTATION.value"
        class="d-inline-flex align-center"
      >
        <indicator-annotation
          :annotation.sync="group.options"
          @save="$emit('save')"
        />
        <v-btn icon small class="mt-4" @click="remove(index)">
          <icon name="trash" size="18" />
        </v-btn>
      </div>
      <div
        v-if="group.type === IndicatorGroupByType.SIMPLE.value"
        class="d-inline-flex align-center"
      >
        <text-input
          v-model="group.options.field"
          class="pr-2"
          hide-details
          persistent-hint
          :input-label="t('Field')"
          @blur="$emit('save')"
        />
        <v-btn
          small
          icon
          class="mt-4"
          @click="
            remove(index);
            $emit('save');
          "
        >
          <icon name="trash" size="18" />
        </v-btn>
      </div>
    </div>
    <div>
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn text small color="primary" v-bind="attrs" v-on="on">
            <icon name="plus" outline size="14" class="mr-1" />
            {{ t("Ajouter") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            link
            @click="
              add(IndicatorGroupByType.SIMPLE.value);
              $emit('save');
            "
          >
            <v-list-item-title>{{ t("Simple") }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="
              add(IndicatorGroupByType.ANNOTATION.value);
              $emit('save');
            "
          >
            <v-list-item-title>{{ t("Annotation") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { IndicatorGroupByType } from "@/enums/indicators";
import IndicatorAnnotation from "@/components/indicators/tabs/parameters/definition/IndicatorAnnotation";
export default {
  name: "IndicatorGroupBy",
  components: { IndicatorAnnotation },
  props: {
    groupBy: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      IndicatorGroupByType,
    };
  },

  methods: {
    add(type) {
      this.groupBy.push({
        type: type,
        options: { field: "", named_field: "", function: null, kwargs: {} },
      });
    },

    remove(index) {
      this.groupBy.splice(index, 1);
    },
  },
};
</script>
