<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0047 7.99441C13.1082 7.99441 14.0028 7.0998 14.0028 5.99627C14.0028 4.89273 13.1082 3.99814 12.0047 3.99814C10.9011 3.99814 10.0065 4.89273 10.0065 5.99627C10.0065 7.0998 10.9011 7.99441 12.0047 7.99441ZM12.0047 9.99255C14.2118 9.99255 16.0009 8.20337 16.0009 5.99627C16.0009 3.78919 14.2118 2 12.0047 2C9.79758 2 8.00839 3.78919 8.00839 5.99627C8.00839 8.20337 9.79758 9.99255 12.0047 9.99255Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2127 17.2159C17.3617 17.7472 17.0517 18.2986 16.5204 18.4476C13.0593 19.4179 9.0868 18.8532 7.51305 18.454C6.97822 18.3183 6.65463 17.7748 6.79029 17.24C6.92595 16.7051 7.4695 16.3815 8.00433 16.5172C9.40281 16.8719 12.9882 17.3626 15.981 16.5236C16.5124 16.3747 17.0638 16.6846 17.2127 17.2159Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7033 20.1802C16.8704 20.706 16.5795 21.2678 16.0536 21.4349C12.9441 22.4229 9.39052 21.8478 7.98209 21.4429C7.45179 21.2905 7.14549 20.737 7.29794 20.2067C7.45038 19.6764 8.00385 19.3701 8.53414 19.5225C9.74828 19.8716 12.8634 20.352 15.4486 19.5306C15.9745 19.3635 16.5362 19.6543 16.7033 20.1802Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.17702 13.7516C8.17677 13.7516 8.17874 13.7492 8.18367 13.7444C8.17974 13.7492 8.17727 13.7516 8.17702 13.7516ZM8.64828 13.4893C8.73612 13.4541 8.83637 13.4182 8.94986 13.3823C9.6871 13.1495 10.7673 12.9898 12.0046 12.9898C13.242 12.9898 14.3222 13.1495 15.0594 13.3823C15.1729 13.4182 15.2731 13.4541 15.361 13.4893C15.2731 13.5245 15.1729 13.5604 15.0594 13.5963C14.3222 13.8291 13.242 13.9888 12.0046 13.9888C10.7673 13.9888 9.6871 13.8291 8.94986 13.5963C8.83637 13.5604 8.73612 13.5245 8.64828 13.4893ZM15.8323 13.7516C15.832 13.7516 15.8295 13.7492 15.8256 13.7444C15.8305 13.7492 15.8325 13.7516 15.8323 13.7516ZM15.8256 13.2342C15.8295 13.2294 15.832 13.2271 15.8323 13.2271C15.8325 13.2271 15.8305 13.2295 15.8256 13.2342ZM8.18367 13.2342C8.17874 13.2295 8.17677 13.2271 8.17702 13.2271C8.17727 13.2271 8.17974 13.2294 8.18367 13.2342ZM8.34816 11.4769C9.32848 11.1674 10.6211 10.9916 12.0046 10.9916C13.3882 10.9916 14.6808 11.1674 15.6611 11.4769C16.1454 11.6299 16.61 11.833 16.975 12.1055C17.3219 12.3645 17.7493 12.8213 17.7493 13.4893C17.7493 14.1573 17.3219 14.6142 16.975 14.8732C16.61 15.1457 16.1454 15.3487 15.6611 15.5017C14.6808 15.8112 13.3882 15.987 12.0046 15.987C10.6211 15.987 9.32848 15.8112 8.34816 15.5017C7.86384 15.3487 7.39928 15.1457 7.03429 14.8732C6.68737 14.6142 6.25999 14.1573 6.25999 13.4893C6.25999 12.8213 6.68737 12.3645 7.03429 12.1055C7.39928 11.833 7.86384 11.6299 8.34816 11.4769Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DrWarehouse",
};
</script>
