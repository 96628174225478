<template>
  <svg
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.53 2H18.48C20.42 2 22 3.58 22 5.53V18.47C22 20.42 20.42 22 18.48 22H5.53C3.58 22 2 20.42 2 18.47V5.53C2 3.58 3.58 2 5.53 2ZM14 8C14 7.63121 13.797 7.29235 13.4718 7.11833C13.1467 6.94431 12.7521 6.96338 12.4453 7.16795L9.44528 9.16795C8.98576 9.4743 8.86158 10.0952 9.16793 10.5547C9.47429 11.0142 10.0952 11.1384 10.5547 10.8321L12 9.86852V16C12 16.5523 12.4477 17 13 17C13.5523 17 14 16.5523 14 16V8Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "NumberOne",
};
</script>
