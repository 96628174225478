<template>
  <div class="mt-5 mx-6">
    <settings-application-types title="Application Types" />
  </div>
</template>

<script>
import SettingsApplicationTypes from "@/components/settings/SettingsApplicationTypes.vue";

export default {
  name: "Settings",
  components: { SettingsApplicationTypes },

  data() {
    return {};
  },
};
</script>
