import HospitalRouter from "@/components/hospitals/HospitalRouter";
import Hospitals from "@/components/hospitals/Hospitals";
import HospitalDetail from "@/components/hospitals/HospitalDetail";
import HospitalVirtualMachine from "@/components/hospitals/tabs/HospitalVirtualMachine";
import HospitalApp from "@/components/hospitals/tabs/HospitalApp";
import AppParameters from "@/components/hospitals/tabs/apps/AppParameters";
import AppKeys from "@/components/hospitals/tabs/apps/AppKeys.vue";

export const hospitalsUrls = [
  {
    path: "/hospitals",
    component: HospitalRouter,
    children: [
      {
        path: "",
        name: "Hospitals",
        component: Hospitals,
      },
      {
        path: ":hospitalId",
        name: "HospitalDetail",
        component: HospitalDetail,
        redirect: { name: "HospitalVirtualMachine" },
        props: true,
        children: [
          {
            path: "vms",
            name: "HospitalVirtualMachine",
            component: HospitalVirtualMachine,
            props: true,
          },
          {
            path: "apps",
            name: "HospitalApp",
            component: HospitalApp,
            props: true,
            redirect: { name: "AppParameters" },
            children: [
              {
                path: "parameters",
                name: "AppParameters",
                component: AppParameters,
                props: true,
              },
              {
                path: "api_key",
                name: "AppKeys",
                component: AppKeys,
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
