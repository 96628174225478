<template>
  <v-container>
    <c-r-u-d-data-table
      namespace="application_types"
      :headers="headers"
      :default-new-object="defaultApplicationType"
      :title="title"
    >
      <template #dialogContent="{ currentObject }">
        <text-input
          v-model="currentObject.name"
          required
          hide-details="auto"
          :rules="requiredRule"
          :input-label="t('Name')"
        />
        <text-input
          v-model="currentObject.repository"
          class="mt-2"
          hide-details="auto"
          :input-label="t('Repository URL')"
        />
      </template>
    </c-r-u-d-data-table>
  </v-container>
</template>

<script>
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "SettingsApplicationTypes",
  components: { CRUDDataTable },
  mixins: [form_rules ],

  props: {
    title: { type: String },
  },

  data() {
    return {
      headers: [
        { text: this.t("Name"), value: "name", sortable: false },
        { text: this.t("Repository"), value: "repository", sortable: false },
        { text: "", value: "edit", align: "right", sortable: false },
      ],

      defaultApplicationType: {
        name: "",
        repository: null,
      },
    };
  },

  mounted() {
    this.$store.dispatch("application_types/list");
  },

};
</script>
