<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5.99837C6 4.64245 6.8185 4 7.33496 4H14V7C14 8.10457 14.8954 9 16 9H18V18.0016C18 19.353 17.1872 20 16.665 20H7.33496C6.82206 20 6 19.3427 6 18.0016V5.99837ZM19.9975 8.07121C19.9992 8.04769 20 8.02394 20 8C20 7.93565 19.9939 7.87273 19.9823 7.81176C19.91 6.96474 19.6166 6.147 19.1092 5.49271L19.1087 5.492L17.3775 3.26338L17.377 3.26282L17.3769 3.26268C16.812 2.53447 16.0056 2.05609 15.0967 2.00462C15.0649 2.00156 15.0326 2 15 2C14.9917 2 14.9834 2.0001 14.9752 2.0003C14.9612 2.0001 14.9473 2 14.9333 2H7.33496C5.28297 2 4 4.02491 4 5.99837V18.0016C4 19.951 5.2794 22 7.33496 22H16.665C18.7308 22 20 19.9601 20 18.0016V8.22769C20 8.17549 19.9992 8.12332 19.9975 8.07121ZM16 4.7503L17.5287 6.71823L17.529 6.71863C17.595 6.80384 17.6557 6.89804 17.71 7H16V4.7503Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 2.5C14 2.22386 13.7761 2 13.5 2H6.66852C5.20084 2 4 3.48187 4 5.33152V18.6685C4 20.4965 5.20084 22 6.66852 22H17.3315C18.8103 22 20 20.5073 20 18.6685V9.5C20 9.22386 19.7761 9 19.5 9H16C14.8954 9 14 8.10457 14 7V2.5ZM19.3124 7C19.6276 7 19.8659 6.71011 19.7574 6.41419C19.6273 6.05962 19.4471 5.73312 19.2217 5.45052L17.2425 2.97349C17.0541 2.73719 16.8404 2.54078 16.6091 2.38775C16.3286 2.20208 16 2.43836 16 2.77475V6.5C16 6.77614 16.2239 7 16.5 7H19.3124Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "File",
};
</script>
