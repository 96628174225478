export const Country = {
  AF: { value: "AF", text: "Afghanistan" },
  AL: { value: "AL", text: "Albania" },
  DZ: { value: "DZ", text: "Algeria" },
  AS: { value: "AS", text: "American Samoa" },
  AD: { value: "AD", text: "Andorra" },
  AO: { value: "AO", text: "Angola" },
  AI: { value: "AI", text: "Anguilla" },
  AQ: { value: "AQ", text: "Antarctica" },
  AG: { value: "AG", text: "Antigua and Barbuda" },
  AR: { value: "AR", text: "Argentina" },
  AM: { value: "AM", text: "Armenia" },
  AW: { value: "AW", text: "Aruba" },
  AU: { value: "AU", text: "Australia" },
  AT: { value: "AT", text: "Austria" },
  AZ: { value: "AZ", text: "Azerbaijan" },
  BS: { value: "BS", text: "Bahamas" },
  BH: { value: "BH", text: "Bahrain" },
  BD: { value: "BD", text: "Bangladesh" },
  BB: { value: "BB", text: "Barbados" },
  BY: { value: "BY", text: "Belarus" },
  BE: { value: "BE", text: "Belgium" },
  BZ: { value: "BZ", text: "Belize" },
  BJ: { value: "BJ", text: "Benin" },
  BM: { value: "BM", text: "Bermuda" },
  BT: { value: "BT", text: "Bhutan" },
  BO: { value: "BO", text: "Bolivia" },
  BA: { value: "BA", text: "Bosnia and Herzegovina" },
  BW: { value: "BW", text: "Botswana" },
  BV: { value: "BV", text: "Bouvet Island" },
  BR: { value: "BR", text: "Brazil" },
  IO: { value: "IO", text: "British Indian Ocean Territory" },
  BN: { value: "BN", text: "Brunei Darussalam" },
  BG: { value: "BG", text: "Bulgaria" },
  BF: { value: "BF", text: "Burkina Faso" },
  BI: { value: "BI", text: "Burundi" },
  KH: { value: "KH", text: "Cambodia" },
  CM: { value: "CM", text: "Cameroon" },
  CA: { value: "CA", text: "Canada" },
  CV: { value: "CV", text: "Cape Verde" },
  KY: { value: "KY", text: "Cayman Islands" },
  CF: { value: "CF", text: "Central African Republic" },
  TD: { value: "TD", text: "Chad" },
  CL: { value: "CL", text: "Chile" },
  CN: { value: "CN", text: "China" },
  CX: { value: "CX", text: "Christmas Island" },
  CC: { value: "CC", text: "Cocos (Keeling) Islands" },
  CO: { value: "CO", text: "Colombia" },
  KM: { value: "KM", text: "Comoros" },
  CG: { value: "CG", text: "Republic of the Congo" },
  CD: { value: "CD", text: "Democratic Republic of the Congo" },
  CK: { value: "CK", text: "Cook Islands" },
  CR: { value: "CR", text: "Costa Rica" },
  CI: { value: "CI", text: "Cote D'Ivoire" },
  HR: { value: "HR", text: "Croatia" },
  CU: { value: "CU", text: "Cuba" },
  CY: { value: "CY", text: "Cyprus" },
  CZ: { value: "CZ", text: "Czech Republic" },
  DK: { value: "DK", text: "Denmark" },
  DJ: { value: "DJ", text: "Djibouti" },
  DM: { value: "DM", text: "Dominica" },
  DO: { value: "DO", text: "Dominican Republic" },
  EC: { value: "EC", text: "Ecuador" },
  EG: { value: "EG", text: "Egypt" },
  SV: { value: "SV", text: "El Salvador" },
  GQ: { value: "GQ", text: "Equatorial Guinea" },
  ER: { value: "ER", text: "Eritrea" },
  EE: { value: "EE", text: "Estonia" },
  ET: { value: "ET", text: "Ethiopia" },
  FK: { value: "FK", text: "Falkland Islands (Malvinas)" },
  FO: { value: "FO", text: "Faroe Islands" },
  FJ: { value: "FJ", text: "Fiji" },
  FI: { value: "FI", text: "Finland" },
  FR: { value: "FR", text: "France" },
  GF: { value: "GF", text: "French Guiana" },
  PF: { value: "PF", text: "French Polynesia" },
  TF: { value: "TF", text: "French Southern Territories" },
  GA: { value: "GA", text: "Gabon" },
  GM: { value: "GM", text: "Gambia" },
  GE: { value: "GE", text: "Georgia" },
  DE: { value: "DE", text: "Germany" },
  GH: { value: "GH", text: "Ghana" },
  GI: { value: "GI", text: "Gibraltar" },
  GR: { value: "GR", text: "Greece" },
  GL: { value: "GL", text: "Greenland" },
  GD: { value: "GD", text: "Grenada" },
  GP: { value: "GP", text: "Guadeloupe" },
  GU: { value: "GU", text: "Guam" },
  GT: { value: "GT", text: "Guatemala" },
  GN: { value: "GN", text: "Guinea" },
  GW: { value: "GW", text: "Guinea-Bissau" },
  GY: { value: "GY", text: "Guyana" },
  HT: { value: "HT", text: "Haiti" },
  HM: { value: "HM", text: "Heard Island and McDonald Islands" },
  VA: { value: "VA", text: "Holy See (Vatican City State)" },
  HN: { value: "HN", text: "Honduras" },
  HK: { value: "HK", text: "Hong Kong" },
  HU: { value: "HU", text: "Hungary" },
  IS: { value: "IS", text: "Iceland" },
  IN: { value: "IN", text: "India" },
  ID: { value: "ID", text: "Indonesia" },
  IR: { value: "IR", text: "Iran" },
  IQ: { value: "IQ", text: "Iraq" },
  IE: { value: "IE", text: "Ireland" },
  IL: { value: "IL", text: "Israel" },
  IT: { value: "IT", text: "Italy" },
  JM: { value: "JM", text: "Jamaica" },
  JP: { value: "JP", text: "Japan" },
  JO: { value: "JO", text: "Jordan" },
  KZ: { value: "KZ", text: "Kazakhstan" },
  KE: { value: "KE", text: "Kenya" },
  KI: { value: "KI", text: "Kiribati" },
  KP: { value: "KP", text: "North Korea" },
  KR: { value: "KR", text: "South Korea" },
  KW: { value: "KW", text: "Kuwait" },
  KG: { value: "KG", text: "Kyrgyzstan" },
  LA: { value: "LA", text: "Lao People's Democratic Republic" },
  LV: { value: "LV", text: "Latvia" },
  LB: { value: "LB", text: "Lebanon" },
  LS: { value: "LS", text: "Lesotho" },
  LR: { value: "LR", text: "Liberia" },
  LY: { value: "LY", text: "Libya" },
  LI: { value: "LI", text: "Liechtenstein" },
  LT: { value: "LT", text: "Lithuania" },
  LU: { value: "LU", text: "Luxembourg" },
  MO: { value: "MO", text: "Macao" },
  MG: { value: "MG", text: "Madagascar" },
  MW: { value: "MW", text: "Malawi" },
  MY: { value: "MY", text: "Malaysia" },
  MV: { value: "MV", text: "Maldives" },
  ML: { value: "ML", text: "Mali" },
  MT: { value: "MT", text: "Malta" },
  MH: { value: "MH", text: "Marshall Islands" },
  MQ: { value: "MQ", text: "Martinique" },
  MR: { value: "MR", text: "Mauritania" },
  MU: { value: "MU", text: "Mauritius" },
  YT: { value: "YT", text: "Mayotte" },
  MX: { value: "MX", text: "Mexico" },
  FM: { value: "FM", text: "Micronesia, Federated States of" },
  MD: { value: "MD", text: "Moldova, Republic of" },
  MC: { value: "MC", text: "Monaco" },
  MN: { value: "MN", text: "Mongolia" },
  MS: { value: "MS", text: "Montserrat" },
  MA: { value: "MA", text: "Morocco" },
  MZ: { value: "MZ", text: "Mozambique" },
  MM: { value: "MM", text: "Myanmar" },
  NA: { value: "NA", text: "Namibia" },
  NR: { value: "NR", text: "Nauru" },
  NP: { value: "NP", text: "Nepal" },
  NL: { value: "NL", text: "Netherlands" },
  NC: { value: "NC", text: "New Caledonia" },
  NZ: { value: "NZ", text: "New Zealand" },
  NI: { value: "NI", text: "Nicaragua" },
  NE: { value: "NE", text: "Niger" },
  NG: { value: "NG", text: "Nigeria" },
  NU: { value: "NU", text: "Niue" },
  NF: { value: "NF", text: "Norfolk Island" },
  MK: { value: "MK", text: "North Macedonia" },
  MP: { value: "MP", text: "Northern Mariana Islands" },
  NO: { value: "NO", text: "Norway" },
  OM: { value: "OM", text: "Oman" },
  PK: { value: "PK", text: "Pakistan" },
  PW: { value: "PW", text: "Palau" },
  PS: { value: "PS", text: "Palestine" },
  PA: { value: "PA", text: "Panama" },
  PG: { value: "PG", text: "Papua New Guinea" },
  PY: { value: "PY", text: "Paraguay" },
  PE: { value: "PE", text: "Peru" },
  PH: { value: "PH", text: "Philippines" },
  PN: { value: "PN", text: "Pitcairn" },
  PL: { value: "PL", text: "Poland" },
  PT: { value: "PT", text: "Portugal" },
  PR: { value: "PR", text: "Puerto Rico" },
  QA: { value: "QA", text: "Qatar" },
  RE: { value: "RE", text: "Reunion" },
  RO: { value: "RO", text: "Romania" },
  RU: { value: "RU", text: "Russia" },
  RW: { value: "RW", text: "Rwanda" },
  SH: { value: "SH", text: "Saint Helena" },
  KN: { value: "KN", text: "Saint Kitts and Nevis" },
  LC: { value: "LC", text: "Saint Lucia" },
  PM: { value: "PM", text: "Saint Pierre and Miquelon" },
  VC: { value: "VC", text: "Saint Vincent and the Grenadines" },
  WS: { value: "WS", text: "Samoa" },
  SM: { value: "SM", text: "San Marino" },
  ST: { value: "ST", text: "Sao Tome and Principe" },
  SA: { value: "SA", text: "Saudi Arabia" },
  SN: { value: "SN", text: "Senegal" },
  SC: { value: "SC", text: "Seychelles" },
  SL: { value: "SL", text: "Sierra Leone" },
  SG: { value: "SG", text: "Singapore" },
  SK: { value: "SK", text: "Slovakia" },
  SI: { value: "SI", text: "Slovenia" },
  SB: { value: "SB", text: "Solomon Islands" },
  SO: { value: "SO", text: "Somalia" },
  ZA: { value: "ZA", text: "South Africa" },
  GS: { value: "GS", text: "South Georgia and the South Sandwich Islands" },
  ES: { value: "ES", text: "Spain" },
  LK: { value: "LK", text: "Sri Lanka" },
  SD: { value: "SD", text: "Sudan" },
  SR: { value: "SR", text: "Suriname" },
  SJ: { value: "SJ", text: "Svalbard and Jan Mayen" },
  SZ: { value: "SZ", text: "Eswatini" },
  SE: { value: "SE", text: "Sweden" },
  CH: { value: "CH", text: "Switzerland" },
  SY: { value: "SY", text: "Syrian Arab Republic" },
  TW: { value: "TW", text: "Taiwan" },
  TJ: { value: "TJ", text: "Tajikistan" },
  TZ: { value: "TZ", text: "Tanzania" },
  TH: { value: "TH", text: "Thailand" },
  TL: { value: "TL", text: "Timor-Leste" },
  TG: { value: "TG", text: "Togo" },
  TK: { value: "TK", text: "Tokelau" },
  TO: { value: "TO", text: "Tonga" },
  TT: { value: "TT", text: "Trinidad and Tobago" },
  TN: { value: "TN", text: "Tunisia" },
  TR: { value: "TR", text: "Turkey" },
  TM: { value: "TM", text: "Turkmenistan" },
  TC: { value: "TC", text: "Turks and Caicos Islands" },
  TV: { value: "TV", text: "Tuvalu" },
  UG: { value: "UG", text: "Uganda" },
  UA: { value: "UA", text: "Ukraine" },
  AE: { value: "AE", text: "United Arab Emirates" },
  GB: { value: "GB", text: "United Kingdom" },
  US: { value: "US", text: "United States of America" },
  UM: { value: "UM", text: "United States Minor Outlying Islands" },
  UY: { value: "UY", text: "Uruguay" },
  UZ: { value: "UZ", text: "Uzbekistan" },
  VU: { value: "VU", text: "Vanuatu" },
  VE: { value: "VE", text: "Venezuela" },
  VN: { value: "VN", text: "Vietnam" },
  VG: { value: "VG", text: "Virgin Islands, British" },
  VI: { value: "VI", text: "Virgin Islands, U.S." },
  WF: { value: "WF", text: "Wallis and Futuna" },
  EH: { value: "EH", text: "Western Sahara" },
  YE: { value: "YE", text: "Yemen" },
  ZM: { value: "ZM", text: "Zambia" },
  ZW: { value: "ZW", text: "Zimbabwe" },
  AX: { value: "AX", text: "Åland Islands" },
  BQ: { value: "BQ", text: "Bonaire, Sint Eustatius and Saba" },
  CW: { value: "CW", text: "Curaçao" },
  GG: { value: "GG", text: "Guernsey" },
  IM: { value: "IM", text: "Isle of Man" },
  JE: { value: "JE", text: "Jersey" },
  ME: { value: "ME", text: "Montenegro" },
  BL: { value: "BL", text: "Saint Barthélemy" },
  MF: { value: "MF", text: "Saint Martin (French part)" },
  RS: { value: "RS", text: "Serbia" },
  SX: { value: "SX", text: "Sint Maarten (Dutch part)" },
  SS: { value: "SS", text: "South Sudan" },
  XK: { value: "XK", text: "Kosovo" },
};
