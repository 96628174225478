<template>
  <component :is="iconName" v-bind="[$props, $attrs]" v-on="$listeners" />
</template>

<script>
import lodash from "lodash";
import AlarmBell from "@/components/design_system/icons/AlarmBell";
import Alert from "@/components/design_system/icons/Alert";
import AlertCircle from "@/components/design_system/icons/AlertCircle";
import ArrowLeftRight from "@/components/design_system/icons/ArrowLeftRight";
import Calendar from "@/components/design_system/icons/Calendar";
import CaretUp from "@/components/design_system/icons/CaretUp";
import CaretDown from "@/components/design_system/icons/CaretDown";
import CaretLeft from "@/components/design_system/icons/CaretLeft";
import CaretRight from "@/components/design_system/icons/CaretRight";
import CheckmarkCircle from "@/components/design_system/icons/CheckmarkCircle";
import Close from "@/components/design_system/icons/Close";
import CloseCircle from "@/components/design_system/icons/CloseCircle";
import ColumnLeft from "@/components/design_system/icons/ColumnLeft";
import ColumnTop from "@/components/design_system/icons/ColumnTop";
import Database from "@/components/design_system/icons/Database";
import Django from "@/components/design_system/icons/Django";
import DotsMenuHorizontal from "@/components/design_system/icons/DotsMenuHorizontal";
import DotsMenuVertical from "@/components/design_system/icons/DotsMenuVertical";
import Excel from "@/components/design_system/icons/Excel";
import Files from "@/components/design_system/icons/Files";
import File from "@/components/design_system/icons/File";
import HospitalBuilding from "@/components/design_system/icons/HospitalBuilding";
import Download from "@/components/design_system/icons/Download";
import Info from "@/components/design_system/icons/Info";
import InfoCircle from "@/components/design_system/icons/InfoCircle";
import Jupyter from "@/components/design_system/icons/Jupyter";
import Megaphone from "@/components/design_system/icons/Megaphone";
import Layers from "@/components/design_system/icons/Layers";
import Minus from "@/components/design_system/icons/Minus";
import Pencil from "@/components/design_system/icons/Pencil";
import Search from "@/components/design_system/icons/Search";
import TextBloc from "@/components/design_system/icons/TextBloc";
import Trash from "@/components/design_system/icons/Trash";
import User from "@/components/design_system/icons/User";
import UserCircle from "@/components/design_system/icons/UserCircle";
import Users from "@/components/design_system/icons/Users";
import Plus from "@/components/design_system/icons/Plus";
import ChevronUp from "@/components/design_system/icons/ChevronUp";
import ChevronDown from "@/components/design_system/icons/ChevronDown";
import ChevronLeft from "@/components/design_system/icons/ChevronLeft";
import ChevronRight from "@/components/design_system/icons/ChevronRight";
import ArrowUpAndDown from "@/components/design_system/icons/ArrowUpAndDown";
import ArrowLeftAndRight from "@/components/design_system/icons/ArrowLeftAndRight";
import Checkmark from "@/components/design_system/icons/Checkmark";
import Logout from "@/components/design_system/icons/Logout";
import Login from "@/components/design_system/icons/Login";
import Picture from "@/components/design_system/icons/Picture";
import Pictures from "@/components/design_system/icons/Pictures";
import ArrowUp from "@/components/design_system/icons/ArrowUp";
import ArrowDown from "@/components/design_system/icons/ArrowDown";
import ZoomPlus from "@/components/design_system/icons/ZoomPlus";
import ZoomMinus from "@/components/design_system/icons/ZoomMinus";
import Upload from "@/components/design_system/icons/Upload";
import Share from "@/components/design_system/icons/Share";
import PencilEditLine from "@/components/design_system/icons/PencilEditLine";
import Open from "@/components/design_system/icons/Open";
import OpenCircle from "@/components/design_system/icons/OpenCircle";
import FilterBasic from "@/components/design_system/icons/FilterBasic";
import FilterLine from "@/components/design_system/icons/FilterLine";
import Column from "@/components/design_system/icons/Column";
import Hyperlink from "@/components/design_system/icons/Hyperlink";
import ArrowUpCircle from "@/components/design_system/icons/ArrowUpCircle";
import ArrowDownCircle from "@/components/design_system/icons/ArrowDownCircle";
import ArrowLeftCircle from "@/components/design_system/icons/ArrowLeftCircle";
import ArrowRightCircle from "@/components/design_system/icons/ArrowRightCircle";
import EyeOpen from "@/components/design_system/icons/EyeOpen";
import Printer from "@/components/design_system/icons/Printer";
import Save from "@/components/design_system/icons/Save";
import Clipboard from "@/components/design_system/icons/Clipboard";
import SettingsGear from "@/components/design_system/icons/SettingsGear";
import BookOpen from "@/components/design_system/icons/BookOpen";
import BookClosed from "@/components/design_system/icons/BookClosed";
import Newspaper from "@/components/design_system/icons/Newspaper";
import AdressBook from "@/components/design_system/icons/AdressBook";
import AdressBooks from "@/components/design_system/icons/AdressBooks";
import ClipboardMedicalFile from "@/components/design_system/icons/ClipboardMedicalFile";
import MedicalFolder from "@/components/design_system/icons/MedicalFolder";
import ClipboardsMedicalFile from "@/components/design_system/icons/ClipboardsMedicalFile";
import ArrowRightUp from "@/components/design_system/icons/ArrowRightUp";
import TextCentered from "@/components/design_system/icons/TextCentered";
import TextLeft from "@/components/design_system/icons/TextLeft";
import TextRight from "@/components/design_system/icons/TextRight";
import ArrowLeft from "@/components/design_system/icons/ArrowLeft";
import ArrowRight from "@/components/design_system/icons/ArrowRight";
import Import from "@/components/design_system/icons/Import";
import Folder from "@/components/design_system/icons/Folder";
import Csv from "@/components/design_system/icons/Csv";
import MenuBurger from "@/components/design_system/icons/MenuBurger";
import List from "@/components/design_system/icons/List";
import BulletList from "@/components/design_system/icons/BulletList";
import NumberList from "@/components/design_system/icons/NumberList";
import DragDotsVertical from "@/components/design_system/icons/DragDotsVertical";
import DragDotsHorizontal from "@/components/design_system/icons/DragDotsHorizontal";
import SettingsSlider from "@/components/design_system/icons/SettingsSlider";
import Play from "@/components/design_system/icons/Play";
import PlayCircle from "@/components/design_system/icons/PlayCircle";
import Squares from "@/components/design_system/icons/Squares";
import Inbox from "@/components/design_system/icons/Inbox";
import SingleBulletList from "@/components/design_system/icons/SingleBulletList";
import ServerDownload from "@/components/design_system/icons/ServerDownload";
import ServerLocker from "@/components/design_system/icons/ServerLocker";
import ServerUpload from "@/components/design_system/icons/ServerUpload";
import ServerConnect from "@/components/design_system/icons/ServerConnect";
import Word from "@/components/design_system/icons/Word";
import Virus from "@/components/design_system/icons/Virus";
import TestTube from "@/components/design_system/icons/TestTube";
import Star from "@/components/design_system/icons/Star";
import SpeechBubbleSquare from "@/components/design_system/icons/SpeechBubbleSquare";
import SpeechBubbles from "@/components/design_system/icons/SpeechBubbles";
import SpeechBubble from "@/components/design_system/icons/SpeechBubble";
import RadioButton from "@/components/design_system/icons/RadioButton";
import PhoneOutcoingCall from "@/components/design_system/icons/PhoneOutcoingCall";
import PhoneIncomingCall from "@/components/design_system/icons/PhoneIncomingCall";
import Phone from "@/components/design_system/icons/Phone";
import Other from "@/components/design_system/icons/Other";
import MobilePhone from "@/components/design_system/icons/MobilePhone";
import MedicalMask from "@/components/design_system/icons/MedicalMask";
import Male from "@/components/design_system/icons/Male";
import MailOpen from "@/components/design_system/icons/MailOpen";
import MailClosed from "@/components/design_system/icons/MailClosed";
import MagicWand from "@/components/design_system/icons/MagicWand";
import LockOpen from "@/components/design_system/icons/LockOpen";
import LockClose from "@/components/design_system/icons/LockClose";
import Flask from "@/components/design_system/icons/Flask";
import Female from "@/components/design_system/icons/Female";
import DocTime from "@/components/design_system/icons/DocTime";
import DesktopMonitor from "@/components/design_system/icons/DesktopMonitor";
import CloudOff from "@/components/design_system/icons/CloudOff";
import CloudFilled from "@/components/design_system/icons/CloudFilled";
import CloudCheckmark from "@/components/design_system/icons/CloudCheckmark";
import CheckButton from "@/components/design_system/icons/CheckButton";
import SpeechMinus from "@/components/design_system/icons/SpeechMinus";
import Section from "@/components/design_system/icons/Section";
import NumberOne from "@/components/design_system/icons/NumberOne";
import InfoSquare from "@/components/design_system/icons/InfoSquare";
import InfoSquareUnderline from "@/components/design_system/icons/InfoSquareUnderline";
import Category from "@/components/design_system/icons/Category";
import Stop from "@/components/design_system/icons/Stop";
import Loop from "@/components/design_system/icons/Loop";
import BirthdayCake from "@/components/design_system/icons/BirthdayCake";
import DrCare from "@/components/design_system/icons/DrCare";
import DrWarehouse from "@/components/design_system/icons/DrWarehouse";
import Pill from "@/components/design_system/icons/Pill";
import Hierarchy from "@/components/design_system/icons/Hierarchy";
import PiggyBank from "@/components/design_system/icons/PiggyBank";
import Umbrella from "@/components/design_system/icons/Umbrella";
import LampIdea from "@/components/design_system/icons/LampIdea";
import Lamp from "@/components/design_system/icons/Lamp";
import Pin from "@/components/design_system/icons/Pin";
import MinusCircle from "@/components/design_system/icons/MinusCircle";
import ExternalLink from "@/components/design_system/icons/ExternalLink";
import ArrowLeftDown from "@/components/design_system/icons/ArrowLeftDown";
import EyeClose from "@/components/design_system/icons/EyeClose";
import DrAnonymous from "@/components/design_system/icons/DrAnonymous";

export default {
  name: "Icon",
  components: {
    AlarmBell,
    AlertCircle,
    Alert,
    ArrowLeftRight,
    Calendar,
    CaretRight,
    CaretLeft,
    CaretDown,
    CaretUp,
    CheckmarkCircle,
    Close,
    CloseCircle,
    ColumnTop,
    ColumnLeft,
    Database,
    Django,
    DotsMenuVertical,
    DotsMenuHorizontal,
    Excel,
    File,
    Files,
    HospitalBuilding,
    Download,
    Info,
    InfoCircle,
    Jupyter,
    Megaphone,
    Layers,
    Minus,
    Pencil,
    Search,
    TextBloc,
    Trash,
    User,
    UserCircle,
    Users,
    Plus,
    ChevronUp,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ArrowUpAndDown,
    ArrowLeftAndRight,
    Checkmark,
    Logout,
    Login,
    Picture,
    Pictures,
    ArrowUp,
    ArrowDown,
    ZoomPlus,
    ZoomMinus,
    Upload,
    Share,
    PencilEditLine,
    Open,
    OpenCircle,
    FilterBasic,
    FilterLine,
    Column,
    Hyperlink,
    ArrowUpCircle,
    ArrowDownCircle,
    ArrowLeftCircle,
    ArrowRightCircle,
    EyeOpen,
    Printer,
    Save,
    Clipboard,
    SettingsGear,
    BookOpen,
    BookClosed,
    Newspaper,
    AdressBooks,
    AdressBook,
    ClipboardMedicalFile,
    MedicalFolder,
    ClipboardsMedicalFile,
    ArrowRightUp,
    TextCentered,
    TextLeft,
    TextRight,
    ArrowLeft,
    ArrowRight,
    Import,
    Folder,
    Csv,
    MenuBurger,
    List,
    BulletList,
    NumberList,
    DragDotsVertical,
    DragDotsHorizontal,
    SettingsSlider,
    Play,
    PlayCircle,
    Squares,
    Inbox,
    SingleBulletList,
    ServerDownload,
    ServerLocker,
    ServerUpload,
    ServerConnect,
    CloudOff,
    CloudCheckmark,
    CloudFilled,
    DocTime,
    DesktopMonitor,
    Female,
    Flask,
    LockClose,
    LockOpen,
    MagicWand,
    Male,
    MailOpen,
    MailClosed,
    MedicalMask,
    MobilePhone,
    Other,
    Phone,
    PhoneOutcoingCall,
    PhoneIncomingCall,
    RadioButton,
    SpeechBubble,
    SpeechBubbles,
    SpeechBubbleSquare,
    Star,
    Virus,
    Word,
    CheckButton,
    TestTube,
    SpeechMinus,
    Section,
    NumberOne,
    Category,
    InfoSquare,
    InfoSquareUnderline,
    Stop,
    Loop,
    BirthdayCake,
    DrCare,
    DrWarehouse,
    Pill,
    Hierarchy,
    PiggyBank,
    Umbrella,
    LampIdea,
    Lamp,
    Pin,
    MinusCircle,
    ExternalLink,
    ArrowLeftDown,
    EyeClose,
    DrAnonymous,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    // eslint-disable-next-line vue/no-unused-properties
    size: {
      type: [String, Number],
      default: 24,
    },

    // eslint-disable-next-line vue/no-unused-properties
    color: {
      type: String,
      default: "primary-main",
    },

    // eslint-disable-next-line vue/no-unused-properties
    outline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    iconName() {
      const name = lodash.camelCase(this.name);
      return name[0].toUpperCase() + name.slice(1);
    },

    // eslint-disable-next-line vue/no-unused-properties
    getColor() {
      return `var(--${this.color})`;
    },
  },
};
</script>
