<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00277 4C6.44694 4 6 4.44671 6 5.00324V18.9968C6 19.5533 6.44694 20 7.00277 20H16.9972C17.5374 20 17.9931 19.5581 18 18.9917V5.00324C18 4.44671 17.5531 4 16.9972 4H7.00277ZM4 5.00324C4 3.34258 5.34194 2 7.00277 2H16.9972C18.6581 2 20 3.34258 20 5.00324V19.0055C19.9855 20.6545 18.6531 22 16.9972 22H7.00277C5.34194 22 4 20.6574 4 18.9968V5.00324Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 12C7 11.4477 7.44772 11 8 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H8C7.44772 13 7 12.5523 7 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 12C11 11.4477 11.4477 11 12 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H12C11.4477 13 11 12.5523 11 12Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SingleBulletList",
};
</script>
