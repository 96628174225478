<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7124 6.68022C19.1 6.2918 19.0952 5.66668 18.7018 5.284C18.3084 4.90131 17.6753 4.90597 17.2876 5.29439L12 10.5932L6.71235 5.29439C6.32474 4.90597 5.6916 4.90131 5.29818 5.284C4.90476 5.66668 4.90004 6.2918 5.28765 6.68022L10.5962 12L5.28765 17.3198C4.90004 17.7082 4.90476 18.3333 5.29818 18.716C5.6916 19.0987 6.32474 19.094 6.71235 18.7056L12 13.4068L17.2876 18.7056C17.6753 19.094 18.3084 19.0987 18.7018 18.716C19.0952 18.3333 19.1 17.7082 18.7124 17.3198L13.4038 12L18.7124 6.68022Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.25 2H5.75C3.68 2 2 3.68 2 5.75V18.25C2 20.32 3.68 22 5.75 22H18.25C20.32 22 22 20.32 22 18.25V5.75C22 3.68 20.32 2 18.25 2ZM15.95 14.94C16.25 15.24 16.24 15.71 15.95 16C15.8 16.15 15.61 16.22 15.42 16.22C15.23 16.22 15.03 16.15 14.89 16L12 13.07L9.12 16C8.97 16.15 8.77 16.22 8.58 16.22C8.39 16.22 8.2 16.15 8.06 16C7.76 15.71 7.76 15.24 8.05 14.94L10.95 12L8.05 9.06C7.76 8.76 7.76 8.29 8.06 8C8.35 7.71 8.81999 7.71 9.12 8L12 10.93L14.89 8C15.18 7.71 15.65 7.71 15.95 8C16.24 8.29 16.25 8.76 15.95 9.06L13.05 12L15.95 14.94Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Close",
};
</script>
