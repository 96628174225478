<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55345 3.95122 3.95122 7.55345 3.95122 12C3.95122 16.4466 7.55345 20.0488 12 20.0488C16.4466 20.0488 20.0488 16.4466 20.0488 12C20.0488 7.55345 16.4466 3.95122 12 3.95122ZM2 12C2 6.47582 6.47582 2 12 2C17.5242 2 22 6.47582 22 12C22 17.5242 17.5242 22 12 22C6.47582 22 2 17.5242 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6781 10.8555C16.1073 10.4311 16.1073 9.74284 15.6781 9.31836C15.249 8.89388 14.5532 8.89388 14.1241 9.31836L11.033 12.3759L9.87594 11.2314C9.44679 10.8069 8.75101 10.8069 8.32186 11.2314C7.89271 11.6559 7.89271 12.3441 8.32186 12.7686L10.2559 14.6816C10.6851 15.1061 11.3809 15.1061 11.81 14.6816L15.6781 10.8555Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.59 10.59L12.07 14.11C11.92 14.26 11.73 14.33 11.54 14.33C11.35 14.33 11.15 14.26 11.01 14.11L9.25 12.35C8.95 12.06 8.95 11.58 9.25 11.29C9.54 11 10.02 11 10.31 11.29L11.54 12.52L14.53 9.53C14.82 9.24 15.29 9.24 15.59 9.53C15.88 9.82 15.88 10.3 15.59 10.59Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckmarkCircle",
};
</script>
