<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V16C4 16.364 4.00474 16.7386 4.09207 17.0705C4.17483 17.385 4.29625 17.5301 4.44721 17.6056C4.94119 17.8526 5.14142 18.4532 4.89443 18.9472C4.64744 19.4412 4.04676 19.6414 3.55279 19.3944C2.70375 18.9699 2.32517 18.215 2.15792 17.5795C1.99964 16.978 1.99987 16.3697 1.99999 16.0278C2 16.0183 2 16.009 2 16V6C2 3.79086 3.79086 2 6 2L16 2.00001C17.1456 2.00001 18.1511 2.61131 18.7715 3.36381C19.1229 3.78992 19.0623 4.42018 18.6362 4.77154C18.2101 5.12289 17.5798 5.06229 17.2285 4.63618C16.9089 4.24869 16.4344 4.00001 16 4.00001L6 4Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 8H19C19.5523 8 20 8.44772 20 9V13.5753L18.9915 12.4869C18.9857 12.4806 18.9798 12.4744 18.9738 12.4683C18.1636 11.6416 16.8388 11.6511 16.0281 12.4618L13.0105 15.4794L13.004 15.486C12.918 15.5737 12.7615 15.5832 12.6577 15.4794L11.9747 14.7964C11.167 13.9763 9.85129 13.9841 9.04111 14.7852L8 15.7799V9C8 8.44772 8.44772 8 9 8ZM8 18.3093V19C8 19.5523 8.44772 20 9 20H19C19.5523 20 20 19.5523 20 19V16.2662L17.6621 13.743C17.5751 13.6604 17.4229 13.6534 17.3213 13.755L14.3065 16.7699C13.496 17.5928 12.1739 17.5821 11.3645 16.7726L10.6787 16.0868L10.6722 16.0803C10.5862 15.9926 10.4297 15.9831 10.3259 16.0868L10.311 16.1014L8 18.3093ZM6 9C6 7.34315 7.34315 6 9 6H19C20.6569 6 22 7.34315 22 9V19C22 20.6569 20.6569 22 19 22H9C7.34315 22 6 20.6569 6 19V9ZM11.5 9C10.6691 9 10 9.66905 10 10.5C10 11.3309 10.6691 12 11.5 12C12.331 12 13 11.3309 13 10.5C13 9.66905 12.3202 9 11.5 9Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.96 4.26001C17.64 4.52001 17.17 4.47 16.9 4.16C16.56 3.74 16.06 3.5 15.52 3.5H5.29001C4.31001 3.5 3.5 4.30001 3.5 5.29001V15.52C3.5 16 3.69 16.45 4.03 16.79C4.32 17.08 4.32 17.55 4.03 17.85C3.88 17.99 3.69 18.07 3.5 18.07C3.3 18.07 3.11 17.99 2.97 17.85C2.34 17.22 2 16.4 2 15.52V5.29001C2 3.48001 3.48001 2 5.29001 2H15.52C16.51 2 17.44 2.44001 18.06 3.20001C18.33 3.52001 18.28 4.00001 17.96 4.26001Z"
      :fill="$parent.getColor"
    />
    <path
      d="M4.03 16.79C4.32 17.08 4.32 17.55 4.03 17.85C3.88 17.99 3.69 18.07 3.5 18.07C3.3 18.07 3.11 17.99 2.97 17.85C2.34 17.22 2 16.4 2 15.52V5.29001C2 3.48001 3.48001 2 5.29001 2H15.52C16.51 2 17.44 2.44001 18.06 3.20001C18.33 3.52001 18.28 4.00001 17.96 4.26001C17.64 4.52001 17.17 4.47 16.9 4.16C16.56 3.74 16.06 3.5 15.52 3.5H5.29001C4.31001 3.5 3.5 4.30001 3.5 5.29001V15.52C3.5 16 3.69 16.45 4.03 16.79Z"
      :fill="$parent.getColor"
    />
    <path
      d="M18.71 5.19H8.48001C6.67001 5.19 5.19 6.67001 5.19 8.48001V18.71C5.19 20.52 6.67001 22 8.48001 22H18.71C20.52 22 22 20.52 22 18.71V8.48001C22 6.67001 20.52 5.19 18.71 5.19ZM10.41 8.42001C11.17 8.42001 11.79 9.04 11.79 9.81C11.79 10.58 11.17 11.2 10.41 11.2C9.64 11.2 9.01999 10.58 9.01999 9.81C9.01999 9.04 9.64 8.42001 10.41 8.42001ZM20.5 18.71C20.5 19.7 19.7 20.5 18.71 20.5H8.48001C7.49001 20.5 6.69 19.7 6.69 18.71V18.41L9.39999 15.71C9.59999 15.5 9.94001 15.5 10.14 15.71L10.89 16.46C11.65 17.22 12.99 17.22 13.75 16.46L17.05 13.16C17.25 12.96 17.6 12.96 17.8 13.16L20.5 15.86V18.71Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Pictures",
};
</script>
