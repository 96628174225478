<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.93208 8.93911C5.21865 8.93911 4.65172 9.50966 4.65172 10.1864V10.6077H4.68866C5.09364 10.6077 5.42193 10.9333 5.42193 11.3349C5.42193 11.7365 5.09364 12.0621 4.68866 12.0621H4.65172V14.0012H4.68866C5.09364 14.0012 5.42193 14.3267 5.42193 14.7283C5.42193 15.1299 5.09364 15.4555 4.68866 15.4555H4.65172V17.3946H4.6891C5.09407 17.3946 5.42237 17.7202 5.42237 18.1218C5.42237 18.5234 5.09407 18.8489 4.6891 18.8489H4.65223C4.67154 19.5101 5.23109 20.0609 5.93208 20.0609H11.7642C12.4777 20.0609 13.0446 19.4903 13.0446 18.8136V10.1864C13.0446 9.50966 12.4777 8.93911 11.7642 8.93911H5.93208ZM2.69633 15.4546V17.3955C2.30852 17.4146 2 17.7325 2 18.1218C2 18.5112 2.30861 18.8291 2.69651 18.848C2.71535 20.5981 4.16887 22 5.93208 22H11.7642C13.539 22 15 20.5796 15 18.8136V10.1864C15 8.42035 13.539 7 11.7642 7H5.93208C4.15732 7 2.69633 8.42035 2.69633 10.1864V10.6086C2.30852 10.6277 2 10.9456 2 11.3349C2 11.7242 2.30852 12.0421 2.69633 12.0612V14.0021C2.30852 14.0212 2 14.339 2 14.7283C2 15.1177 2.30852 15.4355 2.69633 15.4546ZM6.64405 9.63817C6.23908 9.63817 5.91078 9.96373 5.91078 10.3653V13.274C5.91078 13.6756 6.23908 14.0012 6.64405 14.0012H11.5325C11.9375 14.0012 12.2658 13.6756 12.2658 13.274V10.3653C12.2658 9.96373 11.9375 9.63817 11.5325 9.63817H6.64405ZM7.37732 12.5468V11.0925H10.7993V12.5468H7.37732ZM5.91078 16.1827C5.91078 15.7811 6.23908 15.4555 6.64405 15.4555H11.5325C11.9375 15.4555 12.2658 15.7811 12.2658 16.1827C12.2658 16.5843 11.9375 16.9098 11.5325 16.9098H6.64405C6.23908 16.9098 5.91078 16.5843 5.91078 16.1827ZM6.64405 17.3946C6.23908 17.3946 5.91078 17.7202 5.91078 18.1218C5.91078 18.5234 6.23908 18.8489 6.64405 18.8489H11.5325C11.9375 18.8489 12.2658 18.5234 12.2658 18.1218C12.2658 17.7202 11.9375 17.3946 11.5325 17.3946H6.64405Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.96072 4.96846C10.1207 4.41473 10.643 4 11.2631 4H17.6454C18.3991 4 18.9986 4.60786 18.9986 5.32963V14.3959C18.9986 15.1177 18.3991 15.7256 17.6454 15.7256H17.3044C16.7521 15.7256 16.3044 16.1733 16.3044 16.7256C16.3044 17.2778 16.7521 17.7256 17.3044 17.7256H17.6454C19.4846 17.7256 20.9986 16.2412 20.9986 14.3959V5.32963C20.9986 3.48435 19.4846 2 17.6454 2H11.2631C9.73742 2 8.44232 3.01813 8.03928 4.41345C7.88601 4.94404 8.1919 5.49841 8.72249 5.65168C9.25309 5.80494 9.80746 5.49905 9.96072 4.96846ZM14.75 4.97528C14.3358 4.97528 14 5.31107 14 5.72528C14 6.13949 14.3358 6.47528 14.75 6.47528H16.3088V8.66647C16.3088 9.08068 16.6446 9.41647 17.0588 9.41647C17.473 9.41647 17.8088 9.08068 17.8088 8.66647V5.72528C17.8088 5.31107 17.473 4.97528 17.0588 4.97528H14.75ZM17.9077 13.75C17.9077 14.1642 17.5719 14.5 17.1577 14.5C16.7435 14.5 16.4077 14.1642 16.4077 13.75C16.4077 13.3358 16.7435 13 17.1577 13C17.5719 13 17.9077 13.3358 17.9077 13.75ZM17.1577 12.5C17.5719 12.5 17.9077 12.1642 17.9077 11.75C17.9077 11.3358 17.5719 11 17.1577 11C16.7435 11 16.4077 11.3358 16.4077 11.75C16.4077 12.1642 16.7435 12.5 17.1577 12.5Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AdressBooks",
};
</script>
