<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0016 4L10 4.00007V4.42492C10.0018 4.44971 10.0028 4.47474 10.0028 4.5C10.0028 4.52526 10.0018 4.55029 10 4.57508V4.99993L10.0016 5H13.9931C13.9952 4.99789 13.9971 4.99509 13.9983 4.99245C13.9983 4.99245 13.9997 4.9887 14 4.98534V4.00007L13.9984 4H10.0016ZM8.06286 3.5C8.28484 2.63594 9.06898 2 10.0016 2H13.9984C14.931 2 15.7152 2.63594 15.9371 3.5H16.9972C18.6449 3.5 20 4.79289 20 6.5V18.9364C20 20.6022 18.6859 22 16.9972 22H7.00278C5.31414 22 4 20.6022 4 18.9364V6.5C4 4.79469 5.35046 3.5 7 3.5H8.06286ZM15.9329 5.5H16.9972C17.5662 5.5 18 5.92308 18 6.5V18.9364C18 19.5545 17.5253 20 16.9972 20H7.00278C6.47474 20 6 19.5545 6 18.9364V6.5C6 5.92128 6.43287 5.5 7 5.5H8.06286C8.28484 6.36406 9.06897 7 10.0016 7H13.9984C14.934 7 15.7066 6.35557 15.9329 5.5ZM13 9.00003C13 8.44775 12.5523 8.00003 12 8.00003C11.4477 8.00003 11 8.44775 11 9.00003V9.50003H10.5C9.94772 9.50003 9.5 9.94775 9.5 10.5C9.5 11.0523 9.94772 11.5 10.5 11.5H11V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V11.5H13.5C14.0523 11.5 14.5 11.0523 14.5 10.5C14.5 9.94775 14.0523 9.50003 13.5 9.50003H13V9.00003ZM7 15C7 14.4477 7.44772 14 8 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H8C7.44772 16 7 15.5523 7 15ZM8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18C17 17.4477 16.5523 17 16 17H8Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.14 3.55H16.11C16.01 3.55 15.91 3.57 15.82 3.61C15.73 2.71 14.98 2 14.06 2H9.94C9.04 2 8.31 2.68 8.19 3.55H6.86C5.31 3.55 4.06 4.8 4.06 6.35V19.2C4.06 20.75 5.31 22 6.86 22H17.14C18.69 22 19.94 20.75 19.94 19.2V6.35C19.94 4.8 18.69 3.55 17.14 3.55ZM7.14 18.17C7.14 17.76 7.47 17.42 7.89 17.42H16.11C16.53 17.42 16.86 17.76 16.86 18.17C16.86 18.58 16.53 18.92 16.11 18.92H7.89C7.47 18.92 7.14 18.58 7.14 18.17ZM16.11 15.84H7.89C7.47 15.84 7.14 15.5 7.14 15.09C7.14 14.67 7.47 14.34 7.89 14.34H16.11C16.53 14.34 16.86 14.67 16.86 15.09C16.86 15.5 16.53 15.84 16.11 15.84ZM10.46 9.71H11.25V8.92C11.25 8.51 11.59 8.17 12 8.17C12.41 8.17 12.75 8.51 12.75 8.92V9.71H13.54C13.96 9.71 14.29 10.05 14.29 10.46C14.29 10.88 13.96 11.21 13.54 11.21H12.75V12C12.75 12.42 12.41 12.75 12 12.75C11.59 12.75 11.25 12.42 11.25 12V11.21H10.46C10.04 11.21 9.71 10.88 9.71 10.46C9.71 10.05 10.04 9.71 10.46 9.71ZM9.67 3.78C9.67 3.63 9.79 3.5 9.94 3.5H14.06C14.21 3.5 14.33 3.63 14.33 3.78V4.81C14.33 4.96 14.21 5.09 14.06 5.09H9.94C9.79 5.09 9.67 4.96 9.67 4.81V3.78Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ClipboardMedicalFile",
};
</script>
