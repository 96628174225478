<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00277 4C6.44694 4 6 4.44671 6 5.00324V18.9968C6 19.5533 6.44694 20 7.00277 20H16.9972C17.5374 20 17.9931 19.5581 18 18.9917V5.00324C18 4.44671 17.5531 4 16.9972 4H7.00277ZM4 5.00324C4 3.34258 5.34194 2 7.00277 2H16.9972C18.6581 2 20 3.34258 20 5.00324V19.0055C19.9855 20.6545 18.6531 22 16.9972 22H7.00277C5.34194 22 4 20.6574 4 18.9968V5.00324Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 7C7 6.44772 7.44772 6 8 6H9C9.55228 6 10 6.44772 10 7C10 7.55228 9.55228 8 9 8H8C7.44772 8 7 7.55228 7 7Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 7C11 6.44772 11.4477 6 12 6H16C16.5523 6 17 6.44772 17 7C17 7.55228 16.5523 8 16 8H12C11.4477 8 11 7.55228 11 7Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 12C7 11.4477 7.44772 11 8 11H9C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13H8C7.44772 13 7 12.5523 7 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 12C11 11.4477 11.4477 11 12 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H12C11.4477 13 11 12.5523 11 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 17C7 16.4477 7.44772 16 8 16H9C9.55228 16 10 16.4477 10 17C10 17.5523 9.55228 18 9 18H8C7.44772 18 7 17.5523 7 17Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 17C11 16.4477 11.4477 16 12 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H12C11.4477 18 11 17.5523 11 17Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.14 1.96002H6.85999C5.31999 1.96002 4.06 3.21001 4.06 4.76001V19.15C4.06 20.7 5.31999 21.96 6.85999 21.96H17.14C18.69 21.96 19.94 20.7 19.94 19.15V4.76001C19.94 3.21001 18.69 1.96002 17.14 1.96002ZM7.89001 11.25H8.92001C9.33001 11.25 9.67001 11.59 9.67001 12C9.67001 12.42 9.33001 12.75 8.92001 12.75H7.89001C7.48001 12.75 7.14001 12.42 7.14001 12C7.14001 11.59 7.48001 11.25 7.89001 11.25ZM7.14001 6.86002C7.14001 6.45002 7.48001 6.11002 7.89001 6.11002H8.92001C9.33001 6.11002 9.67001 6.45002 9.67001 6.86002C9.67001 7.28002 9.33001 7.61002 8.92001 7.61002H7.89001C7.48001 7.61002 7.14001 7.28002 7.14001 6.86002ZM7.89001 16.39H8.92001C9.33001 16.39 9.67001 16.73 9.67001 17.14C9.67001 17.55 9.33001 17.89 8.92001 17.89H7.89001C7.48001 17.89 7.14001 17.55 7.14001 17.14C7.14001 16.73 7.48001 16.39 7.89001 16.39ZM12 16.39H16.11C16.53 16.39 16.86 16.73 16.86 17.14C16.86 17.55 16.53 17.89 16.11 17.89H12C11.59 17.89 11.25 17.55 11.25 17.14C11.25 16.73 11.59 16.39 12 16.39ZM11.25 12C11.25 11.59 11.59 11.25 12 11.25H16.11C16.53 11.25 16.86 11.59 16.86 12C16.86 12.42 16.53 12.75 16.11 12.75H12C11.59 12.75 11.25 12.42 11.25 12ZM16.11 7.61002H12C11.59 7.61002 11.25 7.28002 11.25 6.86002C11.25 6.45002 11.59 6.11002 12 6.11002H16.11C16.53 6.11002 16.86 6.45002 16.86 6.86002C16.86 7.28002 16.53 7.61002 16.11 7.61002Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BulletList",
};
</script>
