<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H12C14.2091 22 16 20.2091 16 18V17C16 16.4477 15.5523 16 15 16C14.4477 16 14 16.4477 14 17V18C14 19.1046 13.1046 20 12 20H6C4.89543 20 4 19.1046 4 18L4 6C4 4.89543 4.89543 4 6 4H12C13.1046 4 14 4.89543 14 6V7C14 7.55228 14.4477 8 15 8C15.5523 8 16 7.55228 16 7V6C16 3.79086 14.2091 2 12 2H6ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H18.5858L17.2929 14.2929C16.9024 14.6834 16.9024 15.3166 17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289C16.9024 8.68342 16.9024 9.31658 17.2929 9.70711L18.5858 11H10Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Logout",
};
</script>
