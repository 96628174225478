<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.878 3.95217C7.50666 3.95217 3.95217 7.49829 3.95217 11.878V12.122C3.95217 16.4933 7.49829 20.0478 11.878 20.0478H12.122C16.4926 20.0478 20.0478 16.4926 20.0478 12.122V11.878C20.0478 7.50666 16.5017 3.95217 12.122 3.95217H11.878ZM2 11.878C2 6.41874 6.4299 2 11.878 2H12.122C17.5813 2 22 6.4299 22 11.878V12.122C22 17.5708 17.5708 22 12.122 22H11.878C6.41875 22 2 17.5701 2 12.122V11.878Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.926 8.79398C12.3087 8.40088 12.9279 8.40218 13.3091 8.79687L15.7146 11.2879L15.715 11.2882C16.0953 11.6821 16.0949 12.3188 15.7146 12.7121L13.3091 15.2031C12.9279 15.5978 12.3087 15.5991 11.926 15.206C11.5433 14.8129 11.542 14.1743 11.9232 13.7796L12.6677 13.0086H8.97799C8.43786 13.0086 8 12.5571 8 12C8 11.4429 8.43786 10.9914 8.97799 10.9914H12.6677L11.9232 10.2204C11.542 9.82572 11.5433 9.18708 11.926 8.79398Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.13 2H11.87C6.43 2 2 6.43 2 11.87V12.13C2 17.57 6.43 22 11.87 22H12.13C17.57 22 22 17.57 22 12.13V11.87C22 6.43 17.57 2 12.13 2ZM15.62 12.53L13.16 15C13.01 15.14 12.82 15.22 12.63 15.22C12.44 15.22 12.24 15.14 12.1 15C11.8 14.7 11.8 14.23 12.1 13.94L13.28 12.75H8.91C8.49 12.75 8.16 12.41 8.16 12C8.16 11.59 8.49 11.25 8.91 11.25H13.28L12.1 10.06C11.8 9.77 11.8 9.3 12.1 9C12.39 8.71 12.86 8.71 13.16 9L15.62 11.47C15.92 11.76 15.92 12.24 15.62 12.53Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRightCircle",
};
</script>
