<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 5C16 4.44773 16.4477 4 17 4C17.5523 4 18 4.44771 18 5C18 5.55229 17.5523 6 17 6C16.4477 6 16 5.55227 16 5ZM14.1707 4H3C2.44772 4 2 4.44772 2 5C2 5.55228 2.44772 6 3 6H14.1707C14.5826 7.1652 15.6938 8 17 8C18.3062 8 19.4175 7.16519 19.8293 6H21C21.5523 6 22 5.55228 22 5C22 4.44772 21.5523 4 21 4H19.8293C19.4175 2.83481 18.3062 2 17 2C15.6938 2 14.5826 2.8348 14.1707 4ZM17 18C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19C16 18.4477 16.4477 18 17 18ZM3 18H14.1707C14.5826 16.8348 15.6938 16 17 16C18.3062 16 19.4175 16.8348 19.8293 18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H19.8293C19.4175 21.1652 18.3062 22 17 22C15.6938 22 14.5826 21.1652 14.1707 20H3C2.44772 20 2 19.5523 2 19C2 18.4477 2.44772 18 3 18ZM8 12C8 11.4477 7.55227 11 6.99999 11C6.44771 11 6 11.4477 6 12C6 12.5523 6.44771 13 6.99999 13C7.55227 13 8 12.5523 8 12ZM4.17071 11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H4.17071C4.58254 14.1652 5.69377 15 6.99999 15C8.30619 15 9.41745 14.1652 9.82929 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H9.82929C9.41745 9.8348 8.30619 9 6.99999 9C5.69377 9 4.58254 9.83481 4.17071 11Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 12.41 21.66 12.75 21.25 12.75H9.54999C9.21999 13.92 8.14999 14.79 6.85999 14.79C5.57999 14.79 4.50001 13.92 4.17001 12.75H2.75C2.34 12.75 2 12.41 2 12C2 11.59 2.34 11.25 2.75 11.25H4.17001C4.50001 10.08 5.57999 9.20999 6.85999 9.20999C8.14999 9.20999 9.21999 10.08 9.54999 11.25H21.25C21.66 11.25 22 11.59 22 12Z"
      :fill="$parent.getColor"
    />
    <path
      d="M22 19.15C22 19.57 21.66 19.9 21.25 19.9H19.83C19.5 21.08 18.42 21.94 17.14 21.94C15.86 21.94 14.78 21.08 14.45 19.9H2.75C2.34 19.9 2 19.57 2 19.15C2 18.74 2.34 18.4 2.75 18.4H14.45C14.78 17.23 15.86 16.36 17.14 16.36C18.42 16.36 19.5 17.23 19.83 18.4H21.25C21.66 18.4 22 18.74 22 19.15Z"
      :fill="$parent.getColor"
    />
    <path
      d="M22 4.85C22 5.26 21.66 5.6 21.25 5.6H19.83C19.5 6.77 18.42 7.64 17.14 7.64C15.86 7.64 14.78 6.77 14.45 5.6H2.75C2.34 5.6 2 5.26 2 4.85C2 4.43 2.34 4.1 2.75 4.1H14.45C14.78 2.92 15.86 2.06 17.14 2.06C18.42 2.06 19.5 2.92 19.83 4.1H21.25C21.66 4.1 22 4.43 22 4.85Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SettingsSlider",
};
</script>
