import { Indicators } from "@/services/resources";
import { CrudStore } from "@/store/crud";

const state = {
  current: {
    code: "",
    name: "",
    model: "",
    crontab: "",
    definition: {
      filters: [],
      group_by: [],
      annotations: [],
    },
  },
  functions: [],
  lookups: [],
};
const getters = {};

const mutations = {
  setCurrent(state, object) {
    state.current = object;
  },
  setFunctions(state, object) {
    state.functions = object;
  },
  setLookups(state, object) {
    state.lookups = object;
  },
};

const actions = {
  patch({ state, commit }, params) {
    return Indicators.patch(state.current.id, params)
      .then((response) => {
        commit("setCurrent", response.data);
      })
      .catch((error) => {
        let message = null;
        if (error.response.status === 400) {
          message = { message: error.response.data };
        }
        commit("snackbar/errorSnackbar", message, { root: true });
        return Promise.reject(error);
      });
  },
  functions({ commit }) {
    return Indicators.functions()
      .then((response) => {
        commit("setFunctions", response.data);
      })
      .catch(() => {
        commit("snackbar/errorSnackbar", null);
      });
  },
  lookups({ commit }) {
    return Indicators.lookups()
      .then((response) => {
        commit("setLookups", response.data);
      })
      .catch(() => {
        commit("snackbar/errorSnackbar", null);
      });
  },
};

export const indicators = CrudStore(
  Indicators,
  state,
  getters,
  mutations,
  actions
);
