import moment from "moment";
import i18n from "@/mixins/i18n";

const t = i18n.methods.t;

/**
 * Check if the date is in the expected format
 * @param date
 * @param format
 */
function dateFormatRule(date, format) {
  if (date) {
    return moment(date, format, true).isValid() || t("Format invalide");
  }
  return true;
}

export default {
  methods: {
    dateFormatRule,
  },
};
