<template>
  <div>
    <account-navigation-drawer />
    <v-container>
      <router-view />
    </v-container>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import AccountNavigationDrawer from "@/components/account/AccountNavigationDrawer";

export default {
  name: "Account",
  components: { AccountNavigationDrawer },
  mixins: [i18n],
};
</script>
