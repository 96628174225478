<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55478 3.95122 3.95122 7.55478 3.95122 12C3.95122 16.4452 7.55478 20.0488 12 20.0488C16.4452 20.0488 20.0488 16.4452 20.0488 12C20.0488 7.55478 16.4452 3.95122 12 3.95122ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6386 8.82046C14.0636 9.17316 14.1222 9.80361 13.7695 10.2286L12.2995 12L13.7695 13.7714C14.1222 14.1964 14.0636 14.8268 13.6386 15.1795C13.2136 15.5322 12.5832 15.4736 12.2305 15.0486L10.2305 12.6386C9.92318 12.2683 9.92318 11.7317 10.2305 11.3614L12.2305 8.95138C12.5832 8.52638 13.2136 8.46777 13.6386 8.82046Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM13.58 13.94C13.84 14.26 13.8 14.73 13.48 14.99C13.34 15.11 13.17 15.16 13 15.16C12.78 15.16 12.57 15.07 12.42 14.89L10.42 12.48C10.19 12.2 10.19 11.8 10.42 11.52L12.42 9.11C12.69 8.79 13.16 8.74 13.48 9.01C13.8 9.27 13.84 9.74 13.58 10.06L11.98 12L13.58 13.94Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronLeft",
};
</script>
