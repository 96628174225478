<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 4C16.4477 4 16 4.44771 16 4.99999C16 5.55228 16.4477 6 17 6C17.5523 6 18 5.55228 18 4.99999C18 4.44771 17.5523 4 17 4ZM14 4.99999C14 3.34313 15.3431 2 17 2C18.6569 2 20 3.34313 20 4.99999C20 6.65684 18.6569 8 17 8C16.356 8 15.7594 7.79709 15.2707 7.45172L9.89157 11.1979C9.96224 11.4532 10 11.7222 10 12C10 12.2778 9.96224 12.5468 9.89157 12.8021L15.2707 16.5483C15.7594 16.2029 16.356 16 17 16C18.6568 16 20 17.3431 20 19C20 20.6568 18.6569 22 17 22C15.3431 22 14 20.6568 14 19C14 18.7156 14.0396 18.4404 14.1135 18.1796L8.74481 14.4407C8.2532 14.7928 7.6508 15 7 15C5.34314 15 4 13.6568 4 12C4 10.3432 5.34314 9 7 9C7.6508 9 8.25321 9.20723 8.74482 9.55931L14.1135 5.82036C14.0396 5.55962 14 5.28443 14 4.99999ZM7 11C6.44771 11 6 11.4477 6 12C6 12.5523 6.44771 13 7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11ZM16 19C16 18.4477 16.4477 18 17 18C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Share",
};
</script>
