const REFRESH_TOKEN = "codocRefreshToken";
const ACCESS_TOKEN = "codocAccessToken";
const EXPIRY_TIME = "codocExpiryTime";
const ID_TOKEN = "codocIdToken";

class TokenService {
  constructor() {
    this.refreshToken = REFRESH_TOKEN;
    this.accessToken = ACCESS_TOKEN;
    this.expiryTime = EXPIRY_TIME;
    this.idToken = ID_TOKEN;
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshToken);
  }

  setRefreshToken(token) {
    localStorage.setItem(this.refreshToken, token);
  }

  removeRefreshToken() {
    localStorage.removeItem(this.refreshToken);
  }

  getAccessToken() {
    return localStorage.getItem(this.accessToken);
  }

  setAccessToken(token) {
    localStorage.setItem(this.accessToken, token);
  }

  removeAccessToken() {
    localStorage.removeItem(this.accessToken);
  }

  getExpiryTime() {
    return localStorage.getItem(this.expiryTime);
  }

  setExpiryTime(token) {
    localStorage.setItem(this.expiryTime, token);
  }

  removeExpiryTime() {
    localStorage.removeItem(this.expiryTime);
  }

  getIdToken() {
    return localStorage.getItem(this.idToken);
  }

  setIdToken(token) {
    localStorage.setItem(this.idToken, token);
  }

  removeIdToken() {
    localStorage.removeItem(this.idToken);
  }
}

export default new TokenService();
