<template>
  <custom-data-table-method
    v-bind="$props"
    ref="my-custom-table"
    :items-per-page="nbItemsPerPage ? nbItemsPerPage : nbItemsPerPageDefault"
    :header-props="{ sortIcon: 'mdi-chevron-down mdi-18px' }"
    :loading-text="t('Loading...')"
    :no-data-text="noDataText ? noDataText : noDataTextDefault"
    :footer-props="{
      showCurrentPage: true,
      itemsPerPageText: t('Row per page'),
      itemsPerPageOptions: rowsPerPageItems
        ? rowsPerPageItems
        : rowsPerPageItemsDefault,
      itemsPerPageAllText: t('All')
    }"
    :height="loading ? '' : height"
    :items="loading ? [] : items"
    :hide-default-footer="
      hideDefaultFooter
        ? hideDefaultFooter
        : (loading && items.length === 0) || items.length === 0
    "
    v-on="$listeners"
    @update:page="scrollBackTop"
  >
    <template #loading>
      <v-skeleton-loader type="table-row-divider@3, table-row@1" />
    </template>
    <template #[`header.data-table-select`]="{ props, on }">
      <v-simple-checkbox
        :value="props.value || props.indeterminate"
        :indeterminate="props.indeterminate"
        color="primary"
        v-on="on"
      />
    </template>
    <template #item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox
        :value="isSelected"
        color="primary"
        @input="select($event)"
      />
    </template>
    <template v-for="(index, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
    <template #footer.page-text="props">
      <bar-filter
        v-if="sortableColumn"
        :items="filterSortableValue"
        class="mr-6"
      />
      {{ props.pageStop }} {{ t("sur") }} {{ props.itemsLength }}
      {{ itemsName ? itemsName : t("Items") }}
    </template>
  </custom-data-table-method>
</template>

<script>
import CustomDataTableMethod from "@/components/design_system/CustomDataTableMethod";
import BarFilter from "@/components/design_system/BarFilter";

export default {
  name: "CustomDataTable",
  components: {
    CustomDataTableMethod,
    BarFilter,
  },

  extends: CustomDataTableMethod,
  props: {
    nbItemsPerPage: null,
    rowsPerPageItems: Array,
    noDataText: String,
    itemsName: String,
    loading: Boolean,
    height: [String, Number],
    items: Array,
    hideDefaultFooter: Boolean,
    sortableColumn: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      nbItemsPerPageDefault: 10,
      rowsPerPageItemsDefault: [10, 20, 50, 100],
      noDataTextDefault: this.t("No data available"),
    };
  },

  computed: {
    filterSortableValue() {
      const values = [];
      this.items.forEach((item) => {
        if (!values.find((val) => val.value === item[this.sortableColumn])) {
          values.push({
            text: item[this.sortableColumn],
            value: item[this.sortableColumn],
          });
        }
      });
      return values;
    },
  },

  methods: {
    scrollBackTop() {
      this.$vuetify.goTo(this.$refs["my-custom-table"]);
    },
  },
};
</script>
