<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5458 6.03993C15.2355 3.66377 12.5427 2.71728 10.1646 3.07373C7.55381 3.46506 5.15376 5.43981 5.00136 8.8963C2.80161 9.9881 1.61142 12.5681 2.1151 15.0631L2.11536 15.0644C2.37387 16.3362 3.11957 17.568 4.20284 18.3829L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L18.7071 6.70709C19.0976 6.31657 19.0976 5.6834 18.7071 5.29288C18.3166 4.90236 17.6834 4.90236 17.2929 5.29288L16.5458 6.03993ZM5.64053 16.9452C4.85122 16.4591 4.26183 15.5829 4.0754 14.6666C3.73146 12.9612 4.59148 11.2847 5.97142 10.6491C6.60482 10.3734 6.97202 9.76554 6.99469 9.13088L6.99509 9.11671C7.04833 6.64304 8.64617 5.32367 10.4611 5.05164C12.3392 4.77012 14.2785 5.62588 15.0062 7.45486C15.0179 7.48437 15.0309 7.513 15.045 7.54073L5.64053 16.9452ZM16.9578 10.0776C17.1082 9.54623 17.6609 9.23736 18.1923 9.38776C18.8754 9.58107 19.4955 9.93423 20.0232 10.366C20.0338 10.3746 20.0442 10.3835 20.0544 10.3925C20.0491 10.3879 20.0744 10.4102 20.0911 10.4252C20.1125 10.4444 20.1396 10.4692 20.1716 10.4993C20.2354 10.5596 20.3175 10.6405 20.4112 10.7412C20.5982 10.9418 20.8345 11.2242 21.0674 11.5828C21.5323 12.2988 22 13.3458 22 14.66C22 15.9585 21.4339 17.2683 20.5733 18.2503C19.7104 19.2351 18.457 19.9929 17.0058 20L12.5674 20.0025L12.5664 20.0025L9.00351 20C8.45123 20.0019 8.00194 19.5558 8.00001 19.0035C7.99807 18.4512 8.44421 18.0019 8.99649 18C10.2876 17.9955 11.4491 17.999 12.5959 18.0025C14.0117 18.0069 15.4055 18.0111 16.993 18L16.9954 18C17.7337 17.9966 18.4799 17.6046 19.0692 16.9322C19.6611 16.2567 20 15.4014 20 14.66C20 13.0661 18.9038 12.0466 18.742 11.9019C18.3939 11.6199 18.0194 11.4174 17.6477 11.3122C17.1163 11.1618 16.8074 10.6091 16.9578 10.0776Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.19 5.80001C17.9 5.51001 17.42 5.51001 17.13 5.80001L16.04 6.89001C14.83 4.80001 12.38 3.68001 9.78 4.14001C6.94 4.65001 5.07 6.90001 5.01 9.85001C3.16 10.69 2 12.62 2 14.68C2 15.05 2.04 15.43 2.12 15.8C2.37 17.04 3.07 18.15 4.03 18.9L3.25 19.68C2.96 19.97 2.96 20.45 3.25 20.74C3.4 20.88 3.59 20.96 3.78 20.96C3.98 20.96 4.17 20.88 4.31 20.74L18.19 6.86001C18.48 6.57001 18.48 6.10001 18.19 5.80001Z"
      :fill="$parent.getColor"
    />
    <path
      d="M20.12 10.57C19.6 10.13 19.01 9.81998 18.38 9.62998C17.98 9.51998 17.57 9.73998 17.45 10.14C17.34 10.54 17.56 10.96 17.96 11.07C18.39 11.2 18.8 11.42 19.16 11.72C19.18 11.73 20.51 12.86 20.51 14.66C20.5 16.37 19.18 18.37 17.47 18.37L8.91998 18.41C8.49998 18.41 8.16998 18.75 8.16998 19.16C8.16998 19.58 8.50998 19.91 8.91998 19.91L17.48 19.87C20.07 19.86 22 17.12 22.01 14.67C22.01 12.2 20.31 10.73 20.12 10.57Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CloudOff",
};
</script>
