<template>
  <div>
    <c-r-u-d-data-table
      namespace="indicators_apps"
      :headers="headers"
      :default-new-object="defaultIndicatorApp"
      title="Applications"
      no-update
    >
      <template #item.country="{ item }">
        <country-flag
          :country="item.application.vm.hospital.country"
          size="small"
        />
      </template>
      <template #item.hospital="{ item }">
        <badge type="info" :text="item.application.vm.hospital.code" />
      </template>
      <template #item.validation_errors="{ item }">
        <v-btn v-if="item.error_type && item.validation_errors" small @click="openErrorDialog(item.validation_errors)">
          <badge
            :text="'&#9888;&#65039;' + ' ' + item.error_type.charAt(0).toUpperCase() +
              item.error_type.slice(1)" type="danger"
            class="clickable row"
          />
        </v-btn>
        <badge v-else :text="t('No errors')" type="info" />
      </template>
      <template #dialogContent="{ currentObject }">
        <drop-down
          v-model="currentHospital"
          class="pt-4"
          :input-label="t('Hospital')"
          return-object
          required
          hide-details="auto"
          :rules="requiredRule"
          item-value="id"
          item-text="text"
          :items="hospitals"
          search-bar
          @input="$store.dispatch('apps/list', { hospital_id: $event.id })"
        >
          <template #selection="{ item }">
            <country-flag :country="item.country" size="small" /> [{{
              item.code
            }}] {{ item.name }}
          </template>
          <template #customItem="{ item }">
            <v-list-item-title>
              <div class="pl-2">
                <country-flag :country="item.country" size="small" />[{{
                  item.code
                }}] {{ item.name }}
              </div>
            </v-list-item-title>
          </template>
        </drop-down>
        <drop-down
          v-model="currentObject.application_id"
          class="pt-4"
          :input-label="t('Application')"
          item-value="id"
          item-text="text"
          required
          hide-details="auto"
          :rules="requiredRule"
          :items="apps"
          search-bar
        >
          <template #selection="{ item }">
            {{ item.code }} ({{ item.vm.name }})
          </template>
          <template #customItem="{ item }">
            <v-list-item-title>
              <div class="pl-2">
                {{ item.code }} ({{ item.vm.name }})
              </div>
            </v-list-item-title>
          </template>
        </drop-down>
      </template>
    </c-r-u-d-data-table>
    <v-dialog v-model="openError" max-width="500px ">
      <v-card>
        <v-card-title class="pb-0">
          <div class="d-flex align-items-center mt-2">
            <icon name="alert" style="align-self: center;" outline size="25" class="pr-2" />
            <span class="text-subtitle-1 font-demi-bold">
              {{ t("Information error") }}
            </span>
          </div>
        </v-card-title>
        <v-card-text class="text-body-1">
          <div class="d-flex align-items-center pt-3">
            <ul>
              <li v-for="(error, index) in currentValidationErrors" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <div class="text-right">
            <v-btn
              small
              text
              class="primary"
              @click="openError = false"
            >
              {{ t("Close") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import { mapState } from "vuex";
import CountryFlag from "vue-country-flag";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "IndicatorApps",
  components: { CRUDDataTable, CountryFlag },
  mixins: [form_rules ],

  data() {
    return {
      headers: [
        { text: "", value: "country", width: "1%", sortable: false, align: "right" },
        {
          text: this.t("Hospital"),
          value: "hospital",
          width: "1%",
          sortable: false,
        },
        {
          text: this.t("VM"),
          value: "application.vm.name",
          sortable: false,
          width: "20%",
        },
        {
          text: this.t("Code"),
          value: "application.code",
          sortable: false,
          width: "15%",
        },
        {
          text: this.t("Error (type)"),
          value: "validation_errors",
          sortable: false,
          width: "20%",
          align: "start"
        },
        {
          text: "",
          value: "edit",
          align: "right",
          sortable: false,
          width: "1%",
        },
      ],

      currentHospital: null,
      openError: false,
      currentValidationErrors: null,
    };
  },

  computed: {
    ...mapState({
      apps: (state) => state["apps"].objects,
      hospitals: (state) => state["hospitals"].objects,
      indicator: (state) => state["indicators"].current,
      indicators_apps: (state) => state["indicators_apps"].objects,
    }),

    defaultIndicatorApp() {
      return {
        application_id: null,
        indicator_id: this.indicator.id,
      };
    },
  },

  mounted() {
    this.$store.dispatch("hospitals/list");
    this.$store.dispatch("indicators_apps/list", {
      indicator_id: this.indicator.id,
    });
  },

  methods:{
    openErrorDialog(validationErrors) {
      this.openError = true;
      this.currentValidationErrors = validationErrors
    },
  }
};
</script>

<style scoped>
.small-flag {
  vertical-align: middle !important;
  margin-right: -12px !important;
  margin-top: -13px !important;
  transform: scale(0.40) !important;
  -ms-transform: scale(0.40) !important;
  -webkit-transform: scale(0.40) !important;
  -moz-transform: scale(0.40) !important;
}
</style>
