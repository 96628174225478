<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3271 4.6271C17.1666 3.78763 18.5334 3.78763 19.3729 4.6271C20.2124 5.46658 20.2124 6.83342 19.3729 7.6729L13.9529 13.0929C13.1134 13.9324 11.7466 13.9324 10.9071 13.0929C10.5166 12.7024 9.88341 12.7024 9.49289 13.0929C9.10237 13.4834 9.10237 14.1166 9.49289 14.5071C11.1134 16.1276 13.7466 16.1276 15.3671 14.5071L20.7871 9.08711C22.4076 7.46659 22.4076 4.83342 20.7871 3.21289C19.1666 1.59237 16.5334 1.59237 14.9129 3.21289L12.6529 5.47288C12.2624 5.86341 12.2624 6.49657 12.6529 6.8871C13.0434 7.27762 13.6766 7.27762 14.0671 6.8871L16.3271 4.6271ZM10.0471 10.9071C10.8866 10.0676 12.2534 10.0676 13.0929 10.9071C13.4834 11.2976 14.1166 11.2976 14.5071 10.9071C14.8976 10.5165 14.8976 9.88338 14.5071 9.49286C12.8866 7.87233 10.2534 7.87233 8.63289 9.49286L3.21289 14.9129C1.59237 16.5334 1.59237 19.1665 3.21289 20.7871C4.83342 22.4076 7.46658 22.4076 9.0871 20.7871L11.3471 18.5271C11.7376 18.1366 11.7376 17.5034 11.3471 17.1129C10.9566 16.7223 10.3234 16.7223 9.93289 17.1129L7.67289 19.3728C6.83342 20.2123 5.46658 20.2123 4.6271 19.3728C3.78763 18.5334 3.78763 17.1665 4.6271 16.3271L10.0471 10.9071Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.25 2H5.75C3.68 2 2 3.68 2 5.75V18.25C2 20.32 3.68 22 5.75 22H18.25C20.32 22 22 20.32 22 18.25V5.75C22 3.68 20.32 2 18.25 2ZM9.7 10.27C10.81 9.16 12.62 9.16 13.73 10.27C14.02 10.57 14.02 11.04 13.73 11.33C13.44 11.63 12.96 11.63 12.67 11.33C12.14 10.81 11.28 10.81 10.76 11.33L7.15 14.94C6.62 15.47 6.62 16.33 7.15 16.85C7.67 17.38 8.53 17.38 9.06 16.85L10.56 15.35C10.86 15.06 11.33 15.06 11.62 15.35C11.92 15.64 11.92 16.12 11.62 16.41L10.12 17.92C9.56 18.47 8.83 18.75 8.1 18.75C7.37 18.75 6.64 18.47 6.09 17.92C4.97 16.8 4.97 15 6.09 13.88L9.7 10.27ZM14.31 13.73C13.75 14.28 13.02 14.56 12.29 14.56C11.56 14.56 10.83 14.28 10.27 13.73C9.98 13.43 9.98 12.96 10.27 12.67C10.57 12.37 11.04 12.37 11.33 12.67C11.86 13.19 12.72 13.19 13.25 12.67L16.86 9.06C17.38 8.53 17.38 7.66999 16.86 7.14999C16.33 6.61999 15.47 6.61999 14.94 7.14999L13.44 8.64999C13.15 8.93999 12.67 8.93999 12.38 8.64999C12.09 8.35999 12.09 7.88 12.38 7.59L13.88 6.08C15 4.97 16.8 4.97 17.92 6.08C19.03 7.2 19.03 9 17.92 10.12L14.31 13.73Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Hyperlink",
};
</script>
