import { Api } from "@/services/api/base";
import { IndicatorApi } from "@/services/api/indicator";
import { ApplicationAPI } from "@/services/api/applications";
import { ResultApi } from "@/services/api/results";

// Hospitals
export const Hospitals = new Api("hospitals/hospitals");
export const VirtualMachines = new Api("hospitals/vms");

// Applications
export const Applications = new ApplicationAPI("applications/applications");
export const ApplicationTypes = new Api("applications/types");

// Indicators
export const Indicators = new IndicatorApi("indicators/indicators");
export const IndicatorApps = new Api("indicators/indicator_applications");
export const Results = new ResultApi("indicators/results");

// Users 
export const AdminUsers = new Api("accounts/users");
