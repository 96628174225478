<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.878 3.95217C7.50666 3.95217 3.95217 7.49829 3.95217 11.878V12.122C3.95217 16.4926 7.50735 20.0478 11.878 20.0478H12.122C16.4933 20.0478 20.0478 16.5017 20.0478 12.122V11.878C20.0478 7.50666 16.5017 3.95217 12.122 3.95217H11.878ZM2 11.878C2 6.41874 6.42989 2 11.878 2H12.122C17.5813 2 22 6.4299 22 11.878V12.122C22 17.5813 17.5701 22 12.122 22H11.878C6.4292 22 2 17.5708 2 12.122V11.878Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0086 7.9901C13.0086 7.44328 12.5571 7 12 7C11.4429 7 10.9914 7.44328 10.9914 7.9901V12.6264L10.2204 11.8727C9.82572 11.4869 9.18708 11.4881 8.79398 11.8756C8.40088 12.263 8.40217 12.8899 8.79687 13.2758L11.2877 15.7109C11.681 16.096 12.3179 16.0965 12.7118 15.7114L12.7124 15.7108L15.2031 13.2758C15.5978 12.8899 15.5991 12.263 15.206 11.8756C14.8129 11.4881 14.1743 11.4869 13.7796 11.8727L13.0086 12.6264V7.9901Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.13 2H11.87C6.43 2 2 6.43 2 11.87V12.13C2 17.57 6.43 22 11.87 22H12.13C17.57 22 22 17.57 22 12.13V11.87C22 6.43 17.57 2 12.13 2ZM15 13.16L12.53 15.62C12.38 15.77 12.19 15.84 12 15.84C11.81 15.84 11.62 15.77 11.47 15.62L9 13.16C8.71 12.86 8.71 12.39 9 12.1C9.3 11.8 9.77 11.8 10.06 12.1L11.25 13.28V8.91C11.25 8.49 11.59 8.16 12 8.16C12.41 8.16 12.75 8.49 12.75 8.91V13.28L13.94 12.1C14.23 11.8 14.7 11.8 15 12.1C15.29 12.39 15.29 12.86 15 13.16Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownCircle",
};
</script>
