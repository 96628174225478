<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C7.58039 4 4 7.58039 4 12C4 16.4196 7.58039 20 12 20C16.4196 20 20 16.4196 20 12C20 7.58039 16.4196 4 12 4ZM2 12C2 6.47582 6.47582 2 12 2C17.5242 2 22 6.47582 22 12C22 17.5242 17.5242 22 12 22C6.47582 22 2 17.5242 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2834 10.4632C11.1241 10.3836 10.9368 10.4993 10.9368 10.6772V13.3228C10.9368 13.5007 11.1241 13.6164 11.2834 13.5368L13.9308 12.214C14.1073 12.1258 14.1073 11.8742 13.9308 11.786L11.2834 10.4632ZM9.5 10.6772C9.5 9.43201 10.8114 8.62213 11.9259 9.179L14.5734 10.5018C15.8089 11.1191 15.8089 12.8809 14.5734 13.4982L11.9259 14.821C10.8114 15.3779 9.5 14.568 9.5 13.3228V10.6772Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.07 4.92999C17.26 3.11999 14.76 2 12 2C9.24 2 6.73999 3.11999 4.92999 4.92999C3.11999 6.73999 2 9.24 2 12C2 17.52 6.48 22 12 22C14.76 22 17.26 20.88 19.07 19.07C20.88 17.26 22 14.76 22 12C22 9.24 20.88 6.73999 19.07 4.92999ZM15.35 12.72L10.63 15.14C10.52 15.2 10.39 15.23 10.26 15.23C10.12 15.23 9.97 15.19 9.84 15.11C9.6 14.96 9.45001 14.7 9.45001 14.42V9.57999C9.45001 9.29999 9.6 9.04001 9.84 8.89001C10.08 8.74001 10.38 8.72999 10.63 8.85999L15.35 11.28C15.63 11.42 15.79 11.69 15.79 12C15.79 12.31 15.63 12.58 15.35 12.72Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "PlayCircle",
};
</script>
