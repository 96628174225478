import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#233C73",
        secondary: "#F3F5F9",
        accent: "#FFF0BB",
        error: "#F35E5B",
        info: "#315EC2",
        success: "#2F973F",
        warning: "#FF832D",
        danger: "#F35E5B",
      },
    },
  },
});
