/**
 * A common parents for enums.
 */
export class TextValueEnum {
  constructor(value, text, component = null) {
    if (!component) {
      component = value;
    }
    this.value = value;
    this.text = text;
    this.component = component;
  }
}

export function enumFromValue(enumeration, value) {
  if (enumeration && value) {
    return Object.values(enumeration).find((el) => el.value === value);
  }
}

export function textFromEnum(enumeration, value) {
  if (enumeration && value) {
    return Object.values(enumeration).find((el) => el.value === value).text;
  }
  return "";
}
