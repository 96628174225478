<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H12C14.2091 22 16 20.2091 16 18V17C16 16.4477 15.5523 16 15 16C14.4477 16 14 16.4477 14 17V18C14 19.1046 13.1046 20 12 20H6C4.89543 20 4 19.1046 4 18L4 6C4 4.89543 4.89543 4 6 4H12C13.1046 4 14 4.89543 14 6V7C14 7.55228 14.4477 8 15 8C15.5523 8 16 7.55228 16 7V6C16 3.79086 14.2091 2 12 2H6ZM21 11H11.4142L12.7071 9.70711C13.0976 9.31658 13.0976 8.68342 12.7071 8.29289C12.3166 7.90237 11.6834 7.90237 11.2929 8.29289L8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071C13.0976 15.3166 13.0976 14.6834 12.7071 14.2929L11.4142 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Login",
};
</script>
