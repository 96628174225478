import Vue from "vue";

export function CrudStore(
  endpoint,
  state = {},
  getters = {},
  mutations = {},
  actions = {}
) {
  return {
    namespaced: true,
    state: () => ({
      endpoint: endpoint,
      objects: [],
      loading: false,
      ...state,
    }),
    getters: getters,
    mutations: {
      setObjects(state, objects) {
        state.objects = objects;
      },
      setLoading(state, isLoading) {
        state.loading = isLoading;
      },
      create(state, item) {
        state.objects.push(item);
      },
      update(state, { index, item }) {
        Vue.set(state.objects, index, item);
      },
      destroy(state, index) {
        state.objects.splice(index, 1);
      },
      ...mutations,
    },
    actions: {
      list({ state, commit }, params = {}) {
        commit("setLoading", true); 
        return state.endpoint
          .list(params)
          .then((response) => {
            commit("setObjects", response.data.results);
            return Promise.resolve(response);
          })
          .catch((error) => {
            commit("snackbar/errorSnackbar", null, { root: true });
            return Promise.reject(error);
          }).finally(() => {
            commit("setLoading", false); 
          });
      },
      retrieve({ state, commit }, id) {
        return state.endpoint.retrieve(id).catch((error) => {
          commit("snackbar/errorSnackbar", null, { root: true });
          return Promise.reject(error);
        });
      },
      create({ state, commit }, item) {
        commit("setLoading", true); 
        return state.endpoint
          .create(item)
          .then((response) => {
            commit("create", response.data);
            return Promise.resolve(response);
          })
          .catch((error) => {
            let message = null;
            if (error.response.status === 400) {
              message = { message: error.response.data };
            }
            commit("snackbar/errorSnackbar", message, { root: true });
            return Promise.reject(error);
          }).finally(() => {
            commit("setLoading", false); 
          });
      },
      destroy({ state, commit }, index) {
        commit("setLoading", true); 
        let id = state.objects[index].id;
        return state.endpoint
          .destroy(id)
          .then((response) => {
            commit("destroy", index);
            return Promise.resolve(response);
          })
          .catch((error) => {
            commit("snackbar/errorSnackbar", null, { root: true });
            return Promise.reject(error);
          }).finally(() => {
            commit("setLoading", false); 
          });
      },
      update({ state, commit }, { index, item }) {
        commit("setLoading", true); 
        let id = state.objects[index].id;
        return state.endpoint
          .update(id, item)
          .then((response) => {
            commit("update", { index, item });
            return Promise.resolve(response);
          })
          .catch((error) => {
            let message = null;
            if (error.response.status === 400) {
              message = { message: error.response.data };
            }
            commit("snackbar/errorSnackbar", message, { root: true });
            return Promise.reject(error);
          }).finally(() => {
            commit("setLoading", false); 
          });
      },
      ...actions,
    },
  };
}
