<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55478 3.95122 3.95122 7.55478 3.95122 12C3.95122 16.4452 7.55478 20.0488 12 20.0488C16.4452 20.0488 20.0488 16.4452 20.0488 12C20.0488 7.55478 16.4452 3.95122 12 3.95122ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3614 10.2305C11.7317 9.92318 12.2683 9.92318 12.6386 10.2305L15.0486 12.2305C15.4736 12.5832 15.5322 13.2136 15.1795 13.6386C14.8268 14.0636 14.1964 14.1222 13.7714 13.7695L12 12.2995L10.2286 13.7695C9.80361 14.1222 9.17316 14.0636 8.82046 13.6386C8.46777 13.2136 8.52638 12.5832 8.95138 12.2305L11.3614 10.2305Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM14.99 13.48C14.73 13.8 14.26 13.84 13.94 13.58L12 11.98L10.06 13.58C9.92 13.69 9.76 13.75 9.59 13.75C9.37 13.75 9.15999 13.66 9.00999 13.48C8.73999 13.16 8.79 12.68 9.11 12.42L11.52 10.42C11.8 10.19 12.2 10.19 12.48 10.42L14.89 12.42C15.21 12.68 15.26 13.16 14.99 13.48Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronUp",
};
</script>
