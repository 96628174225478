<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="12" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="12" cy="16" r="2" :fill="$parent.getColor" />
    <circle cx="20" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="20" cy="16" r="2" :fill="$parent.getColor" />
    <circle cx="4" cy="16" r="2" :fill="$parent.getColor" />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00357 10C4.8984 10 4 10.8968 4 12C4 13.1032 4.8984 14 6.00357 14C7.10161 14 8 13.1032 8 12C8 10.8968 7.10161 10 6.00357 10Z"
      :fill="$parent.getColor"
    />
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      :fill="$parent.getColor"
    />
    <path
      d="M17.9964 10C16.8984 10 16 10.8968 16 12C16 13.1032 16.8984 14 17.9964 14C19.1016 14 20 13.1032 20 12C20 10.8968 19.1016 10 17.9964 10Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DotsMenuHorizontal",
};
</script>
