import { Applications } from "@/services/resources";
import { CrudStore } from "@/store/crud";

const state = {
  current: {},
  currentIndex: -1,
};
const getters = {};

const mutations = {
  setCurrent(state, object) {
    state.current = object;
    state.currentIndex = state.objects.findIndex((el) => el.id === object.id);
  },
};

const actions = {
  patch({ state, commit }, params) {
    return Applications.patch(state.current.id, params)
      .then((response) => {
        commit("setCurrent", response.data);
      })
      .catch(() => {
        commit("snackbar/errorSnackbar", null);
      });
  },
};

export const apps = CrudStore(Applications, state, getters, mutations, actions);
