<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55478 3.95122 3.95122 7.55478 3.95122 12C3.95122 16.4452 7.55478 20.0488 12 20.0488C16.4452 20.0488 20.0488 16.4452 20.0488 12C20.0488 7.55478 16.4452 3.95122 12 3.95122ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.82046 10.3614C9.17316 9.93638 9.80361 9.87777 10.2286 10.2305L12 11.7005L13.7714 10.2305C14.1964 9.87777 14.8268 9.93638 15.1795 10.3614C15.5322 10.7864 15.4736 11.4168 15.0486 11.7695L12.6386 13.7695C12.2683 14.0768 11.7317 14.0768 11.3614 13.7695L8.95138 11.7695C8.52638 11.4168 8.46777 10.7864 8.82046 10.3614Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM14.89 11.58L12.48 13.58C12.34 13.69 12.17 13.75 12 13.75C11.83 13.75 11.66 13.69 11.52 13.58L9.11 11.58C8.79 11.32 8.73999 10.84 9.00999 10.52C9.26999 10.21 9.75 10.16 10.06 10.42L12 12.02L13.94 10.42C14.26 10.16 14.73 10.2 14.99 10.52C15.26 10.84 15.21 11.32 14.89 11.58Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronDown",
};
</script>
