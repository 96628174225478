import Login from "@/components/auth/Login";
import OTPSetup from "@/components/auth/OTPSetup";
import OTPVerification from "@/components/auth/OTPVerification";
import OTPStaticVerification from "@/components/auth/OTPStaticVerification";
import LockOut from "@/components/auth/LockOut";

export const authUrls = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { disableNavBar: true, disableFooter: false },
  },
  {
    path: "/otp/setup",
    name: "OTPSetup",
    component: OTPSetup,
    meta: { disableNavBar: true, disableFooter: false },
  },
  {
    path: "/otp/verify",
    name: "OTPVerify",
    component: OTPVerification,
    meta: { disableNavBar: true, disableFooter: false },
  },
  {
    path: "/otp/static",
    name: "OTPStatic",
    component: OTPStaticVerification,
    meta: { disableNavBar: true, disableFooter: false },
  },
  {
    path: "/lockout",
    name: "LockOut",
    component: LockOut,
    meta: { disableNavBar: true, disableFooter: true },
  },
];
