<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0158 2.2353C14.5942 1.87829 13.9656 1.93379 13.6119 2.35925C13.1803 2.8783 12.8384 3.54631 12.5993 4.17814C12.408 4.6836 12.2614 5.22164 12.1946 5.71167L9.34839 9.13487C6.23869 8.98413 4.03719 10.579 3.23316 11.546C2.8794 11.9715 2.93439 12.6058 3.35598 12.9628L7.01482 16.0612L4.28202 19.3479C3.92827 19.7734 3.98326 20.4077 4.40484 20.7647C4.82643 21.1217 5.45497 21.0662 5.80872 20.6407L8.54152 17.354L12.2004 20.4524C12.622 20.8094 13.2505 20.7539 13.6043 20.3284C14.5928 19.1394 14.9638 17.6907 15.0554 16.4337C15.1266 15.4553 15.0333 14.5363 14.8901 13.8382L17.7009 10.4576C18.3709 10.3061 18.9728 9.96532 19.4384 9.63415C20.0171 9.22259 20.491 8.74985 20.7668 8.41814C21.1206 7.99268 21.0656 7.35837 20.644 7.00136L15.0158 2.2353ZM9.06258 15.1697L12.6131 18.1763C12.8807 17.5922 13.0201 16.9415 13.0678 16.2863C13.1406 15.2856 12.9917 14.3681 12.8457 13.8647C12.7522 13.542 12.8246 13.1934 13.0388 12.9358L16.4121 8.87872C16.582 8.67441 16.8253 8.54657 17.0886 8.52332C17.4268 8.49346 17.8498 8.30343 18.2904 7.9901C18.3673 7.93537 18.4417 7.87909 18.513 7.82236L14.6166 4.52279C14.5618 4.64249 14.5097 4.76742 14.461 4.89586C14.2704 5.39951 14.1668 5.86994 14.1503 6.17572C14.1385 6.39298 14.0572 6.60054 13.9186 6.76722L10.5453 10.8243C10.3311 11.0819 10.0036 11.2144 9.67243 11.1775C7.82216 10.971 6.38571 11.5841 5.52492 12.174L9.05587 15.164C9.05699 15.1649 9.05811 15.1659 9.05923 15.1668C9.06035 15.1678 9.06147 15.1687 9.06258 15.1697Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Pin",
};
</script>
