<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.24 2C12.7702 2 13.2 2.4264 13.2 2.9524V3.51031L13.4812 3.23136C13.8561 2.85942 14.4639 2.85942 14.8388 3.23136C15.2137 3.60329 15.2137 4.20632 14.8388 4.57825L14.5576 4.85721H15.12C15.6502 4.85721 16.08 5.28361 16.08 5.80961C16.08 6.33561 15.6502 6.76201 15.12 6.76201H14.5576L14.8388 7.04097C15.2137 7.41289 15.2137 8.01595 14.8388 8.38786C14.4639 8.75982 13.8561 8.75982 13.4812 8.38786L13.2 8.1089V8.66682C13.2 9.19283 12.7702 9.61922 12.24 9.61922C11.7098 9.61922 11.28 9.19283 11.28 8.66682V8.1089L10.9989 8.38786C10.624 8.75982 10.0161 8.75982 9.64122 8.38786C9.26632 8.01595 9.26632 7.41289 9.64122 7.04097L9.9224 6.76201H9.36005C8.82986 6.76201 8.40006 6.33561 8.40006 5.80961C8.40006 5.28361 8.82986 4.85721 9.36005 4.85721H9.9224L9.64122 4.57825C9.26632 4.20632 9.26632 3.60329 9.64122 3.23136C10.0161 2.85942 10.624 2.85942 10.9989 3.23136L11.28 3.51031V2.9524C11.28 2.4264 11.7098 2 12.24 2ZM16.0817 19.7804C16.02 18.7479 15.7104 17.2032 15.0168 15.9505C14.3323 14.7144 13.3702 13.905 11.9895 13.905C10.509 13.905 9.60514 14.5811 8.99641 15.5227C8.35521 16.5144 8.05413 17.7989 7.92642 18.8691C7.89715 19.1144 7.96895 19.2951 8.14456 19.4686C8.34662 19.6684 8.69697 19.8585 9.17871 19.9776C10.1787 20.2248 11.3014 20.0681 11.8784 19.6689C12.3133 19.368 12.9117 19.4738 13.215 19.9052C13.5183 20.3366 13.4117 20.9303 12.9768 21.2312C11.8131 22.0364 10.0739 22.1619 8.7145 21.8259C8.01651 21.6533 7.31276 21.3354 6.78933 20.818C6.23943 20.2743 5.91434 19.5285 6.01975 18.6452C6.16139 17.4583 6.50994 15.8405 7.3803 14.4943C8.28314 13.0978 9.75278 12.0002 11.9895 12.0002C14.3259 12.0002 15.8217 13.448 16.6996 15.0333C17.5682 16.6021 17.9252 18.4431 17.9983 19.6678C18.0296 20.1928 17.626 20.6437 17.0967 20.6748C16.5674 20.7059 16.113 20.3054 16.0817 19.7804Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DrAnonymous",
};
</script>
