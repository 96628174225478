<template>
  <div>
    <navigation-drawer :items="items" />
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import NavigationDrawer from "@/components/design_system/NavigationDrawer";

export default {
  name: "AccountNavigationDrawer",
  components: { NavigationDrawer },
  mixins: [i18n],
  data() {
    return {
      items: [
        {
          name: this.t("Authentification"),
          icon: "mdi-security",
          to: { name: "AccountAuthentication" },
          isAllowed: true,
        },
      ],
    };
  },
};
</script>
