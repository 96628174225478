<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9324 4C9.76358 4 8 5.7596 8 7.9324V8.06761C8 10.2364 9.75959 12 11.9324 12H12H12.0676C14.2357 12 16 10.2357 16 8.06761V7.9324C16 5.76358 14.2404 4 12.0676 4H11.9324ZM18 8.06761C18 11.0231 15.8308 13.4787 13 13.9269V17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V19H9C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17H11V13.9271C8.16491 13.4794 6 11.023 6 8.06761V7.9324C6 4.6536 8.66043 2 11.9324 2H12.0676C15.3464 2 18 4.66044 18 7.9324V8.06761Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Female",
};
</script>
