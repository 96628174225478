<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711L5.41421 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H5.41421L8.70711 12.2929C9.09763 12.6834 9.09763 13.3166 8.70711 13.7071C8.31658 14.0976 7.68342 14.0976 7.29289 13.7071L2.29348 8.70769L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289L7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929L21.7071 15.2929C22.0488 15.6346 22.0915 16.1621 21.8352 16.5502C21.7986 16.6057 21.7559 16.6583 21.7071 16.7071L21.7065 16.7077L16.7071 21.7071C16.3166 22.0976 15.6834 22.0976 15.2929 21.7071C14.9024 21.3166 14.9024 20.6834 15.2929 20.2929L18.5858 17H9C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15H18.5858L15.2929 11.7071C14.9024 11.3166 14.9024 10.6834 15.2929 10.2929Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftRight",
};
</script>
