var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea-container mw-100 w-100"},[(_vm.inputLabel)?_c('label',{staticClass:"mb-1",class:[{ 'disabled-label': _vm.disabled }, _vm.inputLabelClass],attrs:{"for":_vm.inputId}},[_vm._v(" "+_vm._s(_vm.inputLabel)+" ")]):_vm._e(),_c('v-textarea',_vm._g(_vm._b({class:[
      {
        'disabled-error': (_vm.disabled && _vm.error) || (_vm.disabled && _vm.errorMessages)
      },
      {
        'disabled-success':
          (_vm.disabled && _vm.success) || (_vm.disabled && _vm.successMessages)
      },
      _vm.textareaClass
    ],attrs:{"id":_vm.inputId,"rows":_vm.rows,"solo":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._t("append")]},proxy:true},{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true},{key:"counter",fn:function(){return [_vm._t("counter")]},proxy:true},{key:"message",fn:function(){return [_vm._t("message")]},proxy:true},{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"prepend-inner",fn:function(){return [_vm._t("prepend-inner")]},proxy:true},{key:"progress",fn:function(){return [_vm._t("progress")]},proxy:true}],null,true)},'v-textarea',[_vm.$props, _vm.$attrs],false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }