<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.8497 14.322C8.45047 13.916 7.776 13.8905 7.34323 14.265C6.91047 14.6395 6.88328 15.2721 7.28251 15.6781L11.2081 19.6696C11.2204 19.6824 11.2331 19.6949 11.2461 19.7071C11.4531 19.9015 11.7261 19.9999 12 20C12.1575 20 12.3152 19.9676 12.4608 19.9021C12.567 19.8544 12.6663 19.7893 12.7539 19.7071C12.7669 19.6949 12.7796 19.6824 12.7919 19.6696L16.7175 15.6781C17.1167 15.2721 17.0895 14.6395 16.6568 14.265C16.224 13.8905 15.5495 13.916 15.1503 14.322L12 17.5252L8.8497 14.322Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4608 4.09795C12.567 4.14565 12.6663 4.21069 12.7539 4.29289C12.7669 4.30514 12.7796 4.31764 12.7919 4.33038L16.7175 8.32195C17.1167 8.72789 17.0895 9.36054 16.6568 9.73502C16.224 10.1095 15.5495 10.084 15.1503 9.67806L12 6.47482L8.8497 9.67806C8.45047 10.084 7.776 10.1095 7.34323 9.73502C6.91047 9.36054 6.88328 8.72789 7.28251 8.32195L11.2081 4.33038C11.2204 4.31764 11.2331 4.30514 11.2461 4.29289C11.3337 4.21069 11.433 4.14565 11.5392 4.09794C11.8286 3.96798 12.1691 3.96672 12.4608 4.09795Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 18.25L22 5.75C22 3.67893 20.3211 2 18.25 2L5.75 2C3.67893 2 2 3.67893 2 5.75L2 18.25C2 20.3211 3.67893 22 5.75 22L18.25 22C20.3211 22 22 20.3211 22 18.25ZM15.8577 13.6347C16.2638 14.009 16.2896 14.6417 15.9153 15.0477L12.7434 18.4889C12.7309 18.5028 12.7179 18.5165 12.7044 18.5298C12.5106 18.7224 12.2557 18.8199 12 18.82C11.8503 18.82 11.7003 18.7867 11.5623 18.7194C11.4658 18.6725 11.3755 18.6092 11.2956 18.5298C11.2821 18.5165 11.2691 18.5028 11.2565 18.4889L8.08471 15.0477C7.7104 14.6417 7.73616 14.009 8.14225 13.6347C8.54834 13.2604 9.18098 13.2862 9.55529 13.6922L12 16.3445L14.4447 13.6922C14.819 13.2862 15.4516 13.2604 15.8577 13.6347ZM15.8577 10.3653C15.4516 10.7396 14.819 10.7138 14.4447 10.3077L12 7.65547L9.55529 10.3077C9.18098 10.7138 8.54834 10.7396 8.14225 10.3653C7.73616 9.99098 7.7104 9.35833 8.08471 8.95224L11.2566 5.51109C11.2691 5.49717 11.2821 5.48352 11.2956 5.47017C11.3755 5.39076 11.4658 5.32752 11.5623 5.2806C11.838 5.14624 12.1611 5.14711 12.435 5.27925C12.5325 5.32631 12.6237 5.39 12.7044 5.47017C12.7179 5.48352 12.7309 5.49717 12.7434 5.51109L15.9153 8.95224C16.2896 9.35834 16.2638 9.99098 15.8577 10.3653Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpAndDown",
};
</script>
