<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 7C9 5.16379 10.3099 4 12 4C13.4619 4 14.6081 4.86907 14.9157 6.22175C15.0382 6.76028 15.574 7.09757 16.1125 6.9751C16.6511 6.85264 16.9884 6.31679 16.8659 5.77825C16.3327 3.4336 14.3093 2 12 2C9.27848 2 7 3.98743 7 7V9.00055C4.69419 9.03886 3 11.0782 3 13.3715V17.6285C3 19.9565 4.72859 22 7.06611 22H16.9339C19.2734 22 21 19.9436 21 17.6285V13.3715C21 11.0414 19.2598 9 16.9339 9H9V7ZM8 11H7.06611C6.00957 11 5 11.9737 5 13.3715V17.6285C5 19.0348 6.00762 20 7.06611 20H16.9339C17.9904 20 19 19.0263 19 17.6285V13.3715C19 11.9673 17.9846 11 16.9339 11H8ZM13 16.7324C13.5978 16.3866 14 15.7403 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 15.7403 10.4022 16.3866 11 16.7324V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16.7324Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 7C9 5.16379 10.3099 4 12 4C13.4619 4 14.6081 4.86907 14.9157 6.22175C15.0382 6.76028 15.574 7.09757 16.1125 6.9751C16.6511 6.85264 16.9884 6.31679 16.8659 5.77825C16.3327 3.4336 14.3093 2 12 2C9.27848 2 7 3.98743 7 7V9.00055C4.69419 9.03886 3 11.0782 3 13.3715V17.6285C3 19.9565 4.72859 22 7.06611 22H16.9339C19.2734 22 21 19.9436 21 17.6285V13.3715C21 11.0414 19.2598 9 16.9339 9H9V7ZM12 13C10.8954 13 10 13.8954 10 15C10 15.7403 10.4022 16.3866 11 16.7324V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16.7324C13.5978 16.3866 14 15.7403 14 15C14 13.8954 13.1046 13 12 13Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "LockOpen",
};
</script>
