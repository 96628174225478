import { Hospitals } from "@/services/resources";
import { CrudStore } from "@/store/crud";

const state = {
  current: {
    code: "",
    status: "",
  },
};
const getters = {};

const mutations = {
  setCurrent(state, object) {
    state.current = object;
  },
};

export const hospitals = CrudStore(Hospitals, state, getters, mutations, {});
