<template>
  <div>
    <div v-cloak>
      <div class="mb-3">
        <span class="text-subtitle-1 font-demi-bold">{{ t("Definition") }}</span>
      </div>
      <div>
        <code class="text-break">{{ queryset }}</code>
        <v-row class="mt-1">
          <v-col cols="12" lg="6">
            <indicator-definition-section
              required
              :open.sync="openGroupBy"
              :title="t('Grouping')"
              :count="definition.group_by.length"
            >
              <template #content>
                <indicator-group-by
                  :group-by.sync="definition.group_by"
                  @save="save"
                />
              </template>
            </indicator-definition-section>
          </v-col>
          <v-col cols="12" lg="6">
            <indicator-definition-section
              required
              :open.sync="openAnnotation"
              :title="t('Annotations')"
              :count="definition.annotations.length"
            >
              <template #content>
                <indicator-annotations
                  :annotations.sync="definition.annotations"
                  @save="save"
                />
              </template>
            </indicator-definition-section>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <indicator-definition-section
              :open.sync="openFilters"
              :title="t('Filtering')"
              :count="definition.filters.length"
            >
              <template #content>
                <indicator-filters
                  :filters="definition.filters"
                  @save="save"
                />
              </template>
            </indicator-definition-section>
          </v-col>
          <v-col cols="12" lg="6">
            <indicator-definition-section
              :open.sync="openOther"
              :title="t('Misc.')"
              :count="countOther"
            >
              <template #content>
                <div class="d-flex mt-2">
                  <text-input
                    v-model="definition.offset"
                    class="pr-2"
                    hide-details
                    persistent-hint
                    :input-label="t('Offset')"
                    clearable
                    @blur="save"
                  />
                  <text-input
                    v-model="definition.limit"
                    class="pr-2"
                    hide-details
                    persistent-hint
                    :input-label="t('Limit')"
                    clearable
                    @blur="save"
                  />
                </div>
                <div class="mt-2 w-50">
                  <text-input
                    v-model="definition.ordering"
                    class="pr-2"
                    hide-details
                    persistent-hint
                    :input-label="t('Ordering')"
                    clearable
                    @blur="save"
                  />
                </div>
              </template>
            </indicator-definition-section>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import IndicatorFilters from "@/components/indicators/tabs/parameters/definition/IndicatorFilters";
import IndicatorGroupBy from "@/components/indicators/tabs/parameters/definition/IndicatorGroupBy";
import IndicatorAnnotations from "@/components/indicators/tabs/parameters/definition/IndicatorAnnotations";
import { IndicatorGroupByType } from "@/enums/indicators";
import { mapState } from "vuex";
import _ from "lodash";
import IndicatorDefinitionSection from "@/components/indicators/tabs/parameters/definition/IndicatorDefinitionSection";

export default {
  name: "IndicatorDefinition",
  components: {
    IndicatorDefinitionSection,
    IndicatorAnnotations,
    IndicatorGroupBy,
    IndicatorFilters,
  },

  data() {
    return {
      openFilters: false,
      openGroupBy: false,
      openAnnotation: false,
      openOther: false,
      definition: {
        filters: [],
        group_by: [],
        annotations: [
          { field: "", named_field: "", function: null, kwargs: {} },
        ],

        ordering: null,
        limit: null,
        offset: null,
      },
    };
  },

  computed: {
    ...mapState({
      functions: (state) => state["indicators"].functions,
      indicator: (state) => state["indicators"].current,
    }),

    countOther() {
      let count = 0;
      if (this.definition.limit) count++;
      if (this.definition.offset) count++;
      if (this.definition.ordering) count++;
      return count;
    },

    queryset() {
      let qs = "queryset";
      let filters = this.definition.filters
          .filter((filter) => !filter.exclude)
          .filter((filter) => filter.field)
          .filter((filter) => filter.value || filter.value===false)
          .map(
              (filter) =>
                  filter.field +
                  (filter.operator ? "__" + filter.operator : "") +
                  "='" +
                  filter.value +
                  "'"
          );
      if (filters.length > 0) {
        qs += ".filters(" + filters.join("', '") + ")";
      }
      let exclude = this.definition.filters
          .filter((filter) => filter.exclude)
          .filter((filter) => filter.field)
          .filter((filter) => filter.value || filter.value===false)
          .map(
              (filter) =>
                  filter.field +
                  (filter.operator ? "__" + filter.operator : "") +
                  "='" +
                  filter.value +
                  "'"
          );
      if (exclude.length > 0) {
        qs += ".exclude(" + exclude.join(", ") + ")";
      }

      let values = this.definition.group_by
          .filter((el) => el.type === IndicatorGroupByType.SIMPLE.value)
          .filter((el) => el.options.field)
          .map((el) => el.options.field);

      let annotate = this.definition.group_by
          .filter((el) => el.type === IndicatorGroupByType.ANNOTATION.value)
          .filter((el) => el.options.field)
          .filter((el) => el.options.named_field)
          .filter((el) => el.options.function)
          .map(
              (el) =>
                  el.options.named_field +
                  "=" +
                  this.enumFromValue(this.functions, el.options.function).label +
                  "('" +
                  el.options.field +
                  "')"
          );
      let values2 = this.definition.group_by
          .filter((el) => el.type === IndicatorGroupByType.ANNOTATION.value)
          .filter((el) => el.options.field)
          .filter((el) => el.options.named_field)
          .filter((el) => el.options.function)
          .map((el) => el.options.named_field);
      if (annotate.length > 0) {
        qs += ".annotate(" + annotate.join(", ") + ")";
      }
      if (values.length > 0 || values2.length > 0) {
        qs += ".values('" + values.concat(values2).join("', '") + "')";
      }
      let annotations = this.definition.annotations
          .filter((el) => el.field)
          .filter((el) => el.named_field)
          .filter((el) => el.function)
          .map(
              (el) =>
                  el.named_field +
                  "=" +
                  this.enumFromValue(this.functions, el.function).label +
                  "('" +
                  el.field +
                  "')"
          );
      if (annotations.length > 0) {
        qs += ".annotate(" + annotations.join(", ") + ")";
      }

      if (this.definition.ordering) {
        qs += ".ordering('" + this.definition.ordering + "')";
      }
      if (this.definition.limit) {
        let sup =
            (this.definition.offset ? parseInt(this.definition.offset) : 0) +
            parseInt(this.definition.limit);
        qs += "[" + (this.definition.offset || 0) + ":" + sup + "]";
      }

      return qs;
    },
  },

  mounted() {
    this.definition =
      Object.keys(this.indicator.definition).length > 0
        ? _.cloneDeep(this.indicator.definition)
        : this.definition;
  },

  methods: {
    save() {
      this.$store.dispatch("indicators/patch", {
        definition: this.definition,
      });
    },
  },
};
</script>
