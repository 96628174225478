<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "IndicatorRouter",
  mounted() {
    this.$store.dispatch("indicators/list");
    this.$store.dispatch("indicators/functions");
    this.$store.dispatch("indicators/lookups");
  },
};
</script>
