<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00672 4C5.21762 4 4.73941 4.87117 5.16307 5.53689L10.8436 14.4631C10.9458 14.6236 11 14.8098 11 15V18.9952L13 19.9952V15C13 14.8098 13.0542 14.6236 13.1563 14.4631L18.8369 5.53689C19.2606 4.87117 18.7824 4 17.9933 4H6.00672ZM3.47577 6.61068C2.20478 4.61351 3.63942 2 6.00672 2H17.9933C20.3606 2 21.7952 4.61351 20.5242 6.61068L15 15.2912V19.9952C15 21.482 13.4354 22.449 12.1056 21.7841L10.1056 20.7841C9.428 20.4453 9 19.7528 9 18.9952V15.2912L3.47577 6.61068Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.52 3.85C19.92 2.71 18.75 2 17.45 2H6.54997C5.24997 2 4.07999 2.71 3.47999 3.85C2.87999 4.99 2.96997 6.36 3.70997 7.42L9.18998 15.29V20.23C9.18998 20.58 9.42999 20.88 9.75999 20.96L13.88 21.98C13.94 21.99 14 22 14.06 22C14.22 22 14.38 21.94 14.52 21.84C14.7 21.69 14.81 21.48 14.81 21.25V15.29L20.3 7.42C21.03 6.36 21.12 4.99 20.52 3.85Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "FilterBasic",
};
</script>
