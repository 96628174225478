<template>
  <v-container>
    <div>
      <div class="mb-3">
        <span class="text-subtitle-2 font-demi-bold">{{ t("Indicator") }}</span>
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <text-input
            v-model="name"
            hide-details
            :input-label="t('Name')"
            required
            @keydown.enter="patch({ name: name })"
            @blur="patch({ name: name })"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <text-input
            v-model="code"
            hide-details="auto"
            :input-label="t('Code')"
            required
            @change="code = code.toLowerCase()"
            @keydown.enter="patch({ code: code.toLowerCase() })"
            @blur="patch({ code: code.toLowerCase() })"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <text-input
            v-model="model"
            hide-details="auto"
            placeholder="accounts.user"
            persistent-hint
            hint="'<app>.<model>' in lowercase, e.g. 'accounts.user'"
            :input-label="t('Model')"
            required
            @change="model = model.toLowerCase()"
            @keydown.enter="patch({ model: model.toLowerCase() })"
            @blur="patch({ model: model.toLowerCase() })"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <text-input
            v-model="crontab"
            hide-details="auto"
            :input-label="t('Cron expression')"
            placeholder="*/30 * * * *"
            :hint="verboseCrontab(crontab)"
            persistent-hint
            required
            @keydown.enter="patch({ crontab: crontab })"
            @blur="patch({ crontab: crontab })"
          />
        </v-col>
      </v-row>
    </div>
    <div class="mt-4">
      <indicator-definition />
    </div>
    <div class="mt-8">
      <indicator-apps />
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import IndicatorApps from "@/components/indicators/tabs/parameters/IndicatorApps";
import IndicatorDefinition from "@/components/indicators/tabs/parameters/definition/IndicatorDefinition";
import { verboseCrontab } from "@/utils/cron";

export default {
  name: "IndicatorParameters",
  components: { IndicatorDefinition, IndicatorApps },
  data() {
    return {
      verboseCrontab,
      name: "",
      crontab: "",
      code: "",
      model: "",
    };
  },

  computed: {
    ...mapState({
      indicator: (state) => state["indicators"].current,
    }),
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.name = this.indicator.name;
      this.crontab = this.indicator.crontab;
      this.code = this.indicator.code;
      this.model = this.indicator.model;
    },

    patch(params) {
      this.loading = true;
      return this.$store
        .dispatch("indicators/patch", params)
        .catch(() => {
          this.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
