<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55478 3.95122 3.95122 7.55478 3.95122 12C3.95122 16.4452 7.55478 20.0488 12 20.0488C16.4452 20.0488 20.0488 16.4452 20.0488 12C20.0488 7.55478 16.4452 3.95122 12 3.95122ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3614 8.82046C10.7864 8.46777 11.4168 8.52638 11.7695 8.95138L13.7695 11.3614C14.0768 11.7317 14.0768 12.2683 13.7695 12.6386L11.7695 15.0486C11.4168 15.4736 10.7864 15.5322 10.3614 15.1795C9.93638 14.8268 9.87777 14.1964 10.2305 13.7714L11.7005 12L10.2305 10.2286C9.87777 9.80361 9.93638 9.17316 10.3614 8.82046Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM13.58 12.48L11.58 14.89C11.43 15.07 11.22 15.16 11 15.16C10.83 15.16 10.66 15.11 10.52 14.99C10.21 14.73 10.16 14.26 10.42 13.94L12.03 12L10.42 10.06C10.16 9.74 10.21 9.27 10.52 9.01C10.84 8.74 11.32 8.79 11.58 9.11L13.58 11.52C13.81 11.8 13.81 12.2 13.58 12.48Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ChevronRight",
};
</script>
