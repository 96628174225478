<template>
  <div>
    <div
      v-for="(filter, index) in filters"
      :key="'filter' + index"
      class="d-inline-flex align-center pt-2"
    >
      <text-input
        v-model="filter.field"
        class="pr-2"
        hide-details
        persistent-hint
        :input-label="t('Field')"
        @blur="$emit('save')"
      />
      <drop-down
        v-model="filter.operator"
        class="pr-2"
        :input-label="t('Operator')"
        item-value="value"
        search-bar
        item-text="label"
        :items="lookups"
        clearable
        @blur="$emit('save')"
      />
      <v-checkbox 
        v-if="filter.operator && filter.operator === 'isnull'"
        v-model="filter.value"
        class="pr-2"
        hide-details
        persistent-hint
        label="True"
        @blur="$emit('save')"
      />
       
      <text-input
        v-else
        v-model="filter.value"
        class="pr-2"
        hide-details
        persistent-hint
        :input-label="t('Value')"
        @blur="$emit('save')"
      />
      <v-checkbox
        v-model="filter.exclude"
        class="pr-2"
        label="Exclure"
        hide-details
        @blur="$emit('save')"
      />
      <v-btn
        icon
        small
        class="mt-4"
        @click="
          remove(index);
          $emit('save');
        "
      >
        <icon name="trash" size="18" />
      </v-btn>
    </div>
    <div>
      <v-btn
        text
        small
        color="primary"
        class="mt-2"
        @click="
          add();
          $emit('save');
        "
      >
        <icon name="plus" outline size="14" class="mr-1" />
        {{ t("Ajouter") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IndicatorFilters",
  props: {
    filters: {
      required: true,
      type: Array,
    },
  },

  computed: {
    ...mapState({
      lookups: (state) => state["indicators"].lookups,
    }),
  },

  methods: {
    add() {
      this.filters.push({ field: "", value: "", exclude: false });
    },

    remove(index) {
      this.filters.splice(index, 1);
    },
  },
};
</script>
