<template>
  <div class="p-5 mt-2">
    <h5 class="text-h4 font-demi-bold">
      {{ t("2-Factor Authentication") }}
    </h5>
    <div>
      {{
        t(
          "Reset 2FA in case of lost or stolen device. You will need to reconfigure 2FA on your new device."
        )
      }}
    </div>
    <div class="mt-1">
      <v-btn small depressed color="error" @click="resetValidation = true">
        {{ t("Reset") }}
      </v-btn>
      <v-dialog v-model="resetValidation" width="500">
        <v-card>
          <v-card-title>
            {{ t("Reset") }}
          </v-card-title>
          <v-card-text>
            <v-alert
              dense
              outlined
              type="error"
              class="text-body-2 mt-3"
              icon="mdi-alert-outline"
            >
              <ul>
                <li>
                  {{
                    t("You will be unable to login with your old 2FA device.")
                  }}
                </li>
                <li>
                  {{
                    t("You will be unable to login with your old 2FA codes.")
                  }}
                </li>
                <li>{{ t("You will be immediately logged out.") }}</li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-card-actions class="pt-0 justify-content-end">
            <v-btn
              small
              depressed
              outlined
              color="secondary"
              class="mr-2"
              @click="resetValidation = false"
            >
              {{ t("CANCEL") }}
            </v-btn>
            <v-btn small depressed color="error" @click="reset2FA">
              {{ t("Reset") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import { Totp } from "@/services/auth/totp";

export default {
  name: "AccountAuthentication",
  mixins: [i18n],
  data() {
    return {
      resetValidation: false,
    };
  },

  methods: {
    reset2FA() {
      Totp.reset().then(() => {
        this.$store
          .dispatch("auth/logout")
          .finally(() => this.$router.push({ name: "Login" }));
      });
    },
  },
};
</script>
