import Vue from "vue";
import VueRouter from "vue-router";
import { authUrls } from "@/router/auth";
import { accountUrls } from "@/router/account";
import { hospitalsUrls } from "@/router/hospitals";
import { indicatorsUrls } from "@/router/indicators";
import { settingsUrls } from "@/router/settings";
import { administrationUrls } from "@/router/administration";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: { name: "Hospitals" },
    },
    ...authUrls,
    ...accountUrls,
    ...hospitalsUrls,
    ...indicatorsUrls,
    ...settingsUrls,
    ...administrationUrls
  ],
});

export default router;
