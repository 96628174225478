<template>
  <svg
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.53 2H18.48C20.42 2 22 3.58 22 5.53V18.47C22 20.42 20.42 22 18.48 22H5.53C3.58 22 2 20.42 2 18.47V5.53C2 3.58 3.58 2 5.53 2ZM11 16.013C11 15.7359 11.0958 15.4978 11.2875 15.2987C11.4875 15.0996 11.725 15 12 15C12.2667 15 12.5 15.0952 12.7 15.2857C12.9 15.4762 13 15.71 13 15.987C13 16.2641 12.9 16.5022 12.7 16.7013C12.5083 16.9004 12.275 17 12 17C11.8667 17 11.7375 16.974 11.6125 16.9221C11.4958 16.8701 11.3917 16.8009 11.3 16.7143C11.2083 16.6277 11.1333 16.5238 11.075 16.4026C11.025 16.2814 11 16.1515 11 16.013ZM11.1655 12.9033C11.1983 12.7679 11.2519 12.5693 11.3156 12.4159C11.3884 12.2624 11.4794 12.1135 11.5885 11.9691C11.7068 11.8246 11.8524 11.6712 12.0252 11.5087L12.6393 10.9265C12.8121 10.773 12.9577 10.6015 13.0759 10.412C13.2033 10.2224 13.267 9.99226 13.267 9.72147C13.267 9.36944 13.1533 9.07608 12.9258 8.84139C12.7075 8.59768 12.3936 8.47582 11.9843 8.47582C11.775 8.47582 11.584 8.51644 11.4111 8.59768C11.2474 8.66989 11.1018 8.77369 10.9745 8.90909L10.5264 9.58226C10.3367 9.86711 9.98526 9.99747 9.65575 9.90516C9.27894 9.79961 9.01573 9.43044 9.09674 9.0476C9.13122 8.88461 9.17914 8.73089 9.25509 8.58414C9.43704 8.24113 9.66902 7.95229 9.95103 7.7176C10.233 7.48291 10.556 7.3069 10.9199 7.18956C11.2838 7.06319 11.675 7 12.0934 7C12.4846 7 12.8531 7.05867 13.1987 7.17601C13.5535 7.28433 13.8628 7.45132 14.1267 7.67698C14.3996 7.89362 14.6134 8.16441 14.768 8.48936C14.9227 8.81431 15 9.18891 15 9.61315C15 9.902 14.9682 10.1547 14.9045 10.3714C14.8408 10.588 14.7498 10.7866 14.6316 10.9671C14.5133 11.1476 14.3677 11.3237 14.1949 11.4952C14.0311 11.6576 13.8447 11.8291 13.6354 12.0097C13.4535 12.1631 13.3034 12.2985 13.1851 12.4159C13.0759 12.5332 12.985 12.6505 12.9122 12.7679C12.8485 12.8852 12.803 13.0116 12.7757 13.147L12.7723 13.2185C12.7513 13.656 12.3904 14 11.9524 14C11.4963 14 11.1232 13.628 11.1434 13.1724C11.1491 13.044 11.1563 12.9412 11.1655 12.9033Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "InfoSquare",
};
</script>
