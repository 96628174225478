<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 1C7.55228 1 8 1.44772 8 2H16C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V2.0018C20.2676 2.06915 21.9878 4.01513 22 6.28986L22 6.29519L22 7V17.7048C22 20.025 20.2148 22 17.8882 22H6.11183C3.78515 22 2 20.025 2 17.7048V7V6.29519C2 4.01228 3.72819 2.06361 6 2.00153V2C6 1.44772 6.44772 1 7 1ZM18 4.00385C18.98 4.0659 19.844 4.87313 19.9811 6H4.01682C4.14692 4.87101 5.01433 4.06059 6.00001 4.00325C6.00176 4.55404 6.4488 5 7 5C7.55228 5 8 4.55228 8 4H16V4.06C16 4.61228 16.4477 5.06 17 5.06C17.5523 5.06 18 4.61228 18 4.06V4.00385ZM20 8H4V17.7048C4 19.0299 4.99605 20 6.11183 20H17.8882C19.0039 20 20 19.0299 20 17.7048V8ZM17 11.5C17 12.3284 16.3284 13 15.5 13C14.6716 13 14 12.3284 14 11.5C14 10.6716 14.6716 10 15.5 10C16.3284 10 17 10.6716 17 11.5ZM11.5 13C12.3284 13 13 12.3284 13 11.5C13 10.6716 12.3284 10 11.5 10C10.6716 10 10 10.6716 10 11.5C10 12.3284 10.6716 13 11.5 13ZM9 11.5C9 12.3284 8.32843 13 7.5 13C6.67157 13 6 12.3284 6 11.5C6 10.6716 6.67157 10 7.5 10C8.32843 10 9 10.6716 9 11.5ZM11.5 18C12.3284 18 13 17.3284 13 16.5C13 15.6716 12.3284 15 11.5 15C10.6716 15 10 15.6716 10 16.5C10 17.3284 10.6716 18 11.5 18ZM9 16.5C9 17.3284 8.32843 18 7.5 18C6.67157 18 6 17.3284 6 16.5C6 15.6716 6.67157 15 7.5 15C8.32843 15 9 15.6716 9 16.5Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.89 3.03H18.05C20.23 3.03 22 4.8 22 6.98V18.05C22 20.23 20.23 22 18.05 22H5.95C3.77 22 2 20.23 2 18.05V6.98C2 4.8 3.77 3.03 5.95 3.03H6.11V2.75C6.11 2.34 6.45 2 6.86 2C7.28 2 7.61 2.34 7.61 2.75V3.03H16.39V2.75C16.39 2.34 16.73 2 17.14 2C17.55 2 17.89 2.34 17.89 2.75V3.03ZM3.5 7.14999H20.5V6.98C20.5 5.63 19.4 4.53 18.05 4.53H17.89V4.81C17.89 5.23 17.55 5.56 17.14 5.56C16.73 5.56 16.39 5.23 16.39 4.81V4.53H7.61V4.81C7.61 5.23 7.28 5.56 6.86 5.56C6.45 5.56 6.11 5.23 6.11 4.81V4.53H5.95C4.6 4.53 3.5 5.63 3.5 6.98V7.14999ZM18 11.5C18 12.3284 17.3284 13 16.5 13C15.6716 13 15 12.3284 15 11.5C15 10.6716 15.6716 10 16.5 10C17.3284 10 18 10.6716 18 11.5ZM11.5 13C12.3284 13 13 12.3284 13 11.5C13 10.6716 12.3284 10 11.5 10C10.6716 10 10 10.6716 10 11.5C10 12.3284 10.6716 13 11.5 13ZM8 11.5C8 12.3284 7.32843 13 6.5 13C5.67157 13 5 12.3284 5 11.5C5 10.6716 5.67157 10 6.5 10C7.32843 10 8 10.6716 8 11.5ZM11.5 18C12.3284 18 13 17.3284 13 16.5C13 15.6716 12.3284 15 11.5 15C10.6716 15 10 15.6716 10 16.5C10 17.3284 10.6716 18 11.5 18ZM8 16.5C8 17.3284 7.32843 18 6.5 18C5.67157 18 5 17.3284 5 16.5C5 15.6716 5.67157 15 6.5 15C7.32843 15 8 15.6716 8 16.5Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Calendar",
};
</script>
