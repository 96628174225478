export const VirtualMachineType = {
  MM_ETL: { value: "vm-mm-etl", text: "VM-MM-ETL" },
  MM_DEP: { value: "vm-mm-dep", text: "VM-MM-DEP" },
  CRE_APP: { value: "vm-cre-app", text: "VM-CRE-APP" },
  CRE_BDD: { value: "vm-cre-bdd", text: "VM-CRE-BDD" },
  EDS_APP: { value: "vm-eds-app", text: "VM-EDS-APP" },
  EDS_BDD: { value: "vm-eds-bdd", text: "VM-EDS-BDD" },
  WKS_APP: { value: "vm-wks-app", text: "VM-WKS-APP" },
  WKS_BDD: { value: "vm-wks-bdd", text: "VM-WKS-BDD" },
  ARNO: { value: "vm-arno", text: "VM-ARNO" },
};

export const HospitalStatus = {
  IN_PROGRESS: { value: "in_progress", text: "En construction" },
  BETA: { value: "beta", text: "Beta" },
  PROD: { value: "prod", text: "Production" },
  IDLE: { value: "idle", text: "Inactif" },
};
