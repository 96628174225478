<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.92001 10.97C7.92001 10.4177 8.36773 9.97 8.92001 9.97H13.03C13.5823 9.97 14.03 10.4177 14.03 10.97C14.03 11.5223 13.5823 11.97 13.03 11.97H8.92001C8.36773 11.97 7.92001 11.5223 7.92001 10.97Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 11C5 7.68611 7.68611 5 11 5C14.3139 5 17 7.68611 17 11C17 14.3139 14.3139 17 11 17C7.68611 17 5 14.3139 5 11ZM11 3C6.58154 3 3 6.58154 3 11C3 15.4185 6.58154 19 11 19C12.8488 19 14.551 18.373 15.9057 17.3199L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L17.3199 15.9057C18.373 14.551 19 12.8488 19 11C19 6.58154 15.4185 3 11 3Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.78 20.72L17.82 16.76C19.14 15.19 19.94 13.18 19.94 10.97C19.94 6.03 15.92 2 10.97 2C6.03 2 2 6.03 2 10.97C2 15.92 6.03 19.94 10.97 19.94C13.18 19.94 15.2 19.14 16.76 17.82L20.72 21.78C20.87 21.93 21.06 22 21.25 22C21.44 22 21.63 21.93 21.78 21.78C22.07 21.49 22.07 21.01 21.78 20.72ZM13.03 11.72H8.92001C8.50001 11.72 8.17001 11.39 8.17001 10.97C8.17001 10.56 8.50001 10.22 8.92001 10.22H13.03C13.44 10.22 13.78 10.56 13.78 10.97C13.78 11.39 13.44 11.72 13.03 11.72Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ZoomMinus",
};
</script>
