<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.063 13.3706C1.063 14.3195 0.987072 14.6285 0.791825 14.8562C0.574525 15.0518 0.292419 15.16 0 15.1598L0.0759289 15.702C0.529276 15.7081 0.969168 15.548 1.31248 15.252C1.4972 15.0268 1.63466 14.7667 1.71666 14.4873C1.79866 14.2078 1.82351 13.9147 1.78975 13.6255V10.0362H1.063V13.349V13.3706Z"
      :fill="$parent.getColor"
    />
    <path
      d="M6.48287 12.943C6.48287 13.3496 6.48287 13.7129 6.51541 14.0273H5.87002L5.82663 13.3821C5.69164 13.6104 5.49859 13.7989 5.26711 13.9284C5.03562 14.0579 4.77396 14.1239 4.50872 14.1195C3.8796 14.1195 3.13116 13.7779 3.13116 12.3845V10.0694H3.85791V12.2381C3.85791 12.9918 4.09112 13.496 4.74736 13.496C4.88237 13.4974 5.01631 13.4719 5.14133 13.4209C5.26636 13.37 5.37993 13.2945 5.47541 13.1991C5.57088 13.1037 5.64633 12.9901 5.69733 12.8651C5.74832 12.7401 5.77385 12.6062 5.7724 12.4713V10.0423H6.49914V12.9213L6.48287 12.943Z"
      :fill="$parent.getColor"
    />
    <path
      d="M7.86042 11.3593C7.86042 10.855 7.86042 10.443 7.82788 10.0689H8.4787L8.51124 10.7466C8.65624 10.5018 8.86498 10.3008 9.11522 10.1652C9.36546 10.0297 9.64782 9.9645 9.93219 9.97672C10.8976 9.97672 11.6243 10.7846 11.6243 11.9882C11.6243 13.4087 10.7511 14.1135 9.80745 14.1135C9.56591 14.1243 9.32569 14.0726 9.11001 13.9634C8.89433 13.8541 8.7105 13.6911 8.57632 13.49V15.6587H7.86042V11.3755V11.3593ZM8.57632 12.4165C8.57829 12.5149 8.58918 12.6129 8.60886 12.7093C8.6704 12.95 8.81048 13.1634 9.00693 13.3156C9.20339 13.4678 9.445 13.5502 9.69355 13.5497C10.4583 13.5497 10.9084 12.9262 10.9084 12.0207C10.9084 11.2292 10.4854 10.5514 9.72068 10.5514C9.41805 10.5762 9.13486 10.7105 8.92418 10.9291C8.71351 11.1476 8.58983 11.4356 8.57632 11.7388V12.4165Z"
      :fill="$parent.getColor"
    />
    <path
      d="M12.9192 10.0652L13.7924 12.402C13.8846 12.6622 13.9822 12.9767 14.0473 13.2098C14.1232 12.9713 14.2046 12.6677 14.3022 12.3857L15.094 10.0652H15.8587L14.774 12.8845C14.2317 14.24 13.9008 14.9394 13.4019 15.3623C13.1506 15.5918 12.8442 15.7524 12.5124 15.8286L12.3335 15.2213C12.5654 15.1451 12.7807 15.0255 12.968 14.8689C13.2326 14.6534 13.4426 14.3784 13.5809 14.0665C13.6105 14.0125 13.6306 13.9538 13.6405 13.893C13.6343 13.8276 13.6179 13.7635 13.5917 13.7032L12.1165 10.0544H12.9083L12.9192 10.0652Z"
      :fill="$parent.getColor"
    />
    <path
      d="M17.788 8.92783V10.0664H18.8293V10.6086H17.788V12.7448C17.788 13.2327 17.929 13.5147 18.3304 13.5147C18.4729 13.517 18.6151 13.5006 18.7534 13.4659L18.7859 14.008C18.5786 14.0798 18.3598 14.1129 18.1405 14.1056C17.9953 14.1147 17.8498 14.0923 17.7141 14.0399C17.5783 13.9875 17.4555 13.9063 17.3541 13.802C17.1332 13.5066 17.0324 13.1385 17.0721 12.7719V10.6032H16.4538V10.061H17.0829V9.0959L17.788 8.92783Z"
      :fill="$parent.getColor"
    />
    <path
      d="M20.168 12.1731C20.1533 12.357 20.1787 12.5419 20.2424 12.715C20.3062 12.8881 20.4068 13.0453 20.5373 13.1757C20.6677 13.3061 20.825 13.4067 20.9981 13.4704C21.1713 13.5342 21.3562 13.5595 21.5402 13.5448C21.9122 13.5532 22.2818 13.483 22.6249 13.3388L22.7496 13.881C22.3286 14.0543 21.876 14.1375 21.4208 14.125C21.1555 14.1431 20.8894 14.1037 20.6408 14.0094C20.3921 13.9151 20.1668 13.7681 19.9803 13.5786C19.7938 13.3891 19.6506 13.1614 19.5603 12.9114C19.4701 12.6613 19.435 12.3946 19.4576 12.1297C19.4576 10.937 20.168 9.99356 21.3287 9.99356C22.6303 9.99356 22.9557 11.1321 22.9557 11.8587C22.9626 11.9706 22.9626 12.0829 22.9557 12.1948H20.1518L20.168 12.1731ZM22.294 11.6309C22.315 11.486 22.3046 11.3382 22.2635 11.1976C22.2225 11.057 22.1518 10.9269 22.0563 10.8159C21.9607 10.7049 21.8424 10.6157 21.7094 10.5543C21.5764 10.4929 21.4318 10.4607 21.2853 10.4598C20.9873 10.4814 20.7081 10.6134 20.5024 10.8301C20.2967 11.0467 20.1794 11.3323 20.1735 11.6309H22.294Z"
      :fill="$parent.getColor"
    />
    <path
      d="M24.0584 11.2982C24.0584 10.8319 24.0584 10.4307 24.0258 10.062H24.6767V10.8373H24.7092C24.7775 10.598 24.9187 10.3859 25.113 10.2305C25.3074 10.075 25.5454 9.98391 25.7939 9.96982C25.8623 9.96085 25.9316 9.96085 26 9.96982V10.6475C25.9171 10.6379 25.8334 10.6379 25.7505 10.6475C25.5043 10.6571 25.27 10.7559 25.0914 10.9255C24.9127 11.0951 24.8019 11.3239 24.7797 11.5692C24.7594 11.6802 24.7485 11.7926 24.7472 11.9054V14.0145H24.0313V11.3036L24.0584 11.2982Z"
      :fill="$parent.getColor"
    />
    <path
      d="M13.0157 20.4087C8.13462 20.4087 3.84463 18.6574 1.62643 16.0712C2.4871 18.3978 4.04008 20.405 6.07634 21.8225C8.11257 23.2401 10.5344 24 13.0157 24C15.4971 24 17.9188 23.2401 19.9551 21.8225C21.9914 20.405 23.5443 18.3978 24.405 16.0712C22.1923 18.6574 17.9185 20.4087 13.0157 20.4087Z"
      :fill="$parent.getColor"
    />
    <path
      d="M13.0157 3.59135C17.8968 3.59135 22.1868 5.34258 24.405 7.92876C23.5443 5.6022 21.9914 3.59505 19.9551 2.17749C17.9188 0.759924 15.4971 0 13.0157 0C10.5344 0 8.11257 0.759924 6.07634 2.17749C4.04008 3.59505 2.4871 5.6022 1.62643 7.92876C3.84463 5.33716 8.11294 3.59135 13.0157 3.59135Z"
      fill="#233C73"
    />
  </svg>
</template>

<script>
export default {
  name: "Jupyter",
};
</script>
