<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V7.99989C20.1898 8.00013 20.3816 8.05429 20.5514 8.16721C21.43 8.75125 22 9.76376 22 10.8947V18.7444C22 20.5114 20.5976 22 18.8108 22H5.18919C3.40244 22 2 20.5114 2 18.7444V10.8947C2 9.76376 2.56998 8.75125 3.44857 8.16721C3.61844 8.05429 3.81024 8.00013 4 7.99989V3ZM18 10.3573V4H6V10.3573L11.3184 14.7592L11.3189 14.7597C11.7105 15.0822 12.3015 15.0768 12.6767 14.7633L12.6803 14.7603L12.6803 14.7603L18 10.3573ZM4 18.6983L8.4552 14.9856L4 11.2981V18.6983ZM10.0226 16.2829L5.56205 20H18.438L13.9774 16.2829L13.9591 16.2981L13.9571 16.2997C12.8347 17.236 11.1708 17.2301 10.0458 16.3021L10.0445 16.301L10.0226 16.2829ZM20 11.2981V18.6983L15.5448 14.9856L20 11.2981ZM7 6C7 5.44772 7.44772 5 8 5H9C9.55228 5 10 5.44772 10 6C10 6.55228 9.55228 7 9 7H8C7.44772 7 7 6.55228 7 6ZM8 8C7.44772 8 7 8.44772 7 9C7 9.55228 7.44772 10 8 10H16C16.5523 10 17 9.55228 17 9C17 8.44772 16.5523 8 16 8H8Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.01 11.17V19C22.01 19.52 21.87 20.02 21.64 20.45L20.44 19.42L15.22 14.95L20.3 10.41L21.43 9.39999C21.8 9.90999 22.01 10.52 22.01 11.17Z"
      :fill="$parent.getColor"
    />
    <path
      d="M20.61 21.53C20.15 21.83 19.59 22 19 22H5.01003C4.47003 22 3.96002 21.85 3.52002 21.6L4.83002 20.48L10 16.03L10.16 16.17C10.68 16.63 11.34 16.86 12 16.86C12.66 16.86 13.33 16.63 13.86 16.16L14.09 15.96L19.35 20.45L20.61 21.53Z"
      :fill="$parent.getColor"
    />
    <path
      d="M8.88 15.02L3.62001 19.55L2.45 20.56C2.17 20.1 2.01001 19.57 2.01001 19V11.17C2.01001 10.53 2.21001 9.92 2.58001 9.41L3.71001 10.42L8.88 15.02Z"
      :fill="$parent.getColor"
    />
    <path
      d="M19.2 2H4.81C4.4 2 4.06 2.34 4.06 2.75V8.71L4.51 9.11L5.55 10.04L11.15 15.04C11.64 15.47 12.38 15.47 12.86 15.04L18.46 10.04L19.51 9.1L19.95 8.71V2.75C19.95 2.34 19.61 2 19.2 2ZM7.89 5.08H8.92C9.34 5.08 9.67 5.42 9.67 5.83C9.67 6.25 9.34 6.58 8.92 6.58H7.89C7.48 6.58 7.14 6.25 7.14 5.83C7.14 5.42 7.48 5.08 7.89 5.08ZM16.12 9.67H7.89C7.48 9.67 7.14 9.33 7.14 8.92C7.14 8.5 7.48 8.17 7.89 8.17H16.12C16.53 8.17 16.87 8.5 16.87 8.92C16.87 9.33 16.53 9.67 16.12 9.67Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MailOpen",
};
</script>
