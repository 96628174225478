<template>
  <div>
    <c-r-u-d-data-table
      namespace="apps"
      :default-new-object="defaultApplication"
      :list-params="{ hospital: hospitalId }"
    >
      <template #content="{ items }">
        <v-expansion-panels accordion flat class="mt-2 bg-basics-20">
          <v-expansion-panel
            v-for="item in items"
            :key="item.id"
            class="rounded-container my-1"
          >
            <v-expansion-panel-header
              class="bg-basics-10 py-0"
              @click="setApp(item)"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    x-small
                    :class="pingColor(item) + ' flex-sm-grow-0 border-circle'"
                    v-on="on"
                  />
                </template>
                <span>Last connection:
                  {{
                    item.last_ping ? moment(item.last_ping).fromNow() : "never"
                  }}</span>
              </v-tooltip>
              <div class="d-flex justify-space-between align-center ml-5">
                <div>
                  <span class="font-demi-bold">
                    {{ item.code }}
                  </span>
                </div>
                <div class="d-flex align-center">
                  <badge
                    type="primary"
                    class="mr-2"
                    :text="item.vm.name"
                  />
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <navigation-drawer :items="tabs" />
              <router-view :key="item.id" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #dialogContent="{ currentObject }">
        <text-input
          v-model="currentObject.code"
          class="pt-2"
          autofocus
          :input-label="t('Code')"
          :rules="requiredRule"
          required
          :hint="t('Le code utilisé dans docker_deployment/config/xxx')"
        />
        <drop-down
          v-model="currentObject.type_id"
          class="mt-2"
          :input-label="t('Type')"
          required
          item-value="id"
          search-bar
          hide-details="auto"
          :rules="requiredRule"
          item-text="name"
          :items="application_types"
        />
        <drop-down
          v-model="currentObject.vm_id"
          hide-details="auto"
          :input-label="t('VM')"
          required
          class="mt-2"
          :rules="requiredRule"
          item-value="id"
          item-text="name"
          :items="vms"
        />
        <text-input
          v-model="currentObject.url"
          class="mt-2"
          hide-details="auto"
          :input-label="t('URL')"
        />
      </template>
    </c-r-u-d-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import NavigationDrawer from "@/components/design_system/NavigationDrawer";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "AppCardList",
  components: { NavigationDrawer, CRUDDataTable },
  mixins: [form_rules],

  props: {
    hospitalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      moment,
    };
  },

  computed: {
    ...mapState({
      application_types: (state) => state["application_types"].objects,
      vms: (state) => state["vms"].objects,
      hospital: (state) => state["hospitals"].current,
    }),

    tabs() {
      return [
        { name: "Parameters", to: { name: "AppParameters" } },
        { name: "Keys", to: { name: "AppKeys" } },
      ];
    },

    defaultApplication() {
      return {
        name: null,
        code: null,
        last_ping: null,
        type_id: null,
        vm_id: null,
      };
    },
  },

  methods: {
    setApp(item) {
      this.$store.commit("apps/setCurrent", item);
    },

    pingColor(application) {
      if (!application.last_ping) {
        return "purple";
      }

      const diff = moment().diff(moment(application.last_ping), "minutes");
      if (diff < 2) {
        return "green";
      } else if (diff < 10) {
        return "yellow";
      }
      return "red";
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ff0000;
}
.yellow {
  background-color: #ffcc00;
}
.green {
  background-color: #00ff00;
}
.purple {
  background-color: #cc00ff;
}
</style>
