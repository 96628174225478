<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.73001 4C7.33239 4 7 4.33057 7 4.73189V19.2681C7 19.6732 7.32615 20 7.73001 20H16.27C16.6676 20 17 19.6694 17 19.2681V14.9968V14.6562V4.73189C17 4.3268 16.6739 4 16.27 4H7.73001ZM19 14.6562V4.73189C19 3.22995 17.7861 2 16.27 2H7.73001C6.22763 2 5 3.22619 5 4.73189V19.2681C5 20.77 6.21387 22 7.73001 22H16.27C17.7724 22 19 20.7738 19 19.2681V14.9968V14.6562ZM10 6C10 5.44772 10.4477 5 11 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H11C10.4477 7 10 6.55228 10 6ZM13 18.01C13 18.5623 12.5523 19.01 12 19.01C11.4477 19.01 11 18.5623 11 18.01C11 17.4577 11.4477 17.01 12 17.01C12.5523 17.01 13 17.4577 13 18.01Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.27 2H7.73999C6.36999 2 5.26001 3.14 5.26001 4.53V19.47C5.26001 20.86 6.36999 22 7.73999 22H16.27C17.64 22 18.76 20.86 18.76 19.47V4.53C18.76 3.14 17.64 2 16.27 2ZM11.51 5.08H12.51C12.92 5.08 13.26 5.42 13.26 5.83C13.26 6.25 12.92 6.58 12.51 6.58H11.51C11.09 6.58 10.76 6.25 10.76 5.83C10.76 5.42 11.09 5.08 11.51 5.08ZM12 19.68C11.08 19.68 10.33 18.93 10.33 18.01C10.33 17.09 11.08 16.34 12 16.34C12.92 16.34 13.67 17.09 13.67 18.01C13.67 18.93 12.92 19.68 12 19.68Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MobilePhone",
};
</script>
