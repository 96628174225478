<template>
  <svg
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C17.51 2 22 6.49 22 12C22 13.34 21.73 14.64 21.2 15.89L21.94 20.86C21.97 21.1 21.89 21.34 21.73 21.5C21.56 21.67 21.32 21.75 21.09 21.72L16.31 21.01C14.95 21.67 13.5 22 12 22C6.48 22 2 17.51 2 12C2 6.49 6.48 2 12 2ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SpeechMinus",
};
</script>
