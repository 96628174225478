/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "@/router/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import TextInput from "@/components/design_system/TextInput";
import DateInput from "@/components/design_system/DateInput";
import TextareaInput from "@/components/design_system/TextareaInput";
import Icon from "@/components/design_system/Icon";
import Badge from "@/components/design_system/Badge";
import DropDown from "@/components/design_system/DropDown";
import RadioButton from "@/components/design_system/RadioButton";
import { textFromEnum, enumFromValue } from "@/utils/enums";
import moment from "moment";
import VueClipboard from "vue-clipboard2";
import "./assets/style.css";

Vue.config.productionTip = false;

Vue.use(VueClipboard);

Vue.mixin({
  methods: {
    t: (s) => s,
    textFromEnum,
    enumFromValue,
  },
});

Vue.component("TextInput", TextInput);
Vue.component("DateInput", DateInput);
Vue.component("TextareaInput", TextareaInput);
Vue.component("Icon", Icon);
Vue.component("Badge", Badge);
Vue.component("DropDown", DropDown);
Vue.component("RadioButton", RadioButton);

Vue.filter("date", (date, pattern = "DD/MM/YYYY") => {
  if (date) {
    return moment(date).format(pattern);
  }
});

Vue.filter("datetime", (date, pattern = "DD/MM/YYYY HH:mm") => {
  if (date) {
    return moment(date).format(pattern);
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
