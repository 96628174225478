<template>
  <div>
    <v-btn :disabled="disabled" :class="disabled ? 'disabled' : important ? 'primary' : 'no-bg mr-1'" class="ml-auto"
           @click="show = true"
    >
      <icon size="16" name="upload" outline :color="important ? 'basics-10' : 'primary-main'" class="mr-1" />
      {{ text }}
    </v-btn>
    <v-dialog v-model="show" width="450" @click:outside="close" @keydown.enter="getExport">
      <v-card class="py-3 px-5">
        <h4 class="mt-2 mb-4 ml-1 text-subtitle-1 font-demi-bold">
          {{ t('Export data') }}
        </h4>
        <ul style="list-style-type: decimal">
          <li>
            <span>{{ t('File format') }}</span>
            <v-card-actions class="pt-0">
              <div>
                <v-radio-group v-model="extension" row hide-details class="mt-2 mb-4">
                  <radio-button label=".csv" value="csv" />
                  <radio-button label=".xlsx" value="xlsx" />
                </v-radio-group>
              </div>
            </v-card-actions>
          </li>
          <li>
            <span>{{ t('Filename') }}</span>
            <v-card-actions class="mt-2">
              <text-input
                v-model="filename"
                style="max-width: 350px"
                :placeholder="t('Filename')"
                hide-details
                class="temporary-class-darker-placeholder"
              />
              <span class="ml-1 d-inline-flex align-items-center">{{ '.' + extension }}</span>
            </v-card-actions>
          </li>
        </ul>
        <v-card-actions class="justify-end p-0 mt-4 mb-2">
          <v-btn small text class="basics-80" @click="close">
            {{ t('Cancel') }}
          </v-btn>
          <v-btn small class="primary" :loading="loading" :disabled="!filename" :class="!filename ? 'disabled' : ''"
                 @click="getExport"
          >
            {{ text }}
          </v-btn>
        </v-card-actions>
        <span v-if="errorMessage" class="d-flex text-center danger-main">
          <v-icon small class="danger-main mr-1">mdi-alert-outline</v-icon>
          {{ t('An error has occurred.') }}
        </span>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/mixins/i18n';
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  name: 'ExportModule',
  props: {
    text: {
      type: String,
      required: false,
      default: i18n.methods.t('Export')
    },

    data: {
      type: Array,
      required: false
    },

    dataFunction: {
      type: Function,
      required: false
    },

    important: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      extension: 'csv',
      filename: this.t('export') + '_' + moment().format('YYYYMMDDHHmm'),
      loading: false,
      errorMessage: false,
      show: false,
    };
  },

  created() {
    if (!(this.data || this.dataFunction)) {
      this.$store.commit(
          "snackbar/errorSnackbar",
          { "message": "You need to defined either `data` or `data-function` when using `ExportModule`" }
      );
    }
  },

  methods: {
    downloadFile(blob, format) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${this.filename}.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.close();

    },

    buildContentCsv(headers, rowData) {
      // Return joined comma if there's no value in row.
      return headers.map((header) => rowData[header] || '').join(',');
    },

    getData() {
      return new Promise((resolve) => {
        if (this.data) {
          resolve(this.data);
        } else {
          resolve(this.dataFunction());
        }
      });
    },

    exportCSV(headers, data) {
      const content = headers.join(',') + '\n' + data.map(
          rowData => this.buildContentCsv(headers, rowData) + '\n'
      ).join('');

      const csvBlob = new Blob([content], { type: "text/csv;charset=utf-8;" });
      this.downloadFile(csvBlob, "csv");
    },

    exportXLSX(headers, data) {
      const wsData = data.map(rowData => headers.reduce((row, header) => {
        // Transforms to a valid json to write in xlsx sheet.
        row[header] = rowData[header] || "";
        return row;
      }, {}));
      const ws = XLSX.utils.json_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, this.filename);
      XLSX.writeFile(wb, `${this.filename}.xlsx`);
      this.close();
    },

    getExport() {
      this.loading = true;
      this.errorMessage = false;


      this.getData().then(data => {
        const headers = Object.keys(data[0]);
        if (this.extension === "csv") {
          this.exportCSV(headers, data);
        } else if (this.extension === 'xlsx') {
          this.exportXLSX(headers, data);
        }
      });
    },

    close() {
      this.extension = 'csv';
      this.errorMessage = false;
      this.show = false;
      this.loading = false;
    }
  }
};
</script>
