<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00364 4C5.44692 4 5 4.44712 5 5.00324V16.1745C5.3139 16.0636 5.65175 16.0033 6.00364 16.0033H17.9964C18.5531 16.0033 19 15.5561 19 15V5.00324C19 4.44712 18.5531 4 17.9964 4H6.00364ZM21 15V5.00324C21 3.34217 19.6573 2 17.9964 2H6.00364C4.34274 2 3 3.34217 3 5.00324V18.9968C3 20.6578 4.34274 22 6.00364 22H17.9964C19.6573 22 21 20.6578 21 18.9968V15ZM19 17.8321C18.6863 17.9429 18.3485 18.0033 17.9964 18.0033H6.00364C5.44375 18.0033 5 18.4535 5 18.9968C5 19.5529 5.44692 20 6.00364 20H17.9964C18.5531 20 19 19.5529 19 18.9968V17.8321ZM8 6C8 5.44772 8.44772 5 9 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H9C8.44772 7 8 6.55228 8 6ZM10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10H14C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8H10Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.17 2H5.83002C4.29002 2 3.03003 3.26 3.03003 4.81V19.19C3.03003 20.74 4.29002 22 5.83002 22H18.17C19.71 22 20.97 20.74 20.97 19.19V4.81C20.97 3.26 19.71 2 18.17 2ZM8.92004 5.08002H15.08C15.5 5.08002 15.83 5.42002 15.83 5.83002C15.83 6.25002 15.5 6.58002 15.08 6.58002H8.92004C8.50004 6.58002 8.17004 6.25002 8.17004 5.83002C8.17004 5.42002 8.50004 5.08002 8.92004 5.08002ZM14.81 8.91998C14.81 9.32998 14.47 9.66998 14.06 9.66998H9.94003C9.53003 9.66998 9.19003 9.32998 9.19003 8.91998C9.19003 8.49998 9.53003 8.16998 9.94003 8.16998H14.06C14.47 8.16998 14.81 8.49998 14.81 8.91998ZM19.47 19.19C19.47 19.91 18.89 20.5 18.17 20.5H5.83002C5.11002 20.5 4.53003 19.91 4.53003 19.19C4.53003 18.47 5.11002 17.89 5.83002 17.89H18.17C18.64 17.89 19.08 17.77 19.47 17.57V19.19Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BookClosed",
};
</script>
