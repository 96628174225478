<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.99999 5.99005C7.88993 5.99005 6.99005 6.88993 6.99005 8C6.99005 9.11007 7.88993 10.0099 8.99999 10.0099C10.1101 10.0099 11.0099 9.11006 11.0099 8C11.0099 6.88994 10.1101 5.99005 8.99999 5.99005ZM5 8C5 5.79086 6.79085 4 8.99999 4C11.2091 4 13 5.79086 13 8C13 10.2091 11.2091 12 8.99999 12C6.79085 12 5 10.2091 5 8Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.53087 15.6247C4.21317 16.0218 4 16.6525 4 17.5V19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19V17.5C2 16.3475 2.28683 15.2282 2.96913 14.3753C3.67546 13.4924 4.72375 13 6 13H12C13.2715 13 14.32 13.4838 15.0284 14.3622C15.7131 15.2112 16 16.3273 16 17.48V19C16 19.5523 15.5523 20 15 20C14.4477 20 14 19.5523 14 19V17.48C14 16.6326 13.7869 16.0088 13.4716 15.6177C13.18 15.2562 12.7285 15 12 15H6C5.27625 15 4.82454 15.2576 4.53087 15.6247Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 8.66667C16.0398 8.66667 15.6667 9.03977 15.6667 9.5C15.6667 9.96023 16.0398 10.3333 16.5 10.3333C16.9602 10.3333 17.3333 9.96024 17.3333 9.5C17.3333 9.03976 16.9602 8.66667 16.5 8.66667ZM14 9.5C14 8.11928 15.1193 7 16.5 7C17.8807 7 19 8.11929 19 9.5C19 10.8807 17.8807 12 16.5 12C15.1193 12 14 10.8807 14 9.5Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 14C17 13.4477 17.4477 13 18 13H18.54C20.6998 13 22 14.8245 22 16.72V18C22 18.5523 21.5523 19 21 19C20.4477 19 20 18.5523 20 18V16.72C20 15.6555 19.3402 15 18.54 15H18C17.4477 15 17 14.5523 17 14Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84 11.59C10.92 11.59 12.61 9.9 12.61 7.82C12.61 5.74 10.92 4.05 8.84 4.05C6.76 4.05 5.07001 5.74 5.07001 7.82C5.07001 9.9 6.76 11.59 8.84 11.59Z"
      :fill="$parent.getColor"
    />
    <path
      d="M16.16 17.48V19.2C16.16 19.61 15.83 19.95 15.41 19.95H2.75C2.34 19.95 2 19.61 2 19.2V17.48C2 15.07 3.96 13.11 6.37 13.11H11.79C12.28 13.11 12.75 13.19 13.19 13.35C13.19 13.35 13.19 13.34 13.2 13.35C14.92 13.93 16.16 15.56 16.16 17.48Z"
      :fill="$parent.getColor"
    />
    <path
      d="M16.16 17.48V19.2C16.16 19.61 15.83 19.95 15.41 19.95H2.75C2.34 19.95 2 19.61 2 19.2V17.48C2 15.07 3.96 13.11 6.37 13.11H11.79C12.28 13.11 12.75 13.19 13.19 13.35C13.19 13.35 13.19 13.34 13.2 13.35C14.92 13.93 16.16 15.56 16.16 17.48Z"
      :fill="$parent.getColor"
    />
    <path
      d="M22 16.62V18.03C22 18.36 21.73 18.63 21.4 18.63H18.66V17.48C18.66 16.47 18.4 15.51 17.95 14.68C17.76 14.34 17.55 14.02 17.31 13.73C17.14 13.54 16.97 13.35 16.79 13.19H18.57C18.68 13.19 18.78 13.19 18.88 13.21C19.01 13.22 19.14 13.24 19.27 13.27C20.03 13.43 20.69 13.85 21.18 14.41C21.26 14.5 21.34 14.6 21.41 14.71C21.42 14.71 21.42 14.71 21.41 14.72C21.6 15 21.75 15.3 21.85 15.63C21.88 15.74 21.91 15.85 21.93 15.96C21.98 16.17 22 16.39 22 16.62Z"
      :fill="$parent.getColor"
    />
    <path
      d="M16.38 11.45C18.0314 11.45 19.37 10.1113 19.37 8.46C19.37 6.80867 18.0314 5.47 16.38 5.47C14.7287 5.47 13.39 6.80867 13.39 8.46C13.39 10.1113 14.7287 11.45 16.38 11.45Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Users",
};
</script>
