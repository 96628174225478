import { AbstractApi, authRequest } from "@/services/api/base";
import { authHeader } from "@/services/auth/headers";

export class ResultApi extends AbstractApi {
  async get(indicator_id, application_id, hospital_id) {
    let url = this.url_list + "get/";
    return await authRequest.get(url, {
      headers: authHeader(),
      params: { indicator_id: indicator_id, application_id: application_id, hospital_id: hospital_id },
    });
  }
}
