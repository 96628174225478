import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import { hospitals } from "@/store/modules/hospitals/hospitals";
import { apps } from "@/store/modules/apps/apps";
import { vms } from "@/store/modules/hospitals/vms";
import { users } from "@/store/modules/users/users";
import { application_types } from "@/store/modules/apps/application_types";
import { indicators } from "@/store/modules/indicators/indicators";
import { indicators_apps } from "@/store/modules/indicators/indicators_apps";
import snackbar from "./modules/snackbar";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    snackbar,
    auth,

    hospitals,
    vms,

    apps,
    application_types: application_types,

    indicators,
    indicators_apps,
    
    users
  },
  strict: debug,
});
