<template>
  <auth-container :title="t('2-Factor Authentication')">
    <v-row justify="center">
      <v-col cols="11" md="10" lg="8">
        <v-card>
          <v-card-text>
            <div>
              <h6 class="text-body-1 font-demi-bold mt-2">
                {{ t("Setup") }}
              </h6>
              {{ t("Download an authenticator application on your phone.") }}
              <br>
              {{ t("You can use apps such as:") }}
              <a
                href="https://www.microsoft.com/fr/security/mobile-authenticator-app"
              >Microsoft Authenticator</a>,
              <a href="https://support.1password.com/fr/one-time-passwords/">
                1Password</a>, <a href="https://authy.com/features/">Authy</a>.

              <h6 class="text-body-2">
                {{ t("Configuration") }}
              </h6>

              {{ t("Scan the QR Code below with your phone application.") }}

              <div v-if="uri">
                <qrcode-vue
                  :key="uri"
                  :value="uri"
                  :size="size"
                  level="H"
                  class="my-3 justify-content-center"
                />
                {{
                  t("If you can't use the QR Code, enter this code instead: ")
                }}: {{ secret }}
              </div>
              <div v-else class="my-3">
                <v-progress-circular indeterminate color="primary" />
              </div>

              <h6 class="text-body-1 font-demi-bold mt-2">
                {{ t("Verification") }}
              </h6>
              {{
                t("Enter the code generated by your phone application below.")
              }}

              <text-input
                v-model="totp"
                class="mt-2"
                style="width: 150px"
                :placeholder="t('Code')"
                required
                hide-details
                :loading="loading"
                autocomplete="off"
                @input="verifyTotp"
              />
              <span class="text-danger">{{ errorMsg }}</span>

              <v-alert v-if="verified" dense type="success" class="my-3">
                {{ t("You have been successfully authenticated !") }}
              </v-alert>
            </div>

            <div v-show="verified" id="recoveryCodes" class="mt-10">
              <h6 class="text-body-1 font-demi-bold mt-2">
                {{ t("Recovery codes") }}
              </h6>
              {{
                t(
                  "Recovery codes allow you to log in if you don't have access to your phone."
                )
              }}
              <br>
              {{ t("Save and protect them as you would for passwords.") }}
              <br>
              <v-alert
                dense
                outlined
                type="warning"
                class="text-body-2 mt-3"
                icon="mdi-alert-outline"
              >
                {{
                  t(
                    "If you lose your recovery codes and lost access to your phone, you will no longer be able to log in to your account"
                  )
                }}
              </v-alert>

              <ul v-if="recovery_codes.length > 0" class="text-body-1">
                <li v-for="(code, index) in recovery_codes" :key="index">
                  {{ code }}
                </li>
              </ul>
              <v-progress-circular v-else indeterminate color="primary" />

              <div class="mt-3 d-flex justify-content-center">
                <v-btn
                  depressed
                  dense
                  color="primary"
                  class="text-none text-body-2"
                  @click="goToHome"
                >
                  {{ t("Continue") }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </auth-container>
</template>

<script>
import i18n from "@/mixins/i18n";
import QrcodeVue from "qrcode.vue";
import { Totp, StaticTotp } from "@/services/auth/totp";
import AuthContainer from "@/components/auth/AuthContainer";

export default {
  name: "OTPSetup",
  components: {
    AuthContainer,
    QrcodeVue,
  },

  mixins: [i18n],
  data() {
    return {
      uri: null,
      size: 150,
      totp: "",
      recovery_codes: [],
      verified: false,
      loading: false,
      errorMsg: "",
    };
  },

  computed: {
    secret() {
      return this.uri.match("secret=(.*?)&")[1];
    },
  },

  mounted() {
    Totp.setup().then(
      (response) => {
        this.uri = response.data;
        if (!this.uri) {
          this.$router.push({ name: "Login" });
        }
      },
      () => {
        this.$router.push({ name: "Login" });
      }
    );
  },

  methods: {
    verifyTotp() {
      if (this.totp.length === 6) {
        this.loading = true;
        Totp.verify(this.totp)
          .then(
            (response) => {
              this.$store.dispatch("auth/login", response.data);
              this.$vuetify.goTo("#recoveryCodes");
              this.verified = true;
              return StaticTotp.setup().then((response) => {
                this.recovery_codes = response.data;
              });
            },
            (err) => {
              if (err.response.status === 401) {
                this.$router.push({ name: "Login" });
              } else {
                this.errorMsg = this.t("Code invalide");
              }
            }
          )
          .finally(() => (this.loading = false));
      } else {
        this.errorMsg = "";
      }
    },

    goToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
