<template>
  <v-container>
    <div class="d-flex mt-3 w-70">
      <div class="w-70">
        <v-text-field
          v-model="application.id"
          prepend-inner-icon="mdi-key"
          label="ID"
          outlined
          hide-details
          readonly
        />
      </div>
    </div>
    <div class="d-flex mt-3 w-70">
      <div class="w-70">
        <v-text-field
          v-model="privateKey"
          prepend-inner-icon="mdi-lock"
          label="Private Key"
          outlined
          hide-details
          readonly
        />
        <v-text-field
          v-model="publicKey"
          prepend-inner-icon="mdi-lock"
          label="Public Key"
          class="mt-3"
          outlined
          hide-details
          readonly
        />
      </div>
      <v-btn small class="danger ml-5 my-auto" @click="openKeysDialog = true">
        <icon name="alert" outline color="basics-10" size="18" class="mr-1" />
        {{ t("Regenerate") }}
      </v-btn>
    </div>
    <v-dialog v-model="openKeysDialog" persistent max-width="600px">
      <v-card>
        <v-card-title> Regenerate the API Key </v-card-title>
        <v-card-text>
          <div>
            <p>
              The public and private keys are used by the client application to
              authenticate itself when communicating with Codoc Monitoring.
            </p>
            <p>
              They should be kept private. As such, it is recommended to only
              save them in the appropriate environment once generated and to not
              save them anywhere else.
            </p>
            <v-alert dense outlined type="error" class="mt-4">
              Regenerating the keys will invalidate the previous ones.
            </v-alert>
            <div class="text-right mt-4">
              <v-btn small class="danger" @click="generateKeys">
                {{ t("Regenerate") }}
              </v-btn>
              <v-btn
                small
                text
                class="ml-4 primary"
                @click="openKeysDialog = false"
              >
                {{ t("Cancel") }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { Applications } from "@/services/resources";

export default {
  name: "AppKeys",
  components: {},
  data() {
    return {
      openKeysDialog: false,
      publicKey: "*".repeat(256),
      privateKey: "*".repeat(256),
    };
  },

  computed: {
    ...mapState({
      application: (state) => state["apps"].current,
      app_index: (state) => state["apps"].currentIndex,
      application_types: (state) => state["application_types"].objects,
      vms: (state) => state["vms"].objects,
    }),
  },

  mounted() {
    this.url = this.application.url;
    this.type_id = this.application.type_id;
    this.vm_id = this.application.vm_id;
  },

  methods: {
    generateKeys() {
      Applications.generateKeys(this.application.id).then((response) => {
        this.publicKey = response.data["public"];
        this.privateKey = response.data["private"];
        this.openKeysDialog = false;
      });
    },
  },
};
</script>
