import { isAfter, subMinutes, differenceInMilliseconds } from "date-fns";
import AuthInterface from "@/services/auth/auth";
import router from "@/router/router";
import { parseJWT } from "@/utils/jwt";
import TokenService from "@/services/auth/tokens";

export function isLoggedIn() {
  let exp = TokenService.getExpiryTime();
  let expiryTime = new Date(exp);
  const now = new Date();
  return !isAfter(now, expiryTime);
}

const state = () => ({
  refreshToken: TokenService.getRefreshToken(),
  accessToken: TokenService.getAccessToken(),
  tokensExpiry: TokenService.getExpiryTime(),
  loggedIn: isLoggedIn(),
});

const getters = {
  tokensExpiry: (state) => state.tokensExpiry,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
  decodedAccessToken: (state) => {
    parseJWT(state.accessToken);
  },
};

const actions = {
  login({ commit, dispatch }, tokens) {
    commit("setTokens", tokens);
    dispatch("initSession");
  },

  logout({ commit }) {
    return AuthInterface.logout().finally(() => {
      commit("resetTokens");
    });
  },

  initSession({ state, dispatch }) {
    let tokenExpiryDate = state.tokensExpiry;

    if (!tokenExpiryDate) {
      if (router.currentRoute.name !== "Login") {
        return router.push({ name: "Login" });
      }
      return;
    }

    tokenExpiryDate = new Date(tokenExpiryDate);

    let tenMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 5);
    const now = new Date();

    if (isAfter(now, tenMinutesBeforeExpiry)) {
      dispatch("refreshTokens");
      return;
    }
    setTimeout(() => {
      dispatch("refreshTokens");
    }, differenceInMilliseconds(tenMinutesBeforeExpiry, now));
  },

  refreshTokens({ commit, dispatch, state }) {
    AuthInterface.refresh().then(
      (response) => {
        commit("setTokens", response.data);
        const tokenExpiryDate = state.tokensExpiry;
        const tenMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 5);
        const now = new Date();
        setTimeout(() => {
          dispatch("refreshTokens");
        }, differenceInMilliseconds(tenMinutesBeforeExpiry, now));
      },
      () => {
        dispatch("logout");
        if (router.currentRoute.name !== "Login") {
          router.push({ name: "Login" });
        }
      }
    );
  },
};

const mutations = {
  setTokens(state, tokens) {
    TokenService.setAccessToken(tokens.access);
    state.accessToken = tokens.access;

    if (tokens.refresh) {
      TokenService.setRefreshToken(tokens.refresh);
      state.refreshToken = tokens.refresh;
    }

    let parsedAccessToken = parseJWT(state.accessToken);
    const tokensExpiry = new Date(parsedAccessToken.exp * 1000);

    TokenService.setExpiryTime(tokensExpiry);
    state.tokensExpiry = tokensExpiry;

    state.loggedIn = true;
    localStorage.setItem("logout", "false");
  },

  resetTokens(state) {
    TokenService.removeAccessToken();
    state.accessToken = null;

    TokenService.removeRefreshToken();
    state.refreshToken = null;

    TokenService.removeExpiryTime();
    state.tokensExpiry = null;

    state.loggedIn = false;
    localStorage.setItem("logout", "true");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
