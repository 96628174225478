<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.98703 4C4.89287 4 4 4.88738 4 5.98703V18.013C4 19.1071 4.88738 20 5.98703 20H18.013C19.105 20 20 19.105 20 18.013V5.98703C20 4.89287 19.1126 4 18.013 4H5.98703ZM2 5.98703C2 3.77858 3.79255 2 5.98703 2H18.013C20.2214 2 22 3.79255 22 5.98703V18.013C22 20.2096 20.2096 22 18.013 22H5.98703C3.77858 22 2 20.2075 2 18.013V5.98703Z"
      :fill="$parent.getColor"
    />
    <rect x="6" y="6" width="5" height="12" rx="1" :fill="$parent.getColor" />
    <rect x="13" y="6" width="5" height="7" rx="1" :fill="$parent.getColor" />
  </svg>
</template>

<script>
export default {
  name: "Column",
};
</script>
