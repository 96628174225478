import { render, staticRenderFns } from "./HospitalBuilding.vue?vue&type=template&id=c03430c2"
import script from "./HospitalBuilding.vue?vue&type=script&lang=js"
export * from "./HospitalBuilding.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports