<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 8C13.1046 8 13.9999 7.10461 13.9999 6C13.9999 4.89537 13.1046 4.00011 12 4.00011C10.8954 4.00011 9.99967 4.89537 9.99967 6C9.99967 7.10461 10.8954 8 12 8ZM12 10.0001C14.2093 10.0001 16 8.20927 16 6C16 3.79074 14.2093 2 12 2C9.79074 2 7.99956 3.79074 7.99956 6C7.99956 8.20927 9.79074 10.0001 12 10.0001Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0688 12.0224C11.7151 11.8829 11.4045 11.7163 11.0641 11.5336C10.8408 11.4138 10.6046 11.2871 10.335 11.1566C9.51839 10.7614 8.54508 10.4479 7.31064 10.8476C5.88001 11.3109 5.1223 12.8203 5.01443 14.3432C4.90188 15.9322 5.44139 17.7942 6.85971 19.4475C8.25891 21.0785 10.5 22 12 22C13.5 22 15.3429 21.5643 16.65 20.0869C17.8649 18.7137 18.6543 17.1272 18.9103 15.6311C19.1637 14.1502 18.9075 12.6011 17.7905 11.5724C17.1391 10.9725 16.1898 10.7498 15.2699 10.7954C14.3225 10.8423 13.2835 11.174 12.3242 11.8367C12.2382 11.8961 12.1531 11.958 12.0688 12.0224ZM10.6058 13.5517C9.71966 14.8032 9.08294 16.4988 8.89676 18.6917C8.72058 18.5248 8.54724 18.3428 8.37774 18.1452C7.28219 16.8682 6.93632 15.5183 7.00954 14.4845C7.08743 13.3848 7.58666 12.8607 7.92679 12.7505C8.46312 12.5768 8.86818 12.6688 9.46379 12.957C9.6005 13.0232 9.75978 13.1085 9.93809 13.204C10.1383 13.3113 10.3626 13.4314 10.6058 13.5517ZM10.8414 19.92C11.2129 20.0515 11.5817 20.1281 11.9399 20.1535C13.093 20.2351 14.2369 19.7958 15.152 18.7616C16.1591 17.6232 16.7548 16.3689 16.9388 15.2937C17.1255 14.2031 16.8767 13.4499 16.4356 13.0436C16.3078 12.926 15.9586 12.7638 15.3689 12.7931C14.8067 12.8209 14.1249 13.0237 13.461 13.4823C12.1823 14.3656 10.8724 16.2791 10.8414 19.92Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DrCare",
};
</script>
