<template>
  <v-dialog
    v-model="open"
    max-width="400px"
    @click:outside="$emit('update:open', false)"
  >
    <v-card>
      <v-form ref="form">
        <v-card-text class="text-center text-subtitle-1">
          <slot name="dialogDeleteContent" :currentObject="currentObject">
            {{ t("Delete") }} {{ currentObject.name }} ?
          </slot>
        </v-card-text>
      </v-form>
      <v-card-actions class="justify-end">
        <div>
          <v-btn
            small
            text
            class="basics-80 mr-2"
            @click="$emit('update:open', false)"
          >
            {{ t("Cancel") }}
          </v-btn>
          <v-btn small class="primary" @click="destroy">
            {{ t("Supprimer") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    open: {
      type: Boolean,
      required: true,
    },

    destroy: {
      type: Function,
      required: true,
    },

    currentObject: {
      type: Object,
      required: true,
    },
  },
};
</script>
