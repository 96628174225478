<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.05489 4C7.29382 4 6.62635 4.41863 6.27126 5.03723C6.69174 5.15546 7 5.54173 7 6C7 6.55228 6.55228 7 6 7V11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13V17C6.55228 17 7 17.4477 7 18C7 18.4583 6.69173 18.8445 6.27126 18.9628C6.62634 19.5814 7.29381 20 8.05489 20H15.9451C17.0759 20 18 19.0759 18 17.9449V6.05513C18 4.9241 17.0759 4 15.9451 4H8.05489ZM4 13V17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H4.13927C4.60493 20.7258 6.18423 22 8.05489 22H15.9451C18.1806 22 20 20.1803 20 17.9449V6.05513C20 3.81968 18.1806 2 15.9451 2H8.05489C6.18423 2 4.60493 3.27421 4.13927 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7V11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13ZM9 6C8.44772 6 8 6.44772 8 7V11C8 11.5523 8.44772 12 9 12H15C15.5523 12 16 11.5523 16 11V7C16 6.44772 15.5523 6 15 6H9ZM10 10V8H14V10H10ZM8 14C8 13.4477 8.44772 13 9 13H15C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H9C8.44772 15 8 14.5523 8 14ZM9 16C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H9Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.84 2H7.73001C5.86001 2 4.29999 3.32 3.92999 5.08H3.56C3.15 5.08 2.81 5.42 2.81 5.83C2.81 6.25 3.15 6.58 3.56 6.58H3.84V11.25H3.56C3.15 11.25 2.81 11.59 2.81 12C2.81 12.41 3.15 12.75 3.56 12.75H3.84V17.42H3.56C3.15 17.42 2.81 17.75 2.81 18.17C2.81 18.58 3.15 18.92 3.56 18.92H3.92999C4.29999 20.68 5.86001 22 7.73001 22H15.84C17.98 22 19.73 20.25 19.73 18.11V5.89C19.73 3.75 17.98 2 15.84 2ZM14.78 17.89H8.78C8.37 17.89 8.03 17.55 8.03 17.14C8.03 16.72 8.37 16.39 8.78 16.39H14.78C15.2 16.39 15.53 16.72 15.53 17.14C15.53 17.55 15.2 17.89 14.78 17.89ZM14.78 14.81H8.78C8.37 14.81 8.03 14.47 8.03 14.06C8.03 13.64 8.37 13.31 8.78 13.31H14.78C15.2 13.31 15.53 13.64 15.53 14.06C15.53 14.47 15.2 14.81 14.78 14.81ZM15.53 8.68C15.53 9.24 15.08 9.68 14.53 9.68H9.03C8.48 9.68 8.03 9.24 8.03 8.68V7.08C8.03 6.53 8.48 6.08 9.03 6.08H14.53C15.08 6.08 15.53 6.53 15.53 7.08V8.68Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AdressBook",
};
</script>
