<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 6C5.44772 6 5 6.44772 5 7V16C5 16.5523 5.44772 17 6 17H10.5C11.0523 17 11.5 17.4477 11.5 18V19.9939L14.5858 17.2524C14.7689 17.0898 15.0052 17 15.25 17H18C18.5523 17 19 16.5523 19 16V7C19 6.44772 18.5523 6 18 6H6ZM3 7C3 5.34315 4.34315 4 6 4H18C19.6569 4 21 5.34315 21 7V16C21 17.6569 19.6569 19 18 19H15.63L12.8283 21.4891C11.5384 22.6351 9.5 21.7194 9.5 19.9939V19H6C4.34315 19 3 17.6569 3 16V7Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 16V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V16C21 17.1046 20.1046 18 19 18H15.25L12.1877 20.9011C11.5504 21.5049 10.5 21.0531 10.5 20.1751V18H5C3.89543 18 3 17.1046 3 16Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SpeechBubbleSquare",
};
</script>
