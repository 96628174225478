import { Api, authRequest } from "@/services/api/base";
import { authHeader } from "@/services/auth/headers";

export class IndicatorApi extends Api {
  async functions() {
    let url = this.url_list + "functions/";
    return await authRequest.get(url, {
      headers: authHeader(),
    });
  }
  async lookups() {
    let url = this.url_list + "lookups/";
    return await authRequest.get(url, {
      headers: authHeader(),
    });
  }
}
