<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9256 9.26629C16.5255 5.7037 13.6034 3.94993 10.8236 4.00115C9.39053 4.02756 7.93736 4.52504 6.82067 5.53077C5.72619 6.51649 5.00697 7.94492 4.92189 9.74734C2.7784 10.8195 1.62119 13.3372 2.11255 15.771L2.11281 15.7722C2.41005 17.2346 3.36572 18.6427 4.74311 19.3984L4.74399 19.3989C5.88185 20.0217 7.5 20 8 20H12H15H17C18.4517 19.9933 19.9678 19.1375 20.7495 18.1357C21.5284 17.1374 22 15.8413 22 14.6096C22 13.3241 21.5425 12.3002 21.0881 11.6005C20.8604 11.2499 20.6294 10.9739 20.4466 10.7777C20.3549 10.6793 20.2746 10.6002 20.2121 10.5412C20.1809 10.5117 20.1543 10.4874 20.1333 10.4685C20.1171 10.454 20.092 10.4319 20.0975 10.4367C20.0898 10.4299 20.0821 10.4233 20.0742 10.4167C19.206 9.69137 18.1012 9.24834 16.9256 9.26629ZM8.15912 7.01689C7.45691 7.64933 6.94549 8.61169 6.91485 9.98035L6.91455 9.99367C6.89215 10.6208 6.52915 11.222 5.90276 11.4947C4.57202 12.1078 3.74042 13.7263 4.07286 15.3745C4.26236 16.3058 4.88308 17.1939 5.70487 17.6448C6.38877 18.0189 7.5 18 8 18C8 18 8.97721 18.0001 9 18H12H13C14.6503 17.9927 15.3451 18.0049 17 18C17.6698 17.9967 18.6504 17.5748 19.1727 16.9054C19.6979 16.2323 20 15.3632 20 14.6096C20 13.0598 18.926 12.0716 18.7815 11.9428L18.792 11.9516L18.7723 11.9347L18.7815 11.9428C18.1934 11.4558 17.4807 11.2137 16.7757 11.2753L16.7673 11.276C15.9101 11.3437 15.0393 10.7456 14.9618 9.7642L14.9607 9.74835C14.8012 7.21509 12.875 5.96369 10.8604 6.00081C9.85074 6.01942 8.87694 6.3704 8.15912 7.01689Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.12 10.58C19.16 9.78 17.98 9.38 16.81 9.48C16.54 5.9 13.67 4.06 10.91 4.06H10.78C8.06 4.11 5.08999 5.96 5.00999 9.86C2.82999 10.86 1.60999 13.36 2.11999 15.82C2.41999 17.32 3.37999 18.64 4.67999 19.36C5.52999 19.83 6.36001 19.95 6.96001 19.95C7.08001 19.95 7.2 19.94 7.31 19.93L17.48 19.89C20.07 19.88 22 17.13 22.01 14.68C22.01 12.22 20.31 10.74 20.12 10.58Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CloudFilled",
};
</script>
