import TokenService from "@/services/auth/tokens";

export function authHeader() {
  let accessToken = TokenService.getAccessToken();

  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return {};
  }
}

export function identityHeader() {
  let idToken = TokenService.getIdToken();

  if (idToken) {
    return { Authorization: "Bearer " + idToken };
  } else {
    return {};
  }
}
