<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 12C4.55228 12 5 12.4477 5 13V18H19V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V13C3 12.4477 3.44772 12 4 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3598 4.23178C11.7307 3.92274 12.2693 3.92274 12.6402 4.23178L15.6402 6.73178C16.0645 7.08534 16.1218 7.71591 15.7682 8.14018C15.4147 8.56446 14.7841 8.62179 14.3598 8.26822L13 7.13504V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V7.13504L9.64018 8.26822C9.2159 8.62179 8.58534 8.56446 8.23177 8.14018C7.87821 7.71591 7.93553 7.08534 8.35981 6.73178L11.3598 4.23178Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Upload",
};
</script>
