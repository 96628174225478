<template>
  <v-radio v-bind="[$props, $attrs]" v-on="$listeners">
    <template #label>
      <slot name="label" />
    </template>
  </v-radio>
</template>

<script>
export default {
  name: "RadioButton",

  props: {
    color: {
      type: String,
      default: "primary-main",
      required: false,
    },
  },

  mounted() {
    const parent = this.$el.querySelector(
      ".v-input--selection-controls__input"
    );
    const activeIcon = document.createElement("div");
    activeIcon.classList.add("active-icon", `bg-${this.color}`);
    parent.appendChild(activeIcon);
  },
};
</script>
