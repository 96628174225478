<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.26855 5.63505C5.36194 4.72934 6.16821 4 7.04088 4H16.9591C17.8318 4 18.6381 4.72934 18.7314 5.63505L18.7314 5.63505L18.7322 5.64257L19.6565 14H19.4419H17.3821H16.2106C15.0312 14 14.0866 14.7286 13.5948 15.6643C13.336 16.1508 12.8166 16.49 12.1984 16.49C11.5852 16.49 11.0601 16.1448 10.7988 15.6581L10.798 15.6566C10.3016 14.7359 9.37301 14 8.18623 14H7.01478H4.95499H4.34347L5.26774 5.64257L5.26777 5.64257L5.26855 5.63505ZM4.12228 16L3.96986 17.3783L3.96964 17.3803C3.79878 18.9956 4.75652 20 5.73276 20H18.2672C19.2434 20 20.2012 18.9957 20.0304 17.3804L20.0301 17.3783L19.8777 16H19.4419H17.3821H16.2106C15.8682 16 15.5249 16.2102 15.3134 16.6133L15.3113 16.6173C14.7107 17.7499 13.532 18.49 12.1984 18.49C10.8597 18.49 9.68644 17.7356 9.08854 16.6229C8.86247 16.2039 8.52218 16 8.18623 16H7.01478H4.95499H4.12228ZM7.04088 2C5.18147 2 3.52871 3.48857 3.32736 5.4209L2.02939 17.1574L2.02938 17.1574L2.02892 17.1617C1.77126 19.5852 3.25365 22 5.73276 22H18.2672C20.7463 22 22.2287 19.5852 21.9711 17.1617L21.9706 17.1574L20.6731 5.42495L20.6726 5.42075C20.4712 3.4885 18.8185 2 16.9591 2H7.04088Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5C12.5523 5 13 5.44772 13 6V9.58577L13.7929 8.79287C14.1834 8.40235 14.8166 8.40234 15.2071 8.79286C15.5976 9.18339 15.5976 9.81655 15.2071 10.2071L12.7071 12.7071C12.6948 12.7194 12.6823 12.7313 12.6696 12.7428C12.5805 12.8233 12.4803 12.8849 12.3743 12.9276C12.2678 12.9706 12.1522 12.9958 12.0311 12.9995C12.0237 12.9998 12.0163 12.9999 12.0088 13C12.0055 13 12.0023 13 11.999 13C11.9889 13 11.9789 12.9998 11.9689 12.9995C11.8478 12.9958 11.7322 12.9706 11.6257 12.9276C11.5046 12.8788 11.3911 12.8053 11.2929 12.7071C11.2929 12.7071 11.2929 12.7071 11.2929 12.7071L8.79289 10.2071C8.40237 9.81655 8.40237 9.18339 8.7929 8.79286C9.18342 8.40234 9.81659 8.40235 10.2071 8.79287L11 9.58577V6C11 5.44772 11.4477 5 12 5Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89999 14.06C8.90999 14.06 9.82999 14.63 10.35 15.59C10.67 16.18 11.31 16.54 12.01 16.54C12.7 16.54 13.34 16.18 13.66 15.59C14.18 14.63 15.1 14.06 16.11 14.06H20.1H21.61L20.64 5.2C20.45 3.4 18.88 2 17.08 2H6.92999C5.12999 2 3.55999 3.4 3.36999 5.2L2.39999 14.06H3.90999H7.89999ZM15 10.06L12.54 12.53C12.58 12.49 12.61 12.45 12.63 12.41C12.49 12.61 12.26 12.73 12.01 12.73C11.8 12.73 11.62 12.65 11.48 12.52V12.53L9.00999 10.06C8.71999 9.77 8.71999 9.3 9.00999 9C9.29999 8.71 9.77999 8.71 10.07 9L11.26 10.19V5.82C11.26 5.4 11.59 5.07 12.01 5.07C12.42 5.07 12.76 5.4 12.76 5.82V10.18L13.94 9C14.23 8.71 14.71 8.71 15 9C15.29 9.3 15.29 9.77 15 10.06Z"
      :fill="$parent.getColor"
    />
    <path
      d="M21.98 17.44L21.77 15.56H20.26H16.11C15.66 15.56 15.23 15.84 14.98 16.31C14.39 17.38 13.25 18.04 12.01 18.04C10.76 18.04 9.62001 17.38 9.04001 16.31C8.78001 15.84 8.35001 15.56 7.90001 15.56H3.74001H2.24001L2.03001 17.44C1.89001 18.73 2.26001 19.98 3.05001 20.85C3.71001 21.59 4.61001 22 5.58001 22H18.43C19.4 22 20.3 21.59 20.96 20.85C21.75 19.98 22.12 18.73 21.98 17.44Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Inbox",
};
</script>
