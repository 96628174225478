<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V11.6478L18.4097 10.1155C17.4186 9.13235 15.811 9.13481 14.8229 10.1229L10.8329 14.1129C10.6234 14.3224 10.2766 14.3224 10.0671 14.1129L9.1671 13.2129C8.18074 12.2265 6.57696 12.2224 5.58541 13.2005L4 14.6892V5ZM2 17.0027V16.9977V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V13.9988V14.0015V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V17.0027ZM20 14.4251V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V17.4328L6.96453 14.649C6.97217 14.6418 6.9797 14.6345 6.98711 14.6271C7.19658 14.4176 7.54341 14.4176 7.75289 14.6271L8.65288 15.5271C9.64341 16.5176 11.2566 16.5176 12.2471 15.5271L16.2371 11.5371C16.4466 11.3276 16.7934 11.3276 17.0029 11.5371L17.0161 11.5501L20 14.4251Z"
      :fill="$parent.getColor"
    />
    <path
      d="M8.49998 6C7.67104 6 7 6.67104 7 7.49998C7 8.32893 7.67104 9 8.49998 9C9.32893 9 10 8.32893 10 7.49998C10 6.67104 9.31906 6 8.49998 6Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.18 2H5.82001C3.72001 2 2 3.71001 2 5.82001V18.18C2 20.29 3.72001 22 5.82001 22H18.18C20.29 22 22 20.29 22 18.18V5.82001C22 3.71001 20.29 2 18.18 2ZM8.14999 5.91C8.98999 5.91 9.67001 6.58999 9.67001 7.42999C9.67001 8.26999 8.98999 8.95001 8.14999 8.95001C7.30999 8.95001 6.63 8.26999 6.63 7.42999C6.63 6.58999 7.30999 5.91 8.14999 5.91ZM20.5 18.18C20.5 19.46 19.46 20.5 18.18 20.5H5.82001C4.54001 20.5 3.5 19.46 3.5 18.18V17.76L6.82001 14.44C7.12001 14.13 7.63 14.13 7.94 14.44L8.84 15.35C9.73 16.24 11.19 16.24 12.08 15.35L16.07 11.36C16.37 11.05 16.88 11.05 17.19 11.36L20.5 14.67V18.18Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Picture",
};
</script>
