import axios from "axios";
import { authHeader } from "@/services/auth/headers";

export const CODOC_API_URL = process.env.VUE_APP_CODOC_API_URL;

export const authRequest = axios.create({
  baseURL: CODOC_API_URL,
});

/**
 * Abstract class allowing communication with a resource endpoint.
 */
export class AbstractApi {
  constructor(resource) {
    this.url_list = `/${resource}/`;
  }

  url_detail(pk) {
    return `${this.url_list}${pk}/`;
  }
}

/**
 * Class allowing read operation (`retrieve` and `list`) operations on an endpoint.
 */
export class ReadOnlyApi extends AbstractApi {
  async list(params = {}, cancelToken = null) {
    return await authRequest.get(this.url_list, {
      headers: authHeader(),
      params: params,
      cancelToken: cancelToken,
    });
  }

  async retrieve(pk, params = {}) {
    return await authRequest.get(this.url_detail(pk), {
      headers: authHeader(),
      params: params,
    });
  }
}

/**
 * Class allowing all operations on an endpoint.
 */
export class Api extends ReadOnlyApi {
  async create(obj = {}) {
    return await authRequest.post(this.url_list, obj, {
      headers: authHeader(),
    });
  }

  async patch(id, obj) {
    return await authRequest.patch(this.url_detail(id), obj, {
      headers: authHeader(),
    });
  }

  async update(id, obj) {
    return await authRequest.put(this.url_detail(id), obj, {
      headers: authHeader(),
    });
  }

  async destroy(id) {
    return await authRequest.delete(this.url_detail(id), {
      headers: authHeader(),
    });
  }
}
