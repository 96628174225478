<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00003 7.39027L4 7.4V16.5858L8.67776 11.908L4.00003 7.39027ZM10.1205 13.2937L5.41421 18H18.5858L13.88 13.2942C12.7858 14.2385 11.2164 14.2322 10.1205 13.2937ZM20 7.4V16.5858L15.3222 11.908L20 7.39027L20 7.4ZM18.5605 6H5.43947L11.3748 11.7323L11.3756 11.7331C11.7497 12.0916 12.2626 12.0851 12.6206 11.7368L12.6232 11.7342L18.5605 6ZM5.33514 4C3.46047 4 2 5.55647 2 7.4V16.6C2 18.459 3.47555 20 5.33514 20H18.6649C20.5369 20 22 18.4563 22 16.6V7.4C22 5.54097 20.5244 4 18.6649 4H5.33514Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.18 12.06C11.67 12.54 12.34 12.54 12.83 12.06L19.15 5.78L20.32 4.61C19.88 4.38 19.39 4.25 18.86 4.25H5.15001C4.62001 4.25 4.13 4.38 3.69 4.61L4.86 5.78L11.18 12.06Z"
      :fill="$parent.getColor"
    />
    <path
      d="M8.93001 11.94L3.67001 6.69999L2.57001 5.60999C2.21001 6.11999 2.01001 6.72999 2.01001 7.39999V16.6C2.01001 17.16 2.16001 17.69 2.41001 18.14C2.42001 18.13 2.42001 18.13 2.43001 18.12L3.57001 17.03L8.93001 11.94Z"
      :fill="$parent.getColor"
    />
    <path
      d="M14.09 12.92L13.89 13.12C13.35 13.65 12.68 13.92 12 13.92C11.33 13.92 10.66 13.65 10.12 13.12L9.99001 12.99L4.59 18.15L3.47001 19.21C3.46001 19.22 3.45 19.23 3.44 19.23C3.93 19.56 4.51001 19.75 5.15001 19.75H18.86C19.49 19.75 20.08 19.56 20.57 19.23L19.45 18.14L14.09 12.92Z"
      :fill="$parent.getColor"
    />
    <path
      d="M21.45 5.60999L20.35 6.69999L15.15 11.86L20.45 17.03L21.6 18.15C21.86 17.7 22.01 17.16 22.01 16.6V7.39999C22.01 6.72999 21.81 6.11999 21.45 5.60999Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MailClosed",
};
</script>
