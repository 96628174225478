<template>
  <div>
    <div class="d-flex justify-space-between align-items-center">
      <div class="d-flex align-items-center">
        <text-input
          :search-input="true"
          :placeholder="t('Search')"
          class="w-100"
          @input="handleSearch"
        />
      </div>
      <div>
        <slot name="filters" />
      </div>
      
      <div class="py-1">
        <v-btn
          class="outlined"
          small
          @click="
            setCurrentObject(-1);
            openDialog = true;
          "
        >
          <icon
            outline
            name="plus"
            color="primary-main"
            size="14"
            class="mr-1"
          />
          {{ t("Create") }}
        </v-btn>
      </div>
    </div>
    <div v-if="title">
      <span class="text-subtitle-1 font-demi-bold">{{ title }}</span>
    </div>
    <slot
      name="content"
      :items="objects"
      :headers="headers"
      :loading="loading"
      :click-row="clickRow"
    >
      <custom-data-table
        fixed-header
        hide-default-footer
        dense
        :nb-items-per-page="20"
        :items="objects"
        :headers="headers"
        :loading="loading"
        class="mt-2"
        @click:row="clickRow"
      >
        <template v-for="(_, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
        <template #item.edit="{ index, item }">
          <slot name="customActions" :item="item" />
          <v-btn
            v-if="!noUpdate"
            small
            icon
            @click.stop="
              setCurrentObject(index);
              openDialog = true;
            "
          >
            <icon name="pencil" size="18" />
          </v-btn>
          <v-btn
            small
            icon
            @click.stop="
              setCurrentObject(index);
              openDestroyDialog = true;
            "
          >
            <icon name="trash" size="18" />
          </v-btn>
        </template>
      </custom-data-table>
    </slot>
    <v-dialog v-model="openDialog" max-width="500px ">
      <v-card>
        <v-card-title class="pb-0">
          <div class="d-flex align-items-center mt-2">
            <icon name="settings-slider" style="align-self: center;" outline size="24" class="pr-2" />
            <span class="text-subtitle-1 font-demi-bold">
              {{ currentIndex > -1 ? t("Update") : t("Create") }} {{ t(" ") + namespace }}
            </span>
          </div>
        </v-card-title>
        <v-form ref="form">
          <v-card-text class="pl-6 pr-5">
            <slot name="dialogContent" :currentObject="currentObject" />
          </v-card-text>
        </v-form>
        <v-card-actions class="justify-space-between">
          <div class="pl-6 pr-5">
            <slot 
              name="customDialogAction"
              :currentObject="currentObject"
            />
          </div>
          <div class="text-right">
            <v-btn
              small
              text
              class="basics-80 mr-2"
              @click="openDialog = false"
            >
              {{ t("Cancel") }}
            </v-btn>
            <v-btn small class="primary" @click="save">
              {{ t("Save") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDestroyDialog" max-width="400px">
      <v-card>
        <v-form ref="form">
          <v-card-text class="text-subtitle-1">
            <div class="d-flex align-items-center pt-3">
              <icon name="trash" style="align-self: center;" outline size="32" class="pr-2" />
              <slot name="dialogDeleteContent" :currentObject="currentObject">
                <span style="align-self: center;">{{ t("Do you want to delete ") }} {{ currentObject.name }} ?</span>
              </slot>
            </div>
          </v-card-text>
        </v-form>
        <v-card-actions class="justify-end">
          <div class="pb-2">
            <v-btn
              small
              text
              class="basics-80 mr-2"
              @click="openDestroyDialog = false;"
            >
              {{ t("Cancel") }}
            </v-btn>
            <v-btn small class="primary" @click="destroy">
              {{ t("Delete") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomDataTable from "@/components/design_system/CustomDataTable";
import _ from "lodash";

export default {
  name: "CRUDDataTable",
  components: { CustomDataTable },
  props: {
    namespace: {
      type: String,
      required: true,
    },

    defaultNewObject: {
      type: Object,
      default() {
        return {};
      },
    },

    headers: {
      type: Array,
      default() {
        return [];
      },
    },

    clickRow: {
      type: Function,
      default() {
        return () => {};
      },
    },

    title: {
      type: String,
      required: false,
    },

    noUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentIndex: -1,
      currentObject: _.cloneDeep(this.defaultNewObject),
      openDialog: false,
      openDestroyDialog: false,
    };
  },

  computed: {
    objects() {
      return this.$store.state[this.namespace].objects;
    },

    loading(){
      return this.$store.state[this.namespace].loading;
    }
   
  },

  watch: {
    openDialog(){
      if(!this.openDialog){
        this.$refs.form.resetValidation();
      }
    }
  },

  mounted() {},

  methods: {
    save() {
      if (this.$refs.form.validate()) {
        if (this.currentIndex > -1) {
          this.$store
            .dispatch(this.namespace + "/update", {
              index: this.currentIndex,
              item: this.currentObject,
            })
            .then(() => {
              this.openDialog = false;
            });
        } else {
          this.$store
            .dispatch(this.namespace + "/create", this.currentObject)
            .then(() => {
              this.openDialog = false;
            });
        }
      }
    },

    destroy() {
      this.$store
        .dispatch(this.namespace + "/destroy", this.currentIndex)
        .then(() => {
          this.openDestroyDialog = false;
        });
    },

    setCurrentObject(index) {
      this.currentIndex = index;
      if (index > -1) {
        this.currentObject = _.cloneDeep(this.objects[this.currentIndex]);
      } else {
        this.currentObject = _.cloneDeep(this.defaultNewObject);
      }
    },

    handleSearch(value) {
      this.$store.dispatch(this.namespace + "/list", {search: value});
    },
  },
};
</script>

<style></style>