<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6319 6.01574C22.1755 6.64087 22.1094 7.58832 21.4843 8.13192L9.98427 18.1319C9.37069 18.6655 8.44371 18.613 7.89427 18.0136L2.39427 12.0136C1.83448 11.4029 1.87574 10.4541 2.48641 9.89428C3.09709 9.33449 4.04594 9.37574 4.60573 9.98642L9.11836 14.9093L19.5157 5.8681C20.1409 5.3245 21.0883 5.3906 21.6319 6.01574Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.48 2H5.53C3.58 2 2 3.58 2 5.53V18.47C2 20.42 3.58 22 5.53 22H18.48C20.42 22 22 20.42 22 18.47V5.53C22 3.58 20.42 2 18.48 2ZM16.1612 7.15213C16.6386 6.61596 17.4259 6.61596 17.9033 7.15213C18.3656 7.67139 18.3656 8.50257 17.9033 9.02183L10.936 16.8475C10.5208 17.3139 9.86454 17.379 9.38311 17.0219C9.31546 16.9718 9.25192 16.9137 9.1935 16.8481L6.09672 13.3698C5.63443 12.8505 5.63443 12.0193 6.09672 11.5001C6.57408 10.9639 7.3614 10.9639 7.83876 11.5001L10.0645 14.0001L16.1612 7.15213Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Checkmark",
};
</script>
