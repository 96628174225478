import SettingsRouter from "@/components/settings/SettingsRouter.vue";
import Settings from "@/components/settings/Settings.vue";

export const settingsUrls = [
  {
    path: "/settings",
    component: SettingsRouter,
    redirect: { name: "Settings" },
    children: [
      {
        path: "",
        name: "Settings",
        component: Settings,
      },
    ],
  },
];
