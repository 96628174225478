import AdministrationRouter from "@/components/administration/AdministrationRouter.vue";
import AdministrationUsers from "@/components/administration/AdministrationUsers.vue";

export const administrationUrls = [
  {
    path: "/administration",
    component: AdministrationRouter,
    redirect: { name: "AdministrationUsers" },
    children: [
      {
        path: "",
        name: "AdministrationUsers",
        component: AdministrationUsers,
      },
    ],
  },
];
