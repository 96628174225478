<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.9 9C4.87434 9 4 9.82997 4 11V17.9C4 19.07 4.87434 19.9 5.9 19.9H18.8C19.8257 19.9 20.7 19.07 20.7 17.9V11C20.7 9.82997 19.8257 9 18.8 9L17.4 9.00001C16.8477 9.00001 16.4 8.5523 16.4 8.00001C16.4 7.44773 16.8477 7.00001 17.4 7.00001L18.8 7C20.9743 7 22.7 8.77003 22.7 11V17.9C22.7 20.13 20.9743 21.9 18.8 21.9H5.9C3.72566 21.9 2 20.13 2 17.9V11C2 8.77003 3.72566 7 5.9 7H7.4C7.95228 7 8.4 7.44772 8.4 8C8.4 8.55228 7.95228 9 7.4 9H5.9Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3V14.5858L14.2929 13.2929C14.6834 12.9024 15.3166 12.9024 15.7071 13.2929C16.0976 13.6834 16.0976 14.3166 15.7071 14.7071L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071C11.2928 17.707 11.2926 17.7068 11.2925 17.7067L8.29289 14.7071C7.90237 14.3166 7.90237 13.6834 8.29289 13.2929C8.68342 12.9024 9.31658 12.9024 9.70711 13.2929L11 14.5858V3C11 2.44772 11.4477 2 12 2Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Import",
};
</script>
