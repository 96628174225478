<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C7.58039 4 4 7.58039 4 12C4 16.4196 7.58039 20 12 20C16.4196 20 20 16.4196 20 12C20 7.58039 16.4196 4 12 4ZM2 12C2 6.47582 6.47582 2 12 2C17.5242 2 22 6.47582 22 12C22 17.5242 17.5242 22 12 22C6.47582 22 2 17.5242 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "RadioButton",
};
</script>
