import moment from "moment";
import cronstrue from "cronstrue";
import cronParser from "cron-parser";

export function verboseCrontab(crontab) {
  try {
    const interval = cronParser.parseExpression(crontab);
    return (
      cronstrue.toString(crontab) +
      " - Next at " +
      moment(interval.next().toString())
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    );
  } catch (e) {
    return "Invalid cron expression";
  }
}
