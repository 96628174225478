import { Api, authRequest } from "@/services/api/base";
import { authHeader } from "@/services/auth/headers";

export class ApplicationAPI extends Api {
  async generateKeys(pk) {
    return authRequest.post(
      this.url_detail(pk) + "keys/",
      {},
      { headers: authHeader() }
    );
  }
}
