<template>
  <div class="drop-down w-100" @click="toggle">
    <label
      v-if="inputLabel"
      :class="{ 'disabled-label': disabled }"
      class="mb-1"
    >
      {{ inputLabel }}
      <span v-if="required" class="danger-main"> * </span>
    </label>
    <v-select
      v-click-outside="clickOutside"
      :clearable="clearable"
      :items="filteredList"
      persistent-placeholder
      item-disabled="disable"
      v-bind="[$props, $attrs]"
      class="font-demi-bold"
      :return-object="returnObject"
      :class="
        small
          ? [
            'small text-body-2',
            { 'disabled-error': disabled && error },
            { 'disabled-success': disabled && success },
            { 'disabled-label': disabled }
          ]
          : [
            'text-body-1 ',
            { 'disabled-error': disabled && error },
            { 'disabled-success': disabled && success },
            { 'disabled-label': disabled }
          ]
      "
      :menu-props="{
        value: caret,
        contentClass: 'drop-down',
        bottom: true,
        offsetY: true
      }"
      hide-details="auto"
      @change="search = null"
      v-on="$listeners"
    >
      <template v-for="(_, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>

      <template v-if="multiple" #selection="{ item, index }">
        <badge
          v-if="amount ? index < amount : index < 2"
          :text="
            Array.isArray(itemText) ? arrayData(item) : item[$props.itemText]
          "
          :type="success ? 'success' : error ? 'danger' : 'outlined'"
          :close="!item.disable"
          :class="[
            'ml-1 pt-0 my-1 rounded',
            { 'bg-success-surface': success },
            { 'bg-danger-surface': error }
          ]"
          @close="
            deleteBadge(index, $attrs.value);
            caret = false;
          "
        />
      </template>

      <template #append>
        <div class="d-inline-flex">
          <span
            v-if="multiple && $attrs.value && $attrs.value.length > amount"
            :class="[
              'basics-90 my-auto',
              { 'success--text': success },
              { 'error--text': error }
            ]"
          >
            +{{ $attrs.value.length - amount }}
          </span>
          <icon
            class="mr-2"
            :size="small ? '16' : '20'"
            :name="caret && !disabled ? 'caret-up' : 'caret-down'"
            outline
          />
        </div>
      </template>

      <template v-if="searchBar" #prepend-item>
        <div class="d-flex align-items-center pl-2 search-bar-select br-t-6">
          <icon
            id="search-icon"
            size="20"
            name="search"
            color="basics-90"
            outline
            class="mt-1 mr-1"
          />
          <v-text-field
            id="search-input"
            v-model="search"
            class="text-body-2 font-medium m-0 p-0"
            :placeholder="t('Rechercher...')"
            persistent-placeholder
            hide-details
          />
        </div>
      </template>
      <template #no-data>
        <v-list-item-title class="basics-90 text-body-2 font-medium py-2 pl-2">
          {{ $props.noDataText }}
        </v-list-item-title>
      </template>

      <template #item="{ item }">
        <slot name="customItem" :item="item">
          <template v-if="item.header">
            <v-list-item-title v-text="item" />
          </template>

          <template v-else>
            <v-list-item-title
              v-if="item"
              class="d-inline-flex align-items-center basics-90 text-body-2 ml-2 font-medium"
            >
              <v-list-item-icon v-if="item.icon">
                <icon
                  v-if="item.icon"
                  size="16"
                  :name="item.icon"
                  :outline="item.outline"
                  color="basics-90"
                />
              </v-list-item-icon>
              {{
                Array.isArray(itemText)
                  ? arrayData(item)
                  : item[$props.itemText]
              }}
            </v-list-item-title>
          </template>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    items: {
      type: Array,
      required: true,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    returnObject: {
      type: Boolean,
      default: false,
    },

    searchBar: {
      type: Boolean,
      default: false,
    },

    // eslint-disable-next-line vue/no-unused-properties
    placeholder: {
      type: String,
      default: "Sélectionner",
    },

    small: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    success: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    itemText: {
      type: [String, Array],
      default: "text",
    },

    inputLabel: {
      type: String,
      default: null,
    },

    // eslint-disable-next-line vue/no-unused-properties
    noDataText: {
      type: String,
      default: "Aucun résultat",
    },

    amount: {
      type: [Number, String],
      default: 3,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: null,
      caret: false,
      listCategory: [],
      itemsList: [],
    };
  },

  computed: {
    filteredList() {
      const results = [];
      if (!this.search) {
        return this.itemsList;
      } else {
        this.itemsList.forEach((item) => {
          let text = "";
          Array.isArray(this.itemText)
            ? this.itemText.forEach((itemText) => {
                text += item[itemText] + " ";
              })
            : (text = item[this.$props.itemText]);
          if (
            text &&
            text.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          ) {
            if (this.listCategory.length !== 0) {
              this.listCategory.forEach((category) => {
                if (item.group === category.group) {
                  results.push(category);
                }
              });
            }
            results.push(item);
          }
        });
        return results;
      }
    },
  },

  watch: {
    items() {
      this.initializeData();
    },
  },

  created() {
    this.initializeData();
  },

  methods: {
    initializeData() {
      if (typeof this.items[0] === "string") {
        this.itemsList = this.items.map((value) => ({ text: value }));
      } else {
        this.itemsList = this.items;
      }
      this.listCategory = this.items.filter((item) => {
        return item.header;
      });
    },

    toggle() {
      this.caret = !this.caret;
    },

    clickOutside(e) {
      if (this.multiple === true) {
        if (
          e.target.id !== "search-input" &&
          e.target.id !== "search-icon" &&
          e.target.classList[0] !== "v-list-item__icon" &&
          e.target.classList[0] !== "v-list-item__title" &&
          e.target.classList[0] !== "v-list-item" &&
          e.target.localName !== "svg" &&
          e.target.localName !== "path"
        ) {
          this.caret = false;
        }
      } else if (
        e.target.id !== "search-input" &&
        e.target.id !== "search-icon"
      ) {
        this.caret = false;
      }
    },

    deleteBadge(itemToRemove, array) {
      array.forEach((item, index) => {
        if (index === itemToRemove) {
          array.splice(index, 1);
        }
      });
    },

    arrayData(data) {
      let result = [];
      this.itemText.forEach((item) => {
        result.push(data[item]);
      });
      return result.join(" ");
    },
  },
};
</script>
