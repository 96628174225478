<template>
  <div class="textarea-container mw-100 w-100">
    <label
      v-if="inputLabel"
      :for="inputId"
      :class="[{ 'disabled-label': disabled }, inputLabelClass]"
      class="mb-1"
    >
      {{ inputLabel }}
    </label>
    <v-textarea
      :id="inputId"
      :class="[
        {
          'disabled-error': (disabled && error) || (disabled && errorMessages)
        },
        {
          'disabled-success':
            (disabled && success) || (disabled && successMessages)
        },
        textareaClass
      ]"
      :rows="rows"
      solo
      v-bind="[$props, $attrs]"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append" />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
      <template #counter>
        <slot name="counter" />
      </template>
      <template #message>
        <slot name="message" />
      </template>
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>
      <template #progress>
        <slot name="progress" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
export default {
  name: "TextareaInput",
  props: {
    inputLabel: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      default: null,
    },

    success: {
      type: Boolean,
      default: false,
    },

    successMessages: {
      type: [String, Array],
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    textareaClass: {
      type: String,
      default: null,
    },

    inputLabelClass: {
      type: String,
      default: null,
    },

    rows: {
      type: String,
      default: "3",
    },
  },

  data() {
    return {
      inputId: "",
    };
  },

  mounted() {
    if (this.inputLabel || this.label) {
      this.isInputId();
    }
  },

  methods: {
    isInputId() {
      this.id
        ? (this.inputId = this.id)
        : (this.inputId = "textarea-input-" + this._uid);
    },
  },
};
</script>
