<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7Z"
      :fill="$parent.getColor"
    />
    <path
      d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55478 3.95122 3.95122 7.55478 3.95122 12C3.95122 16.4452 7.55478 20.0488 12 20.0488C16.4452 20.0488 20.0488 16.4452 20.0488 12C20.0488 7.55478 16.4452 3.95122 12 3.95122ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 16.08C11.59 16.08 11.25 15.75 11.25 15.33C11.25 14.92 11.59 14.58 12 14.58C12.42 14.58 12.75 14.92 12.75 15.33C12.75 15.75 12.42 16.08 12 16.08ZM12.75 12.58C12.75 13 12.41 13.33 12 13.33C11.59 13.33 11.25 13 11.25 12.58V7.92C11.25 7.5 11.59 7.17 12 7.17C12.41 7.17 12.75 7.5 12.75 7.92V12.58Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Alert",
};
</script>
