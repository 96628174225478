<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9065 3.5H15V3H17.9065C17.8859 3.07991 17.875 3.16368 17.875 3.25C17.875 3.33632 17.8859 3.42009 17.9065 3.5ZM19.901 4.25C19.6552 4.97063 18.978 5.5 18.1653 5.5H14.8347C14.0256 5.5 13.3383 4.97764 13.0946 4.25H12.2961C12.2928 4.25028 12.2851 4.251 12.2727 4.25267C12.2476 4.25607 12.2131 4.26211 12.172 4.27154C12.0913 4.29005 12.008 4.31641 11.9384 4.34633C11.7975 4.72792 11.4305 5 11 5C10.4477 5 10 4.55228 10 4C10 3.52613 10.2341 3.17553 10.453 2.95974C10.6652 2.75057 10.9134 2.61326 11.1144 2.52384C11.5023 2.35129 11.9625 2.25 12.2882 2.25H13.0946C13.3383 1.52235 14.0256 1 14.8347 1H18.1653C18.9744 1 19.6617 1.52235 19.9054 2.25H20.2118C21.7707 2.25 23 3.52806 23 5.07086V16.4291C23 17.9719 21.7707 19.25 20.2118 19.25H17C16.4477 19.25 16 18.8023 16 18.25C16 17.6977 16.4477 17.25 17 17.25H20.2118C20.6321 17.25 21 16.9017 21 16.4291V5.07086C21 4.59834 20.6321 4.25 20.2118 4.25H19.901ZM17.5 7C17.5 6.58579 17.1642 6.25 16.75 6.25C16.3358 6.25 16 6.58579 16 7V7.5C15.5858 7.5 15.25 7.83579 15.25 8.25C15.25 8.66421 15.5858 9 16 9V9.5C16 9.91421 16.3358 10.25 16.75 10.25C17.1642 10.25 17.5 9.91421 17.5 9.5V9H17.8C18.2142 9 18.55 8.66421 18.55 8.25C18.55 7.83579 18.2142 7.5 17.8 7.5H17.5V7ZM16.25 13C16.25 12.5858 16.5858 12.25 17 12.25H18.25C18.6642 12.25 19 12.5858 19 13C19 13.4142 18.6642 13.75 18.25 13.75H17C16.5858 13.75 16.25 13.4142 16.25 13ZM17 14.25C16.5858 14.25 16.25 14.5858 16.25 15C16.25 15.4142 16.5858 15.75 17 15.75H18.25C18.6642 15.75 19 15.4142 19 15C19 14.5858 18.6642 14.25 18.25 14.25H17Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 8H3.71667C3.37365 8 3 8.30915 3 8.82086V20.1791C3 20.6908 3.37365 21 3.71667 21H12.2833C12.6263 21 13 20.6908 13 20.1791V8.82086C13 8.30915 12.6264 8 12.2833 8H12C12 9.10457 11.1046 10 10 10H6C4.89543 10 4 9.10457 4 8ZM11.7324 6H12.2833C13.8403 6 15 7.31724 15 8.82086V20.1791C15 21.6827 13.8403 23 12.2833 23H3.71667C2.15968 23 1 21.6827 1 20.1791V8.82086C1 7.31724 2.15968 6 3.71667 6H4.26756C4.61337 5.4022 5.25972 5 6 5H10C10.7403 5 11.3866 5.4022 11.7324 6ZM6 7H10V8H6V7ZM8.75 11.5C8.75 11.0858 8.41421 10.75 8 10.75C7.58579 10.75 7.25 11.0858 7.25 11.5V11.75H7C6.58579 11.75 6.25 12.0858 6.25 12.5C6.25 12.9142 6.58579 13.25 7 13.25H7.25V13.5C7.25 13.9142 7.58579 14.25 8 14.25C8.41421 14.25 8.75 13.9142 8.75 13.5V13.25H9C9.41421 13.25 9.75 12.9142 9.75 12.5C9.75 12.0858 9.41421 11.75 9 11.75H8.75V11.5ZM5 15.25C4.58579 15.25 4.25 15.5858 4.25 16C4.25 16.4142 4.58579 16.75 5 16.75H11C11.4142 16.75 11.75 16.4142 11.75 16C11.75 15.5858 11.4142 15.25 11 15.25H5ZM4.25 19C4.25 18.5858 4.58579 18.25 5 18.25H11C11.4142 18.25 11.75 18.5858 11.75 19C11.75 19.4142 11.4142 19.75 11 19.75H5C4.58579 19.75 4.25 19.4142 4.25 19Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ClipboardsMedicalFile",
};
</script>
