<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 18.25L22 5.75C22 3.67893 20.3211 2 18.25 2L5.75 2C3.67893 2 2 3.67893 2 5.75L2 18.25C2 20.3211 3.67893 22 5.75 22L18.25 22C20.3211 22 22 20.3211 22 18.25ZM6.32195 9.26499C6.72789 8.89051 7.36054 8.91601 7.73501 9.32195L12 13.9452L16.265 9.32195C16.6395 8.91601 17.2721 8.89051 17.6781 9.26499C18.084 9.63946 18.1095 10.2721 17.735 10.6781L12.7427 16.0898C12.7312 16.1025 12.7193 16.115 12.7071 16.1272C12.6235 16.2109 12.5284 16.2768 12.4267 16.3247C12.1543 16.4533 11.8374 16.4512 11.5678 16.3221C11.4681 16.2744 11.375 16.2094 11.2929 16.1272C11.2806 16.1149 11.2687 16.1024 11.2572 16.0896L6.26498 10.6781C5.89051 10.2721 5.91601 9.63946 6.32195 9.26499Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CaretDown",
};
</script>
