<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9647 3.55022C15.0316 1.48326 18.3828 1.48326 20.4498 3.55022C22.5167 5.61717 22.5167 8.96836 20.4498 11.0353L11.0353 20.4498C8.96837 22.5167 5.61717 22.5167 3.55022 20.4498C1.48326 18.3828 1.48326 15.0316 3.55022 12.9647L12.9647 3.55022ZM19.0415 4.95851C17.7523 3.66934 15.6622 3.66934 14.373 4.95851L10.3729 8.95863L15.0414 13.6271L19.0415 9.62702C20.3307 8.33785 20.3307 6.24769 19.0415 4.95851ZM4.95851 14.373L8.95865 10.3728L13.6272 15.0414L9.62702 19.0415C8.33785 20.3307 6.24769 20.3307 4.95851 19.0415C3.66934 17.7523 3.66934 15.6622 4.95851 14.373ZM16 7C15.7282 7 15.466 6.89242 15.2913 6.69682C15.0971 6.511 15 6.25672 15 5.99267C15 5.71883 15.0971 5.47433 15.2913 5.27873C15.6602 4.90709 16.3301 4.90709 16.699 5.27873C16.8932 5.47433 17 5.71883 17 5.99267C17 6.25672 16.8932 6.511 16.699 6.69682C16.5146 6.89242 16.2621 7 16 7ZM14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L13.7071 9.70711C13.3166 10.0976 12.6834 10.0976 12.2929 9.70711C11.9024 9.31658 11.9024 8.68342 12.2929 8.29289L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.34 3.66C19.28 2.6 17.86 2.01 16.33 2C14.82 2.01 13.4 2.6 12.34 3.66L8 8L3.66 12.34C2.59 13.41 2 14.83 2 16.34C2 17.85 2.59 19.27 3.66 20.34C4.72 21.4 6.14 21.99 7.67 22C9.18 21.99 10.6 21.4 11.66 20.34L16 16L20.34 11.67C21.41 10.6 22 9.18 22 7.66C22 6.15 21.41 4.73 20.34 3.66ZM10.6 19.28C9.82 20.06 8.78 20.5 7.67 20.5C6.55 20.5 5.5 20.06 4.72 19.28C3.93 18.5 3.5 17.45 3.5 16.34C3.5 15.23 3.93 14.19 4.72 13.4L8.53 9.59L14.41 15.47L10.6 19.28ZM19.28 10.61L15.47 14.41L9.59 8.53L13.4 4.72C14.18 3.95 15.22 3.51 16.33 3.5C17.45 3.51 18.5 3.95 19.28 4.72C20.07 5.51 20.5 6.55 20.5 7.66C20.5 8.78 20.07 9.82 19.28 10.61Z"
      :fill="$parent.getColor"
    />
    <path
      d="M20.34 3.66C19.28 2.6 17.86 2.01 16.33 2C14.82 2.01 13.4 2.6 12.34 3.66L8 8L3.66 12.34C2.59 13.41 2 14.83 2 16.34C2 17.85 2.59 19.27 3.66 20.34C4.72 21.4 6.14 21.99 7.67 22C9.18 21.99 10.6 21.4 11.66 20.34L16 16L20.34 11.67C21.41 10.6 22 9.18 22 7.66C22 6.15 21.41 4.73 20.34 3.66ZM16.36 6.19C16.17 6.38 15.91 6.49 15.64 6.49C15.36 6.49 15.09 6.38 14.91 6.19C14.71 6 14.61 5.73 14.61 5.47C14.61 5.19 14.71 4.93 14.91 4.74C15.29 4.36 15.98 4.36 16.36 4.74C16.55 4.93 16.67 5.19 16.67 5.47C16.67 5.73 16.55 6 16.36 6.19ZM12.2 7.84L12.92 7.11C13.22 6.82 13.69 6.82 13.98 7.11C14.28 7.41 14.28 7.88 13.98 8.17L13.26 8.9C13.11 9.05 12.92 9.12 12.73 9.12C12.53 9.12 12.34 9.05 12.2 8.9C11.9 8.61 11.9 8.13 12.2 7.84ZM10.6 19.28C9.82 20.06 8.78 20.5 7.67 20.5C6.55 20.5 5.5 20.06 4.72 19.28C3.93 18.5 3.5 17.45 3.5 16.34C3.5 15.23 3.93 14.19 4.72 13.4L8.53 9.59L14.41 15.47L10.6 19.28Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Pill",
};
</script>
