<template>
  <v-container>
    <c-r-u-d-data-table
      class="mt-5"
      namespace="hospitals"
      title="Hospitals"
      :headers="headers"
      :default-new-object="defaultHospital"
      :click-row="redirect"
    >
      <template #item.country="{ item }">
        <country-flag :country="item.country" size="small" />
      </template>
      <template #item.status="{ item }">
        {{ textFromEnum(HospitalStatus, item.status) }}
      </template>
      <template #dialogContent="{ currentObject }">
        <text-input
          v-model="currentObject.code"
          autofocus
          required
          hide-details="auto"
          :rules="requiredRule"
          persistent-hint
          :input-label="t('Code')"
        />
        <text-input
          v-model="currentObject.name"
          :rules="requiredRule"
          hide-details="auto"
          required
          class="mt-2"
          persistent-hint
          :input-label="t('Name')"
        />
        <drop-down
          v-model="currentObject.status"
          :input-label="t('Status')"
          item-value="value"
          item-text="text"
          class="mt-2"
          :items="Object.values(HospitalStatus)"
        />
        <drop-down
          v-model="currentObject.country"
          :input-label="t('Country')"
          item-value="value"
          item-text="text"
          class="mt-2"
          :items="Object.values(Country)"
        />
      </template>
    </c-r-u-d-data-table>
  </v-container>
</template>

<script>
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import { HospitalStatus } from "@/enums/hospitals";
import CountryFlag from "vue-country-flag";
import { Country } from "@/enums/country";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "Hospitals",
  components: { CRUDDataTable, CountryFlag },
  mixins: [form_rules],

  data() {
    return {
      headers: [
        { text: "", value: "country", width: "1%", sortable: false },
        { text: this.t("Code"), value: "code", width: "1%", sortable: false },
        { text: this.t("Name"), value: "name", sortable: false },
        { text: this.t("Status"), value: "status", sortable: false },
        { text: "", value: "edit", align: "right", sortable: false },
      ],

      defaultHospital: {
        name: "",
        status: HospitalStatus.IN_PROGRESS.value,
        country: "FR",
      },

      HospitalStatus,
    };
  },

  computed: {
    Country() {
      return Country;
    },
  },

  mounted() {
    this.$store.dispatch("hospitals/list");
  },

  methods: {
    redirect(object) {
      this.$store.commit("hospitals/setCurrent", object);
      this.$router.push({
        name: "HospitalDetail",
        params: { hospitalId: object.id.toString() },
      });
    },
  },
};
</script>

<style>
.small-flag {
  vertical-align: middle !important;
  margin-right: -30px !important;
  margin-top: -15px !important;
  transform: scale(0.5) !important;
  -ms-transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  -moz-transform: scale(0.5) !important;
}
</style>
