<template>
  <div>
    <div class="bg-basics-10 pb-0">
      <div class="d-flex justify-space-between align-center pt-5 px-5">
        <div class="d-flex align-center">
          <badge type="info" class="mb-1 mr-2" :text="hospital.code" />
          <span class="text-h5">
            <b>{{ hospital.name }}</b>
          </span>
        </div>
        <div class="d-flex align-center">
          <badge
            class="mr-3"
            type="primary"
            :text="textFromEnum(HospitalStatus, hospital.status)"
          />
        </div>
      </div>
      <navigation-drawer :items="tabs" />
    </div>
    <router-view />
  </div>
</template>

<script>
import NavigationDrawer from "@/components/design_system/NavigationDrawer";
import { HospitalStatus } from "@/enums/hospitals";
import { mapState } from "vuex";

export default {
  name: "HospitalDetail",
  components: { NavigationDrawer },
  props: {
    hospitalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabs: [
        { name: this.t("VMs"), to: { name: "HospitalVirtualMachine" } },
        { name: this.t("Applications"), to: { name: "HospitalApp" } },
      ],

      HospitalStatus,
    };
  },

  computed: {
    ...mapState({
      hospital: (state) => state["hospitals"].current,
    }),
  },

  mounted() {
    if (!this.hospital.id) {
      this.$store
        .dispatch("hospitals/retrieve", this.hospitalId)
        .then((response) => {
          this.$store.commit("hospitals/setCurrent", response.data);
        });
    }
    this.$store.dispatch("vms/list", { hospital_id: this.hospitalId });
    this.$store.dispatch("apps/list", { hospital_id: this.hospitalId });
    this.$store.dispatch("application_types/list");
  },
};
</script>
