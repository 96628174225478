import i18n from "@/mixins/i18n";

const t = i18n.methods.t;

const passwordFormatError = t(
  "Le mot de passe doit contenir minimum 10 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial."
);

let requiredRule = [(v) => !!v || t("Required field")];
let boolRequiredRule = [(v) => v !== null || t("Required field")];

let requiredRuleArray = [(v) => (!!v && v.length > 0) || t("Required field")];

let emailRule = [
  (v) => !v || /.+@.+\..+/.test(v) || t("L'e-mail est invalide."),
];

let passwordRule = [
  (v) =>
    !v ||
    (v.length > 9 &&
      v.toUpperCase() !== v &&
      v.toLowerCase() !== v &&
      /\d/.test(v) &&
      /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v)) ||
    passwordFormatError,
];

function confirmPasswordRule(password, v) {
  return (!v && !password) || password === v
    ? true
    : t("Mots de passe différents");
}

function emailNotInList(emailList, v) {
  return !!v && emailList.includes(v)
    ? t("Cet e-mail est déjà utilisé.")
    : true;
}

export default {
  data() {
    return {
      boolRequiredRule,
      requiredRule,
      requiredRuleArray,
      emailRule,
      passwordRule,
    };
  },
  methods: {
    confirmPasswordRule,
    emailNotInList,
  },
};
