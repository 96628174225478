<template>
  <v-container>
    <app-card-list :hospital-id="hospitalId" />
  </v-container>
</template>

<script>
import AppCardList from "@/components/hospitals/tabs/apps/AppCardList";

export default {
  name: "HospitalApp",
  components: { AppCardList },
  props: {
    hospitalId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
