<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 2C8.72258 2 8.93367 2.09887 9.07617 2.26986L9.85242 3.20136C10.7527 4.28165 10.6462 6.04332 9.78254 7.00003H10C10.5523 7.00003 11 7.44774 11 8.00003V11H13V8.00003C13 7.44774 13.4477 7.00003 14 7.00003H14.2175C13.3538 6.04332 13.2473 4.28165 14.1476 3.20136L14.9238 2.26986C15.0663 2.09887 15.2774 2 15.5 2C15.7226 2 15.9337 2.09887 16.0762 2.26986L16.8524 3.20136C17.7527 4.28165 17.6462 6.04332 16.7825 7.00003H17C17.5523 7.00003 18 7.44774 18 8.00003V11C20.2091 11 22 12.7909 22 15V16V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V16V15C2 12.7909 3.79086 11 6 11V8.00003C6 7.44774 6.44772 7.00003 7 7.00003H7.21746C6.35381 6.04332 6.24734 4.28165 7.14758 3.20136L7.92383 2.26986C8.06633 2.09887 8.27742 2 8.5 2ZM8.5 6.14756C9.03366 5.74725 9.15404 4.70638 8.70009 4.16164L8.5 3.92154L8.29991 4.16164C7.84596 4.70638 7.96634 5.74725 8.5 6.14756ZM9 11V9.00003H8V11H9ZM15 9.00003V11H16V9.00003H15ZM14 13H10H7H6C4.89543 13 4 13.8955 4 15V15.4648L5.4453 16.4284C5.7812 16.6523 6.2188 16.6523 6.5547 16.4284L7.3359 15.9076C8.3436 15.2358 9.6564 15.2358 10.6641 15.9076L11.4453 16.4284C11.7812 16.6523 12.2188 16.6523 12.5547 16.4284L13.3359 15.9076C14.3436 15.2358 15.6564 15.2358 16.6641 15.9076L17.4453 16.4284C17.7812 16.6523 18.2188 16.6523 18.5547 16.4284L20 15.4648V15C20 13.8955 19.1046 13 18 13H17H14ZM19.6641 18.0925L20 17.8685V20H4V17.8685L4.3359 18.0925C5.3436 18.7643 6.6564 18.7643 7.6641 18.0925L8.4453 17.5717C8.7812 17.3477 9.2188 17.3477 9.5547 17.5717L10.3359 18.0925C11.3436 18.7643 12.6564 18.7643 13.6641 18.0925L14.4453 17.5717C14.7812 17.3477 15.2188 17.3477 15.5547 17.5717L16.3359 18.0925C17.3436 18.7643 18.6564 18.7643 19.6641 18.0925ZM15.7001 4.16164C16.154 4.70638 16.0337 5.74725 15.5 6.14756C14.9663 5.74725 14.846 4.70638 15.2999 4.16164L15.5 3.92154L15.7001 4.16164Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9781 2.41751L14.7051 2.6359C13.2956 3.76352 13.1922 5.71786 14.1727 6.99999H14C13.4477 6.99999 13 7.4477 13 7.99999V11H11V7.99999C11 7.4477 10.5523 6.99999 10 6.99999H9.8273C10.8078 5.71786 10.7044 3.76353 9.29489 2.6359L9.0219 2.41751C8.71678 2.17341 8.28322 2.17341 7.9781 2.41751L7.70511 2.6359C6.29558 3.76352 6.19215 5.71786 7.1727 6.99999H7C6.44772 6.99999 6 7.4477 6 7.99999V11C3.79086 11 2 12.7908 2 15V15.382L4.48985 16.6269C4.7889 16.7764 5.1429 16.7666 5.4332 16.6007L7.01158 15.6988C7.93388 15.1717 9.06612 15.1717 9.98842 15.6988L11.5039 16.5647C11.8113 16.7404 12.1887 16.7404 12.4961 16.5647L14.0116 15.6988C14.9339 15.1717 16.0661 15.1717 16.9884 15.6988L18.5668 16.6007C18.8571 16.7666 19.2111 16.7764 19.5102 16.6269L22 15.382V15C22 12.7908 20.2091 11 18 11V7.99999C18 7.4477 17.5523 6.99999 17 6.99999H16.8273C17.8078 5.71786 17.7044 3.76353 16.2949 2.6359L16.0219 2.41751C15.7168 2.17341 15.2832 2.17341 14.9781 2.41751ZM2 21V17.618L3.59542 18.4157C4.49258 18.8643 5.55458 18.8348 6.42548 18.3372L8.00386 17.4352C8.31129 17.2596 8.68871 17.2596 8.99614 17.4352L10.5116 18.3012C11.4339 18.8282 12.5661 18.8282 13.4884 18.3012L15.0039 17.4352C15.3113 17.2596 15.6887 17.2596 15.9961 17.4352L17.5745 18.3372C18.4454 18.8348 19.5074 18.8643 20.4046 18.4157L22 17.618V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "BirthdayCake",
};
</script>
