<template>
  <v-container>
    <c-r-u-d-data-table
      class="mt-5"
      namespace="indicators"
      title="Indicators"
      :headers="headers"
      :default-new-object="defaultIndicator"
      :click-row="redirect"
    >
      <template #item.errors="{ item }">
        <badge v-if="item.has_errors" :text="t('Error')" type="danger" />
        <badge v-else :text="t('No status')" type="info" />
      </template>
      <template #dialogContent="{ currentObject }">
        <text-input
          v-model="currentObject.name"
          :rules="requiredRule"
          :input-label="t('Name')"
          hide-details="auto"
          required
        />
        <text-input
          v-model="currentObject.code"
          class="mt-2"
          :input-label="t('Code')"
          required
          hide-details="auto"
          :rules="requiredRule"
          @change="currentObject.code = currentObject.code.toLowerCase()"
        />
        <text-input
          v-model="currentObject.model"
          class="mt-2"
          hide-details="auto"
          :input-label="t('Model')"
          placeholder="accounts.user"
          hint="'<app>.<model>' in lowercase, e.g. 'accounts.user'"
          required
          :rules="requiredRule"
          @change="currentObject.model = currentObject.model.toLowerCase()"
        />
        <text-input
          v-model="currentObject.crontab"
          class="mt-2"
          hide-details="auto"
          :input-label="t('Cron expression')"
          placeholder="*/30 * * * *"
          :hint="verboseCrontab(currentObject.crontab)"
          persistent-hint
          required
          :rules="requiredRule"
        />
      </template>
    </c-r-u-d-data-table>
  </v-container>
</template>

<script>
import CRUDDataTable from "@/components/commons/CRUDDataTable";
import form_rules from "@/mixins/rules/form_rules";
import Badge from "@/components/design_system/Badge.vue";
import { verboseCrontab } from "@/utils/cron";
export default {

  name: "Indicators",
  components: { CRUDDataTable, Badge },
  mixins: [form_rules ],

  data() {
    return {
      verboseCrontab,

      headers: [
        {
          text: this.t("Name"),
          value: "name",
          sortable: false,
        },
        { text: this.t("Code"), value: "code", sortable: false },
        { text: this.t("Cron expression"), value: "crontab", sortable: false },
        { text: this.t("Status"), value: "errors", sortable: false },
        {
          text: "",
          value: "edit",
          align: "right",
          sortable: false,
        },
      ],

      defaultIndicator: {
        name: "",
        code: "",
        crontab: "*/30 * * * *",
        model: "",
      },
    };
  },

  methods: {
    redirect(object) {
      this.$store.commit("indicators/setCurrent", object);
      this.$router.push({
        name: "IndicatorDetail",
        params: { indicatorId: object.id.toString() },
      });
    },
  },
};
</script>
