<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75011 6.77733C10.8203 5.6773 12.3242 5 14.0049 5C17.3236 5 20 7.68118 20 11C20 11.8766 19.8016 12.7094 19.4628 13.4756C19.366 13.6943 19.3521 13.9372 19.4176 14.1609L19.7762 16.6038L17.3229 16.2408C17.1162 16.2102 16.9052 16.2451 16.7194 16.3406C16.1359 16.6405 15.504 16.8455 14.8298 16.9396L14.8095 16.9427C14.5638 16.9821 14.2881 17 13.9951 17C10.6764 17 8 14.3188 8 11C8 9.34542 8.65594 7.87611 9.74192 6.78565L9.74197 6.78569L9.75011 6.77733ZM22 11C22 12.0691 21.7808 13.076 21.4144 13.9933L21.9505 17.6447C21.9964 17.9577 21.8916 18.274 21.6678 18.4975C21.444 18.7211 21.1276 18.8255 20.8147 18.7792L17.3363 18.2645C16.6439 18.585 15.9009 18.8085 15.1163 18.919C14.7426 18.9783 14.3604 19 13.9951 19C9.56962 19 6 15.4212 6 11C6 8.79667 6.87956 6.82762 8.32068 5.37848C9.76027 3.901 11.7766 3 14.0049 3C18.4304 3 22 6.57882 22 11ZM4.82163 8.17848C5.27535 8.49338 5.38788 9.11647 5.07298 9.57018C4.39795 10.5428 4 11.7224 4 13C4 13.8767 4.19828 14.7096 4.53689 15.4758C4.63351 15.6944 4.6474 15.9372 4.58203 16.1607L4.22363 18.6037L6.67435 18.2408C6.87973 18.2104 7.08949 18.2446 7.27451 18.3388C8.10429 18.7613 9.02873 19 10 19C10.5523 19 11 19.4477 11 20C11 20.5523 10.5523 21 10 21C8.81227 21 7.68249 20.7334 6.66272 20.2643L3.18536 20.7792C2.87245 20.8255 2.55607 20.7211 2.33225 20.4976C2.10843 20.2741 2.00356 19.9578 2.04947 19.6448L2.5852 15.9932C2.21906 15.0759 2 14.069 2 13C2 11.2994 2.53144 9.72438 3.42994 8.42982C3.74484 7.97611 4.36792 7.86358 4.82163 8.17848ZM11 12C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12ZM15 11C15 11.5523 14.5523 12 14 12C13.4477 12 13 11.5523 13 11C13 10.4477 13.4477 10 14 10C14.5523 10 15 10.4477 15 11ZM17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4436 22C9.18876 22 7.97646 21.7193 6.83859 21.158L2.94645 21.753C2.69123 21.7979 2.44664 21.6969 2.26586 21.5173C2.08507 21.3264 1.99999 21.057 2.04253 20.7988L2.63806 16.6337C2.21269 15.556 2 14.4334 2 13.2995C2 11.0317 2.8401 8.87624 4.37143 7.2484C4.67983 6.91161 5.19027 6.92284 5.49866 7.2484C5.80706 7.57397 5.81769 8.11284 5.49866 8.43841C4.26509 9.75191 3.58451 11.4696 3.58451 13.2995C3.58451 14.2762 3.78655 15.2529 4.19065 16.1847C4.27573 16.3755 4.28636 16.5888 4.22256 16.7909L3.77592 19.9343L6.84922 19.4628C7.00873 19.4404 7.16825 19.4628 7.31713 19.5414C8.50817 20.1701 9.83745 20.4171 11.1242 20.2823C11.5496 20.2262 11.9537 20.5742 11.9962 21.0345C12.0387 21.4948 11.7197 21.9102 11.2837 21.9551C11.0178 21.9888 10.7307 22 10.4436 22Z"
      :fill="$parent.getColor"
    />
    <path
      d="M22.12 11.25C22.12 6.84226 18.5253 3.25 14.115 3.25C11.9501 3.25 9.93623 4.09645 8.44598 5.61387C6.94567 7.13129 6.12 9.12355 6.12 11.25C6.12 15.6577 9.70464 19.25 14.115 19.25C14.5177 19.25 14.9004 19.219 15.2226 19.1674C15.2327 19.1674 15.2528 19.1674 15.2629 19.1571C16.0583 19.0435 16.8135 18.8165 17.5284 18.4758L21.2138 19.0229C21.4554 19.0642 21.687 18.9816 21.8582 18.8061C22.0193 18.6306 22.0999 18.3829 22.0697 18.1455L21.5058 14.3055C21.9085 13.3248 22.12 12.2926 22.12 11.25ZM11.648 11.5081C11.5473 11.8281 11.2452 12.0552 10.8928 12.0552C10.4498 12.0552 10.0873 11.6939 10.0873 11.25C10.0873 10.8061 10.4498 10.4448 10.8928 10.4448C10.9834 10.4448 11.064 10.4552 11.1445 10.4861C11.4668 10.5894 11.6984 10.899 11.6984 11.25C11.6984 11.3429 11.6782 11.4255 11.648 11.5081ZM14.115 12.0552C13.6719 12.0552 13.3094 11.6939 13.3094 11.25C13.3094 10.8061 13.6719 10.4448 14.115 10.4448C14.558 10.4448 14.9205 10.8061 14.9205 11.25C14.9205 11.6939 14.558 12.0552 14.115 12.0552ZM17.3371 12.0552C16.8941 12.0552 16.5316 11.6939 16.5316 11.25C16.5316 10.8061 16.8941 10.4448 17.3371 10.4448C17.7802 10.4448 18.1427 10.8061 18.1427 11.25C18.1427 11.6939 17.7802 12.0552 17.3371 12.0552Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SpeechBubbles",
};
</script>
