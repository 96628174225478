<template>
  <auth-container :title="t('Récupération de compte')">
    <v-row class="mt-5 mb-16 mx-5 justify-center" align="center">
      <v-col cols="10" sm="8" md="7" lg="4" class="mb-16">
        <v-card outlined>
          <v-card-text>
            <div class="text-center">
              <h5 class="basic-90 text--darken-3 text-body-1">
                {{ t("Code de récupération") }}
              </h5>
            </div>
            <text-input
              v-model="totp"
              :loading="loading"
              input-class="mt-2"
              :placeholder="t('Code')"
              required
              hide-details
              @input="errorMsg = ''"
            />
            <div class="mt-1">
              <span class="text-danger">{{ errorMsg }}</span>
            </div>
            <div class="mt-4">
              <v-btn class="primary" block depressed small @click="verifyTotp">
                {{ t("Vérification") }}
              </v-btn>
            </div>
            <div class="mt-3">
              <small>
                Si vous n'avez pas accès à votre téléphone vous pouvez utiliser
                un des codes de récupération.
                <b>Un code n'est valide qu'une fois</b>.
              </small>
            </div>
            <div class="mt-3 text-center">
              <router-link :to="{ name: 'LockOut' }">
                {{ t("Pas de code ?") }}
              </router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </auth-container>
</template>

<script>
import i18n from "@/mixins/i18n";
import { StaticTotp } from "@/services/auth/totp";
import AuthContainer from "@/components/auth/AuthContainer";

export default {
  name: "OTPStaticVerification",
  components: { AuthContainer },
  mixins: [i18n],
  data() {
    return {
      totp: "",
      loading: false,
      errorMsg: "",
    };
  },

  computed: {},
  mounted() {},
  methods: {
    verifyTotp() {
      this.loading = true;
      StaticTotp.verify(this.totp)
        .then(
          () => {
            this.$router.push({ name: "Authorization" });
          },
          (err) => {
            if (err.response.status === 401) {
              this.$router.push({ name: "Login" });
            } else {
              this.errorMsg = this.t("Code invalide");
            }
          }
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
