<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.3807 3.075C20.2636 3.02676 20.1353 3.0001 20.0008 3C20.0005 3 20.0003 3 20 3L19.9998 3L14 3C13.4477 3 13 3.44772 13 4C13 4.55228 13.4477 5 14 5H17.5858L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L19 6.41421V10C19 10.5523 19.4477 11 20 11C20.5523 11 21 10.5523 21 10V4.00098V4C21 3.86529 20.9734 3.7368 20.9251 3.61951C20.8763 3.5007 20.8036 3.38939 20.7071 3.29289C20.6112 3.19702 20.5007 3.12468 20.3828 3.07588C20.3821 3.07559 20.3814 3.07529 20.3807 3.075ZM4 5C3.44772 5 3 5.44772 3 6V20C3 20.5523 3.44772 21 4 21H17C17.5523 21 18 20.5523 18 20V14C18 13.4477 17.5523 13 17 13C16.4477 13 16 13.4477 16 14V19H5V7H10C10.5523 7 11 6.55228 11 6C11 5.44772 10.5523 5 10 5H4Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ExternalLink",
};
</script>
