<template>
  <div>
    <div class="border-bottom-b-50 bg-basics-10 pb-0">
      <div class="d-flex align-center px-5">
        <router-link
          :to="{ name: 'Indicators' }"
          class="text-body-1 text-decoration-none"
        >
          {{ t("Indicators") }}
        </router-link>
        <span class="px-2 text-body-2"> > </span>
        <span class="text-body-1 font-demi-bold">
          {{ indicator.verbose_name }}
        </span>
        <v-divider class="ml-4 mr-1 my-2" vertical />
        <navigation-drawer
          :items="tabs"
          navbar-class="subnavbar"
        />
      </div>
    </div>
    <router-view v-if="ready" />
  </div>
</template>

<script>
import NavigationDrawer from "@/components/design_system/NavigationDrawer";
import { mapState } from "vuex";

export default {
  name: "IndicatorDetail",
  components: { NavigationDrawer },
  props: {
    indicatorId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabs: [
        { name: this.t("Parameters"), to: { name: "IndicatorParameters" } },
        { name: this.t("Results"), to: { name: "IndicatorResults" } },
      ],

      // updating: false,
      // verbose_name: "",
      ready: false,
    };
  },

  computed: {
    ...mapState({
      indicator: (state) => state["indicators"].current,
    }),
  },

  mounted() {
    if (!this.indicator.id) {
      this.$store
        .dispatch("indicators/retrieve", this.indicatorId)
        .then((response) => {
          this.$store.commit("indicators/setCurrent", response.data);
          // this.verbose_name = response.data.verbose_name;
          this.ready = true;
        });
    } else {
      this.ready = true;
    }
  },

  methods: {},
};
</script>

<style scoped></style>
