<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4C6 2.89543 6.89543 2 8 2H16C17.1046 2 18 2.89543 18 4V7H19C20.6569 7 22 8.34315 22 10V15C22 16.6569 20.6569 18 19 18V19.6573C19 21.1118 17.6503 22 16.3891 22H7.6109C6.3497 22 5 21.1118 5 19.6573V18C3.34315 18 2 16.6569 2 15V10C2 8.34315 3.34315 7 5 7H6V4ZM5 9H8H16H19C19.5523 9 20 9.44772 20 10V15C20 15.5523 19.5523 16 19 16V12C19 11.4477 18.5523 11 18 11H6C5.44772 11 5 11.4477 5 12V16C4.44772 16 4 15.5523 4 15V10C4 9.44772 4.44772 9 5 9ZM17 17.0014V19.6573C17 19.6837 16.9893 19.7525 16.887 19.8378C16.783 19.9245 16.6095 20 16.3891 20H7.6109C7.3905 20 7.21701 19.9245 7.11303 19.8378C7.01072 19.7525 7 19.6837 7 19.6573V17V13H17V16.9986L17 17L17 17.0014ZM9 18C9 17.4477 9.44772 17 10 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H10C9.44772 19 9 18.5523 9 18ZM10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16H14C14.5523 16 15 15.5523 15 15C15 14.4477 14.5523 14 14 14H10ZM8 4V7H16V4H8Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.14 7.39C19.76 7.23 19.35 7.14 18.92 7.14H17.89V3.78C17.89 2.8 17.1 2 16.12 2H7.89001C6.91001 2 6.12 2.8 6.12 3.78V7.14H5.09C4.66 7.14 4.25 7.23 3.87 7.39C2.78 7.86 2.01001 8.95 2.01001 10.22V14.81C2.01001 16.51 3.39 17.89 5.09 17.89H6.12V19.87C6.12 21.04 7.06999 22 8.23999 22H15.77C16.94 22 17.89 21.04 17.89 19.87V17.89H18.92C20.62 17.89 22.01 16.51 22.01 14.81V10.22C22.01 8.95 21.24 7.86 20.14 7.39ZM7.62 3.78C7.62 3.62 7.74001 3.5 7.89001 3.5H16.12C16.27 3.5 16.39 3.62 16.39 3.78V7.14H7.62V3.78ZM11.73 18.17C11.73 18.58 11.39 18.92 10.98 18.92H9.95001C9.54001 18.92 9.20001 18.58 9.20001 18.17C9.20001 18.07 9.22001 17.97 9.26001 17.89C9.37001 17.61 9.64001 17.42 9.95001 17.42H10.98C11.29 17.42 11.56 17.61 11.67 17.89C11.71 17.97 11.73 18.07 11.73 18.17ZM14.06 15.83H9.95001C9.54001 15.83 9.20001 15.5 9.20001 15.08C9.20001 14.67 9.54001 14.33 9.95001 14.33H14.06C14.47 14.33 14.81 14.67 14.81 15.08C14.81 15.5 14.47 15.83 14.06 15.83ZM20.51 14.81C20.51 15.68 19.8 16.39 18.92 16.39H17.89V12C17.89 11.59 17.56 11.25 17.14 11.25H6.87C6.45 11.25 6.12 11.59 6.12 12V16.39H5.09C4.21 16.39 3.51001 15.68 3.51001 14.81V10.22C3.51001 9.35 4.21 8.64 5.09 8.64H6.87H17.14H18.92C19.8 8.64 20.51 9.35 20.51 10.22V14.81Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Printer",
};
</script>
