<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55345 3.95122 3.95122 7.55345 3.95122 12C3.95122 16.4466 7.55345 20.0488 12 20.0488C16.4466 20.0488 20.0488 16.4466 20.0488 12C20.0488 7.55345 16.4466 3.95122 12 3.95122ZM2 12C2 6.47582 6.47582 2 12 2C17.5242 2 22 6.47582 22 12C22 17.5242 17.5242 22 12 22C6.47582 22 2 17.5242 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7017 10.7481C15.1039 10.3438 15.0987 9.69328 14.6902 9.29522C14.2816 8.89717 13.6244 8.90229 13.2222 9.30666L12 10.5355L10.7778 9.30666C10.3756 8.90229 9.7184 8.89717 9.30984 9.29522C8.90127 9.69328 8.8961 10.3438 9.29829 10.7481L10.5434 12L9.29829 13.2519C8.8961 13.6562 8.90127 14.3067 9.30984 14.7048C9.7184 15.1028 10.3756 15.0977 10.7778 14.6933L12 13.4645L13.2222 14.6933C13.6244 15.0977 14.2816 15.1028 14.6902 14.7048C15.0987 14.3067 15.1039 13.6562 14.7017 13.2519L13.4566 12L14.7017 10.7481Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM14.42 13.39C14.72 13.69 14.71 14.16 14.42 14.45C14.27 14.59 14.08 14.67 13.89 14.67C13.7 14.67 13.5 14.59 13.36 14.44L12 13.07L10.65 14.44C10.5 14.59 10.31 14.67 10.11 14.67C9.91999 14.67 9.73 14.59 9.59 14.45C9.29 14.16 9.28999 13.69 9.57999 13.39L10.95 12L9.57999 10.61C9.28999 10.31 9.29 9.84 9.59 9.55C9.88 9.26 10.36 9.26 10.65 9.56L12 10.93L13.36 9.56C13.65 9.26 14.12 9.26 14.42 9.55C14.71 9.84 14.72 10.31 14.42 10.61L13.05 12L14.42 13.39Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseCircle",
};
</script>
