<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00324 4C4.44541 4 4 4.44701 4 5V12H20V5C20 4.44701 19.5546 4 18.9968 4H5.00324ZM20 14H4V15C4 15.553 4.44541 16 5.00324 16H9.97494C9.99109 15.9996 10.0073 15.9996 10.0236 16H13.9791C13.9936 15.9997 14.008 15.9997 14.0224 16H18.9968C19.5546 16 20 15.553 20 15V14ZM2 13V5C2 3.33939 3.34389 2 5.00324 2H18.9968C20.6561 2 22 3.33939 22 5V13V15C22 16.6606 20.6561 18 18.9968 18H15.2808L15.7808 20H17C17.5523 20 18 20.4477 18 21C18 21.5523 17.5523 22 17 22H15.0209C15.0064 22.0003 14.992 22.0003 14.9776 22H9.01942C9.00701 22.0002 8.99456 22.0002 8.98209 22H7C6.44772 22 6 21.5523 6 21C6 20.4477 6.44772 20 7 20H8.21921L8.71921 18H5.00324C3.34389 18 2 16.6606 2 15V13ZM13.7192 20H10.2808L10.7808 18H13.2192L13.7192 20Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2 2H4.81001C3.26001 2 2.01001 3.26 2.01001 4.81V15.1C2.01001 15.97 2.40002 16.74 3.02002 17.26C3.51002 17.67 4.13001 17.91 4.81001 17.91H9.26001L8.83002 20.5H7.83002C7.42002 20.5 7.08002 20.84 7.08002 21.25C7.08002 21.66 7.42002 22 7.83002 22H16.05C16.47 22 16.8 21.66 16.8 21.25C16.8 20.84 16.47 20.5 16.05 20.5H15.06L14.63 17.91H19.2C19.89 17.91 20.51 17.67 21 17.26C21.62 16.74 22.01 15.97 22.01 15.1V4.81C22.01 3.26 20.75 2 19.2 2ZM3.51001 14.75C3.57001 14.77 3.64002 14.79 3.72002 14.79H20.51V15.1C20.51 15.82 19.92 16.41 19.2 16.41H4.81001C4.09001 16.41 3.51001 15.82 3.51001 15.1V14.75Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DesktopMonitor",
};
</script>
