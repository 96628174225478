<template>
  <div>
    <auth-container :title="title">
      <v-row class="mt-8 mb-16 mx-5 justify-center" align="center">
        <v-col cols="10" sm="8" md="6" lg="4" class="mb-16">
          <v-card outlined>
            <v-card-text class="py-5 px-10">
              <v-form ref="form" v-model="valid" lazy-validation>
                <text-input
                  v-model="username"
                  :input-label="t('Identifier')"
                  required
                  :rules="requiredRule"
                  :placeholder="t('Identifier')"
                  @keyup.enter="login"
                />

                <text-input
                  v-model="password"
                  :input-label="t('Password')"
                  required
                  :password="true"
                  :rules="requiredRule"
                  :error-messages="errorMsg ? errorMsg : null"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :placeholder="t('••••••••')"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  @keyup.enter="login"
                />
                <div class="text-center">
                  <v-btn
                    class="primary"
                    small
                    block
                    depressed
                    text
                    @click="login"
                  >
                    <span v-if="!loading"> {{ t("Log in") }} </span>
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="var(--basics-10)"
                      size="18"
                      width="2"
                    />
                  </v-btn>
                </div>
                <div class="text-center mt-4">
                  <router-link :to="{ name: 'LockOut' }">
                    {{ t("Forgot your password ?") }}
                  </router-link>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </auth-container>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import Auth from "@/services/auth/auth";
import AuthContainer from "@/components/auth/AuthContainer";
import form_rules from "@/mixins/rules/form_rules";

export default {
  name: "Login",
  components: { AuthContainer },
  mixins: [i18n, form_rules],
  data() {
    return {
      showConfirmPassword: false,
      valid: true,
      username: "",
      password: "",
      loading: false,
      errorMsg: "",
    };
  },

  computed: {
    title() {
      return this.t("Welcome to Codoc Monitoring");
    },
  },

  methods: {
    login() {
      this.$refs.form.validate();
      this.loading = true;
      Auth.identification(this.username, this.password)
        .then(
          (response) => {
            let is_two_factor_setup = response.data.two_factor;
            if (is_two_factor_setup) {
              this.$router.push({ name: "OTPVerify" });
            } else {
              this.$router.push({ name: "OTPSetup" });
            }
          },
          (error) => {
            this.$store.commit(
              "snackbar/apiErrorSnackbar",
              error.response.data
            );
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              this.errorMsg = error.response.data.message;
            } else {
              this.errorMsg = this.t("Une erreur est survenue.");
            }
          }
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
