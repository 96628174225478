<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V3C13 3.0238 12.9992 3.0474 12.9975 3.07078C14.5293 3.29003 15.9022 4.00666 16.9487 5.05213C18.2212 6.31369 19.0049 8.06464 19.0049 10.0028V14.0543C20.143 14.2874 21 15.2947 21 16.5017C21 17.8813 19.8804 19 18.5012 19H15.8747C15.4313 20.7312 13.8625 22 12 22C10.1371 22 8.57053 20.7247 8.12617 19H5.49879C4.11958 19 3 17.8813 3 16.5017C3 15.2947 3.85703 14.2874 4.99512 14.0543V10.0028C4.99512 6.48099 7.60406 3.55545 11.0024 3.07054C11.0008 3.04724 11 3.02372 11 3V2C11 1.44772 11.4477 1 12 1ZM9 17H15H18.5012C18.7765 17 19 16.7761 19 16.5017C19 16.2274 18.7765 16.0035 18.5012 16.0035H18.0049C17.4526 16.0035 17.0049 15.5558 17.0049 15.0035V10.0028C17.0049 8.61441 16.4461 7.36949 15.5395 6.47134L15.5363 6.46818L15.5363 6.46817C14.6255 5.55769 13.3778 5 12 5C9.23427 5 6.99512 7.24649 6.99512 10.0028V15.0035C6.99512 15.5558 6.54741 16.0035 5.99512 16.0035H5.49879C5.22349 16.0035 5 16.2274 5 16.5017C5 16.7761 5.2235 17 5.49879 17H9ZM10.268 19C10.6141 19.5975 11.2606 20 12 20C12.7422 20 13.3892 19.5995 13.7344 19H10.268Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.23999 20.41H14.77C14.22 21.37 13.19 22 12.01 22C10.81 22 9.78999 21.37 9.23999 20.41Z"
      :fill="$parent.getColor"
    />
    <path
      d="M21.32 17.19C21.32 17.69 21.16 18.15 20.91 18.53C20.48 19.16 19.77 19.58 18.95 19.58H5.06003C4.81003 19.58 4.57004 19.54 4.35004 19.47C3.82004 19.31 3.38002 18.98 3.08002 18.53C2.83002 18.15 2.67004 17.69 2.67004 17.19C2.67004 16.12 3.38003 15.22 4.34003 14.91C4.49003 14.87 4.64002 14.84 4.80002 14.82V10.25C4.80002 6.55 7.62002 3.51 11.21 3.11V2.78C11.21 2.35 11.56 2 11.99 2C12.42 2 12.77 2.35 12.77 2.78V3.11C14.39 3.29 15.9 3.99999 17.07 5.17999C18.43 6.51999 19.18 8.33 19.18 10.25V14.82C20.38 14.95 21.32 15.96 21.32 17.19Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AlarmBell",
};
</script>
