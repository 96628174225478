import axios from "axios";
import TokenService from "@/services/auth/tokens";
import { authHeader } from "@/services/auth/headers";

const API_URL = process.env.VUE_APP_CODOC_API_URL;

class AuthInterface {
  identification(username, password) {
    return axios
      .post(API_URL + "/auth/identification/", {
        username: username,
        password: password,
      })
      .then((response) => {
        TokenService.setIdToken(response.data.identity);
        return response;
      });
  }

  logout() {
    return axios.post(
      API_URL + "/auth/logout/",
      {
        refresh: TokenService.getRefreshToken(),
      },
      { headers: authHeader() }
    );
  }

  refresh() {
    return axios.post(
      API_URL + "/auth/refresh/",
      {
        refresh: TokenService.getRefreshToken(),
      },
      { headers: authHeader() }
    );
  }
}

export default new AuthInterface();
