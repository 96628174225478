<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.32732 8.26007C2.73598 7.88856 3.36843 7.91868 3.73993 8.32733C4.39503 9.04795 5.10279 9.66379 5.84684 10.1766C7.12565 11.0581 8.51566 11.638 9.93827 11.9214C11.3755 12.2076 12.8505 12.1921 14.2838 11.8747C15.7242 11.5556 17.1273 10.9306 18.4105 9.9941C19.0606 9.51962 19.6809 8.96444 20.2601 8.32733C20.6316 7.91868 21.264 7.88856 21.6727 8.26007C22.0813 8.63157 22.1114 9.26402 21.7399 9.67268C21.2906 10.167 20.8176 10.6209 20.3247 11.0341L21.8479 13.4698C22.1407 13.9381 21.9985 14.555 21.5302 14.8479C21.0619 15.1407 20.445 14.9985 20.1521 14.5302L18.6993 12.2071C17.7211 12.8088 16.6899 13.2699 15.6297 13.589L15.9876 15.8432C16.0742 16.3886 15.7023 16.901 15.1568 16.9876C14.6114 17.0742 14.099 16.7023 14.0124 16.1568L13.672 14.013C12.6189 14.1547 11.554 14.162 10.4996 14.0348L9.97242 16.2332C9.84363 16.7703 9.30385 17.1012 8.76679 16.9724C8.22973 16.8436 7.89877 16.3039 8.02757 15.7668L8.53802 13.6383C7.49753 13.3406 6.48352 12.9069 5.51846 12.3382L3.79624 14.605C3.46212 15.0447 2.83478 15.1304 2.39502 14.7963C1.95527 14.4621 1.86963 13.8348 2.20375 13.395L3.87359 11.1972C3.30896 10.7413 2.76935 10.2329 2.26005 9.67268C1.88855 9.26402 1.91866 8.63157 2.32732 8.26007Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeClose",
};
</script>
