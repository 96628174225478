<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3C17 3.55228 16.5523 4 16 4L16 9.75601L19.5871 16.6694L19.5874 16.67C20.1802 17.8142 20.1226 19.1549 19.4547 20.2484C18.7885 21.3557 17.6093 22 16.3295 22H7.6628C6.3951 22 5.20497 21.3574 4.53768 20.2484C3.86972 19.1549 3.81207 17.8143 4.40489 16.67L4.40557 16.6687L8.00001 9.75556L8 4C7.44772 4 7 3.55228 7 3ZM10 4H14V10C14 10.1603 14.0385 10.3183 14.1124 10.4606L15.43 13H8.56727L9.88724 10.4613C9.96133 10.3188 10 10.1606 10 10V4ZM7.52738 15L6.18072 17.59L6.18046 17.5905C5.91426 18.1048 5.93587 18.7021 6.24557 19.2077L6.25034 19.2155L6.2503 19.2155C6.54346 19.7041 7.072 20 7.6628 20H16.3295C16.9282 20 17.4478 19.7059 17.742 19.2155L17.7467 19.2077L17.7468 19.2077C18.0564 18.7021 18.0781 18.1048 17.8119 17.5906L17.8118 17.5905L17.8116 17.59L16.4677 15H7.52738Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.84 13.83C17.82 13.76 17.79 13.7 17.75 13.64L15.66 9.56V3.5H16.11C16.53 3.5 16.86 3.17 16.86 2.75C16.86 2.34 16.53 2 16.11 2H7.88999C7.46999 2 7.13999 2.34 7.13999 2.75C7.13999 3.17 7.46999 3.5 7.88999 3.5H8.33997V9.56L6.24997 13.64C6.20997 13.7 6.17998 13.76 6.15998 13.83L4.51996 17.03C3.96996 18.1 4.01997 19.35 4.64997 20.37C5.25997 21.39 6.34997 22 7.55997 22H16.44C17.65 22 18.74 21.39 19.35 20.37C19.98 19.35 20.03 18.1 19.48 17.04L17.84 13.83ZM18.07 19.59C17.72 20.16 17.12 20.5 16.44 20.5H7.55997C6.87997 20.5 6.27997 20.16 5.92997 19.59C5.56997 19 5.53998 18.32 5.84998 17.72L7.33997 14.81H16.66L18.15 17.72C18.46 18.32 18.43 19 18.07 19.59Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Flask",
};
</script>
