<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row class="mt-8">
          <v-col class="text-center">
            <div class="d-flex justify-center">
              <v-img
                :src="logo_codoc"
                max-height="60"
                max-width="100"
                contain
              />
            </div>
            <div class="mt-8">
              <span class="text-h6 basic-90 text--darken-3">{{ title }}</span>
            </div>
          </v-col>
        </v-row>
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from "@/mixins/i18n";
import logo_codoc from "@/assets/img/logo/Codoc.svg";

export default {
  name: "AuthContainer",
  mixins: [i18n],
  props: {
    title: {
      type: String,
    },
  },

  data() {
    return {
      logo_codoc,
    };
  },
};
</script>
