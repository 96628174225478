<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="18" r="2" :fill="$parent.getColor" />
    <circle cx="8" cy="12" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="12" r="2" :fill="$parent.getColor" />
    <circle cx="8" cy="6" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="6" r="2" :fill="$parent.getColor" />
    <circle cx="16" cy="18" r="2" :fill="$parent.getColor" />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16C10.8968 16 10 16.8984 10 17.9964C10 19.1016 10.8968 20 12 20C13.1032 20 14 19.1016 14 17.9964C14 16.8984 13.1032 16 12 16Z"
      :fill="$parent.getColor"
    />
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      :fill="$parent.getColor"
    />
    <path
      d="M12 4C10.8968 4 10 4.8984 10 6.00357C10 7.10161 10.8968 8 12 8C13.1032 8 14 7.10161 14 6.00357C14 4.8984 13.1032 4 12 4Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DotsMenuVertical",
};
</script>
