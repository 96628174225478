<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 3C7.30772 3 6 4.30772 6 6V8.72003H5.05C3.36102 8.72003 1.98771 10.0854 2 11.784V20C2 20.5523 2.44772 21 3 21H6.99352L7.00001 21.0001L16.9929 21H17L21 21C21.5523 21 22 20.5523 22 20V11.78C22 10.0877 20.6323 8.72003 18.94 8.72003H18V6.00402C18.0124 4.31227 16.6857 3 15 3H9ZM16 9.86185V19L14.58 19V16C14.58 15.4477 14.1323 15 13.58 15H10.5C9.94772 15 9.5 15.4477 9.5 16V19L8 19V6C8 5.41228 8.41229 5 9 5H15C15.5927 5 16.005 5.42523 16 5.99115L16 6V9.57821L16 9.5L16 9.86185ZM20 19H18V10.72H18.94C19.5277 10.72 20 11.1923 20 11.78V19ZM3.99996 11.7713C3.99488 11.1921 4.46059 10.72 5.05 10.72H6V19H4V11.78L3.99996 11.7713ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V8H10C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10H11V11C11 11.5523 11.4477 12 12 12C12.5523 12 13 11.5523 13 11V10H14C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8H13V7ZM11.5 19V17H12.58V19H11.5Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.08 3.03H8.91998C7.36998 3.03 6.10999 4.29 6.10999 5.84V20.98H17.89V5.84C17.89 4.29 16.63 3.03 15.08 3.03ZM12.79 19.48H11.21V16.87H12.79V19.48ZM14.06 9.67H12.75V10.98C12.75 11.39 12.41 11.73 12 11.73C11.59 11.73 11.25 11.39 11.25 10.98V9.67H9.93999C9.52999 9.67 9.18999 9.33 9.18999 8.92C9.18999 8.51 9.52999 8.17 9.93999 8.17H11.25V6.87C11.25 6.45 11.59 6.12 12 6.12C12.41 6.12 12.75 6.45 12.75 6.87V8.17H14.06C14.47 8.17 14.81 8.51 14.81 8.92C14.81 9.33 14.47 9.67 14.06 9.67Z"
      :fill="$parent.getColor"
    />
    <path
      d="M2 12V20.23C2 20.64 2.34 20.98 2.75 20.98H4.61V19.48V10.72V9.20999C3.15 9.30999 2 10.53 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      d="M19.39 9.20999V10.72V19.48V20.98H21.25C21.66 20.98 22 20.64 22 20.23V12C22 10.53 20.85 9.30999 19.39 9.20999Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "HospitalBuilding",
};
</script>
