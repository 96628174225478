<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 8.01729C11.1811 8.01729 10.5173 8.68112 10.5173 9.5C10.5173 10.3189 11.1811 10.9827 12 10.9827C12.8189 10.9827 13.4827 10.3189 13.4827 9.5C13.4827 8.68112 12.8189 8.01729 12 8.01729ZM8.5 9.5C8.5 7.567 10.067 6 12 6C13.933 6 15.5 7.567 15.5 9.5C15.5 11.433 13.933 13 12 13C10.067 13 8.5 11.433 8.5 9.5Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.95122 12C3.95122 7.55478 7.55478 3.95122 12 3.95122C16.4452 3.95122 20.0488 7.55478 20.0488 12C20.0488 14.1388 19.2145 16.0828 17.8537 17.5243V17.4537C17.8537 16.36 17.3859 15.4523 16.6306 14.8374C15.8944 14.2379 14.9328 13.9512 13.9512 13.9512H10.0488C8.02736 13.9512 6.32338 15.5423 6.15928 17.538C4.79077 16.0951 3.95122 14.1456 3.95122 12ZM8.09756 19.0411C9.25346 19.6831 10.584 20.0488 12 20.0488C13.416 20.0488 14.7465 19.6831 15.9024 19.0411V17.4537C15.9024 16.957 15.7068 16.6013 15.3986 16.3504C15.0715 16.0841 14.5696 15.9024 13.9512 15.9024H10.0488C8.99524 15.9024 8.09756 16.7946 8.09756 17.8537V19.0411ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM11.8 6.77C13.58 6.77 15.02 8.21 15.02 9.99C15.02 11.76 13.58 13.21 11.8 13.21C10.03 13.21 8.59 11.76 8.59 9.99C8.59 8.21 10.03 6.77 11.8 6.77ZM17.93 18.08C17.48 18.53 16.97 18.92 16.43 19.25C15.14 20.05 13.62 20.5 12 20.5C10.38 20.5 8.86001 20.05 7.57001 19.25C7.03001 18.92 6.52001 18.53 6.07001 18.08V17.89C6.07001 15.84 7.74 14.17 9.78 14.17H14.22C16.26 14.17 17.93 15.84 17.93 17.89V18.08Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "UserCircle",
};
</script>
