<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 5.78066C7 5.31272 7.35313 5 7.71666 5H16.2833C16.6469 5 17 5.31271 17 5.78066V9.17393C16.5185 9.06019 16.0163 9 15.5 9C11.9101 9 9 11.9101 9 15.5C9 16.7886 9.375 17.9897 10.0218 19H7.71666C7.35313 19 7 18.6873 7 18.2193V5.78066ZM12.0334 20.9995C12.0223 20.9998 12.0112 21 12 21H7.71666C6.1802 21 5 19.7222 5 18.2193V5.78066C5 4.27777 6.1802 3 7.71666 3H16.2833C17.8198 3 19 4.27777 19 5.78066V10L18.9998 10.0217C20.8041 11.1767 22 13.1987 22 15.5C22 19.0899 19.0899 22 15.5 22C14.2255 22 13.0368 21.6332 12.0334 20.9995ZM15.5 20C17.9853 20 20 17.9853 20 15.5C20 13.0147 17.9853 11 15.5 11C13.0147 11 11 13.0147 11 15.5C11 17.9853 13.0147 20 15.5 20ZM18.0071 13.8929C18.3976 14.2834 18.3976 14.9166 18.0071 15.3071L16.2239 17.0903C16.1728 17.1439 16.1164 17.1911 16.0559 17.2314C15.9389 17.3099 15.8089 17.361 15.675 17.3846C15.5243 17.4115 15.3664 17.4041 15.2137 17.3583C15.1517 17.3399 15.0912 17.3153 15.0331 17.2846L13.2528 16.3944C12.7588 16.1474 12.5586 15.5468 12.8056 15.0528C13.0526 14.5588 13.6532 14.3586 14.1472 14.6056L15.3025 15.1832L16.5929 13.8929C16.9834 13.5023 17.6166 13.5023 18.0071 13.8929Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "DocTime",
};
</script>
