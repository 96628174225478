<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 18.25L22 5.75C22 3.67893 20.3211 2 18.25 2L5.75 2C3.67893 2 2 3.67893 2 5.75L2 18.25C2 20.3211 3.67893 22 5.75 22L18.25 22C20.3211 22 22 20.3211 22 18.25ZM12 10.4748L16.265 15.0981C16.6395 15.504 17.2721 15.5295 17.6781 15.1551C18.084 14.7806 18.1095 14.1479 17.735 13.742L12.7425 8.3301C12.7316 8.31799 12.7203 8.3061 12.7087 8.29443C12.6247 8.20998 12.529 8.14354 12.4267 8.09531C12.2917 8.03158 12.1457 7.99996 12 7.99999C11.7424 8.00006 11.4856 8.09903 11.2913 8.29443C11.2797 8.3061 11.2684 8.31799 11.2575 8.3301L6.26498 13.742C5.89051 14.1479 5.91601 14.7806 6.32195 15.1551C6.72789 15.5295 7.36054 15.504 7.73501 15.0981L12 10.4748Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CaretUp",
};
</script>
