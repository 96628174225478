<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4795 21.4837L10.4726 21.476C10.161 21.1278 10 20.714 10 20.25C10 19.775 10.1588 19.3561 10.4794 19.0166L10.4867 19.0089C10.8277 18.6679 11.2579 18.5 11.75 18.5C12.2316 18.5 12.6529 18.6709 12.991 19.009C13.3291 19.3471 13.5 19.7684 13.5 20.25C13.5 20.7204 13.3268 21.1367 12.998 21.4838L12.9911 21.4911C12.6529 21.8292 12.2316 22 11.75 22C11.2579 22 10.8278 21.832 10.4868 21.491L10.4795 21.4837Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.63092 7.33489C9.90095 6.91534 10.2274 6.62894 10.6084 6.45157L10.6152 6.44834C11.0459 6.24097 11.4906 6.13912 11.9555 6.13912C12.4442 6.13912 12.8803 6.23689 13.2731 6.42562L13.283 6.4304L13.2931 6.43479C13.6914 6.60787 14.0085 6.86303 14.2546 7.20529C14.4815 7.52073 14.6086 7.91878 14.6086 8.43044C14.6086 8.93969 14.4826 9.34841 14.2518 9.68249C13.9769 10.0638 13.6287 10.4319 13.2019 10.7852C12.7526 11.1546 12.2958 11.539 11.8315 11.9386L11.8242 11.945C11.3343 12.3812 10.9205 12.8845 10.5835 13.4527C10.2098 14.0669 10.0406 14.8014 10.0406 15.6252C10.0406 16.3845 10.6604 17 11.4249 17H12.0016C12.7661 17 13.3859 16.3845 13.3859 15.6252C13.3859 15.2466 13.4981 14.919 13.7277 14.6226C14.0028 14.2674 14.3531 13.9176 14.7844 13.575C15.2579 13.1987 15.7304 12.7919 16.2018 12.3549C16.7101 11.8838 17.1326 11.3376 17.4692 10.7192L17.4722 10.7136C17.8317 10.0352 18 9.25248 18 8.38462C18 7.27832 17.7202 6.29718 17.1363 5.46964C16.5833 4.66417 15.8405 4.04939 14.922 3.6267C14.026 3.20727 13.0576 3 12.0247 3C11.0162 3 10.0484 3.21754 9.12715 3.64972C8.19673 4.078 7.43324 4.73738 6.83981 5.61304C6.41379 6.24166 6.14385 6.97472 6.01793 7.79823C5.8656 8.79454 6.70901 9.53028 7.59513 9.53028C8.54239 9.53028 9.17644 8.77054 9.35646 8.02228C9.42051 7.75604 9.51349 7.52866 9.63092 7.33489Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Info",
};
</script>
