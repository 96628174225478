<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.74994 22L18.2499 22C20.321 22 21.9999 20.321 21.9999 18.25L21.9999 5.74997C21.9999 3.6789 20.321 1.99997 18.2499 1.99997L5.74994 1.99997C3.67887 1.99997 1.99994 3.6789 1.99994 5.74997L1.99994 18.25C1.99994 20.321 3.67887 22 5.74994 22ZM13.5251 12L8.90183 16.265C8.4959 16.6394 8.47039 17.2721 8.84487 17.678C9.21935 18.084 9.852 18.1095 10.2579 17.735L15.6698 12.7425C15.6819 12.7316 15.6938 12.7203 15.7055 12.7086C15.79 12.6247 15.8564 12.529 15.9046 12.4267C15.9684 12.2916 16 12.1457 15.9999 12C15.9999 11.7424 15.9009 11.4856 15.7055 11.2913C15.6938 11.2797 15.6819 11.2684 15.6698 11.2574L10.2579 6.26496C9.852 5.89048 9.21935 5.91598 8.84487 6.32192C8.47039 6.72786 8.4959 7.36051 8.90183 7.73499L13.5251 12Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CaretRight",
};
</script>
