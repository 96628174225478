<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V17.5858L7.70711 14.2929C7.31658 13.9024 6.68342 13.9024 6.29289 14.2929C5.90237 14.6834 5.90237 15.3166 6.29289 15.7071L11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L12.7077 20.7065L17.7071 15.7071C18.0976 15.3166 18.0976 14.6834 17.7071 14.2929C17.3166 13.9024 16.6834 13.9024 16.2929 14.2929L13 17.5858V4Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDown",
};
</script>
