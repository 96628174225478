<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5969 1.19772C13.8505 1.38641 14 1.6839 14 2.00001V4.00001H21C21.5523 4.00001 22 4.44772 22 5.00001V19C22 19.5523 21.5523 20 21 20H14V22C14 22.3318 13.8354 22.642 13.5606 22.8281C13.2859 23.0141 12.9367 23.0517 12.6286 22.9285L2.62861 18.9285C2.24895 18.7766 2 18.4089 2 18V5.00001C2 4.5584 2.28967 4.16908 2.71265 4.04218L12.7127 1.04218C13.0154 0.95135 13.3433 1.00902 13.5969 1.19772ZM14 18H20V6.00001H14V7.00001H18C18.5523 7.00001 19 7.44772 19 8.00001C19 8.55229 18.5523 9.00001 18 9.00001H14V9.50001H18C18.5523 9.50001 19 9.94772 19 10.5C19 11.0523 18.5523 11.5 18 11.5H14V12.5H18C18.5523 12.5 19 12.9477 19 13.5C19 14.0523 18.5523 14.5 18 14.5H14V15H18C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17H14V18ZM4 5.74404V9.47936C4.00904 9.0204 4.33517 8.61317 4.80388 8.51943C5.34544 8.41112 5.87227 8.76233 5.98058 9.30389L6.14982 10.1501L6.53524 8.73689C6.65118 8.31175 7.03176 8.01262 7.47225 8.00039C7.91275 7.98817 8.30933 8.26573 8.44868 8.68378L9.32478 11.3121L10.0256 8.27515C10.1498 7.73701 10.6867 7.40143 11.2249 7.52562C11.6802 7.6307 11.9906 8.03133 12 8.47815V3.34404L4 5.74404ZM12 8.5201C11.9986 8.58777 11.9902 8.65631 11.9744 8.72487L10.4744 15.2249C10.3737 15.6614 9.99493 15.9776 9.54746 15.9989C9.09999 16.0201 8.69298 15.7412 8.55132 15.3162L7.59575 12.4495L6.96476 14.7631C6.84274 15.2106 6.42908 15.5154 5.96558 15.4994C5.50208 15.4835 5.11037 15.1509 5.01942 14.6961L4.01942 9.69612C4.00751 9.63657 4.00115 9.57719 4 9.51853V17.323L12 20.523V8.5201Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.75 19H20C20.5523 19 21 18.5523 21 18V5.99999C21 5.4477 20.5523 4.99999 20 4.99999H13.75V7.24999H18C18.4142 7.24999 18.75 7.58577 18.75 7.99999C18.75 8.4142 18.4142 8.74999 18 8.74999H13.75V9.91665H18C18.4142 9.91665 18.75 10.2524 18.75 10.6667C18.75 11.0809 18.4142 11.4167 18 11.4167H13.75V12.5833H18C18.4142 12.5833 18.75 12.9191 18.75 13.3333C18.75 13.7475 18.4142 14.0833 18 14.0833H13.75V15.25H18C18.4142 15.25 18.75 15.5858 18.75 16C18.75 16.4142 18.4142 16.75 18 16.75H13.75V19ZM12.25 16V13.3333V10.6667V7.99999V3.12678C11.7611 2.73571 11.0921 2.57236 10.4253 2.7724L4.4253 4.5724C3.57934 4.82619 3 5.60483 3 6.48805V16.6459C3 17.4637 3.4979 18.1991 4.25722 18.5029L10.2572 20.9029C10.9697 21.1879 11.716 21.0337 12.25 20.6073V16ZM10.6973 8.27641C11.097 8.3854 11.3326 8.79771 11.2236 9.19732L9.72357 14.6973C9.63551 15.0202 9.34413 15.2457 9.00945 15.2499C8.67477 15.2541 8.37781 15.0361 8.28163 14.7155L7.46236 11.9846L6.71151 14.2372C6.60491 14.557 6.29802 14.7664 5.96136 14.749C5.6247 14.7316 5.34099 14.4918 5.26786 14.1627L4.26786 9.66268C4.178 9.25833 4.43295 8.8577 4.8373 8.76785C5.24165 8.67799 5.64228 8.93294 5.73214 9.33729L6.14475 11.194L6.78849 9.26282C6.8919 8.95259 7.18439 8.74512 7.51136 8.75007C7.83833 8.75503 8.1244 8.97126 8.21837 9.28448L8.96566 11.7755L9.77643 8.80265C9.88541 8.40303 10.2977 8.16743 10.6973 8.27641Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Word",
};
</script>
