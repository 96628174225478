<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="12" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="12" cy="16" r="2" :fill="$parent.getColor" />
    <circle cx="20" cy="8" r="2" :fill="$parent.getColor" />
    <circle cx="20" cy="16" r="2" :fill="$parent.getColor" />
    <circle cx="4" cy="16" r="2" :fill="$parent.getColor" />
  </svg>
</template>

<script>
export default {
  name: "DragDotsHorizontal",
};
</script>
