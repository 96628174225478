<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.95122C7.55345 3.95122 3.95122 7.55345 3.95122 12C3.95122 16.4466 7.55345 20.0488 12 20.0488C16.4466 20.0488 20.0488 16.4466 20.0488 12C20.0488 7.55345 16.4466 3.95122 12 3.95122ZM2 12C2 6.47582 6.47582 2 12 2C17.5242 2 22 6.47582 22 12C22 17.5242 17.5242 22 12 22C6.47582 22 2 17.5242 2 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 12C8 11.4477 8.35817 11 8.8 11H15.2C15.6418 11 16 11.4477 16 12C16 12.5523 15.6418 13 15.2 13H8.8C8.35817 13 8 12.5523 8 12Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MinusCircle",
};
</script>
