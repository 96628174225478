<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 6.5V4.5H9.5V6.5H7.5ZM6 4C6 3.44772 6.44772 3 7 3H10C10.5523 3 11 3.44772 11 4V7C11 7.55228 10.5523 8 10 8H9.5V10.5H13V10C13 9.44772 13.4477 9 14 9H17C17.5523 9 18 9.44772 18 10V13C18 13.5523 17.5523 14 17 14H14C13.4477 14 13 13.5523 13 13V12.5H9.5V17.5H13V17C13 16.4477 13.4477 16 14 16H17C17.5523 16 18 16.4477 18 17V20C18 20.5523 17.5523 21 17 21H14C13.4477 21 13 20.5523 13 20V19.5H8.5C7.94772 19.5 7.5 19.0523 7.5 18.5V11.5V8H7C6.44772 8 6 7.55228 6 7V4ZM14.5 11.5V12.5H16.5V10.5H14.5V11.5ZM16.5 17.5H14.5V19.5H16.5V17.5Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3C6.44772 3 6 3.44772 6 4V7C6 7.55228 6.44772 8 7 8H7.5V11.5V18.5C7.5 19.0523 7.94772 19.5 8.5 19.5H13V20C13 20.5523 13.4477 21 14 21H17C17.5523 21 18 20.5523 18 20V17C18 16.4477 17.5523 16 17 16H14C13.4477 16 13 16.4477 13 17V17.5H9.5V12.5H13V13C13 13.5523 13.4477 14 14 14H17C17.5523 14 18 13.5523 18 13V10C18 9.44772 17.5523 9 17 9H14C13.4477 9 13 9.44772 13 10V10.5H9.5V8H10C10.5523 8 11 7.55228 11 7V4C11 3.44772 10.5523 3 10 3H7Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Hierarchy",
};
</script>
