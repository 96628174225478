<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 6.03562C10.9151 6.03562 10.0356 6.9151 10.0356 8C10.0356 9.0849 10.9151 9.96438 12 9.96438C13.0849 9.96438 13.9644 9.0849 13.9644 8C13.9644 6.9151 13.0849 6.03562 12 6.03562ZM8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.52994 15.5867C7.218 15.9485 7 16.5271 7 17.33V19C7 19.5523 6.55228 20 6 20C5.44772 20 5 19.5523 5 19V17.33C5 16.1829 5.312 15.0965 6.01506 14.2808C6.73494 13.4457 7.77523 13 9 13H15C16.2308 13 17.2717 13.449 17.9902 14.2863C18.6912 15.1033 19 16.1891 19 17.33V19C19 19.5523 18.5523 20 18 20C17.4477 20 17 19.5523 17 19V17.33C17 16.5309 16.7838 15.9517 16.4723 15.5887C16.1783 15.246 15.7192 15 15 15H9C8.28477 15 7.82506 15.2443 7.52994 15.5867Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.76 11.62C13.79 11.62 15.45 9.97 15.45 7.94C15.45 5.91 13.8 4.25 11.76 4.25C9.73002 4.25 8.08002 5.9 8.08002 7.94C8.08002 9.98 9.73002 11.62 11.76 11.62Z"
      :fill="$parent.getColor"
    />
    <path
      d="M14.64 13.05H9.37001C7.01001 13.05 5.10001 14.97 5.10001 17.32V18C5.10001 18.96 5.89001 19.75 6.85001 19.75H17.16C18.12 19.75 18.91 18.96 18.91 18V17.33C18.91 14.97 16.99 13.05 14.64 13.05Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "User",
};
</script>
