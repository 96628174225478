<template>
  <v-container>
    <drop-down
      class="pt-4"
      :input-label="t('Application')"
      item-value="application_id"
      item-text="text"
      :items="indicators_apps"
      search-bar
      return-object
      @input="updateTable"
    >
      <template #selection="{ item }">
        <country-flag :country="item.application.vm.hospital.country" size="small" class="ml-1 selected-flag" />
        <badge type="info" :text="item.application.vm.hospital.code" class="ml-2" />
        <span class="ml-2">{{ item.application.vm.name }} - {{ item.application.code }}</span>
      </template>
      <template #customItem="{ item }">
        <v-list-item-title class="d-flex justify-start space">
          <country-flag :country="item.application.vm.hospital.country" size="small" class="ml-1 mt-n1" />
          <badge type="info" :text="item.application.vm.hospital.code" class="ml-2" />
          <span class="ml-2">{{ item.application.vm.name }} - {{ item.application.code }}</span>
        </v-list-item-title>
      </template>
    </drop-down>
    <div class="d-flex justify-end mb-2 mt-7">
      <span v-if="timestamp" class="basics-90 mr-auto font-italic">Results last retrieved {{ moment(timestamp) }}</span>
      <export-module
        v-if="!loading && objects.length > 0"
        :data="exportData"
      />
      <export-module
        text="Export all"
        class="ml-5"
        :data-function="allExportData"
      />
    </div>
    <custom-data-table
      :items="objects"
      :headers="headers"
      :loading="loading"
      dense
      class="mt-1"
    />
  </v-container>
</template>

<script>
import ExportModule from "@/components/commons/ExportModule.vue";
import CustomDataTable from "@/components/design_system/CustomDataTable.vue";
import moment from "moment";
import { mapState } from "vuex";
import { Results } from "@/services/resources";
import CountryFlag from "vue-country-flag";
import _ from "lodash";

export default {
  name: "IndicatorResults",
  components: { CustomDataTable, CountryFlag, ExportModule },
  data() {
    return {
      moment,

      loading: false,
      timestamp: null,
      headers: [],
      objects: [],
      exportData: []
    };
  },

  computed: {
    ...mapState({
      indicator: (state) => state["indicators"].current,
      indicators_apps: (state) => state["indicators_apps"].objects,
    }),
  },

  mounted() {
    this.$store.dispatch("indicators_apps/list", {
      indicator_id: this.indicator.id,
    });
  },

  methods: {
    updateTable(result) {
      this.loading = true;
      Results.get(result.indicator_id, result.application_id, result.application.vm.hospital.id).then((response) => {
        if (response.data.rows.length === 0) {
          this.$store.commit("snackbar/errorSnackbar", { message: "No result has yet been received from this application." }, { root: true });
          return;
        }

        // Retrieve timestamp_ from the first row
        this.timestamp = response.data.rows[0].timestamp_;

        // Replace any `null` value by `"<null>"` to avoid empty cells and remove "timestamp_"
        this.objects = response.data.rows.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            if (key !== "timestamp_") {
              acc[key] = row[key] === null ? "<null>" : row[key];
            }
            return acc;
          }, {});
        });

        // Create the export data
        this.exportData = _.cloneDeep(this.objects);
        // Create the headers from the first object keys
        this.headers = Object.keys(this.objects[0]).map((key) => {
          return { text: key, value: key };
        });
      }).catch((error) => {
        let message = null;
        if (error.response.status === 404) {
          message = { message: "No result has yet been received for this application." };
          this.$store.commit("snackbar/errorSnackbar", message, { root: true });
        } else {
          this.$store.commit("snackbar/apiErrorSnackbar", error, { root: true });
        }
        this.headers = [];
        this.objects = [];

      }).finally(() => {
        this.loading = false;
      });
    },

    async allExportData() {
      let promises = [];
      let data = [];

      this.indicators_apps.forEach((ia, index) => {
        let hospitalInfo = {
          hospital: ia.application.vm.hospital.name,
          hospital_code: ia.application.vm.hospital.code,
          application_code: ia.application.code,
        };

        let promise = Results.get(this.indicator.id, ia.application_id)
          .then((response) => {
            // Replace any `null` value by `"<null>"` to avoid empty cells and add hospital information
            // Ignore the first row if `index > 0` since we already have the headers
            data = data.concat(response.data.rows.slice(index > 0).map((row) => {
              return {
                ...Object.keys(row).reduce((acc, key) => {
                  acc[key] = row[key] === null ? "<null>" : row[key];
                  return acc;
                }, {}),
                ...hospitalInfo
              };
            }));
          })
          .catch((error) => {
            // Ignore 404 since some applications may not have results yet
            if (error.response.status !== 404) {
              this.$store.commit("snackbar/apiErrorSnackbar", error, { root: true });
            }
          });

        promises.push(promise);
      });

      // Wait for all promises to resolve before returning data
      return await Promise.all(promises).then(() => data);
    }
  },
};
</script>

<style scoped>
.small-flag {
  vertical-align: middle !important;
  margin-right: -12px !important;
  margin-top: -9px !important;
  transform: scale(0.40) !important;
  -ms-transform: scale(0.40) !important;
  -webkit-transform: scale(0.40) !important;
  -moz-transform: scale(0.40) !important;
}

.selected-flag {
  margin-top: -13px !important;
}
</style>
