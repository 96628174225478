<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L6 16.5858V11C6 10.4477 5.55228 10 5 10C4.44772 10 4 10.4477 4 11V19C4 19.5523 4.44772 20 5 20H13C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18H7.41421L18.7071 6.70711Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowLeftDown",
};
</script>
