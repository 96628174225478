<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4V9C4.00046 9 4.00092 9 4.00139 9H8.99861C8.99908 9 8.99954 9 9 9V4C8.99954 4 8.99908 4 8.99861 4H4.00139C4.00092 4 4.00046 4 4 4ZM2 3.77904C2 2.5765 3.14314 2 4.00139 2H8.99861C9.85686 2 11 2.5765 11 3.77904V9.23224C10.9866 10.4197 9.86061 11 8.99861 11H4.00139C3.14314 11 2 10.4235 2 9.22096V3.77904Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 15V20C4.00046 20 4.00092 20 4.00139 20H8.99861C8.99908 20 8.99954 20 9 20V15C8.99954 15 8.99908 15 8.99861 15H4.00139C4.00092 15 4.00046 15 4 15ZM2 14.779C2 13.5765 3.14314 13 4.00139 13H8.99861C9.85686 13 11 13.5765 11 14.779V20.2322C10.9866 21.4197 9.86061 22 8.99861 22H4.00139C3.14314 22 2 21.4235 2 20.221V14.779Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 4V9C15.0005 9 15.0009 9 15.0014 9H19.9986C19.9991 9 19.9995 9 20 9V4C19.9995 4 19.9991 4 19.9986 4H15.0014C15.0009 4 15.0005 4 15 4ZM13 3.77904C13 2.5765 14.1431 2 15.0014 2H19.9986C20.8569 2 22 2.5765 22 3.77904V9.23224C21.9866 10.4197 20.8606 11 19.9986 11H15.0014C14.1431 11 13 10.4235 13 9.22096V3.77904Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 15V20C15.0005 20 15.0009 20 15.0014 20H19.9986C19.9991 20 19.9995 20 20 20V15C19.9995 15 19.9991 15 19.9986 15H15.0014C15.0009 15 15.0005 15 15 15ZM13 14.779C13 13.5765 14.1431 13 15.0014 13H19.9986C20.8569 13 22 13.5765 22 14.779V20.2322C21.9866 21.4197 20.8606 22 19.9986 22H15.0014C14.1431 22 13 21.4235 13 20.221V14.779Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Squares",
};
</script>
