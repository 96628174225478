import { render, staticRenderFns } from "./Django.vue?vue&type=template&id=03647670"
import script from "./Django.vue?vue&type=script&lang=js"
export * from "./Django.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports