<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20L6 16C6 14.3431 7.34315 13 9 13H14C15.6569 13 17 14.3431 17 16V20H18C19.1046 20 20 19.1046 20 18V8.15685C20 7.62642 19.7893 7.11771 19.4142 6.74264L17.2574 4.58579C17.1773 4.50573 17.0911 4.43315 17 4.36852V7C17 8.65685 15.6569 10 14 10H9C7.34315 10 6 8.65685 6 7V4ZM17 22H18C20.2091 22 22 20.2091 22 18V8.15685C22 7.09599 21.5786 6.07857 20.8284 5.32843L18.6716 3.17157C17.9214 2.42143 16.904 2 15.8431 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H8H15H17ZM15 20V16C15 15.4477 14.5523 15 14 15H9C8.44771 15 8 15.4477 8 16V20H15ZM8 4H15V7C15 7.55228 14.5523 8 14 8H9C8.44772 8 8 7.55228 8 7V4ZM12 5.5C12 4.94772 12.4477 4.5 13 4.5C13.5523 4.5 14 4.94772 14 5.5V6.5C14 7.05228 13.5523 7.5 13 7.5C12.4477 7.5 12 7.05228 12 6.5V5.5Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.87 5.43L18.58 3.13C17.862 2.40921 16.8874 2.0028 15.87 2H5.87001H5.77001C4.76294 2.01577 3.8026 2.42762 3.09699 3.14634C2.39137 3.86506 1.99726 4.8328 2.00001 5.84V18.17C2.00265 19.185 2.40702 20.1576 3.12471 20.8753C3.8424 21.593 4.81505 21.9974 5.83001 22H18.16C19.1767 22 20.1519 21.5968 20.8718 20.8788C21.5916 20.1609 21.9974 19.1867 22 18.17V8.17C22.0031 7.66112 21.9048 7.15672 21.7108 6.68626C21.5168 6.21581 21.231 5.78874 20.87 5.43ZM13.77 5.32V6.32C13.77 6.51891 13.691 6.70968 13.5503 6.85033C13.4097 6.99098 13.2189 7.07 13.02 7.07C12.8211 7.07 12.6303 6.99098 12.4897 6.85033C12.349 6.70968 12.27 6.51891 12.27 6.32V5.32C12.27 5.12109 12.349 4.93032 12.4897 4.78967C12.6303 4.64902 12.8211 4.57 13.02 4.57C13.2189 4.57 13.4097 4.64902 13.5503 4.78967C13.691 4.93032 13.77 5.12109 13.77 5.32ZM20.5 18.17C20.4966 18.6565 20.3416 19.1298 20.0566 19.5241C19.7716 19.9184 19.3709 20.2141 18.91 20.37V16.11C18.91 15.3665 18.6153 14.6532 18.0905 14.1266C17.5657 13.5999 16.8535 13.3026 16.11 13.3H7.88001C7.13649 13.3026 6.42432 13.5999 5.89951 14.1266C5.37469 14.6532 5.08001 15.3665 5.08001 16.11V20.37C4.62023 20.2137 4.22078 19.9176 3.9375 19.5232C3.65422 19.1287 3.50126 18.6556 3.50001 18.17V5.84C3.50035 5.35536 3.65311 4.8831 3.93667 4.49007C4.22022 4.09705 4.62021 3.80315 5.08001 3.65V7.18C5.08001 7.84039 5.34235 8.47373 5.80932 8.9407C6.27628 9.40766 6.90963 9.67 7.57001 9.67H14.37C15.0296 9.66737 15.6614 9.40418 16.1278 8.93779C16.5942 8.47139 16.8574 7.83958 16.86 7.18V3.74C17.1052 3.85355 17.3286 4.00924 17.52 4.2L19.81 6.49C20.0289 6.70536 20.2027 6.9622 20.3212 7.24551C20.4397 7.52882 20.5005 7.83291 20.5 8.14V18.17Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Save",
};
</script>
