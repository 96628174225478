import Account from "@/components/account/Account";
import AccountAuthentication from "@/components/account/AccountAuthentication";

export const accountUrls = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    redirect: { name: "AccountAuthentication" },
    children: [
      {
        path: "auth",
        name: "AccountAuthentication",
        component: AccountAuthentication,
      },
    ],
  },
];
