<template>
  <v-container>
    <div>
      <div>
        <v-row>
          <v-col cols="12" md="6">
            <drop-down
              v-model="type_id"
              required
              :input-label="t('Type')"
              item-value="id"
              search-bar
              item-text="name"
              :items="application_types"
              @change="patch({ type_id: type_id })"
            />
          </v-col>
          <v-col cols="12" md="6">
            <drop-down
              v-model="vm_id"
              required
              :input-label="t('VM')"
              item-value="id"
              item-text="name"
              :items="vms"
              @change="patch({ vm_id: vm_id })"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <text-input
              v-model="url"
              required
              hide-details="auto"
              :input-label="t('URL')"
              persistent-hint
              @keydown.enter="patch({ url: url })"
              @blur="patch({ url: url })"
            />
          </v-col>
        </v-row>
      </div>
      <div class="text-right">
        <v-btn
          class="primary-main"
          icon
          @click="
            currentObject = application;
            openDestroyDialog = true;
          "
        >
          <icon name="trash" />
        </v-btn>
      </div>
    </div>
    <delete-dialog
      :current-object="application"
      :open.sync="openDestroyDialog"
      :destroy="destroy"
    >
      <template #dialogDeleteContent="{ currentObject }">
        {{ t("Delete") }} {{ currentObject.code }} ?
      </template>
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import DeleteDialog from "@/components/commons/DeleteDialog";

export default {
  name: "AppParameters",
  components: { DeleteDialog },

  data() {
    return {
      url: null,
      type_id: null,
      vm_id: null,
      loading: false,
      openDestroyDialog: false,
    };
  },

  computed: {
    ...mapState({
      application: (state) => state["apps"].current,
      app_index: (state) => state["apps"].currentIndex,
      application_types: (state) => state["application_types"].objects,
      vms: (state) => state["vms"].objects,
    }),
  },

  mounted() {
    this.url = this.application.url;
    this.type_id = this.application.type_id;
    this.vm_id = this.application.vm_id;
  },

  methods: {
    patch(params) {
      this.loading = true;
      return this.$store.dispatch("apps/patch", params).finally(() => {
        this.loading = false;
      });
    },

    destroy() {
      this.$store.dispatch("apps/destroy", this.app_index).then(() => {
        this.openDestroyDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
