<template>
  <v-container>
    <c-r-u-d-data-table
      namespace="users"
      title="Users"
      class="mt-5"
      :default-new-object="defaultUser"
      :headers="headers"
      @save-clicked-changed="updateSaveClicked"
    >
      <template #item.first_name="{ item }">
        {{ item.first_name + " " + item.last_name }}
      </template>
      <template #dialogContent="{ currentObject }">
        <text-input
          v-model="currentObject.first_name"
          autofocus
          hide-details="auto"
          persistent-hint
          :input-label="t('First name')"
        />
        <text-input
          v-model="currentObject.last_name"
          autofocus
          hide-details="auto"
          persistent-hint
          class="mt-2"
          :input-label="t('Last name')"
        />
        <text-input
          v-model="currentObject.username"
          autofocus
          persistent-hint
          hide-details="auto"
          class="mt-2"
          :rules="requiredRule"
          :input-label="t('Username')"
        />
        <text-input
          v-model="currentObject.email"
          autofocus
          :rules="requiredRule"
          hide-details="auto"
          class="mt-2"
          persistent-hint
          :input-label="t('Email')"
        />
        <text-input
          v-model="currentObject.password"
          autofocus
          :password="true"
          hide-details="auto"
          class="mt-2"
          persistent-hint
          :input-label="t('Password')"
        />
      </template>
    </c-r-u-d-data-table>
  </v-container>
</template>
  
<script>
  import i18n from "@/mixins/i18n";
  import form_rules from "@/mixins/rules/form_rules";
  import CRUDDataTable from "@/components/commons/CRUDDataTable";

  export default {
    name: "AdministrationUsers",
    components: { CRUDDataTable },
    mixins: [i18n, form_rules],
    
    data(){
      return {
        headers: [
          { text: this.t("Full name"), align: "start", value: "first_name" },
          { text: this.t("Username"), align: "start", value: "username" },
          { text: this.t("Email"), align: "start", value: "email", sortable: false },
          { text: this.t("Creation date"), align: "start", value: "date_joined", sortable: false },
          { text: "", value: "edit", align: "right", sortable: false },
        ],

        defaultUser: {
          first_name: "",
          last_name: "",
          email: "",
          date_joinded: "" 
        },
      };
    },

    mounted() {
      this.$store.dispatch("users/list");
    },

  };
</script>
  