<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 17C5 16.4477 5.44772 16 6 16H18C18.5523 16 19 16.4477 19 17C19 17.5523 18.5523 18 18 18H6C5.44772 18 5 17.5523 5 17Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 12C5 11.4477 5.44772 11 6 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H6C5.44772 13 5 12.5523 5 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 7C5 6.44772 5.44772 6 6 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H6C5.44772 8 5 7.55228 5 7Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.48 2H5.53C3.58 2 2 3.58 2 5.53V18.47C2 20.42 3.58 22 5.53 22H18.48C20.42 22 22 20.42 22 18.47V5.53C22 3.58 20.42 2 18.48 2ZM6 17.75C5.59 17.75 5.25 17.41 5.25 17C5.25 16.59 5.59 16.25 6 16.25H18C18.41 16.25 18.75 16.59 18.75 17C18.75 17.41 18.41 17.75 18 17.75H6ZM5.25 12C5.25 11.59 5.59 11.25 6 11.25H12C12.41 11.25 12.75 11.59 12.75 12C12.75 12.41 12.41 12.75 12 12.75H6C5.59 12.75 5.25 12.41 5.25 12ZM18 7.75H6C5.59 7.75 5.25 7.41 5.25 7C5.25 6.59 5.59 6.25 6 6.25H18C18.41 6.25 18.75 6.59 18.75 7C18.75 7.41 18.41 7.75 18 7.75Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "TextLeft",
};
</script>
