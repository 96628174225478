<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.64 3.99841L20.004 10.3624L18.5897 11.7766L18.1061 11.2929L12.7094 5.89628L12.2258 5.41262L13.64 3.99841ZM10.8116 6.82684C10.0305 6.04579 10.0305 4.77946 10.8116 3.99841L12.2258 2.5842C13.0068 1.80315 14.2732 1.80315 15.0542 2.5842L21.4182 8.94816C22.1992 9.72921 22.1992 10.9955 21.4182 11.7766L20.004 13.1908C19.2229 13.9718 17.9566 13.9718 17.1755 13.1908L9.6802 20.6861C7.92284 22.4435 5.0736 22.4435 3.31624 20.6861C1.55888 18.9288 1.55888 16.0795 3.31624 14.3222L5.15472 12.4837L9.39736 8.24105L10.8116 6.82684ZM12.2258 8.24105L11.5187 8.94816L12.9329 10.3624C13.3234 10.7529 13.3234 11.3861 12.9329 11.7766L8.69025 16.0192C8.29973 16.4097 7.66656 16.4097 7.27604 16.0192L5.86182 14.605L4.73045 15.7364C3.75414 16.7127 3.75414 18.2956 4.73046 19.2719C5.70677 20.2482 7.28968 20.2482 8.26599 19.2719L15.7613 11.7766L12.2258 8.24105ZM7.27604 13.1908L10.1045 10.3624L10.8116 11.0695L7.98315 13.8979L7.27604 13.1908Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.46 8.8L15.2 2.55C14.85 2.2 14.38 2 13.89 2C13.39 2 12.93 2.2 12.58 2.55L11.01 4.11C10.29 4.84 10.29 6.01 11.01 6.74L11.26 6.99L4.91 13.34L3.19 15.06C2.42 15.83 2 16.85 2 17.94C2 19.02 2.42 20.04 3.19 20.81C3.96 21.58 4.98001 22 6.07001 22C7.15001 22 8.18 21.58 8.94 20.81L17.02 12.74L17.27 12.99C17.63 13.35 18.1 13.53 18.58 13.53C19.05 13.53 19.53 13.35 19.89 12.99L21.46 11.43C21.81 11.08 22 10.61 22 10.12C22 9.62 21.81 9.15 21.46 8.8ZM6.64 13.73L9.2 11.18L10.48 12.46L6.85001 16.09L5.56 14.81L6.64 13.73ZM20.4 10.37L18.83 11.93C18.69 12.07 18.47 12.07 18.33 11.93L17.55 11.15L12.85 6.46L12.07 5.68C11.93 5.54 11.93 5.31 12.07 5.17L13.64 3.61C13.73 3.52 13.83 3.5 13.89 3.5C13.94 3.5 14.05 3.52 14.14 3.61L20.4 9.86C20.4 9.86 20.4 9.86 20.4 9.87C20.49 9.96 20.5 10.06 20.5 10.12C20.5 10.17 20.49 10.28 20.4 10.37Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "TestTube",
};
</script>
