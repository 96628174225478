<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C6.47582 2 2 6.47582 2 12C2 17.5242 6.47582 22 12 22C13.5176 22 14.9593 21.6544 16.2463 21.0518L20.8049 21.7265C21.1178 21.7728 21.4342 21.6684 21.658 21.4448C21.8818 21.2213 21.9867 20.9051 21.9408 20.5921L21.2399 15.8151C21.7231 14.6414 22 13.3498 22 12C22 6.47582 17.5242 2 12 2ZM19.2896 15.2882C19.1911 15.5081 19.1765 15.7528 19.2424 15.9782L19.7666 19.5511L16.2329 19.0281C16.0278 18.9977 15.8184 19.0318 15.6336 19.1257C14.5413 19.6805 13.3045 20 12 20C7.58039 20 4 16.4196 4 12C4 7.58039 7.58039 4 12 4C16.4196 4 20 7.58039 20 12C20 13.1646 19.7412 14.2808 19.2896 15.2882ZM9 12C9 12.5523 8.55229 13 8 13C7.44771 13 7 12.5523 7 12C7 11.4477 7.44771 11 8 11C8.55229 11 9 11.4477 9 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 6.49 17.51 2 12 2C6.48 2 2 6.49 2 12C2 17.51 6.48 22 12 22C13.5 22 14.95 21.67 16.31 21.01L21.09 21.72C21.32 21.75 21.56 21.67 21.73 21.5C21.89 21.34 21.97 21.1 21.94 20.86L21.2 15.89C21.73 14.64 22 13.34 22 12ZM7.88 13.03C7.32 13.03 6.86 12.57 6.86 12C6.86 11.43 7.32 10.97 7.88 10.97C8.45001 10.97 8.91 11.43 8.91 12C8.91 12.57 8.45001 13.03 7.88 13.03ZM11.99 13.03C11.43 13.03 10.97 12.57 10.97 12C10.97 11.43 11.43 10.97 11.99 10.97C12.56 10.97 13.02 11.43 13.02 12C13.02 12.57 12.56 13.03 11.99 13.03ZM16.11 13.03C15.54 13.03 15.08 12.57 15.08 12C15.08 11.43 15.54 10.97 16.11 10.97C16.67 10.97 17.13 11.43 17.13 12C17.13 12.57 16.67 13.03 16.11 13.03Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SpeechBubble",
};
</script>
