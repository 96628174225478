export default {
  methods: {
    /**
     * Return value only if type is number (for Firefox and Edge)
     * @returns {string}
     * @param $event
     */
    allowOnlyNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
  },
};
