<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5496 2.03705C16.0815 2.18596 16.3919 2.73781 16.243 3.26964L15.963 4.26964C15.8141 4.80147 15.2622 5.11189 14.7304 4.96298C14.1985 4.81406 13.8881 4.26221 14.037 3.73038L14.317 2.73038C14.4659 2.19855 15.0178 1.88813 15.5496 2.03705ZM19.9771 5.4371C20.3676 5.04658 20.3676 4.41341 19.9771 4.02289C19.5866 3.63236 18.9534 3.63236 18.5629 4.02288L17.7929 4.79287C17.4024 5.18339 17.4024 5.81656 17.7929 6.20708C18.1834 6.59761 18.8166 6.59761 19.2071 6.20709L19.9771 5.4371ZM14.4482 8.0036C14.4429 8.00576 14.4359 8.00964 14.4276 8.01796L12.4571 9.98846L13.958 11.5965L15.9821 9.57244L15.9904 9.56412C15.9952 9.55943 15.9968 9.55632 15.9977 9.5542C15.9988 9.55137 16 9.54693 16 9.54099C16 9.53503 15.9988 9.5288 15.9964 9.52287C15.9942 9.51757 15.9904 9.51062 15.9821 9.50231L14.4977 8.01796L14.4894 8.00964C14.4847 8.00484 14.4816 8.00321 14.4795 8.00233C14.4767 8.00116 14.4722 8.00001 14.4663 8.00001C14.4603 8.00001 14.4541 8.00118 14.4482 8.0036ZM4.01795 18.4276L11.042 11.4035L12.5429 13.0116L5.57241 19.9821L5.56415 19.9904C5.55945 19.9952 5.5563 19.9968 5.55418 19.9977C5.55135 19.9989 5.54691 20 5.54097 20C5.53501 20 5.52878 19.9988 5.52285 19.9964C5.51756 19.9943 5.51061 19.9904 5.50228 19.9821L4.01795 18.4977C4.00963 18.4894 4.00575 18.4825 4.00359 18.4772C4.00117 18.4713 4 18.465 4 18.4591C4 18.4531 4.00116 18.4487 4.00232 18.4459C4.0032 18.4437 4.00478 18.4406 4.00958 18.4359L4.01795 18.4276ZM15.9155 6.60727C15.1134 5.79137 13.8121 5.805 13.0134 6.60375L2.60725 17.0099C1.79136 17.812 1.805 19.1132 2.60374 19.912L4.08808 21.3963C4.88681 22.195 6.18802 22.2087 6.99012 21.3928L17.3928 10.9902C18.2086 10.1881 18.195 8.88683 17.3963 8.0881L15.9155 6.60727ZM21.2696 9.113C21.8015 8.96409 22.1119 8.41224 21.963 7.88041C21.8141 7.34858 21.2622 7.03816 20.7304 7.18707L19.7304 7.46707C19.1985 7.61598 18.8881 8.16783 19.037 8.69966C19.1859 9.23149 19.7378 9.54191 20.2696 9.393L21.2696 9.113Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8 5.25997L19.03 6.02996C18.89 6.17996 18.69 6.24996 18.5 6.24996C18.31 6.24996 18.12 6.17996 17.97 6.02996C17.68 5.73996 17.68 5.25996 17.97 4.96996L18.74 4.19998C19.04 3.89998 19.51 3.89998 19.8 4.19998C20.1 4.48998 20.1 4.95997 19.8 5.25997Z"
      :fill="$parent.getColor"
    />
    <path
      d="M16.57 2.94002L16.29 4.00001C16.2 4.33001 15.9 4.55 15.57 4.55C15.5 4.55 15.44 4.55001 15.37 4.53001C14.97 4.42001 14.73 4.01 14.84 3.61L15.13 2.56001C15.23 2.16001 15.65 1.92001 16.04 2.03001C16.44 2.13001 16.68 2.54002 16.57 2.94002Z"
      :fill="$parent.getColor"
    />
    <path
      d="M21.44 8.88005L20.39 9.16005C20.33 9.18005 20.26 9.18004 20.2 9.18004C19.86 9.18004 19.56 8.96005 19.47 8.63005C19.36 8.23005 19.6 7.82004 20 7.71004L21.06 7.43004C21.47 7.32004 21.87 7.56004 21.98 7.96004C22.08 8.36004 21.84 8.77005 21.44 8.88005Z"
      :fill="$parent.getColor"
    />
    <path
      d="M17.49 8.04996L15.95 6.50998C15.6 6.15998 15.14 5.96997 14.65 5.96997C14.65 5.96997 14.65 5.96997 14.64 5.96997C14.15 5.96997 13.69 6.15998 13.34 6.50998L2.54 17.31C1.82 18.03 1.82 19.2 2.54 19.92L4.08 21.46C4.44 21.82 4.91 22 5.39 22C5.86 22 6.33 21.82 6.69 21.46L17.49 10.66C17.84 10.31 18.03 9.84995 18.03 9.35995C18.03 8.85995 17.84 8.39996 17.49 8.04996ZM16.43 9.59998L14.58 11.45C14.54 11.34 14.49 11.23 14.4 11.14L12.86 9.59998C12.77 9.50998 12.67 9.45998 12.55 9.41998L14.4 7.56998C14.49 7.48998 14.59 7.46997 14.64 7.46997H14.65C14.7 7.46997 14.8 7.48998 14.89 7.56998L16.43 9.11996C16.52 9.19996 16.53 9.29995 16.53 9.35995C16.53 9.40995 16.52 9.50998 16.43 9.59998Z"
      :fill="$parent.getColor"
    />
    <path
      d="M21.98 7.96001C21.87 7.56001 21.47 7.32001 21.06 7.43001L20 7.71001C19.6 7.82001 19.36 8.23002 19.47 8.63002C19.56 8.96002 19.86 9.18001 20.2 9.18001C20.26 9.18001 20.33 9.18002 20.39 9.16002L21.44 8.88002C21.84 8.77002 22.08 8.36001 21.98 7.96001ZM19.8 4.20003C19.51 3.90003 19.04 3.90003 18.74 4.20003L17.97 4.97002C17.68 5.26002 17.68 5.74001 17.97 6.03001C18.12 6.18001 18.31 6.25001 18.5 6.25001C18.69 6.25001 18.89 6.18001 19.03 6.03001L19.8 5.26002C20.1 4.96002 20.1 4.49003 19.8 4.20003ZM16.04 2.03001C15.65 1.92001 15.23 2.16001 15.13 2.56001L14.84 3.61C14.73 4.01 14.97 4.42001 15.37 4.53001C15.44 4.55001 15.5 4.55 15.57 4.55C15.9 4.55 16.2 4.33001 16.29 4.00001L16.57 2.94002C16.68 2.54002 16.44 2.13001 16.04 2.03001Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MagicWand",
};
</script>
