<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.88236 9.29303C4.88236 8.28999 5.48893 7.8823 5.79603 7.8823H10.0588V10.2249C10.0588 11.1728 10.8272 11.9412 11.7751 11.9412H14C14.0411 11.9412 14.0816 11.9385 14.1213 11.9334V14.7067V18.9424C14.1213 19.2364 14.002 19.5536 13.8014 19.7913C13.602 20.0275 13.3841 20.1191 13.2077 20.1191H5.79603C5.49233 20.1191 4.88236 19.7004 4.88236 18.7084V9.29303ZM13.8528 10.0588C13.83 10.0235 13.8062 9.99004 13.7814 9.95842L13.7813 9.95831L12.4061 8.2103L12.4056 8.20964C12.2514 8.01324 12.0863 7.92251 11.9412 7.89335V10.0588H13.8528ZM5.79603 5.99994C4.06279 5.99994 3 7.68453 3 9.29303V18.7084C3 20.2973 4.0594 22.0015 5.79603 22.0015H13.2077C14.0592 22.0015 14.7687 21.5636 15.2398 21.0056C15.7096 20.449 16.0037 19.7073 16.0037 18.9424V14.7067V11.0417C16.0037 10.2303 15.7547 9.42351 15.2618 8.79577L15.2612 8.79511L13.8861 7.0471C13.3851 6.40904 12.654 5.99994 11.832 5.99994H5.79603Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0013 2L16.0074 2.00002H16.8158C17.6424 2.00002 18.3765 2.41061 18.879 3.04926L20.2558 4.79599C20.7501 5.42426 21 6.23198 21 7.04319V10.7054V14.9381C21 15.7031 20.7053 16.4451 20.2341 17.0021C19.7617 17.5606 19.0493 18 18.1932 18H17.3264V16.1088H18.1932C18.3661 16.1088 18.5822 16.0191 18.7811 15.784C18.9812 15.5474 19.1004 15.2313 19.1004 14.9381V10.7054V7.92027L17.3189 7.92184C17.0006 7.92212 16.7007 7.77328 16.5092 7.52002L15.2549 5.8605C15.1229 5.68583 15.0515 5.47315 15.0515 5.25455V3.89122H10.7727C10.5291 3.89122 10.0826 4.15615 9.92356 4.79321H8C8.19478 3.35574 9.21128 2.00002 10.7727 2.00002H15.9952L16.0013 2ZM16.9511 6.03109H18.8109L17.384 4.21613C17.2402 4.03336 17.0872 3.9427 16.9511 3.90839V6.03109Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1682 2H16.25V5.17647C16.25 6.04551 16.9545 6.75 17.8235 6.75H21L21 10.8V15.6C21 16.8 20.0334 18 18.8318 18H17.5V13.3V9.14683C17.5 8.43726 17.2741 7.76231 16.8676 7.26042L15.2596 5.27879C14.853 4.7769 14.3019 4.5 13.7238 4.5H8.00409C8.07351 3.09823 9.02042 2 10.1682 2ZM20.3676 4.76041C20.4884 4.90954 20.5933 5.07395 20.681 5.25H17.8235C17.7829 5.25 17.75 5.21708 17.75 5.17647V2.07824C18.1296 2.19324 18.479 2.43244 18.7596 2.77879L20.3676 4.76041Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.25 6H5.16817C3.97568 6 3 7.18549 3 8.66521V19.3348C3 20.7972 3.97568 22 5.16817 22H13.8318C15.0334 22 16 20.8 16 19.6V14.8V10.75H12.8235C11.9545 10.75 11.25 10.0455 11.25 9.17647V6ZM15.681 9.25C15.5932 9.07395 15.4884 8.90954 15.3676 8.76041L13.7596 6.77879C13.479 6.43244 13.1296 6.19323 12.75 6.07824V9.17647C12.75 9.21708 12.7829 9.25 12.8235 9.25H15.681Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Files",
};
</script>
