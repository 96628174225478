<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.19023 4C4.96182 4 4 4.97036 4 6.13297V17.867C4 19.0296 4.96182 20 6.19023 20H15H17V19.5021L17 19.4973V16.7455L17 16.7427V6.13297C17 4.97236 16.0303 4 14.8098 4H6.19023ZM18 22H19.3751L19.3797 22L19.3842 22H19.5C19.564 22 19.6267 21.994 19.6874 21.9825C20.9373 21.8397 22 20.8387 22 19.4973V18.9313V16.7455V13.9254C22 12.0411 20.7939 10.5236 19 10.1111V6.13297C19 3.8298 17.0965 2 14.8098 2H6.19023C3.89155 2 2 3.8318 2 6.13297V17.867C2 20.1682 3.89155 22 6.19023 22H15H18ZM20 19.4973C20 19.7054 19.7964 19.9988 19.3823 20H19.3772C19.2812 19.9997 19.2234 19.982 19.1914 19.9678C19.1598 19.9538 19.1353 19.9353 19.1132 19.9095C19.0669 19.8553 19.0006 19.7275 19 19.5003V12.2187C19.6349 12.5341 20 13.1634 20 13.9254V16.7455V18.9313V19.4973ZM7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H14C14.5523 8 15 7.55228 15 7C15 6.44772 14.5523 6 14 6H7ZM6 12C6 11.4477 6.44772 11 7 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H7C6.44772 13 6 12.5523 6 12ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H9C9.55228 18 10 17.5523 10 17C10 16.4477 9.55228 16 9 16H7Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.64 10.23V5.97C18.64 3.78 16.85 2 14.67 2H5.97C3.78 2 2 3.78 2 5.97V18.03C2 20.21 3.78 22 5.97 22H19.18C19.22 22 19.26 21.98 19.3 21.97C19.39 21.98 19.48 22 19.57 22C20.91 22 22 20.97 22 19.7V13.99C22 12.11 20.55 10.56 18.64 10.23ZM14.79 6.87C14.79 7.27999 14.45 7.62 14.04 7.62H6.85C6.43 7.62 6.1 7.27999 6.1 6.87C6.1 6.45 6.43 6.12 6.85 6.12H14.04C14.45 6.12 14.79 6.45 14.79 6.87ZM6.85 11.25H14.04C14.45 11.25 14.79 11.58 14.79 12C14.79 12.41 14.45 12.75 14.04 12.75H6.85C6.43 12.75 6.1 12.41 6.1 12C6.1 11.58 6.43 11.25 6.85 11.25ZM9.68 17.12C9.68 17.53 9.35 17.87 8.93 17.87H6.88C6.46 17.87 6.13 17.53 6.13 17.12C6.13 16.7 6.46 16.37 6.88 16.37H8.93C9.35 16.37 9.68 16.7 9.68 17.12ZM20.5 19.7C20.5 20.14 20.08 20.5 19.57 20.5C19.05 20.5 18.64 20.14 18.64 19.7V11.76C19.71 12.05 20.5 12.94 20.5 13.99V19.7Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Newspaper',
};
</script>
