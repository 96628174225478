<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 3.99997C12.4477 3.99997 12 4.44768 12 4.99997C12 5.55225 12.4477 5.99997 13 5.99997H16.5858L6 16.5858V13C6 12.4477 5.55228 12 5 12C4.44772 12 4 12.4477 4 13V19C4 19.5523 4.44772 20 5 20H11C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18H7.41421L18 7.41418V11C18 11.5523 18.4477 12 19 12C19.5523 12 20 11.5523 20 11V4.99997C20 4.44768 19.5523 3.99997 19 3.99997H13Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Open",
};
</script>
