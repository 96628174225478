<template>
  <div class="text-input-container date-input flex-fill mw-100">
    <label
      v-if="inputLabel"
      :class="[{ 'disabled-label': disabled }, inputLabelClass]"
      class="mb-1"
      :for="inputId"
    >
      {{ inputLabel }}
    </label>
    <v-text-field
      :id="inputId"
      :key="reload"
      v-model="display_date"
      :class="[
        {
          'disabled-error': (disabled && error) || (disabled && errorMessages)
        },
        {
          'disabled-success':
            (disabled && success) || (disabled && successMessages)
        },
        { 'super-dense': superDense },
        { 'absolute-details': absoluteDetails },
        inputClass
      ]"
      class="br-4"
      dense
      solo
      maxlength="10"
      :rules="rules"
      v-bind="[$props, $attrs]"
      v-on="$listeners"
      @blur="blur"
      @keypress="allowOnlyNumbers"
    >
      <template v-if="!appendIcon" #append>
        <template v-if="!$slots.append">
          <icon name="calendar" outline color="basics-80" />
        </template>
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import moment from "moment";
import inputNumber from "@/mixins/inputNumber";
import date_rules from "@/mixins/rules/date_rules";

export default {
  name: "DateInput",
  mixins: [inputNumber, date_rules],

  props: {
    inputLabel: {
      type: String,
      default: null,
    },

    inputLabelClass: {
      type: String,
      default: null,
    },

    // eslint-disable-next-line vue/no-unused-properties
    placeholder: {
      type: String,
      default: "JJ/MM/YYYY",
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      default: null,
    },

    success: {
      type: Boolean,
      default: false,
    },

    successMessages: {
      type: [String, Array],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    appendOuterIcon: {
      type: String,
      default: null,
    },

    prependIcon: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    inputClass: {
      type: String,
      default: null,
    },

    initialValue: {
      type: String,
      required: false,
    },

    rules: {
      type: Array,
      required: false,
      default() {
        return [
          (date) => date_rules.methods.dateFormatRule(date, "DD/MM/YYYY"),
        ];
      },
    },

    superDense: {
      type: Boolean,
      default: false,
    },

    absoluteDetails: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputId: "",
      display_date: "",
      reload: 0,
      dateFormat: ["DD-MM-YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "YYYY/MM/DD"],
      finalDate: "",
    };
  },

  watch: {
    display_date() {
      if (this.display_date.length === 0) {
        this.$emit("getDate", null);
      }
      if (this.display_date && this.display_date.length === 10) {
        const parsed = moment(this.display_date, this.dateFormat, true);
        this.finalDate = parsed.isValid() ? parsed.format("YYYY-MM-DD") : null;
        this.$emit("getDate", this.finalDate);
      }
      if (
        this.display_date &&
        (this.display_date.length === 2 || this.display_date.length === 5)
      ) {
        if (!this.display_date.includes("//")) {
          this.display_date += "/";
        }
      }
    },
  },

  mounted() {
    if (this.inputLabel) {
      this.isInputId();
    }
    if (
      this.prependIcon ||
      this.appendOuterIcon ||
      this.$slots.prepend ||
      this.$slots["append-outer"]
    ) {
      this.iconStyle();
    }
    if (this.initialValue) {
      this.formatInitialValue();
    }
  },

  methods: {
    isInputId() {
      this.id
        ? (this.inputId = this.id)
        : (this.inputId = "date-input-" + this._uid);
    },

    iconStyle() {
      const hoverElement = this.$el.querySelector(".v-input__slot");
      const inputNode = this.$el.querySelector(".v-text-field");
      hoverElement.addEventListener("mouseenter", () => {
        inputNode.classList.add("hovering");
      });
      hoverElement.addEventListener("mouseleave", () => {
        inputNode.classList.remove("hovering");
      });
    },

    formatInitialValue() {
      const parsed = moment(
        moment(this.initialValue).format("YYYY-MM-DD"),
        this.dateFormat,
        true
      );
      this.display_date = parsed.isValid() ? parsed.format("DD/MM/YYYY") : "";
    },

    blur(v) {
      this.$emit("blur", v);
    },
  },
};
</script>
