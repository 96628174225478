<template>
  <svg
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.53 2H18.48C20.42 2 22 3.58 22 5.53V18.47C22 20.42 20.42 22 18.48 22H5.53C3.58 22 2 20.42 2 18.47V5.53C2 3.58 3.58 2 5.53 2ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Section",
};
</script>
