<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 4C12.4477 4 12 4.44772 12 5C12 5.55228 12.4477 6 13 6H16.5858L13.4954 9.09042C12.5277 8.40387 11.3451 8 10.0676 8H9.93239C6.66043 8 4 10.6536 4 13.9324V14.0676C4 17.3396 6.6536 20 9.93239 20H10.0676C13.3403 20 16 17.3403 16 14.0676V13.9324C16 12.6563 15.5964 11.4733 14.9095 10.5048L18 7.41422V11C18 11.5523 18.4477 12 19 12C19.5523 12 20 11.5523 20 11V5.0019L19.9999 4.98501C19.998 4.8552 19.9713 4.73137 19.9245 4.61804C19.876 4.50066 19.8043 4.39063 19.7092 4.29502L19.705 4.29078C19.6108 4.19713 19.5026 4.12611 19.3872 4.0777C19.2681 4.02766 19.1373 4 19 4H13ZM6 13.9324C6 11.7596 7.76358 10 9.93239 10H10.0676C12.2404 10 14 11.7636 14 13.9324V14.0676C14 16.2357 12.2357 18 10.0676 18H9.93239C7.75959 18 6 16.2364 6 14.0676V13.9324Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Male",
};
</script>
