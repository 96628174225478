<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 12C4.55228 12 5 12.4477 5 13V18H19V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V13C3 12.4477 3.44772 12 4 12Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C12.5523 4 13 4.44772 13 5V12.1315L14.4453 11.1679C14.9048 10.8616 15.5257 10.9858 15.832 11.4453C16.1384 11.9048 16.0142 12.5257 15.5547 12.8321L12.5547 14.8321C12.2188 15.056 11.7812 15.056 11.4453 14.8321L8.4453 12.8321C7.98577 12.5257 7.8616 11.9048 8.16795 11.4453C8.4743 10.9858 9.09517 10.8616 9.5547 11.1679L11 12.1315V5C11 4.44772 11.4477 4 12 4Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Download",
};
</script>
