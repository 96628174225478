<script>
// This component is an extend of vuetify v-data-table component
// The only change is :
// custom filers don't have to all match, if one custom filter or default filter match the row is displayed

import { VDataTable } from "vuetify/es5/components/VDataTable";

const _helpers = require("vuetify/es5/util/helpers");

function filterFn(item, search, filter) {
  return function (header) {
    const value = (0, _helpers.getObjectValueByPath)(item, header.value);
    return header.filter
      ? header.filter(value, search, item)
      : filter(value, search, item);
  };
}

function searchTableItems(
  items,
  search,
  headersWithCustomFilters,
  headersWithoutCustomFilters,
  customFilter
) {
  search = typeof search === "string" ? search.trim() : null;
  return items.filter(function (item) {
    const matchesColumnFilters = headersWithCustomFilters.some(
      filterFn(item, search, _helpers.defaultFilter)
    ); // Headers without custom filters are only filtered by the `search` property if it is defined.

    const matchesSearchTerm =
      !search ||
      headersWithoutCustomFilters.some(filterFn(item, search, customFilter));
    return matchesColumnFilters || matchesSearchTerm;
  });
}

export default {
  name: "CustomDataTableMethod",
  extends: VDataTable,
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    customFilterWithColumns: function customFilterWithColumns(items, search) {
      return searchTableItems(
        items,
        search,
        this.headersWithCustomFilters,
        this.headersWithoutCustomFilters,
        this.customFilter
      );
    },
  },
};
</script>
