<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9371 15.8665C20.2049 15.349 19.928 14.7352 19.3841 14.5264C18.8401 14.3176 18.2362 14.5943 17.9521 15.103C17.2545 16.3522 16.205 17.3791 14.9237 18.049C13.329 18.8827 11.4848 19.1059 9.73726 18.6766C7.98968 18.2474 6.45878 17.1952 5.43177 15.7175C4.40476 14.2399 3.95222 12.4382 4.15905 10.6506C4.36588 8.86303 5.21787 7.21233 6.55518 6.00822C7.89248 4.80411 9.62319 4.12933 11.4226 4.11048C13.222 4.09164 14.9665 4.73002 16.3287 5.90586C17.3928 6.82435 18.1665 8.02146 18.5706 9.35122L17.8714 9.07152C17.3586 8.86641 16.7766 9.11583 16.5715 9.62861C16.3664 10.1414 16.6158 10.7234 17.1286 10.9285L19.6072 11.9199C19.7953 12.0002 20.003 12.0201 20.2009 11.9797C20.3988 11.939 20.582 11.8388 20.7234 11.6908L22.7071 9.70711C23.0976 9.31658 23.0976 8.68342 22.7071 8.29289C22.3166 7.90237 21.6834 7.90237 21.2929 8.29289L20.648 8.9378C20.1468 7.14841 19.1286 5.53518 17.7075 4.30854C15.9563 2.79696 13.7137 1.97629 11.4005 2.00052C9.0873 2.02475 6.86241 2.8922 5.14326 4.44013C3.42411 5.98806 2.32885 8.11009 2.06296 10.4081C1.79707 12.7061 2.37883 15.0222 3.69908 16.9218C5.01934 18.8214 6.98737 20.174 9.23393 20.7258C11.4805 21.2776 13.8512 20.9907 15.9013 19.9189C17.6383 19.0109 19.044 17.5922 19.9371 15.8665Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Loop",
};
</script>
