<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.61111 5.8C9.80453 3.4 14.1955 3.4 17.3889 5.8L18.6221 6.72681L20.3482 8.28353C22.5506 10.2697 22.5506 13.7303 20.3482 15.7165L18.6221 17.2732L17.3889 18.2C14.1955 20.6 9.80453 20.6 6.61111 18.2L5.3779 17.2732L3.65177 15.7165C1.44941 13.7303 1.44941 10.2697 3.65177 8.28353L5.3779 6.72681L6.61111 5.8ZM16.1914 7.4C13.7076 5.53333 10.2924 5.53333 7.80864 7.4L6.64678 8.27319L4.98695 9.77012C3.66553 10.9618 3.66553 13.0382 4.98695 14.2299L6.64678 15.7268L7.80864 16.6C10.2924 18.4667 13.7076 18.4667 16.1914 16.6L17.3532 15.7268L19.0131 14.2299C20.3345 13.0382 20.3345 10.9618 19.0131 9.77012L17.3532 8.27319L16.1914 7.4Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79085 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79085 16 8 14.2091 8 12Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.33 12C14.33 13.29 13.29 14.33 12 14.33C10.71 14.33 9.67004 13.29 9.67004 12C9.67004 10.71 10.71 9.66998 12 9.66998C13.29 9.66998 14.33 10.71 14.33 12Z"
      :fill="$parent.getColor"
    />
    <path
      d="M20.78 8.52999C18.55 5.84999 15.35 4.31 12 4.31C8.65004 4.31 5.45005 5.84999 3.23005 8.52999C1.58005 10.51 1.58005 13.49 3.23005 15.47C5.45005 18.15 8.65004 19.69 12 19.69C15.35 19.69 18.55 18.15 20.78 15.47C22.42 13.49 22.42 10.51 20.78 8.52999ZM12 15.83C9.89004 15.83 8.17006 14.11 8.17006 12C8.17006 9.89 9.89004 8.16999 12 8.16999C14.11 8.16999 15.83 9.89 15.83 12C15.83 14.11 14.11 15.83 12 15.83Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeOpen",
};
</script>
