<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5969 1.19772C13.8505 1.38641 14 1.6839 14 2.00001V4.00001H21C21.5523 4.00001 22 4.44772 22 5.00001V19C22 19.5523 21.5523 20 21 20H14V22C14 22.3318 13.8354 22.642 13.5606 22.8281C13.2859 23.0141 12.9367 23.0517 12.6286 22.9285L2.62861 18.9285C2.24895 18.7766 2 18.4089 2 18V5.00001C2 4.5584 2.28967 4.16908 2.71265 4.04218L12.7127 1.04218C13.0154 0.95135 13.3433 1.00902 13.5969 1.19772ZM14 18H20V6.00001H14V7.00001H15C15.5523 7.00001 16 7.44772 16 8.00001C16 8.55229 15.5523 9.00001 15 9.00001H14V10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H14V12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H14V15H15C15.5523 15 16 15.4477 16 16C16 16.5523 15.5523 17 15 17H14V18ZM4 5.74404V17.323L12 20.523V3.34404L4 5.74404ZM10.5547 7.16796C11.0142 7.47431 11.1384 8.09518 10.8321 8.55471L9.05584 11.219L10.7809 13.3753C11.1259 13.8066 11.056 14.4359 10.6247 14.7809C10.1934 15.1259 9.56414 15.056 9.21913 14.6247L7.89163 12.9653L6.83205 14.5547C6.5257 15.0142 5.90483 15.1384 5.4453 14.8321C4.98577 14.5257 4.8616 13.9048 5.16795 13.4453L6.58052 11.3264L5.21913 9.6247C4.87412 9.19344 4.94404 8.56415 5.3753 8.21914C5.80657 7.87413 6.43586 7.94405 6.78087 8.37531L7.74473 9.58014L9.16795 7.44531C9.4743 6.98578 10.0952 6.86161 10.5547 7.16796ZM16.5 8.00001C16.5 7.44772 16.9477 7.00001 17.5 7.00001H18.5C19.0523 7.00001 19.5 7.44772 19.5 8.00001C19.5 8.55229 19.0523 9.00001 18.5 9.00001H17.5C16.9477 9.00001 16.5 8.55229 16.5 8.00001ZM16.5 11C16.5 10.4477 16.9477 10 17.5 10H18.5C19.0523 10 19.5 10.4477 19.5 11C19.5 11.5523 19.0523 12 18.5 12H17.5C16.9477 12 16.5 11.5523 16.5 11ZM16.5 13.5C16.5 12.9477 16.9477 12.5 17.5 12.5H18.5C19.0523 12.5 19.5 12.9477 19.5 13.5C19.5 14.0523 19.0523 14.5 18.5 14.5H17.5C16.9477 14.5 16.5 14.0523 16.5 13.5ZM16.5 16C16.5 15.4477 16.9477 15 17.5 15H18.5C19.0523 15 19.5 15.4477 19.5 16C19.5 16.5523 19.0523 17 18.5 17H17.5C16.9477 17 16.5 16.5523 16.5 16Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.75 19H20C20.5523 19 21 18.5523 21 18V5.99999C21 5.4477 20.5523 4.99999 20 4.99999H13.75V7.24999H15C15.4142 7.24999 15.75 7.58577 15.75 7.99999C15.75 8.4142 15.4142 8.74999 15 8.74999H13.75V10.25H15C15.4142 10.25 15.75 10.5858 15.75 11C15.75 11.4142 15.4142 11.75 15 11.75H13.75V12.75H15C15.4142 12.75 15.75 13.0858 15.75 13.5C15.75 13.9142 15.4142 14.25 15 14.25H13.75V15.25H15C15.4142 15.25 15.75 15.5858 15.75 16C15.75 16.4142 15.4142 16.75 15 16.75H13.75V19ZM12.25 16V13.5V11V7.99999V3.12678C11.7611 2.73571 11.0921 2.57236 10.4253 2.7724L4.4253 4.5724C3.57934 4.82619 3 5.60483 3 6.48805V16.6459C3 17.4637 3.4979 18.1991 4.25722 18.5029L10.2572 20.9029C10.9697 21.1879 11.716 21.0337 12.25 20.6073V16ZM16.75 7.99999C16.75 7.58577 17.0858 7.24999 17.5 7.24999H18.5C18.9142 7.24999 19.25 7.58577 19.25 7.99999C19.25 8.4142 18.9142 8.74999 18.5 8.74999H17.5C17.0858 8.74999 16.75 8.4142 16.75 7.99999ZM9.94113 7.89343C10.2761 8.13706 10.3502 8.60613 10.1066 8.94112L8.15742 11.6212L10.0857 14.0315C10.3444 14.3549 10.292 14.8269 9.96852 15.0856C9.64507 15.3444 9.17311 15.292 8.91435 14.9685L7.24269 12.8789L6.10655 14.4411C5.86292 14.7761 5.39386 14.8502 5.05887 14.6065C4.72388 14.3629 4.64982 13.8938 4.89345 13.5589L6.27115 11.6645L4.91435 9.96851C4.65559 9.64506 4.70803 9.17309 5.03148 8.91434C5.35493 8.65558 5.82689 8.70802 6.08565 9.03147L7.18588 10.4068L8.89345 8.05886C9.13708 7.72387 9.60614 7.64981 9.94113 7.89343ZM16.75 11C16.75 10.5858 17.0858 10.25 17.5 10.25H18.5C18.9142 10.25 19.25 10.5858 19.25 11C19.25 11.4142 18.9142 11.75 18.5 11.75H17.5C17.0858 11.75 16.75 11.4142 16.75 11ZM16.75 13.5C16.75 13.0858 17.0858 12.75 17.5 12.75H18.5C18.9142 12.75 19.25 13.0858 19.25 13.5C19.25 13.9142 18.9142 14.25 18.5 14.25H17.5C17.0858 14.25 16.75 13.9142 16.75 13.5ZM16.75 16C16.75 15.5858 17.0858 15.25 17.5 15.25H18.5C18.9142 15.25 19.25 15.5858 19.25 16C19.25 16.4142 18.9142 16.75 18.5 16.75H17.5C17.0858 16.75 16.75 16.4142 16.75 16Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Excel",
};
</script>
