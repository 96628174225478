import axios from "axios";
import { authHeader, identityHeader } from "@/services/auth/headers";

const API_URL = process.env.VUE_APP_CODOC_API_URL;

class TotpService {
  setup() {
    return axios.post(
      API_URL + "/auth/totp/setup/",
      {},
      { headers: identityHeader() }
    );
  }
  verify(totp) {
    return axios.post(
      API_URL + "/auth/totp/verify/",
      { token: totp },
      { headers: identityHeader() }
    );
  }
  retrieve() {
    return axios.get(API_URL + "/auth/totp/", { headers: authHeader() });
  }
  reset() {
    return axios.post(
      API_URL + "/auth/totp/reset/",
      {},
      { headers: authHeader() }
    );
  }
}

class StaticTotpService {
  setup() {
    return axios.post(
      API_URL + "/auth/totp/static/setup/",
      {},
      { headers: authHeader() }
    );
  }
  verify(totp) {
    return axios.post(
      API_URL + "/auth/totp/static/verify/",
      { token: totp },
      { headers: identityHeader() }
    );
  }
  retrieve() {
    return axios.get(API_URL + "/auth/totp/static/", { headers: authHeader() });
  }
  reset() {
    return axios.post(
      API_URL + "/auth/totp/static/reset/",
      {},
      { headers: authHeader() }
    );
  }
}

export const Totp = new TotpService();
export const StaticTotp = new StaticTotpService();
