var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-data-table-method',_vm._g(_vm._b({ref:"my-custom-table",attrs:{"items-per-page":_vm.nbItemsPerPage ? _vm.nbItemsPerPage : _vm.nbItemsPerPageDefault,"header-props":{ sortIcon: 'mdi-chevron-down mdi-18px' },"loading-text":_vm.t('Loading...'),"no-data-text":_vm.noDataText ? _vm.noDataText : _vm.noDataTextDefault,"footer-props":{
    showCurrentPage: true,
    itemsPerPageText: _vm.t('Row per page'),
    itemsPerPageOptions: _vm.rowsPerPageItems
      ? _vm.rowsPerPageItems
      : _vm.rowsPerPageItemsDefault,
    itemsPerPageAllText: _vm.t('All')
  },"height":_vm.loading ? '' : _vm.height,"items":_vm.loading ? [] : _vm.items,"hide-default-footer":_vm.hideDefaultFooter
      ? _vm.hideDefaultFooter
      : (_vm.loading && _vm.items.length === 0) || _vm.items.length === 0},on:{"update:page":_vm.scrollBackTop},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@3, table-row@1"}})]},proxy:true},{key:`header.data-table-select`,fn:function({ props, on }){return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary"}},on))]}},{key:"item.data-table-select",fn:function({ isSelected, select }){return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"color":"primary"},on:{"input":function($event){return select($event)}}})]}},_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}}),{key:"footer.page-text",fn:function(props){return [(_vm.sortableColumn)?_c('bar-filter',{staticClass:"mr-6",attrs:{"items":_vm.filterSortableValue}}):_vm._e(),_vm._v(" "+_vm._s(props.pageStop)+" "+_vm._s(_vm.t("sur"))+" "+_vm._s(props.itemsLength)+" "+_vm._s(_vm.itemsName ? _vm.itemsName : _vm.t("Items"))+" ")]}}],null,true)},'custom-data-table-method',_vm.$props,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }