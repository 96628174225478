<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.878 3.95217C7.50665 3.95217 3.95217 7.49829 3.95217 11.878V12.122C3.95217 16.4933 7.49829 20.0478 11.878 20.0478H12.122C16.4926 20.0478 20.0478 16.4926 20.0478 12.122V11.878C20.0478 7.50666 16.5017 3.95217 12.122 3.95217H11.878ZM2 11.878C2 6.41875 6.42989 2 11.878 2H12.122C17.5813 2 22 6.4299 22 11.878V12.122C22 17.5708 17.5708 22 12.122 22H11.878C6.41874 22 2 17.5701 2 12.122V11.878Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6433 7.9858C12.6433 7.4454 13.0814 7.00732 13.6218 7.00732H16.0289C16.5692 7.00732 17.0073 7.4454 17.0073 7.9858V10.3928C17.0073 10.9332 16.5692 11.3713 16.0289 11.3713C15.4885 11.3713 15.0504 10.9332 15.0504 10.3928V10.348L13.707 11.6914C13.3249 12.0735 12.7054 12.0735 12.3233 11.6914C11.9412 11.3093 11.9412 10.6897 12.3233 10.3076L13.6666 8.96427H13.6218C13.0814 8.96427 12.6433 8.52619 12.6433 7.9858Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6914 13.707C12.0735 13.3249 12.0735 12.7054 11.6914 12.3233C11.3093 11.9411 10.6897 11.9411 10.3076 12.3233L8.96427 13.6666V13.6218C8.96427 13.0814 8.52619 12.6433 7.9858 12.6433C7.4454 12.6433 7.00732 13.0814 7.00732 13.6218V16.0288V16.0288C7.00732 16.5692 7.4454 17.0073 7.9858 17.0073H10.3928C10.9332 17.0073 11.3713 16.5692 11.3713 16.0288C11.3713 15.4884 10.9332 15.0504 10.3928 15.0504H10.348L11.6914 13.707Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.13 2H11.87C6.43 2 2 6.43 2 11.87V12.13C2 17.57 6.43 22 11.87 22H12.13C17.57 22 22 17.57 22 12.13V11.87C22 6.43 17.57 2 12.13 2ZM11.5 13.56L9.7 15.36H10.35C10.76 15.36 11.1 15.7 11.1 16.11C11.1 16.53 10.76 16.86 10.35 16.86H7.89C7.48 16.86 7.14 16.53 7.14 16.11V13.65C7.14 13.24 7.48 12.9 7.89 12.9C8.3 12.9 8.64 13.24 8.64 13.65V14.3L10.44 12.5C10.74 12.2 11.21 12.2 11.5 12.5C11.8 12.79 11.8 13.27 11.5 13.56ZM16.86 10.35C16.86 10.76 16.53 11.1 16.11 11.1C15.7 11.1 15.36 10.76 15.36 10.35V9.7L13.56 11.5C13.41 11.65 13.22 11.72 13.03 11.72C12.84 11.72 12.65 11.65 12.5 11.5C12.21 11.21 12.21 10.73 12.5 10.44L14.3 8.64H13.65C13.24 8.64 12.9 8.3 12.9 7.89C12.9 7.47 13.24 7.14 13.65 7.14H16.11C16.53 7.14 16.86 7.47 16.86 7.89V10.35Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "OpenCircle",
};
</script>
