import Indicators from "@/components/indicators/Indicators";
import IndicatorRouter from "@/components/indicators/IndicatorRouter";
import IndicatorDetail from "@/components/indicators/IndicatorDetail";
import IndicatorParameters from "@/components/indicators/tabs/parameters/IndicatorParameters";
import IndicatorDefinition from "@/components/indicators/tabs/parameters/definition/IndicatorDefinition";
import IndicatorResults from "@/components/indicators/tabs/IndicatorResults";

export const indicatorsUrls = [
  {
    path: "/indicators",
    component: IndicatorRouter,
    redirect: { name: "Indicators" },
    children: [
      {
        path: "",
        name: "Indicators",
        component: Indicators,
      },
      {
        path: ":indicatorId",
        name: "IndicatorDetail",
        component: IndicatorDetail,
        redirect: { name: "IndicatorParameters" },
        props: true,
        children: [
          {
            path: "parameters",
            name: "IndicatorParameters",
            component: IndicatorParameters,
            props: true,
          },
          {
            path: "definition",
            name: "IndicatorDefinition",
            component: IndicatorDefinition,
            props: true,
          },
          {
            path: "results",
            name: "IndicatorResults",
            component: IndicatorResults,
            props: true,
          },
        ],
      },
    ],
  },
];
