<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 11C5 7.68611 7.68611 5 11 5C14.3139 5 17 7.68611 17 11C17 14.3139 14.3139 17 11 17C7.68611 17 5 14.3139 5 11ZM11 3C6.58154 3 3 6.58154 3 11C3 15.4185 6.58154 19 11 19C12.8488 19 14.551 18.373 15.9057 17.3199L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L17.3199 15.9057C18.373 14.551 19 12.8488 19 11C19 6.58154 15.4185 3 11 3Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7122 7.3877C10.2247 7.3877 7.38721 10.2252 7.38721 13.7127C7.38721 17.2002 10.2247 20.0502 13.7122 20.0502C17.2122 20.0502 20.0497 17.2002 20.0497 13.7127C20.0497 10.2252 17.2122 7.3877 13.7122 7.3877Z"
      :fill="$parent.getColor"
    />
    <path
      d="M27.225 25.9L22.275 20.95C23.925 18.9875 24.925 16.475 24.925 13.7125C24.925 7.5375 19.9 2.5 13.7125 2.5C7.5375 2.5 2.5 7.5375 2.5 13.7125C2.5 19.9 7.5375 24.925 13.7125 24.925C16.475 24.925 19 23.925 20.95 22.275L25.9 27.225C26.0875 27.4125 26.325 27.5 26.5625 27.5C26.8 27.5 27.0375 27.4125 27.225 27.225C27.5875 26.8625 27.5875 26.2625 27.225 25.9ZM13.7125 21.925C9.1875 21.925 5.5125 18.2375 5.5125 13.7125C5.5125 9.1875 9.1875 5.5125 13.7125 5.5125C18.2375 5.5125 21.925 9.1875 21.925 13.7125C21.925 18.2375 18.2375 21.925 13.7125 21.925Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Search",
};
</script>
