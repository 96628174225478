<template>
  <div>
    <v-tabs :class="navbarClass" :show-arrows="true">
      <template v-for="(item, index) in items">
        <v-tab :key="index" :to="item.to" :disabled="item.disabled || false">
          {{ item.name }}
          <badge
            v-if="item.wip"
            text="WIP"
            class="unclickable ml-1"
            type="warning"
          />
        </v-tab>
      </template>
    </v-tabs>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";

export default {
  name: "NavigationDrawer",
  mixins: [i18n],
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },

    navbarClass: {
      type: String,
      default: "subnavbar border-bottom-b-50",
    },
  },

  data() {
    return {};
  },

  computed: {},
};
</script>
