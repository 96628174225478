<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1071 2.29289C17.7166 1.90237 17.0834 1.90237 16.6929 2.29289L2.30262 16.6832C2.11574 16.87 2.01044 17.1233 2.00973 17.3876L2 20.9973C1.99929 21.2635 2.1047 21.5189 2.29289 21.7071C2.48109 21.8953 2.73654 22.0007 3.00269 22L6.61243 21.9903C6.87671 21.9896 7.12997 21.8843 7.31684 21.6974L21.7071 7.3071C22.0976 6.91658 22.0976 6.28341 21.7071 5.89289L18.1071 2.29289ZM4.0027 19.9973L4.00861 17.8056L14.7 7.11421L16.8858 9.3L6.19441 19.9914L4.0027 19.9973ZM18.3 7.88579L19.5858 6.6L17.4 4.41421L16.1142 5.7L18.3 7.88579Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.78 5.92L18.08 2.22C17.94 2.08 17.75 2 17.55 2C17.35 2 17.16 2.08 17.02 2.22L2.23 17.01C2.09 17.15 2.01999 17.33 2.00999 17.53L2 21.25C2 21.45 2.08 21.64 2.22 21.78C2.36 21.92 2.55 22 2.75 22L6.47 21.99C6.67 21.99 6.86001 21.91 6.99001 21.77L21.78 6.98C22.07 6.69 22.07 6.21 21.78 5.92ZM18.45 8.19C18.43 8.17 18.43 8.14 18.4 8.12L15.88 5.60001C15.86 5.58001 15.83 5.57 15.81 5.55L17.55 3.81L20.19 6.45L18.45 8.19Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "Pencil",
};
</script>
