<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5V3ZM18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L17.7071 7.70711C17.3166 8.09763 16.6834 8.09763 16.2929 7.70711C15.9024 7.31658 15.9024 6.68342 16.2929 6.29289L18.2929 4.29289ZM9.70711 7.29289C9.31658 6.90237 8.68342 6.90237 8.29289 7.29289C7.90237 7.68342 7.90237 8.31658 8.29289 8.70711L8.64715 9.06137L4.23178 14.3598C4.22649 14.3662 4.22129 14.3726 4.21618 14.379L2.75878 15.8364C1.74707 16.8481 1.74707 18.4789 2.75877 19.4906L4.50699 21.2388C5.5137 22.2577 7.15589 22.2489 8.16358 21.2412L9.49528 19.9095L11.2929 21.7071C11.6834 22.0976 12.3166 22.0976 12.7071 21.7071C13.0976 21.3166 13.0976 20.6834 12.7071 20.2929L11.0255 18.6113L14.9384 15.3526L15.2929 15.7071C15.6834 16.0976 16.3166 16.0976 16.7071 15.7071C17.0976 15.3166 17.0976 14.6834 16.7071 14.2929L15.7083 13.2941L15.7071 13.2929L14.5288 12.1146C14.9727 11.3574 14.8422 10.383 14.2063 9.75477C13.5704 9.1266 12.6086 9.0081 11.8527 9.43852L10.7071 8.29289L10.7054 8.29116L9.70711 7.29289ZM6.35713 14.9335L9.065 17.6414L13.5183 13.9326L12.8672 13.2814L10.7483 11.1626L10.0672 10.4814L6.35713 14.9335ZM4.17299 17.2506L5.00436 16.4193L7.57952 18.9968L6.74936 19.827C6.5157 20.0607 6.14603 20.0528 5.92895 19.8324L5.92897 19.8324L5.92358 19.827L4.17299 18.0764C3.94234 17.8458 3.94234 17.4813 4.17299 17.2506ZM19 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H19C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ColumnTop",
};
</script>
