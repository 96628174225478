<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.25 2H5.75C3.67893 2 2 3.67893 2 5.75L2 18.25C2 20.3211 3.67893 22 5.75 22H18.25C20.3211 22 22 20.3211 22 18.25V5.75C22 3.67893 20.3211 2 18.25 2ZM10.4748 12L15.0981 7.73501C15.504 7.36053 15.5295 6.72788 15.1551 6.32194C14.7806 5.91601 14.1479 5.8905 13.742 6.26498L8.3301 11.2575C8.31799 11.2684 8.3061 11.2797 8.29443 11.2913C8.20998 11.3753 8.14354 11.471 8.09531 11.5733C8.03158 11.7083 7.99996 11.8543 8 12C8.00006 12.2576 8.09903 12.5144 8.29443 12.7087C8.3061 12.7203 8.31799 12.7316 8.3301 12.7425L13.742 17.735C14.1479 18.1095 14.7806 18.084 15.1551 17.6781C15.5295 17.2721 15.504 16.6395 15.0981 16.265L10.4748 12Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CaretLeft",
};
</script>
