<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 7C9 5.16379 10.3099 4 12 4C13.6901 4 15 5.16379 15 7V9H9V7ZM7 9.00055V7C7 3.98743 9.27848 2 12 2C14.7215 2 17 3.98743 17 7V9.00055C19.2926 9.0388 21 11.0635 21 13.3715V17.6285C21 19.9436 19.2734 22 16.9339 22H7.06611C4.72859 22 3 19.9565 3 17.6285V13.3715C3 11.0782 4.69419 9.03886 7 9.00055ZM8 11H7.06611C6.00957 11 5 11.9737 5 13.3715V17.6285C5 19.0348 6.00762 20 7.06611 20H16.9339C17.9904 20 19 19.0263 19 17.6285V13.3715C19 11.9673 17.9846 11 16.9339 11H16H8ZM13 16.7324C13.5978 16.3866 14 15.7403 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 15.7403 10.4022 16.3866 11 16.7324V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16.7324Z"
      :fill="$parent.getColor"
    />
  </svg>

  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 7C9 5.16379 10.3099 4 12 4C13.6901 4 15 5.16379 15 7V9H9V7ZM7 9.00055V7C7 3.98743 9.27848 2 12 2C14.7215 2 17 3.98743 17 7V9.00055C19.2926 9.0388 21 11.0635 21 13.3715V17.6285C21 19.9436 19.2734 22 16.9339 22H7.06611C4.72859 22 3 19.9565 3 17.6285V13.3715C3 11.0782 4.69419 9.03886 7 9.00055ZM12 13C10.8954 13 10 13.8954 10 15C10 15.7403 10.4022 16.3866 11 16.7324V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16.7324C13.5978 16.3866 14 15.7403 14 15C14 13.8954 13.1046 13 12 13Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "LockClose",
};
</script>
