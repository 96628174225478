<template>
  <div class="d-flex align-center">
    <span
      class="d-inline-flex my-auto text-caption border rounded px-2 font-demi-bold padding-badge"
      :class="customClass"
    >
      <icon
        v-if="prependIcon"
        class="mr-1"
        :name="prependIcon.name"
        :color="prependIcon.color"
        :size="prependIcon.size"
        :outline="prependIcon.outline"
      />
      <span :class="wrap ? 'text-wrap' : 'text-nowrap'" v-html="text" />
      <button v-if="close" type="button" @click.stop="$emit('close')">
        <icon
          name="close"
          outline
          width="12"
          height="12"
          class="my-auto clickable ml-1"
          :color="customClass.split(' ').pop()"
        />
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    text: {
      type: [String, Number],
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: "primary",
    },

    close: {
      type: Boolean,
      required: false,
      default: false,
    },

    prependIcon: {
      type: Object,
      required: false,
      default: null,
    },

    wrap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      customClass: null,
    };
  },

  created() {
    this.customClass = this.getClass();
  },

  methods: {
    getClass() {
      switch (this.type) {
        case "primary":
          return "bg-primary-secondary b-primary-border primary-main";
        case "outlined":
          return "bg-basics-10 b-basics-50 basics-100";
        case "danger":
          return "bg-danger-surface b-danger-border danger-pressed";
        case "warning":
          return "bg-warning-surface b-warning-border warning-pressed";
        case "success":
          return "bg-success-surface b-success-border success-pressed";
        case "info":
          return "bg-info b-info basics-10";
        case "green-clear":
          return "bg-green-clear b-green-clear basics-10";
      }
    },
  },
};
</script>

<style>
.padding-badge {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
</style>
