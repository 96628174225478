import { TextValueEnum } from "@/utils/enums";

/**
 * List of errors returns from api
 *
 * @type Object.<string,TextValueEnum>
 */
export const Errors = {
  /* Basics errors */
  NO_ACTIVE_ACCOUNT: new TextValueEnum(
    "no_active_account",
    "Identifiant ou mot de passe invalide"
  ),
  UNAUTHORIZED: new TextValueEnum(
    "unauthorized",
    "Accès refusé: problème d'identification"
  ),
  FORBIDDEN: new TextValueEnum(
    "forbidden",
    "Accès refusé: vous n'avez pas les droits"
  ),
  NOT_FOUND: new TextValueEnum(
    "not_found",
    "La ressource demandée n'a pas été trouvée"
  ),
  TOO_MANY_REQUESTS: new TextValueEnum(
    "too_many_requests",
    "Vous avez envoyé trop de requêtes, veuillez patienter"
  ),
};
