<template>
  <div class="d-inline-flex">
    <v-btn
      v-for="(item, index) in allItems"
      :key="item.text"
      class="Z-3 py-1 sorting-button br-0 text-body-1"
      :disabled="item.disabled"
      :class="[
        item.value === selected
          ? 'selected bg-primary-main basics-10'
          : 'bg-basics-10 basics-100 tw-cursor-pointer',
        { 'mr-01': index + 1 !== allItems.length },
        { 'br-l-4': index === 0 },
        { 'br-r-4': index + 1 === allItems.length },
        { disabled: item.disabled }
      ]"
      @click="
        selected = item.value;
        $emit('sort', item.value);
      "
    >
      {{ t(item.text) }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BarFilter",

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      allItems: null,
      selected: "*",
    };
  },

  created() {
    this.allItems = this.items;
    this.allItems.unshift({ text: this.t("Tout"), value: "*" });
  },
};
</script>

<style>
.mr-01 {
  margin-right: 1px;
}
</style>
