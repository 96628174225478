<template>
  <svg
    v-if="$parent.outline"
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.878 3.95217C7.50666 3.95217 3.95217 7.49829 3.95217 11.878V12.122C3.95217 16.4933 7.49829 20.0478 11.878 20.0478H12.122C16.4933 20.0478 20.0478 16.5017 20.0478 12.122V11.878C20.0478 7.50735 16.4926 3.95217 12.122 3.95217H11.878ZM2 11.878C2 6.41874 6.42989 2 11.878 2H12.122C17.5708 2 22 6.4292 22 11.878V12.122C22 17.5813 17.5701 22 12.122 22H11.878C6.41874 22 2 17.5701 2 12.122V11.878Z"
      :fill="$parent.getColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2879 7.28893L8.79687 9.72422C8.40217 10.1101 8.40088 10.737 8.79398 11.1244C9.18708 11.5119 9.82572 11.5131 10.2204 11.1273L10.9914 10.3736V15.0099C10.9914 15.5567 11.4429 16 12 16C12.5571 16 13.0086 15.5567 13.0086 15.0099V10.3736L13.7796 11.1273C14.1743 11.5131 14.8129 11.5119 15.206 11.1244C15.5991 10.737 15.5978 10.1101 15.2031 9.72422L12.7123 7.2891L12.7118 7.28857C12.3179 6.90353 11.6812 6.90397 11.2879 7.28893Z"
      :fill="$parent.getColor"
    />
  </svg>
  <svg
    v-else
    :width="$parent.size"
    :height="$parent.size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.13 2H11.87C9.25313 2.00265 6.74419 3.04337 4.89378 4.89378C3.04337 6.74419 2.00265 9.25313 2 11.87L2 12.13C2.00265 14.7469 3.04337 17.2558 4.89378 19.1062C6.74419 20.9566 9.25313 21.9974 11.87 22H12.13C14.7469 21.9974 17.2558 20.9566 19.1062 19.1062C20.9566 17.2558 21.9974 14.7469 22 12.13V11.87C21.9974 9.25313 20.9566 6.74419 19.1062 4.89378C17.2558 3.04337 14.7469 2.00265 12.13 2V2ZM15 11.9C14.9308 11.9701 14.8482 12.0257 14.7572 12.0635C14.6662 12.1012 14.5685 12.1205 14.47 12.12C14.3712 12.1222 14.273 12.1038 14.1818 12.0659C14.0905 12.028 14.0082 11.9715 13.94 11.9L12.75 10.71V15.09C12.75 15.2889 12.671 15.4797 12.5303 15.6203C12.3897 15.761 12.1989 15.84 12 15.84C11.8011 15.84 11.6103 15.761 11.4697 15.6203C11.329 15.4797 11.25 15.2889 11.25 15.09V10.71L10.06 11.9C9.99183 11.9715 9.90948 12.028 9.81822 12.0659C9.72695 12.1038 9.62879 12.1222 9.53 12.12C9.43146 12.1205 9.33382 12.1012 9.24281 12.0635C9.15179 12.0257 9.06924 11.9701 9 11.9C8.85955 11.7594 8.78066 11.5688 8.78066 11.37C8.78066 11.1712 8.85955 10.9806 9 10.84L11.47 8.38C11.5389 8.30924 11.6214 8.25301 11.7124 8.21461C11.8034 8.17621 11.9012 8.15643 12 8.15643C12.0988 8.15643 12.1966 8.17621 12.2876 8.21461C12.3786 8.25301 12.4611 8.30924 12.53 8.38L15 10.84C15.1405 10.9806 15.2193 11.1712 15.2193 11.37C15.2193 11.5688 15.1405 11.7594 15 11.9Z"
      :fill="$parent.getColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowUpCircle",
};
</script>
